import React, {useEffect} from "react";
import "./MyOrderPageModals.css";


interface ModalProps {
    onClose: () => void;
}

export function MyOrderSmallModal({onClose}: ModalProps) {
    useEffect(()=>{
        setTimeout(()=>onClose(),6000);
    },[])

    return (
        <>
            <div id="mop-smallM-close-wrapper" onClick={onClose} />
            <div id="mop-smallM-wrapper">
                <div id="mop-smallM-container">
                    <div className="mop-smallM-notification-img">
                        <img id={"mop-smallM-notification-pic"} src="/Pictures/non-notification.svg" alt=""/>
                        <img id={"mop-smallM-notification-pic-circle"} src="/Pictures/Ellipse 87.svg" alt=""/>
                    </div>
                    <span id="mop-smallM-text">
                        Позиция отменена, ваш заказ заморожен, пока поставщик не увидит отмену
                    </span>
                </div>
            </div>
        </>
    )
}