import {IDropdownOption} from "../../models/models";

export const TimeTo:IDropdownOption[] = [
    {key:'9:00',name:'9:00'},
    {key:'10:00',name:'10:00'},
    {key:'11:00',name:'11:00'},
    {key:'12:00',name:'12:00'},
    {key:'13:00',name:'13:00'},
    {key:'14:00',name:'14:00'},
    {key:'15:00',name:'15:00'},
    {key:'16:00',name:'16:00'},
    {key:'17:00',name:'17:00'},
    {key:'18:00',name:'18:00'},
    {key:'19:00',name:'19:00'},
    {key:'20:00',name:'20:00'},
    {key:'21:00',name:'21:00'},
]