import React, {useContext, useEffect, useRef, useState} from 'react';
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IReklama} from "../../../../models/models";
import {Context} from "../../../../index";
import AdminService from "../../../../services/AdminService";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import PreviewReklama from "../../../customUI/PreviewReklama/PreviewReklama";

interface IReklamaItems {
    reklama: IReklama,
    openProps: boolean,
    updateData: () => void
}

const ModerationReklamaItem = ({reklama, openProps, updateData}: IReklamaItems) => {
    const [reklamaData, setReklamaData] = useState<IReklama>(reklama)
    const [reklamaMainPreview, setReklamaMainPreview] = useState<string | undefined>()
    const [reklamaMiniPreview, setReklamaMiniPreview] = useState<string | undefined>()

    //const [open, setOpen] = useState(false)
    let open = false
    const [previewReklama, setPreviewReklama] = useState<JSX.Element | null>(null)

    const {store} = useContext(Context)

    const navigate = useNavigate()

    useEffect(() => {
        setReklamaData(reklama)
        setReklamaMainPreview(reklama.imageBigUrl ? store.imgServer + reklama.imageBigUrl : undefined)
        setReklamaMiniPreview(reklama.imageSmallUrl ? store.imgServer + reklama.imageSmallUrl : undefined)
    }, [reklama])

    const RejectReklama = () => {
        if (reklama && reklama.reklamaId) {
            store.DataLoadingON()
            AdminService.rejectEntertainment(reklama.reklamaId)
                .then(() => {
                    alert("Реклама была отклонена")
                    updateData()
                })
                .catch(() => alert("Что-то пошло не так"))
                .finally(() => store.DataLoadingOFF())
        }
    }

    const ApproveReklama = () => {
        if (reklama && reklama.reklamaId) {
            store.DataLoadingON()
            AdminService.approveEntertainment(reklama.reklamaId)
                .then(() => {
                    alert("Реклама была одобрена")
                    updateData()
                })
                .catch(() => alert("Что-то пошло не так"))
                .finally(() => store.DataLoadingOFF())
        }
    }


    return (
        <>
            {previewReklama && previewReklama}
            <details className={"moderationReklama-reklama-container"} open={open || openProps}>
                <summary className={"moderationReklama-reklama-header"} onClick={() => open = !open}>
                    <div>{reklama.name? reklama.name : "Нет названия"}, Поставщик: {reklama.owner?.displayedName}</div>
                </summary>
                <div className={"moderationReklama-reklama"}>
                    <div className={"moderationReklama-reklama-images"}>
                        <div className={"moderationReklama-reklama-preview"}>
                            <div className={"moderationReklama-reklama-hint"}>
                                Слайд для компьютера
                            </div>
                            <div
                                className={"moderationReklama-reklama-photo-image-desktop"}
                                style={{aspectRatio: reklama.type === 0 ? "1600/500" : "1800/150"}}
                            >
                                {reklamaMainPreview ?
                                    <img src={reklamaMainPreview}
                                         style={{aspectRatio: reklama.type === 0 ? "1600/500" : "1800/150"}}/>
                                    :
                                    <div className={"moderationReklama-reklama-image-text"}>Фото</div>
                                }
                            </div>
                            <div className={"moderationReklama-reklama-preview-buttons"}>
                                <button onClick={() => {
                                    if (reklama.imageBigUrl) {
                                        setPreviewReklama
                                        (<PreviewReklama key={0} reklamaImg={store.imgServer + reklama.imageBigUrl}
                                                         typePreview={reklama.type}
                                                         typeDevice={"desktop"}
                                                         description={reklama.description}
                                                         linkName={reklama.linkName}
                                                         link={reklama.link}
                                                         onClose={() => setPreviewReklama(null)}
                                        />)
                                    } else alert("Нет картинки")
                                }}>
                                    Предпросмотр
                                </button>
                            </div>
                        </div>
                        <div className={"moderationReklama-reklama-preview"}>
                            <div className={"moderationReklama-reklama-hint"}>
                                Слайд для телефона
                            </div>
                            <div
                                data-type={reklama.type}
                                className={"moderationReklama-reklama-photo-image-mobile"}
                                style={{aspectRatio: reklama.type === 0 ? "390/200" : "600/50"}}
                            >
                                {reklamaMiniPreview ?
                                    <img src={reklamaMiniPreview}
                                         style={{aspectRatio: reklama.type === 0 ? "390/200" : "600/50"}}/>
                                    :
                                    <div className={"moderationReklama-reklama-image-text"}>Фото</div>
                                }
                            </div>
                            <div className={"moderationReklama-reklama-preview-buttons"}>
                                <button onClick={() => {
                                    if (reklama.imageSmallUrl) {
                                        setPreviewReklama
                                        (<PreviewReklama key={0} reklamaImg={store.imgServer + reklama.imageSmallUrl}
                                                         typePreview={reklama.type}
                                                         typeDevice={"mobile"}
                                                         description={reklama.description}
                                                         linkName={reklama.linkName}
                                                         link={reklama.link}
                                                         onClose={() => setPreviewReklama(null)}
                                        />)
                                    } else alert("Нет картинки")
                                }}>
                                    Предпросмотр
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"moderationReklama-reklama-settings"}>
                        <div className={"moderationReklama-reklama-setting"}>
                            <div className={"moderationReklama-reklama-hint"}>
                                Название
                            </div>
                            <CustomInput name={"name"} inputSize={"small"}
                                         value={reklamaData.name}
                                         onChange={(e) => setReklamaData({...reklamaData, name: e.target.value})}
                                         type={"text"}
                                         disabled={reklama.status === 1}
                            />
                        </div>
                        <div className={"moderationReklama-reklama-setting"}>
                            <div className={"moderationReklama-reklama-hint"}>
                                Описание
                            </div>
                            <textarea value={reklamaData.description ? reklamaData.description : undefined}
                                      onChange={(e) => {
                                          setReklamaData({...reklamaData, description: e.target.value})
                                      }}
                                      disabled={reklama.status === 1}
                            />
                        </div>
                        <div className={"moderationReklama-reklama-setting"}>
                            <div className={"moderationReklama-reklama-hint"}>
                                Название URL
                            </div>
                            <CustomInput name={"urlName"} inputSize={"small"}
                                         value={reklamaData.linkName}
                                         type={"text"}
                                         disabled={true}
                            />
                        </div>
                        <div className={"moderationReklama-reklama-setting"}>
                            <div className={"moderationReklama-reklama-hint"}>
                                URL
                            </div>
                            <CustomInput name={"URL"} inputSize={"small"}
                                         value={reklamaData.link}
                                         type={"text"}
                                         disabled={true}
                            />
                        </div>
                        <div className={"moderationReklama-reklama-settings-buttons"}>
                            <button onClick={() => {
                                navigate(SpaRoutes.ADMIN_CHAT + `/:${reklama.ownerId}/:${1}/:${0}`)
                            }}>
                                Перейти в чат
                            </button>
                            <button onClick={RejectReklama}>
                                Отклонить
                            </button>
                            <button onClick={ApproveReklama}>
                                Одобрить рекламу
                            </button>
                        </div>
                    </div>
                </div>
            </details>
        </>
    );
};

export default ModerationReklamaItem;