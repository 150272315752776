import React from "react";
import './register.css'

interface Interface {
    title:string
}

export const InvalidFeedback =({title}:Interface)=>{
    return(
        <div className="invalid-feedback">{title}</div>
    )
}