import React, {useContext, useRef, useState} from "react";
import {Context} from "../../../../index";
import '../ChatWindowItem/ChatWindowItem.css'
import './MessageEditPopup.css'
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface Interface {
    id: string;
    text: string;
    onUpdate: (arg0: string) => void;
    onDelete: () => void;
    onCancel: () => void;
}


function MessageEditPopup(props: Interface) {
    const {store} = useContext(Context)
    const [message, setMessage] = useState(props.text);
    const refPopup = useRef(null)
    const onMessageUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    };

    useOnClickOutside(refPopup, () => {
        props.onCancel()
    })

    return (
        <div className={'message userMessage'}>
            <div className={'message_span message_popup_border'} ref={refPopup}>

                <div className={'chat-buttonBox'}>
                    <div>
                        <div className={'chat_update_button'}
                             onClick={() => {
                                 props.onUpdate(message)
                             }}
                        >
                            <img src="/Pictures/checkbox.svg"/>
                        </div>
                        <div className={'chat_delete_button'} onClick={props.onDelete}>
                            <img src="/Pictures/trashRed.svg"/>
                        </div>
                    </div>

                    <textarea
                        className="message message-area-popup"
                        placeholder="Сообщение"
                        id="message"
                        name="message"
                        value={message}
                        onChange={onMessageUpdate}
                        autoComplete="off"
                    />
                </div>

            </div>
        </div>

    );
}

export default MessageEditPopup;
