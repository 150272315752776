import {Link} from "react-router-dom";
import {burger_menu} from "../../../../../data/UserBurgerMenu/burger_menu";
import React from "react";

interface Interface {
    link:string;
    handler:()=>void;
    title:string;
}

export const BurgerItem = ({link, handler, title}:Interface) => {
  return(
      <li>
          <Link
              className="a-link"
              to={link}
              onClick={handler}
          >
              {title}
          </Link>
      </li>
  )
}