import React, {useEffect, useRef, useState} from "react";
import {ISupplierAdress} from "../../../models/supplier/supplier";
import {ICity, IDropdownOption, IRegion} from "../../../models/models";
import "./CustomDropdown.css";
import {event} from "jquery";
import {ITimeDropdown} from "../../../models/OrderFormation/IOrderFormation";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

interface Interface {
    importantId?:boolean;
    options: IDropdownOption[];
    selectedOptions: IDropdownOption[];
    displayedName: string ;
    onChangeHandler:(val:any)=>any;
    dropdownSize?: "small" | "medium" | "large";
    styleDropdownDisplay?:string;
    styleDropdownList1?:string;
    styleDropdownList2?:string;
    icon?: boolean;
    error?: boolean;
    errorMsg?: [string] | any;
    disabled?: boolean;
    isRemoved?: boolean;
    closeAfterSelection?:boolean;
}

const MultiSelectDropDown = ({importantId, options, selectedOptions ,displayedName, onChangeHandler,
                                 styleDropdownDisplay = "", icon, error,dropdownSize='small',
                                 errorMsg,styleDropdownList1='',styleDropdownList2='', disabled=false,
                                 closeAfterSelection}:Interface) => {
    const [hideMe, setHideMe] = useState("true");
    const listRef = useRef(null);

    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => setHideMe("true"));

    const dropdownHandler = (val:any)=> {
        if (closeAfterSelection && closeAfterSelection===true){
            setHideMe("true");
        }
        if (val.key) onChangeHandler(val.key);
    }


    const clickHandler = (event: any) => {
        if (!disabled) {
            let dropdowns = document.getElementsByClassName("DropdownDisplay")
            Array.prototype.forEach.call(dropdowns, function (item) {
                if (event?.currentTarget.identifier != item && item.dataset["hideme"] === "false") {
                    item.click();
                }
            })
            if (options.length > 0) {
                hideMe == "true" ? setHideMe("false") : setHideMe("true");
            } else {
                setHideMe("true");
            }
        }
    }

    const ListDropdown = options.map((item) => {
        return (
            <div className={item.notBlocked?"":"dropdown-item-name-grey"} style={item.notBlocked? {display:"flex"}:{display:"flex",fontWeight:"400"}}>
                {selectedOptions.some((opt)=>{return opt.key===item.key})?
                    <>
                        <img onClick={() => dropdownHandler(item)} style={{width:"20px",height:"20px",padding:"2px 0px 0px 10px"}} src={"/Pictures/checkbox.svg"}/>
                    </>:
                    <>
                        <img onClick={() => dropdownHandler(item)} style={{width:"20px",height:"20px",padding:"2px 0px 0px 10px"}} src={"/Pictures/checkbox-empty.png"}/>
                    </>
                }
                <div
                    className={"dropdown-item-name" + styleDropdownList2 }
                    onClick={() => dropdownHandler(item)}
                >
                    {item.name}
                </div>
            </div>
        )
    })

    return (
        <div className={"dropdown-internal-container"} ref={ref}>
            <div className={"DropdownDisplay " + styleDropdownDisplay} onClick={clickHandler} data-hideMe={hideMe}
                 data-size={dropdownSize}
                 style={{
                     borderColor: error ? "#db2020" : "",
                     paddingRight: icon ? error ? "75px" : "44px" : "21px"
                 }}>
                <p className={"DropdownDisplayText"}>
                    {displayedName}
                </p>
                {error &&
                    <div className={"dropDown-error"}>
                        <img
                            className={"dropDown-error-image"}
                            src={"/Pictures/errorIcon.svg"}
                        />
                        <div className={"dropDown-error-text"}>
                            {errorMsg.map((e: any) => <div>{e}</div>)}
                        </div>
                    </div>
                }

                {icon &&
                    <img className={"dropDown-image"}
                         data-hide={hideMe}
                         src={"/Pictures/dropDownIcon.svg"}
                    />
                }
            </div>
            <div className={"dropdown-list-div" + styleDropdownList1} data-hideMe={hideMe}
                 data-size={dropdownSize}>
                <div className={"dropdown-list-div-content"}>
                    {ListDropdown}
                </div>
            </div>
        </div>
    )
}
export default MultiSelectDropDown;