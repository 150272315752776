import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../../index";
import {useNavigate, useParams} from "react-router-dom";
import {ICard} from "../../../models/product/productCard";
import HomeService from "../../../services/HomeService";
import {ToogleVisible2} from "../../customUI/Searchbar/functions";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Searchbar from "../../customUI/Searchbar/Searchbar";
import FilterForCatalog from "./FilterForCatalog";
import NoProductsPage from "../../customUI/NoProductsPage/NoProductsPage";
import ProductCard from "../../customUI/ProductCard/ProductCard";

const RelatedProducts = () => {
    const {store} = useContext(Context)
    let {id} = useParams()
    const [subCatObj, setsubCatObj] = useState<any>();
    const [itemArray, setitemArray] = useState<ICard[]>([]);
    const [nothingShown, setNothingShown] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState(false)
    const navigate = useNavigate()
    const [searchword, setSearchword] = useState<string>("");

    const getData = () => {
        if (itemArray.length === 0) {
            store.DataLoadingON()
            let filter = {
                topId: "-1",
                topCatName: "",
                subId: "id",
                subCatName: "",
                selectedIds: []
            }
            HomeService.catalog(id, filter).then(response => {
                setsubCatObj(response.data[0]);
                let cards = (response.data[0].cards.map((card: ICard) => {
                        card.visible = [true];
                        card.content = {Name: card.name_of_product, Id: card.productId, Seller: card.supply_company};
                        return card;
                    }
                ));
                setitemArray(cards)
            })
                .finally(() => {
                    store.DataLoadingOFF()
                    setDataLoaded(true)
                })
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        setitemArray(ToogleVisible2(searchword, ["Name", "Id", "Seller"], ["", "", ""], itemArray))
    }, [searchword])

    const changeHandler = (text: string) => {
        setSearchword(text)
        navigate(SpaRoutes.RELATED_PRODUCTS + `/${id}`)
    }

    return (
        <div id={"CatSelect"}>
            <div id={"CatSelectBox"}>
                <CustomNavigateHeader
                    trashClickHandler ={() => {}}
                    title={subCatObj && subCatObj.catName}
                    withTrash={false}
                    onClick={() => navigate(-1)}
                />
                <div className={"catalog_searchBox"}>
                    <Searchbar
                        changeHandler={changeHandler}
                        initialText={"Искать"}
                        value={searchword}
                    />
                </div>
            </div>

            {nothingShown ?
                <NoProductsPage
                    tittle={"Не смогли ничего для вас найти"}
                    text={"Не нашли по вашему запросу ни одного продукта, попробуйте изменить параметры фильтра"}
                />
                : <div id={"card-container"}>

                    {itemArray && itemArray.map((c, i) => {
                        if(c.visible && c.visible[0]){
                            return(
                                <ProductCard
                                    isAuth={store.roles.includes('Customer')}
                                    key={itemArray[i].productId}
                                    item={itemArray[i]}
                                    // style={"product_wrap"}
                                />
                            )
                        }
                    })}
                </div>}
        </div>
    )
};

export default RelatedProducts;