import './index.css';
import {BrowserRouter, HashRouter} from "react-router-dom";
import {ModalState} from "./context/ModalContext";
import UserLayout from "./components/Layout/UserLayout/UserLayout";
import App from "./App";
import ReactDOM from 'react-dom';
import React, {createContext, useEffect, useState} from 'react';
import Store from './store/store'
import SellerLayout from "./components/Layout/SellerLayout/SellerLayout";
import Layout from "./components/Layout/Layout";
import './font/Password.otf'
import {Loader} from "./components/customUI/Loader/Loader";
import ContextProviderContainer from "./ContextProviderContainer";

interface IStore {
    store:Store;
}

const store = new Store();

export const Context = createContext<IStore>({
    store,
})

ReactDOM.render(
        // <HashRouter>
    <HashRouter>
            <ModalState>
                <div className={"wrapper"}>
                    <div className={"content"}>
                        <Layout/>
                    </div>
                    {/*<Footer/>*/}
                </div>
            </ModalState>
    </HashRouter>
    ,
    document.getElementById('root')
);