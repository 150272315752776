import React, {useContext, useEffect, useState} from "react";
import Carousel from "./Carousel/Carousel";
import CustomSlide from "./Carousel/CustomSlide";
import './UserHome.css'
import CustomSwiper from "../../customUI/CustomSwiper/CustomSwiper";
import Advertising from "./Advertising/Advertising";
import Menu from "../../Layout/UserLayout/MenuSticky/Menu/Menu";
import {Context} from "../../../index";
import {strReplace} from "../../../functions/functions";
import {ICard} from "../../../models/product/productCard";
import UserMenuAuth from "../../Layout/UserLayout/MenuSticky/UserMenuAuth/UserMenuAuth";
import UserMenu from "../../Layout/UserLayout/MenuSticky/UserMenu/UserMenu";
import Layout from "../../Layout/UserLayout/UserLayout";
import {MenuSticky} from "../../Layout/UserLayout/MenuSticky/MenuSticky";
import HomeService from "../../../services/HomeService";
import {observer} from "mobx-react-lite";
import {Loader} from "../../customUI/Loader/Loader";
import PreviewReklama from "../../customUI/PreviewReklama/PreviewReklama";

interface IitemArray {
    catName: string;
    catId: number;
    sorter: number;
    cards: ICard[];
    isAuth: boolean;
}

interface IReklama {
    header?: string;
    text?: string;
    url1: string;
    url2?: string;
    desktopImageUrl?: string,
    mobileImageUrl?: string,
    description?: string,
    linkName?: string,
    link?: string,
    type?: number
}

function UserHome() {
    const [itemArray, setitemArray] = useState<IitemArray[]>([]);
    const [CarSlide, setCarSlide] = useState<IReklama[]>([]);
    const [reklama, setReklama] = useState<IReklama>();

    const [previewReklama, setPreviewReklama] = useState<JSX.Element | null>(null)

    const widthWindow = window.innerWidth
    const {store} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        store.DataLoadingON()
        HomeService.indexProduct()
            .then(data => {
                let sortedData = data.data.sort((a: any, b: any) => {
                    if (a.sorter < b.sorter) return -1
                    if (a.sorter > b.sorter) return 1
                    return 0
                })
                setitemArray(sortedData);
            })
            .then(() => {
                HomeService.mainPageReklama()
                    .then((data) => {
                        if (data.data.slider)
                            setCarSlide(data.data.slider);
                        if (data.data.reklama)
                            setReklama(data.data.reklama);
                    })
                /*HomeService.indexSlider()
                    .then(data => {

                    })*/

            })
            /*.then(()=>{
                /!*HomeService.indexReklama()
                    .then(data => {

                    })*!/

            })*/
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF()
            })
    }, [store.regionId])


    const ListSlides = CarSlide.map((item: IReklama, key) => {
        return (
            <CustomSlide
                key={key}
                img1={store.imgServer + strReplace(item.desktopImageUrl)}
                img2={store.imgServer + strReplace(item.mobileImageUrl)}
                onClick={() => {
                    if (item && item.desktopImageUrl && item.mobileImageUrl){
                        setPreviewReklama(<PreviewReklama key={0} reklamaImg={store.imgServer + (widthWindow > 450? item.desktopImageUrl : item.mobileImageUrl)}
                                         typePreview={0}
                                         typeDevice={widthWindow > 450? "desktop" : "mobile"}
                                         description={item.description}
                                         linkName={item.linkName}
                                         link={item.link}
                                         onClose={() => setPreviewReklama(null)}
                                         isUser={true}
                        />)
                    }
                }}
            />
        )
    })

    const ListKategories: any = () => {
        var list = [];
        for (let i = 0; i < itemArray.length; i++) {
            if (i == 0)
                continue;
            list.push(
                <CustomSwiper
                    key={itemArray[i].catName}
                    data={itemArray[i].cards}
                    title={itemArray[i].catName}
                    toSub={false}
                    id={itemArray[i].catId.toString()}
                    isAuth={store.roles.includes('Customer')}
                    isMainPage={true}
                />
            )
        }
        return (list)
    }

    // async function refresh() {
    //     await store.checkAuth()
    // }
    // useEffect(()=> {
    //     refresh()
    // },[])
    // const divStyleHome = {
    //     marginBottom: '10px',
    // };

    return (
        <>
            {previewReklama && previewReklama}
            <MenuSticky/>
            {(!store.isError && !store.isDataLoading) &&
                <div className={"home-container"}>
                    <Carousel>
                        {ListSlides}
                    </Carousel>
                    <div id={"swiper-container"}>
                        <CustomSwiper
                            key={itemArray[0]?.catName}
                            data={itemArray[0]?.cards}
                            title={itemArray[0]?.catName}
                            toSub={false}
                            id={itemArray[0]?.catId.toString()}
                            isAuth={store.roles.includes('Customer')}
                            isMainPage={true}
                        />
                    </div>
                    <Advertising key={'Реклама'}
                                 url={strReplace(widthWindow > 450 ? reklama?.desktopImageUrl : reklama?.mobileImageUrl)}
                                 link={'/'}
                                 onClick={() => {
                                     if (reklama && reklama.desktopImageUrl && reklama.mobileImageUrl) {
                                         setPreviewReklama(<PreviewReklama key={0}
                                                                           reklamaImg={store.imgServer + (widthWindow > 450 ? reklama.desktopImageUrl : reklama.mobileImageUrl)}
                                                                           typePreview={1}
                                                                           typeDevice={widthWindow > 450 ? "desktop" : "mobile"}
                                                                           description={reklama.description}
                                                                           linkName={reklama.linkName}
                                                                           link={reklama.link}
                                                                           onClose={() => setPreviewReklama(null)}
                                                                           isUser={true}
                                         />)
                                     }
                                 }}
                    />
                    <div id={"swiper-container"}>
                        <ListKategories/>
                    </div>
                </div>
            }
            {/*<Bottom_panel />*/}
        </>

    )
}

export default observer(UserHome);