import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import "./MyOrderPage.css";
import React, {useContext, useEffect, useState} from "react";
import {ModalContext} from "../../../../../context/ModalContext";
import {MyOrderBigModal} from "./MyOrderPageModal/MyOrderBigModal";
import {MyOrderSmallModal} from "./MyOrderPageModal/MyOrderSmallModal";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import {Context} from "../../../../../index";

interface Interface{
    nameOfProduct:string;
    orderId:string;
    currentStatus:number;
    statusOrder?:number;
}

function MyOrderPageCancel(props:Interface)
{
    const [canceled,setCanceled] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const [openModal2,setOpenModal2] = useState(false);
    const {modal, open, close} = useContext(ModalContext);
    const {store} = useContext(Context);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const OpenSmallModalHandler = () => {
        setOpenModal2(true);
        open();
    }
    const CloseSmallModalHandler = () => {
        setOpenModal2(false);
        close();
    }

    const OpenBigModalHandler = () => {
        setOpenModal(true);
        open();
    }
    const CloseBigModalHandler = () => {
        setOpenModal(false);
        close();
    }

    useEffect(()=>{
        if(props.currentStatus === 9 || props.currentStatus === 10 ||props.currentStatus === 11)
            setCanceled(true);
    },[])

    async function cancelHandler(canceledOrder:boolean,orderId:string){
        canceledOrder ? (
            CabinetBuyerService.revert_order(orderId).then(data => {
                if (data.status === 200){
                    setCanceled(false);
                }
                else{
                    store.showDialog("Этот заказ больше нельзя отменить. Пожалуйста, свяжитесь с продавцом.").then(()=>{})
                }
            })
        ):(
            CabinetBuyerService.cancel_order(orderId).then(data => {
                if (data.status === 200){
                    setCanceled(true);
                }
            })
        );
    }

    const MyButton = (status:(number|undefined), orderId:string) => {
        switch (status) {
            case undefined:
                return(
                    <span className={canceled?"myorderpage-fourth-column-red":""} onClick={OpenBigModalHandler}>
                    {canceled?"вернуть":"отменить"}
                </span>
                )
            case 7:
                return(
                    <span className={canceled?"myorderpage-fourth-column-red":""}
                          onClick={()=>{CabinetSellerService.postReturnDone(orderId)
                    }}>
                    возврат обработан
                </span>
                )
            case 9:
                return(
                    <span className={canceled?"myorderpage-fourth-column-red":""}
                          onClick={()=>{CabinetSellerService.postCancelSeen(orderId)
                          }}>
                    подтвердить отмену
                </span>
                )
            case 10:
                return(
                    <span className={canceled?"myorderpage-fourth-column-red":""}
                          onClick={()=>{CabinetSellerService.postCancelDone(orderId)
                          }}>
                    убрал из коробки
                </span>
                )
        }

    }

    return(
        <>{!canceled?<div style={{textDecoration: "underline"}}>
            {MyButton(props.statusOrder, props.orderId)}
            {openModal2 && <MyOrderSmallModal onClose={CloseSmallModalHandler}/>}
            {openModal && <MyOrderBigModal
                handler={cancelHandler}
                openSmallModal={OpenSmallModalHandler}
                canceled={canceled}
                orderId={props.orderId}
                nameOfProduct={props.nameOfProduct}
                title={canceled?"Вернуть товар":"Отмена заказа"}
                text={canceled?"Вы точно уверены, что хотите вернуть":"Вы точно уверены в отмене позиции"}
                onClose={CloseBigModalHandler}>
            </MyOrderBigModal>}
        </div>:
        <div style={{color:"#8E4646",textDecoration:"none"}}>отменён</div>}

        </>

    )
}export default MyOrderPageCancel;