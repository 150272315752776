import React, {useContext, useEffect, useRef, useState} from "react";
import "./AdminProductDetails.css"

import {
    IDropdownOption, IFilter,
    IRootProductQuestion,
    ISubCategories, ISubCategory,
    ITopCategories, ITopCategory
} from "../../../../models/models";

import "react-datepicker/dist/react-datepicker.css";
import {Context} from "../../../../index";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CustomInput from "../../../customUI/CustomInput/CustomInput";

import {useNavigate, useParams} from "react-router-dom";

import HomeService from "../../../../services/HomeService";
import AdminService from "../../../../services/AdminService";
import ProductCard_1 from "../../../Supplier_UI_kit/ProductCard_1/ProductCard_1";
import ProductCardSupplier from "../../../Supplier_UI_kit/CustomSwiperSupplier/ProductCardSupplier/ProductCardSupplier";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";
import FilterDropDowns from "../../../customUI/CustomDropdown/FilterDropDowns";

const AdminProductDetails = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const [productData, setProductData] = useState<IRootProductQuestion>({})
    const [oldProductData, setOldProductData] = useState<IRootProductQuestion>({})
    const [loadedProductData, setLoadedProductData] = useState(false)
    const [loadedTopCategories, setLoadedTopCategories] = useState(false)
    const [loadedAll, setLoadedAll] = useState(false)

    const [topCategoryName, setTopCategoryName] = useState("Категория")
    const [subCategoryName, setSubCategoryName] = useState("Подкатегория")
    const [topCategories, setTopCategories] = useState<ITopCategory[]>([]);
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);

    const inputRef = useRef(null);
    const [productFile, setProductFile] = useState<File>()
    const [productPreview, setProductPreview] = useState<string>()

    const [postRequest, setPostRequest] = useState("")
    const [titlePage, setTitlePage] = useState("")
    const [buttonText, setButtonText] = useState("")
    const [drag, setDrag] = useState(false)
    const [activate, setActivate] = useState(true)
    const [arrayTopCat, setArrayTopCat] = useState<IDropdownOption[]>([])
    const [arraySupCat, setArraySubCat] = useState<IDropdownOption[]>([])
    const [filterArray, setFilterArray] = useState<IFilter[]>([])
    const [buttonSaveStyle, setButtonSaveStyle] = useState<any>({})
    const [buttonDeleteStyle, setButtonDeleteStyle] = useState<any>({display: "none"})
    const [isChange, setIsChange] = useState(false)
    const [isVisiblePreview, setIsVisiblePreview] = useState("firstLoad")
    let {rootId} = useParams();
    if (rootId === undefined) rootId = "0"

    const getProductData = () => {
        store.DataLoadingON()
        AdminService.getProductRootInfo({"ProductRootId": rootId})
            .then(response => {

                let data = response.data;
                //console.log(data)
                data.filterAttrIds = data.filters.map((filter:any)=>{return filter.attrId})
                //console.log(data)
                setProductData(data)
                setOldProductData(data)
                setProductPreview(response.data["imageUrl"] ? store.imgServer + response.data["imageUrl"] : "");

                setTitlePage(response.data["deleted"] ? "Удаленный коренной товар" : "Изменить коренной товар")
                setButtonText("Сохранить изменения коренного товара")
                setPostRequest("editRoot")
                setButtonSaveStyle(response.data["deleted"] ? {
                    display: "none"
                } : {})
                setButtonDeleteStyle(response.data["deleted"] ? {
                    display: "block",
                } : {})
                setLoadedProductData(true)
            })
            .catch((error) => {
                if(error.response.status === 500){
                    rootId = "0"
                    setTitlePage("Создание коренного товара")
                    setButtonText("Создать коренной товар")
                    setPostRequest("createRoot")
                    setButtonSaveStyle({width: "100%"})
                    setButtonDeleteStyle({display: "none"})
                    setLoadedProductData(true)
                }
                else
                    store.ErrorON()
            })
    }

    useEffect(() => {

        if (!loadedProductData) {

            // для редактирования коренного товара
            if (rootId != "0") {
                getProductData()
            }
            // создание коренного товара
            else if (rootId === "0") {
                setTitlePage("Создание коренного товара")
                setButtonText("Создать коренной товар")
                setPostRequest("createRoot")
                setButtonSaveStyle({width: "100%"})
                setButtonDeleteStyle({display: "none"})
                setLoadedProductData(true)
            }
        }
    }, [])

    useEffect(() => {
        if (!productFile) {
            setProductPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(productFile)
        setProductPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [productFile])

    const selectProductPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setProductPreview(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setProductFile(event.target.files[event.target.files.length - 1])
    };

    const onBtnPictureClick = () => {
        // @ts-ignore
        inputRef.current?.click();
    }

    useEffect(() => {
        if (!loadedTopCategories) {
            AdminService.getAllCategories()
                .then(response => {
                    setTopCategories(response.data.topCategories.sort( (a:any, b:any)=> {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }))
                    setLoadedTopCategories(true)
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }

    }, [loadedProductData])


    useEffect(() => {
        if (topCategories) {
            let newArrayTopCat:IDropdownOption[] = []
            topCategories.map((item) => {
                newArrayTopCat.push({key: item.topCategoryId, name: item.name})
            });
            setArrayTopCat(newArrayTopCat)
        }
    }, [topCategories])

    useEffect(() => {
        if (subCategories) {
            let newArraySubCat:IDropdownOption[] = []
            subCategories.map((item) => {
                newArraySubCat.push({key: item.categoryId, name: item.name})
            });
            setArraySubCat(newArraySubCat)
        }
    }, [subCategories])

    useEffect(()=>{
        let filtarr:IFilter[] = []
        if(topCategories && subCategories){
            topCategories.map((c)=>{
                if (c.topCategoryId === subCategories[0].topCategoryId){
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
            subCategories.map((c)=>{
                if (c.categoryId === productData["subCategoryId"]){
                    filtarr = filtarr.concat(c.filterAttributes)
                }
            })
        }
        let uniqueArray:IFilter[] = []
        filtarr.map( (filt) => {
            if (uniqueArray.every((item)=>item.attrId !==filt.attrId)){
                uniqueArray.push(filt)
            }
        })
        setFilterArray(uniqueArray)
    },[subCategoryName])

    useEffect(() => {
        if (loadedProductData && loadedTopCategories && topCategories && subCategories) {


            let subCatId = productData["subCategoryId"]
            if (subCatId != undefined) {
                // don't touch
                const topCat = topCategories
                    .find(item => item.categories
                        .find((item: ISubCategory) => item.categoryId === subCatId))
                const subCat = topCat?.categories
                    .find((item: ISubCategory) => item.categoryId === subCatId)
                if (topCat) {
                    setTopCategoryName(topCat.name);
                    setSubCategoryName(subCat.name);
                    setSubCategories(topCat?.categories)
                }
            }

            setLoadedAll(true);
        }
    }, [loadedProductData, loadedTopCategories, topCategories, subCategories])

    useEffect(() => {
        if(rootId != "0"){


            if(oldProductData.title !== productData.title || oldProductData.subCategoryId !== productData.subCategoryId
                || oldProductData.description !== productData.description || productFile ||oldProductData.filterAttrIds !== productData.filterAttrIds)
                setIsChange(true)
            else
                setIsChange(false)
        }else {
            if(productData.title && productData.subCategoryId && productData.description && productPreview)
                setIsChange(true)
            else
                setIsChange(false)
        }
    }, [productData, oldProductData, productPreview, productFile])

    useEffect(() => {
        let visible= isVisiblePreview;

        if (productData["title"] && productPreview) {
            if(visible ==="false" || visible === "firstLoad")
                setIsVisiblePreview("true")
        } else if (!productData["title"] || (!productPreview) ) {
            if(visible === "true")
                setIsVisiblePreview("false")
        }
    },[productData["title"], productPreview])

    const onTopCategorySelectChange = (val: string) => {
        const TopCategoryId = val;
        const topCategory = topCategories.find(item => item.topCategoryId === TopCategoryId);
        const subCategories = topCategory?.categories;
        if ((subCategories != null) && subCategories.length != 0) setSubCategories(subCategories);
        else setSubCategories([{categoryId: 'no', name: 'Нет подкатегорий',sorter:0,topCategoryId:val,filterAttributes:[]}]);
        setSubCategoryName("Подкатегория");
        setProductData({...productData, "subCategoryId": undefined})
    }

    const updateSelectedIds = (val:string[])=>{
        setProductData({
            ...productData,
            ["filterAttrIds"]:val
        });

    }

    const onSubCategorySelectChange = (val: string) => {
        setProductData({
            ...productData,
            ["subCategoryId"]: val
        });
    }

    const createFormData = (data: any) => {
        return Object.keys(data).reduce((formData, key) => {
            formData.append(key, data[key]);
            return formData;
        }, new FormData());
    };
    const refactorProductData = (data: { [x: string]: any; }) => {
        for (const key in data) {
            if (data[key] === null || data[key] === undefined)
                delete data[key]
        }
        return data
    }

    const uploadProduct = () => {
            let mydata: any = {
                "productRootId": rootId,
                "title": productData["title"],
                "subCategoryId": productData["subCategoryId"],
                "imageFile": productFile,
                "description": productData["description"],
                "deleted": productData["deleted"],
                "filterAttrIds":productData["filterAttrIds"]
            }
            const data = createFormData(refactorProductData(mydata));

            if (postRequest === "editRoot") {
                store.DataLoadingON()
                AdminService.postProductRootUpdate(data)
                    .then(response => {
                        if (response.status === 200) {
                            alert("Информация обновлена")
                            getProductData()
                        }
                        navigate(-1)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.data.status === "Error_500") {
                                alert("Такого коренного товара нет")
                            } else if (error.response.data.status === "Error_501") {
                                alert("Такое название уже занято")
                            } else {
                                alert("Что-то пошло не так")
                            }
                        }
                    })
                    .finally(() => {
                        store.DataLoadingOFF()
                    })
            } else if (postRequest === "createRoot") {
                store.DataLoadingON()
                AdminService.postProductRootCreate(data)
                    .then(response => {
                        if (response.status === 200) {
                            alert("Коренной товар успешно добавлен")
                        }
                        navigate(-1)
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 501) {
                                alert("Такое название уже занято")
                            } else {
                                alert("Что-то пошло не так")
                            }
                        }
                    })
                    .finally(() => {
                        store.DataLoadingOFF()
                    })
            }

    }

    const deleteProduct = () => {
        if (window.confirm(productData["deleted"] ? "Восстановить коренной товар?" : "Удалить коренной товар?")) {
            let data = {
                "productRootId": rootId,
                "deleted": !productData["deleted"]
            }
            store.DataLoadingON()
            AdminService.postProductRootDelete(data)
                .then(response => {
                    if (response.status === 200) {
                        productData["deleted"] ?
                            alert("Коренной товар восстановлен") : alert("Коренной товар удален")
                    }
                    navigate(-1)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 500) {
                            alert("Такого коренного товара нет")
                        } else {
                            alert("Что-то пошло не так")
                        }
                    }
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }
    }
    const checkErrors = (_callback: () => void) => {
        let errors = false
        // тут должен быть обработчик ошибок

        if (!errors) {
            _callback()
        }
    }

    const buttonSave = () => {
        if(oldProductData != productData || productFile){
            checkErrors(uploadProduct)
        }
    }
    const buttonDelete = () => {
        checkErrors(deleteProduct)
    }

    const dragOver = (e: any) => {
        e.preventDefault();
    }

    const dragEnter = (e: any) => {
        e.preventDefault();
        setDrag(true)
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
        setDrag(false)
    }

    const fileDrop = (e: any) => {
        if (productData["deleted"] === false
            && ["image/jpg", "image/jpeg","image/png"].includes(e.dataTransfer.files[e.dataTransfer.files.length - 1].type))
        {
            e.preventDefault();
            setProductFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
        }
        setDrag(false)
    }

    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <>
                    <div className={"admin-productDetails-mainContainer"}>
                        <div className={"productDetails-navigate-container"}>
                            <img className={"productDetails-navigate-image"} onClick={() => navigate(-1)}
                                 src={"/Pictures/strelochka.svg"}/>
                            <div className={"productDetails-header"}>{titlePage}</div>
                        </div>

                        <div className={"productDetails-hint"} style={{display: productData["deleted"] ? "none" : ""}}>
                            Напишите название на русском и английском языках (при наличии),
                            Например: Volbella, Волбелла
                        </div>

                        <div className={"productDetails-firstContainer"}>
                            <div className={"productDetails-productNames"}>
                                <div className={"productDetails-inputName"} data-deleted={productData["deleted"]}>
                                    <div className={"productDetails-hint-text"}>Название</div>
                                    <CustomInput
                                        name={"name"}
                                        disabled={productData["deleted"]}
                                        placeHolder={"Название"}
                                        value={productData["title"]}
                                        styleInputField={"productDetails-inputField"}
                                        onChange={
                                            (event) => setProductData({...productData, ["title"]: event.target.value})
                                        }
                                        inputSize={"large"}
                                    />
                                </div>

                                <div className={"productDetails-categories-container"}>
                                    <div className={"productDetails-category"}>
                                        <div className={"productDetails-hint-text"}>Категория</div>
                                        <div className={"productDetails-dropDown"}
                                             data-deleted={productData["deleted"]}>
                                            <CustomDropdown
                                                disabled={productData["deleted"]}
                                                icon={!productData["deleted"]}
                                                options={arrayTopCat}
                                                dropdownSize={"large"}
                                                value={topCategoryName}
                                                valueSetter={setTopCategoryName}
                                                onChangeHandler={onTopCategorySelectChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"productDetails-subcategory"}>
                                        <div className={"productDetails-hint-text"}>Подкатегория</div>
                                        <div className={"productDetails-dropDown"}
                                             data-deleted={productData["deleted"]}>
                                            <CustomDropdown
                                                disabled={productData["deleted"]}
                                                icon={!productData["deleted"]}
                                                dropdownSize={"large"}
                                                options={arraySupCat}
                                                value={subCategoryName}
                                                valueSetter={setSubCategoryName}
                                                onChangeHandler={onSubCategorySelectChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"productDetails-secondContainer"}>
                            <div className={"productDetails-photo"}>
                                <div className={"productDetails-hint-text"}>Фото</div>
                                <div
                                    className={"productDetails-photo-image" + (drag && productData["deleted"] === false ? " productDetails-photo-image-dragEnter" : "")}
                                    onDragOver={dragOver}
                                    onDragEnter={dragEnter}
                                    onDragLeave={dragLeave}
                                    onDrop={fileDrop}
                                >
                                    {productPreview ?
                                        <img style={{opacity: productData["deleted"] ? "70%" : ""}}
                                             src={productPreview}
                                             alt={"product picture"}
                                        />
                                        :
                                        <div className={"productDetails-photo-image-text"}
                                             style={{opacity: productData["deleted"] ? "70%" : ""}}>
                                            {productData["deleted"] ? "Нет фото" : "Поместите сюда фото"}
                                        </div>
                                    }
                                </div>
                                <input
                                    ref={inputRef}
                                    style={{display: 'none'}}
                                    type="file"

                                    name={"profilePicture"}
                                    onChange={selectProductPicture}
                                    accept="image/jpeg, image/png"
                                />
                                <button className={"productDetails-photo-button"}
                                        style={{display: productData["deleted"] ? "none" : "block"}}
                                        onClick={onBtnPictureClick}
                                />
                            </div>

                            <div className={"productDetails-description"}>
                                <div className={"productDetails-hint-text"}>Описание</div>
                                <textarea className={"productDetails-description-input"}
                                          disabled={productData["deleted"]}
                                          data-deleted={productData["deleted"]}
                                          placeholder={"Описание"}
                                          value={productData["description"]}
                                          onChange={
                                              (event) => setProductData({
                                                  ...productData,
                                                  ["description"]: event.target.value
                                              })
                                          }
                                />
                            </div>

                        </div>
                        <div className={"admin-productDetails-preview-main"}>
                            <div className={"productDetails-previewContainer"} data-visible={isVisiblePreview}>
                                <div className={"productDetails-hint-text"}>Предпросмотр</div>
                                <div className="product_card_container">
                                    <div className={"product_card"}>
                                        <div style={{display: "flex"}}>
                                            <img id="img_of_card" src={productPreview} />
                                        </div>
                                        <div id="text_cont">
                                            <span id="text_of_card">{productData["title"]} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {filterArray&&filterArray.length>0&&<FilterDropDowns
                            filters={filterArray}
                            selectedIds={productData["filterAttrIds"]?productData["filterAttrIds"]:[]}
                            updateSelectedIds={updateSelectedIds}
                        />}


                        <div className={"productDetails-buttons-container"}>
                            <button className={"productDetails-admin-buttonSave"}
                                    onClick={buttonSave}
                                    style={{backgroundColor: isChange? "" : "#33333370", color: isChange? "" : "#FFFFFF", display: productData["deleted"]? "none": "block" }}
                                    disabled={!isChange}
                            >
                                {buttonText}
                            </button>

                            <button className={"productDetails-admin-buttonDelete"}
                                    data-deleted={productData["deleted"]}
                                    onClick={buttonDelete}
                                    style={buttonDeleteStyle}>{productData["deleted"] ? "Восстановить коренной товар" : ""}</button>

                        </div>
                    </div>
                    <div style={{height:"40px",minHeight:"40px"}}></div>
                </>
            }</>
    );
};

export default AdminProductDetails;