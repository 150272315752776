import {Link} from "react-router-dom"
import '../../../index.css'
import './Button_4.css'
import React, {useContext, useEffect, useState} from "react";
import '../../Layout/UserLayout/UserLayout.css'
import $api from "../../../api/axios";
import {CustomIMG} from "../../customUI/CustomIMG/CustomIMG";
import {randomizeDefaultProfileImgPath, strReplace} from "../../../functions/functions";
import {IUser} from "../../../models/user/IUser";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import {Context} from "../../../index";

interface IButton4{
    link: string;
}
function Button_4({link}:IButton4) {
    const {store} = useContext(Context)

    const firstname = store.user.firstname;
    return (
        <div className="menu-container_dealer">
            <Link className={"menu-profile-div"} to={link}>
                <div className="menu-profile">
                    <div className={"img-profile-container_dealer"}>
                            <div className={"default-profile"}>
                                {
                                    store.user?.image?
                                        <CustomIMG className="img-profile-menu" src={store.user?.image} />:
                                        <img className="img-profile-menu" src={randomizeDefaultProfileImgPath(store.user.displayedName)} alt="User Logo"/>
                                }
                            </div>
                            <div>
                                <img src="/Pictures/edit-3.svg" className="overlay-image"/>
                            </div>
                    </div>
                    <span>{firstname}</span>
                </div>
            </Link>
            <div className="menuEmptyDiv"/>
        </div>
    )
}

export default Button_4;