import {Link, useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import "./ButtonToCart.css";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {Context} from "../../../../index";
import {ICard} from "../../../../models/product/productCard";
import {SpaRoutes} from "../../../../Routes/spaRoutes";

interface IButtonToCart {
    item:ICard;
}

function ButtonToCart(item:IButtonToCart)
{
    const {store} = useContext(Context);
    const navigate = useNavigate()
    const handlerAddProduct = async () => {
        store.DataLoadingON()
        CabinetBuyerService.to_cart(item.item.productId, item.item.orderId, item.item.orderAmount)
            .then(()=> {
                store.cartNumber()
            })
            .catch((e)=>{store.showDialog("Что-то пошло не так")})
            .finally(() => {
                store.DataLoadingOFF()
                navigate(SpaRoutes.CART)
            })
    }

    return(
        <div className="button-to-cart-div">
            <div onClick={handlerAddProduct} className={"button-to-cart"}>
                В корзину
            </div>
        </div>
    )
}export default ButtonToCart;