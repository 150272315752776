import React, {useContext, useEffect, useRef, useState} from "react";
import "./MagazineEdit.css"

import {ICity, IDropdownOption, IRegion, IMagazineData} from "../../../../models/models";
import "react-datepicker/dist/react-datepicker.css";
import {Context} from "../../../../index";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import HomeService from "../../../../services/HomeService";
import {useNavigate, useParams} from "react-router-dom";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {wait} from "@testing-library/user-event/dist/utils";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {BeatLoader} from "react-spinners";
import KladrInputRegion from "../../../customUI/CustomDropdown/KladrInputRegion";
import KladrInputCity from "../../../customUI/CustomDropdown/KladrInputCity";


function MagazineEdit() {
    let {id,connectedId} = useParams();
    const {store} = useContext(Context)
    const [loadedUserData, setLoadedUserData] = useState(false)
    const [loadedRegions, setLoadedRegions] = useState(false)
    const [regionName, setRegionName] = useState("")
    const [virtualRegionName, setVirtualRegionName] = useState("")

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const [cityName, setCityName] = useState("Город")
    const [loadedAll, setLoadedAll] = useState(false)
    const [magazineData, setMagazineData] = useState<IMagazineData>({id:"-1",street:"",number:"",postCode:"",cityId:"",regionId:"",isVirtual:false,virtualRegionId:""});
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [selectedRegionId, setSelectedRegionId] = useState<string>("");
    const [regionSuccess , setRegionSuccess] = useState<string>("false")
    const [cities, setCities] = useState<ICity[]>([]);
    const [citySuccess , setCitySuccess] = useState<string>("false")
    const [streetError, setStreetError] = useState(false)
    const [streetErrorMsg, setStreetErrorMsg] = useState<any>([])
    const [numberError, setNumberError] = useState(false)
    const [numberErrorMsg, setNumberErrorMsg] = useState<any>([])
    const [postIndexError, setPostIndexError] = useState(false)
    const [postIndexErrorMsg, setPostIndexErrorMsg] = useState<any>([])
    const [regionIdError, setRegionIdError] = useState(false)
    const [regionIdErrorMsg, setRegionIdErrorMsg] = useState<any>([])
    const [virtualRegionIdError, setVirtualRegionIdError] = useState(false)
    const [virtualRegionIdErrorMsg, setVirtualRegionIdErrorMsg] = useState<any>([])
    const [cityIdError, setCityIdError] = useState(false)
    const [cityIdErrorMsg, setCityIdErrorMsg] = useState<any>([])
    const [statusChangesCompanyData, setStatusChangesCompanyData] = useState("")

    useEffect(() => {
        if (!loadedUserData){
            if (id ==="-1" && connectedId === "-1"){
                setLoadedUserData(true);
            }
            else if (id==="-1" && connectedId !== "-1"){
                setMagazineData({
                    ...magazineData,
                    ["isVirtual"]: true
                })
                setLoadedUserData(true);
            }
            else if (id){
                CabinetSellerService.getSingleMagazineInfo(id)
                    .then(response => {
                        let fixdata = response.data;
                        if (response.data["postCode"] == 0) fixdata["postCode"] = "";
                        fixdata["id"] = response.data["dropAdressId"];
                        setMagazineData(fixdata);
                    })
                    .then(() => setLoadedUserData(true))
            }
        }
    }, [])

    useEffect(() => {
        if (!loadedRegions) {
            HomeService.GetRegions()
                .then(response => {
                    setRegions(response.data.regions)
                })
                .then(() => setLoadedRegions(true))
        }
    }, [loadedUserData])

    useEffect(() => {
        if (loadedUserData && loadedRegions) {
            let regionId = magazineData["regionId"]
            let virtualRegionId = magazineData["virtualRegionId"]
            if (regionId != undefined) {
                const region = regions.find(item => item.regionId === regionId);
                if (region != undefined) {
                    setRegionName(region.name);
                }
                const virtualRegion = regions.find(item => item.regionId === virtualRegionId);
                if (virtualRegion != undefined) {
                    setVirtualRegionName(virtualRegion.name);
                }
                const cities = region?.cities;
                if ((cities != null) && cities.length != 0) {
                    setCities(cities);
                    let cityId = magazineData["cityId"]
                    if (cityId != undefined) {
                        const city = cities.find(item => item.cityId === cityId);
                        if (city != undefined) {
                            setCityName(city.name);
                        }
                    }
                } else setCities([{cityId: 'no', name: 'Нет городов', region: '', sorter: 0}]);
            }
            setLoadedAll(true);
        }
    }, [loadedUserData, regions])

    const checkError = (value: string | any, setError: any, setErrorMsg: any, message: string | any, type: number) => {
        if (type=== 0 && value === undefined || value === "" || value === null) {
            setError(true)
            setErrorMsg(["Пустое поле"])
            return true
        } else if (type === 1 && /_/.test(value as string)) {
            setError(true)
            setErrorMsg([message])
            return true
        } else if (type === 2 && !/@/.test(value as string)) {
            setError(true)
            setErrorMsg([message])
            return true
        } else if (type === 3 && value?.trim().split(".").length !== 3) {
            setError(true)
            setErrorMsg([message])
            return true
        } else if (type === 4 && value.trim().split(" ").length < 3) {
            setError(true)
            setErrorMsg([message])
            return true
        } else {
            setError(false)
            return false
        }
    }

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;

        setMagazineData({
            ...magazineData,
            [name]: value
        });
    };

    const onRegionsSelectChange = (name: string, key:string) => {
        setMagazineData({
            ...magazineData,
            ["virtualRegionId"]: name
        });
        setSelectedRegionId(key)
        setCities([]);
        setCityName("");
    }

    const onVirtualRegionsSelectChange = (name:string, key:string) => {
        setVirtualRegionName(name)
        setMagazineData({
            ...magazineData,
            ["virtualRegionId"]: name
        });
    }

    const onCitySelectChange = (val: string) => {
        let CityId = val
        setMagazineData({
            ...magazineData,
            ["cityId"]: CityId
        });
    }

    useEffect(() => {
        setTimeout(function () {
            setStatusChangesCompanyData("")
        }, 5000);
    }, [statusChangesCompanyData])

    const navigate = useNavigate()

    const uploadMagazineData = () => {
        ((magazineData["isVirtual"] && regionSuccess==="true")||
            (!magazineData["isVirtual"] && regionSuccess==="true" && citySuccess==="true"))&&
            CabinetSellerService.updateMagazine(
                magazineData["id"],
                magazineData["cityId"],
                magazineData["street"],
                magazineData["number"],
                Number( magazineData["postCode"] ),
                magazineData["isVirtual"],
                connectedId,
                magazineData["virtualRegionId"]
                )
                .then(response => {
                    if (response.status === 200)
                        setMagazineData({...magazineData, id:response.data.newId});
                        setStatusChangesCompanyData("Успешно");
                        wait(2000).then( ()=>
                            navigate(-1)
                        );

                })
                .catch((error) => {
                    if (error.response)
                        setStatusChangesCompanyData("Что-то пошло не так, попробуйте позже еще раз ")
                })
    }
    const checkErrorsMagazine = (_callback: () => void) => {
        let errors = false
        if(!magazineData["isVirtual"]){
            if(checkError(magazineData["street"], setStreetError, setStreetErrorMsg, "", 0)) errors = true
            if(checkError(magazineData["number"], setNumberError, setNumberErrorMsg, "", 0)) errors = true
            if(checkError(magazineData["postCode"], setPostIndexError, setPostIndexErrorMsg, "Неверно введен почтовый индекс", 1)) errors = true
            if(checkError(magazineData["virtualRegionId"], setRegionIdError, setRegionIdErrorMsg, "Не выбран регион", 0)) errors = true
            if(checkError(magazineData["cityId"], setCityIdError, setCityIdErrorMsg, "Не выбран город", 0)) errors = true
        }
        else{
            if(checkError(magazineData["virtualRegionId"], setVirtualRegionIdError, setVirtualRegionIdErrorMsg, "Не выбран регион", 0)) errors = true
        }
        if(!errors) {
            _callback()
        }
    }
    const BtnMagazineClick = () => {
        checkErrorsMagazine(uploadMagazineData)
    }


    let ArrayRegions:IDropdownOption[] = []
    regions.map((item)=>{
        ArrayRegions.push({key:item.regionId,name:item.name})
    })

    let ArrayCitys:IDropdownOption[] = []
    cities.map((item)=>{
        ArrayCitys.push({key:item.cityId,name:item.name})
    })


    return (
        <>
            <CustomNavigateHeader title={id==="-1"?"Добавить новый склад":"Редактировать склад"} trashClickHandler={()=>{}} withTrash={false}/>
            {!loadedAll ? <BeatLoader color="black" loading={true} /> :
            <>
                <div className={"magazin-edit-container"}>
                    { magazineData["isVirtual"]?
                        <div className={"magazin-edit-organization-data"}>
                            <div className={"magazin-edit-inputs"}>
                                        <div className={"magazin-edit-input-text"}>Регион</div>
                                        <div className={"magazin-edit-select-component-input"}>
                                            <KladrInputRegion
                                                styleDropdownDisplay={"magazin-edit-dropDown-input"}
                                                icon={true}
                                                error={virtualRegionIdError}
                                                errorMsg={virtualRegionIdErrorMsg}
                                                value={virtualRegionName}
                                                dropdownSize={"large"}
                                                valueSetter={setVirtualRegionName}
                                                successSetter={setRegionSuccess}
                                                onChangeHandler={onVirtualRegionsSelectChange}
                                            />
                                        </div>
                            </div>
                            <div className={"magazin-edit-saveChanges-container"}>
                                <div className={"magazin-edit-saveChanges-status"}
                                     style={{color: statusChangesCompanyData === "Успешно" ? "green" : "#db2020"}}>{statusChangesCompanyData}</div>
                                <button className={"magazin-edit-saveChanges-button"} onClick={BtnMagazineClick}>
                                    Сохранить изменения
                                </button>
                            </div>
                        </div>
                    :
                        <div className={"magazin-edit-organization-data"}>
                            <div className={"magazin-edit-inputs"}>
                                <div className={"magazin-edit-half-inputs"}>
                                    <div className={"magazin-edit-address-inputs"}>

                                        <div className={"magazin-edit-address-input-street"}>
                                            <div className={"magazin-edit-input-text"}>Адрес склада</div>

                                            <CustomInput
                                                name={"street"}
                                                value={magazineData["street"]}
                                                error={streetError}
                                                errorMsg={streetErrorMsg}
                                                placeHolder={"Улица"}
                                                onChange={(event) => {
                                                    handleInputChange(event)
                                                    setStreetError(false)
                                                }}
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <div className={"magazin-edit-address-input-numHome"}>
                                            <CustomInput
                                                name={"number"}
                                                value={magazineData["number"]}
                                                error={numberError}
                                                errorMsg={numberErrorMsg}
                                                placeHolder={"Дом"}
                                                onChange={(event) => {
                                                    handleInputChange(event)
                                                    setNumberError(false)
                                                }}
                                                inputSize={"large"}
                                            />
                                        </div>
                                    </div>
                                    <div className={"magazin-edit-half-input"}>
                                        <div className={"magazin-edit-input-text"}>Почтовый индекс</div>
                                        <CustomInput
                                            name={"postCode"}
                                            type={"inputMask"}
                                            mask={"999999"}
                                            value={magazineData["postCode"]}
                                            icon={true}
                                            error={postIndexError}
                                            errorMsg={postIndexErrorMsg}
                                            onChange={(event) => {
                                                handleInputChange(event)
                                                setPostIndexError(false)
                                            }}
                                            placeHolder={"Почтовый индекс"}
                                            inputSize={"large"}
                                        />
                                    </div>
                                </div>

                                <div className={"magazin-edit-half-inputs"}>
                                    <div className={"magazin-edit-half-input"}>

                                        <div className={"magazin-edit-input-text"}>Регион</div>

                                        <div className={"magazin-edit-select-component-input"}>
                                            <KladrInputRegion
                                                styleDropdownDisplay={"magazin-edit-dropDown-input"}
                                                icon={true}
                                                error={regionIdError}
                                                errorMsg={regionIdErrorMsg}
                                                value={regionName}
                                                dropdownSize={"large"}
                                                valueSetter={setRegionName}
                                                successSetter={setRegionSuccess}
                                                onChangeHandler={onRegionsSelectChange}
                                            />
                                        </div>
                                    </div>
                                    <div className={"magazin-edit-half-input"}>

                                        <div className={"magazin-edit-input-text"}>Город</div>

                                        <div className={"magazin-edit-select-component-input"}>
                                            <KladrInputCity
                                                styleDropdownDisplay={"magazin-edit-dropDown-input"}
                                                icon={true}
                                                regionId={selectedRegionId}
                                                error={cityIdError}
                                                errorMsg={cityIdErrorMsg}
                                                dropdownSize={"large"}
                                                value={cityName}
                                                successSetter={setCitySuccess}
                                                valueSetter={setCityName}
                                                onChangeHandler={onCitySelectChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"magazin-edit-saveChanges-container"}>
                                <div className={"magazin-edit-saveChanges-status"}
                                     style={{color: statusChangesCompanyData === "Успешно" ? "green" : "#db2020"}}>{statusChangesCompanyData}</div>
                                <button className={"magazin-edit-saveChanges-button"} onClick={BtnMagazineClick}>
                                    Сохранить изменения
                                </button>
                            </div>
                        </div>
                    }


                </div>
            </>
        }
        </>
    )
}

export default MagazineEdit;