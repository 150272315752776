import React, {useContext, useEffect, useState} from 'react';
import "./ReservationReklama.css"
import RegionSelector from "../../../customUI/RegionSelector/RegionSelector";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IRegion, IDateReservation, IReklama} from "../../../../models/models";
import CalendarReklama from "../../../Supplier_UI_kit/CalendarReklama/CalendarReklama";
import ReservationPopup from "./ReservationPopup";

const ReservationReklama = () => {
    const {store} = useContext(Context)

    const [typeRek, setTypeRek] = useState(0)
    const [regions, setRegions] = useState<IRegion[]>()
    const [selectRegion, setSelectRegion] = useState<string>("global")
    const [currentDate, setCurrentDate] = useState({month: new Date().getMonth() + 1, year: new Date().getFullYear()})

    const [datesReservations, setDatesReservations] = useState<IDateReservation[]>([])
    const [price, setPrice] = useState({userBonuses: 0, first: 0, other: 0})

    const [popup, setPopup] = useState<JSX.Element | null>(null)

    const [userReklama, setUserReklama] = useState<IReklama[]>([])
    const [onlyMy, setOnlyMy] = useState(false)
    const getRegions = () => {
        store.DataLoadingON()
        CabinetSellerService.getRegions4Reservations(typeRek)
            .then((r) => setRegions(r.data))
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    const getUserReklama = () => {
        store.DataLoadingON()
        CabinetSellerService.getEntertainment4Reservations()
            .then((r) => setUserReklama(r.data))
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    const updateData = () => {
        store.DataLoadingON()
        CabinetSellerService.getRegions4Reservations(typeRek)
            .then((r) => {
                setRegions(r.data)
                let data = {
                    month: currentDate.month,
                    year: currentDate.year,
                    type: typeRek,
                    regionId: selectRegion,
                    onlyMy: onlyMy
                }

                CabinetSellerService.getReservations(data)
                    .then((r) => {

                        setDatesReservations(r.data.dates.map((d: any) => {
                            return {
                                day: d.day,
                                month: currentDate.month,
                                year: currentDate.year,
                                reservations: d.reservations,
                                selected: d.selected
                            }
                        }))
                        setPrice({userBonuses: r.data.totalBonus, first: r.data.price1, other: r.data.price2})
                    })
                    .catch(() => store.ErrorON())
                    .finally(() => store.DataLoadingOFF())
            })
            .catch(() => store.ErrorON())
            .finally()
    }

    const getReservations = async () => {
        let data = {
            month: currentDate.month,
            year: currentDate.year,
            type: typeRek,
            regionId: selectRegion,
            onlyMy: onlyMy
        }

        store.DataLoadingON()
        await CabinetSellerService.getReservations(data)
            .then((r) => {

                setDatesReservations(r.data.dates.map((d: any) => {
                    return {
                        day: d.day,
                        month: currentDate.month,
                        year: currentDate.year,
                        reservations: d.reservations,
                        selected: d.selected
                    }
                }))
                setPrice({userBonuses: r.data.totalBonus, first: r.data.price1, other: r.data.price2})
            })
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getRegions()
        getUserReklama()
    }, [])

    useEffect(() => {
        getReservations()
    }, [currentDate, typeRek, selectRegion, onlyMy])

    return (
        <div className={"reservationReklama"}>
            <CustomNavigateHeader title={onlyMy? "Забронированная вами реклама" : "Бронирование рекламы"} withTrash={false}/>
            <div className={"reservationReklama-types"}>
                <button className={"reservationReklama-type"}
                        data-sel={typeRek === 0}
                        onClick={() => setTypeRek(0)}
                        data-onlyMy={onlyMy}
                >
                    Верхний баннер
                </button>
                <button className={"reservationReklama-type"}
                        data-sel={typeRek === 1}
                        onClick={() => setTypeRek(1)}
                        disabled={selectRegion === "global"}
                        data-onlyMy={onlyMy}
                >
                    Нижний баннер
                </button>
            </div>
            {/*<div className={"reservationReklama-types-sec"}>
                <button className={"reservationReklama-type"}
                        data-sel={!onlyMy}
                        data-onlyMy={onlyMy}
                        data-hidden={"hide"}
                        onClick={() => setOnlyMy(false)}
                >
                    Забронировать рекламу
                </button>
                <button className={"reservationReklama-type"}
                        data-sel={onlyMy}
                        onClick={() => setOnlyMy(true)}
                        data-onlyMy={onlyMy}
                >
                    Моя реклама
                </button>
            </div>*/}
            <div className={"reservationReklama-hint-text"}>Выберите район, в котором будет показываться реклама:</div>
            <div className={"reservationReklama-types-regions"}>
                <button className={"reservationReklama-type"}
                        data-sel={selectRegion === "global"}
                        onClick={() => setSelectRegion("global")}
                        disabled={typeRek === 1}
                        data-onlyMy={onlyMy}
                >
                    Вся Россия {regions && regions[0].number !== 0 && `(${regions && regions[0].number})`}
                </button>
                {regions?.map((r, index) => {
                    if (r.regionId !== "global") {
                        return <button className={"reservationReklama-type"} key={index}
                                       data-sel={selectRegion === r.regionId}
                                       onClick={() => setSelectRegion(r.regionId)}
                                       data-onlyMy={onlyMy}
                        >
                            {r.name} {r.number !== 0 && `(${r.number})`}
                        </button>
                    }
                })}
            </div>

            <CalendarReklama calendarEvent={datesReservations}
                             updateDropdown={(month, year) => setCurrentDate({month: month, year: year})}
                             onlyMy={onlyMy}
                             type={typeRek}
                             selectRegion={selectRegion}
                             itemEventClick={(day, month, year, reservations, selectedReklama, onlyMy1) => {
                                 setPopup(<ReservationPopup reservations={reservations}
                                                            day={day} month={month} year={year}
                                                            onClose={() => setPopup(null)}
                                                            userReklama={userReklama}
                                                            price={price}
                                                            onUpdate={() => {
                                                                updateData()
                                                            }}
                                                            type={typeRek}
                                                            onlyMyProps={onlyMy}
                                                            onlyMyProps1={onlyMy1}
                                                            selectRegion={selectRegion}
                                                            selectedReklama={selectedReklama}

                                 />)
                             }}
                             userReklama={userReklama}
            />

            {popup && popup}
        </div>
    );
};

export default ReservationReklama;