// import './SupplierCardProduct.css'
//
// interface IChangeAmountProps {
//     orderId:string;
//     supplierAmount:number;
//     orderAmount:number;
//     level2:string;
//     productId:string;
//     changeAmount:(orderId: string, newAmount: number, level2: string, productId: string)=>void;
//     disabled:boolean;
// }
// const ChangeAmountBar = (props:IChangeAmountProps) => {
//
//     const onClickHandlerMinus = async () => {
//         if(props.orderAmount!==1){
//             props.changeAmount(props.orderId, props.orderAmount - 1, props.level2, props.productId)
//         }
//     }
//     const onClickHandlerPlus = async () => {
//         if(props.supplierAmount&&(props.orderAmount!==props.supplierAmount)) {
//             props.changeAmount(props.orderId, props.orderAmount + 1, props.level2, props.productId)
//             }
//     }
//
//     return(
//         <div className={'add-item'} style={props.disabled?{display:`none`}:{}}>
//             <img
//                 onClick={onClickHandlerMinus}
//                 src={"/Pictures/minus.svg"}/>
//             <p className={"add-item-p"}>{props.orderAmount}</p>
//             <img
//                 onClick={onClickHandlerPlus}
//                 src={"/Pictures/plus.svg"}/>
//         </div>
//     )
// }
// export default ChangeAmountBar


import './SupplierCardProduct.css';
import Counter from '../../../../customUI/Counter/Counter';
import {Context} from "../../../../../index";
import {useContext} from "react";

interface IChangeAmountBarProps {
    orderId:string;
    supplierAmount:number;
    orderAmount:number;
    level2:string;
    productId:string;
    onChangeAmount:(orderId: string, newAmount: number, level2: string, productId: string)=>void;
    disabled:boolean;
}

const ChangeAmountBar = (props:IChangeAmountBarProps) => {

    const {store} = useContext(Context);

    const onDecreaseHandler = async () => {
        if(props.orderAmount!==1){
            props.onChangeAmount(props.orderId, props.orderAmount - 1, props.level2, props.productId)
        }
    }
    const onIncreaseHandler = async () => {
        if(props.supplierAmount&&(props.orderAmount!==props.supplierAmount)) {
            props.onChangeAmount(props.orderId, props.orderAmount + 1, props.level2, props.productId)
        }
        else
            store.showDialog("Больше нельзя изменить количество!").then(()=>{});
    }

    return(
        <Counter
            count={props.orderAmount}
            onDecrease={onDecreaseHandler}
            onIncrease={onIncreaseHandler}
            disabled={props.disabled}
        />
    )
}

export default ChangeAmountBar;
