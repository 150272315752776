import React, {useContext, useEffect, useState} from 'react';
import "./AdminHome.css"
import {Loader} from "../../customUI/Loader/Loader";
import {Context} from "../../../index";
import {CabinetResponse} from "../../../models/response/CabinetResponse";
import {IAdminCabinet} from "../../../models/models";
import AdminService from "../../../services/AdminService";
import {observer} from "mobx-react-lite";
import Navbar from "../../ProtectedPages/Admin/Navbar/Navbar";
import {Link} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";

const AdminHome = () => {
    const {store} = useContext(Context)

    const [cabinetData, setCabinetData] = useState<IAdminCabinet>();
    const [loading, setLoading] = useState(true)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
        if (!cabinetData) {
            store.DataLoadingON()
            AdminService.getAdminMarketKabinet()
                .then((r) => {
                    setCabinetData(r.data)
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    store.DataLoadingOFF()
                })
        }
    }, [])


    return (
        <>{(!store.isError && !store.isDataLoading) &&
            <div className={"admin-home-main"}>
                <div className={"admin-navbar-main"}>
                    <Navbar/>
                </div>
                <div className={"admin-content-main"}>
                    <div className={"admin-home-header"}>
                        Кабинет маркет-администратора
                    </div>
                    <div className={"admin-home-content"}>
                        <div className={"admin-home-content-stroke"}>
                            <Link to={SpaRoutes.ADMIN_BUYERS} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Косметологи</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.customer}</div>
                            </Link>
                            <Link to={SpaRoutes.ADMIN_SUPPLIERS} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Поставщики</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.dealer}</div>
                            </Link>
                            <Link to={SpaRoutes.ADMIN_CATEGORIES} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Категории</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.topCategories}</div>
                            </Link>
                        </div>
                        <div className={"admin-home-content-stroke"}>
                            <Link to={SpaRoutes.UNPROCESSED_PRODUCTS} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Необработанные коренные товары</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.rootQuestion}</div>
                            </Link>
                            <Link to={SpaRoutes.ADMIN_ROOT_PREVIEW + "/0"} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Все коренные товары</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.productRoot}</div>
                            </Link>
                            <Link to={SpaRoutes.ADMIN_ROOT_PREVIEW + "/1"} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Удаленные коренные товары</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.deletedProductRoot}</div>
                            </Link>
                        </div>

                    </div>
                    <div className={"admin-home-header"}>
                        Платежная информация
                    </div>
                    <div className={"admin-home-content"}>
                        <div className={"admin-home-content-stroke"}>
                            <Link to={SpaRoutes.ADMIN_MODERATION_REKLAMA} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Модерация рекламы</div>
                                <div className={"admin-home-content-item-val"}>{cabinetData?.moderationReklama}</div>
                            </Link>
                            <Link to={SpaRoutes.ADMIN_REKLAMA} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Календарь бронирования</div>
                            </Link>
                            <Link to={SpaRoutes.ADMIN_CARDS_PAYMENT_HISTORY} className={"admin-home-content-item"}>
                                <div className={"admin-home-content-item-text"}>Оплата за карточки</div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    );
};

export default observer(AdminHome);