import React, {useContext} from "react"
import "../CustomSwiper/CustomSwiper.css"
import Favorite from "../Favorite/Favorite"
import {Context} from "../../../index"
import {ICard} from "../../../models/product/productCard"
import "./ProductCard.css"
import {Ru} from "../../../functions/functions"
import CabinetBuyerService from "../../../services/CabinetBuyerService"
import {Link, useNavigate} from "react-router-dom"
import {CustomIMG} from "../CustomIMG/CustomIMG"
import {SpaRoutes} from "../../../Routes/spaRoutes"

interface ISwiperItemProps {
    item: ICard
    isAuth: boolean
    style?: string
}

function ProductCard({item, isAuth, style}: ISwiperItemProps) {
    const {store} = useContext(Context)
    const navigate = useNavigate()
    const handlerAddProduct = async () => {
        CabinetBuyerService.to_cart(
            item.productId,
            item.orderId,
            item.orderAmount
        ).then(() => store.cartNumber())
    }
    return (
        <div className={style ? style : "product_wrap-slider"}
             onClick={() => navigate(SpaRoutes.PRODUCT + `/:${item.productId}`)}>
            <div id="product_and_heart">

                <CustomIMG id="img_of_product" src={item.image}/>

                {isAuth && (
                    <Favorite
                        favorite={item?.onFavorite}
                        id={item.productId}
                    />
                )}
            </div>

            <div id="description_containter">
                        <span id="description_of_product">
                            {item.name_of_product}{" "}
                        </span>
            </div>
            {/*position: relative;*/}
            {/*top: 13px;*/}
            <div className={"price-div"}>
                {isAuth && (
                    <>
                        <div className={"productCard-price-amount-container"}>
                            <div className={"productCard-price-amount"}>
                                {item.visibleMaskPrice ?
                                    <div style={{maxWidth: 70}}>уточняйте цену</div>
                                    :
                                    <div className={"productCard-price"}>
                                        <div>
                                            {item.discount
                                                ? Ru(item.price2 ?? 0)
                                                : Ru(item.price1 ?? 0)}
                                        </div>
                                        <div className={"productCard-discount"}>
                                            {item.discount && !item.visibleMaskPrice && Ru(item.price1 ?? 0)}
                                        </div>
                                    </div>
                                }

                                {item.visibleMaskAmount ?
                                    <div style={{textAlign: "right", maxWidth: 52}}>товар под заказ</div>
                                    :
                                    <div>{item.amount} шт.</div>
                                }

                            </div>
                        </div>
                    </>
                )}
            </div>
            <div id={"supply-company-div"}>
                <img
                    id={"productCard-1SConnected-img"}
                    src={`${
                        item.isConnected1S
                            ? "/Pictures/CheckGreen.svg"
                            : "/Pictures/ExclamRed.svg"
                    }`}
                />
                <span id="text_supply_company">
                            {item.supply_company}
                        </span>

                {item.image_supply_company && (
                    <CustomIMG
                        id="img_supply_company"
                        src={item.image_supply_company}
                    />
                )}
            </div>
            {/*{isAuth&&<button style={{position: "absolute",top: "10px"}} onClick={handlerAddProduct}>+</button>}*/}
            {/*{isAuth&&*/}
            {/*    <div className={"button-to-cart"} onClick={handlerAddProduct}>*/}
            {/*        В корзину*/}
            {/*    </div>}*/}

        </div>

    )
}

export default ProductCard
