import {Link} from "react-router-dom";
import "./Favorites.css";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {useEffect} from "react";

function FavoritesEmpty() {

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    return(
        <div id={"emptycart-container"}>
            <div id={"emptycart-text-div"}>
                <p id={"empty-h"}>В избранном пока ничего нет</p>
                <p id={"empty-title"}>Чтобы добавить нужный товар в избраннное, воспользуйтесь поиском или перейдите в каталог</p>
            </div>
            <Link className={"fav-empty-button"} id={"katalog-button"} to={SpaRoutes.CATALOG_INDEX}>Перейти в каталог</Link>
        </div>
    )
}export default FavoritesEmpty;