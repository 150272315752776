import React, {useEffect, useState} from "react";
import "./OrderFormationCalendar.css";
import Calendar from "./Calendar";
import ChooseMonthFromDate from "./ChooseMonthFromDate";
import {TimeFrom} from "../../../../../data/OrderDeliveryChoose/TimeFrom";
import {TimeTo} from "../../../../../data/OrderDeliveryChoose/TimeTo";
import CustomDropdown from "../../../../customUI/CustomDropdown/CustomDropdown";
import {deliveryDateTransform} from "../../Cart/functions";

interface Interface {
    SetTime: (supplierId: string | undefined, from: string, until: string) => void;
    SetComment: (supplierId: string | undefined, comment: string) => void;
    name: string | undefined;
    invoiceId: string | undefined;
    initialComment:string;
    initialFrom:string;
    initialTo:string;
    selectedTime:boolean;
}

export default function OrderFormationCalendar({SetTime,SetComment, name, initialComment, invoiceId,initialFrom,initialTo,selectedTime}: Interface) {
// export default function OrderFormationCalendar() {
    const [showModal, setShowModal] = useState(false);
    const [deliveryDate, setDeliveryDate] = useState('date');
    const [deliveryDay, setDeliveryDay] = useState('day');
    const [deliveryMonth, setDeliveryMonth] = useState('month');
    const [deliveryTimeFrom, setDeliveryTimeFrom] = useState('TimeFrom');
    const [deliveryTimeTo, setDeliveryTimeTo] = useState('TimeTo');
    const [isDaytime, setIsDaytime] = useState(false);
    const [comment, setThisComment] = useState(initialComment);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleDaytimeClick = () => {
        setIsDaytime(true);
        setDeliveryTimeFrom("00:00");
        setDeliveryTimeTo("23:00");
        let deliveryString:string = deliveryDateTransform(new Date(deliveryDate))+"00:00:00"
        let deliveryStringTo:string = deliveryDateTransform(new Date(deliveryDate))+"23:00:00"
        SetTime(invoiceId, deliveryString, deliveryStringTo);
    };

    const handleChooseTimeClick = () => {
        setIsDaytime(false);
        setDeliveryTimeFrom("8:00");
        setDeliveryTimeTo("9:00");
        let deliveryString:string = deliveryDateTransform(new Date(deliveryDate))+"08:00:00"
        let deliveryStringTo:string = deliveryDateTransform(new Date(deliveryDate))+"09:00:00"
        SetTime(invoiceId, deliveryString, deliveryStringTo);
    };

    const handleTimeFromSelection = (value: string) => {
        setDeliveryTimeFrom(value);
        if (value.length<5){
            value="0"+value;
        }
        let timeToStringyStringy = deliveryTimeTo
        if (deliveryTimeTo.length<5){
            timeToStringyStringy="0"+timeToStringyStringy;
        }
        let deliveryString:string = deliveryDateTransform(new Date(deliveryDate))+value+":00"
        let deliveryStringTo:string = deliveryDateTransform(new Date(deliveryDate))+timeToStringyStringy+":00"
        SetTime(invoiceId, deliveryString, deliveryStringTo);
    };

    const handleTimeToSelection = (value: any) => {
        setDeliveryTimeTo(value);
        let timeFromStringyStringy = deliveryTimeFrom
        if (deliveryTimeTo.length<5){
            timeFromStringyStringy="0"+timeFromStringyStringy;
        }
        let deliveryString:string = deliveryDateTransform(new Date(deliveryDate))+value+":00"
        let deliveryStringFrom:string = deliveryDateTransform(new Date(deliveryDate))+timeFromStringyStringy+":00"
        SetTime(invoiceId, deliveryStringFrom, deliveryString);
    };

    const handleCommentInput = (event: any) => {
        setThisComment(event.target.value);
    };

    const handleOkButtonClick = () => {
        // Handle delivery time selection and comment submission here
        SetComment(invoiceId, comment);
        toggleModal();
    };

    function handleDateSelect(date: any) {
        setDeliveryDate(date);
        setDeliveryDay(date.getDate());
        setDeliveryMonth(date.getMonth().toString());
        let timeFromStringyStringy = deliveryTimeFrom
        if (deliveryTimeFrom.length<5){
            timeFromStringyStringy="0"+timeFromStringyStringy;
        }
        let timeToStringyStringy = deliveryTimeTo
        if (deliveryTimeTo.length<5){
            timeToStringyStringy="0"+timeToStringyStringy;
        }
        let deliveryStringFrom:string = deliveryDateTransform(date)+timeFromStringyStringy+":00"
        let deliveryStringTo:string = deliveryDateTransform(date)+timeToStringyStringy+":00"
        SetTime(invoiceId, deliveryStringFrom, deliveryStringTo);
    }

    useEffect(() => {
        if (initialFrom && initialTo && initialFrom!="" && initialTo!=""){
            const day = new Date(initialFrom).getDate().toString();
            const month = (new Date(initialFrom).getMonth()).toString();
            setDeliveryDate(new Date(initialFrom).toString());
            setDeliveryDay(day);
            setDeliveryMonth(month);
            let hoursFrom = new Date(initialFrom).getHours()
            let hoursTo = new Date(initialTo).getHours()
            setDeliveryTimeFrom(hoursFrom+":00")
            setDeliveryTimeTo(hoursTo+":00")
            if(hoursFrom===0 && hoursTo===23){
                setIsDaytime(true);
            }
        }
        else{
            const day = new Date().getDate().toString();
            const month = new Date().getMonth().toString();

            setDeliveryDate(new Date().toString());
            setDeliveryDay(day);
            setDeliveryMonth(month);
            setDeliveryTimeFrom(TimeFrom[0].name)
            setDeliveryTimeTo(TimeTo[0].name)
        }
    }, [])


    function OfcTwoDropdowns() {
        return (
            <>
                <div className={!isDaytime
                    ? 'ofcTextChooseDeliveryWrapper1'
                    : 'ofcTextChooseDeliveryWrapper2'}>
                    <span id={"ofcTextChooseDelivery"}>Выберете время доставки:</span>
                </div>
                <div className="ofcDeliveryTimeSelection">

                    {!isDaytime && (
                        <>
                            <div className={"ofcTwoDropdowns"}>
                                <span>C</span>
                                <div className={"ofcDropdownWrapper"}>
                                    {/*<select className={"ofcDropdown"} onChange={handleTimeFromSelection}>*/}
                                    {/*    {TimeFrom.map((option, index) => (*/}
                                    {/*        <option key={index} value={option}>*/}
                                    {/*            {option}*/}
                                    {/*        </option>*/}
                                    {/*    ))}*/}
                                    {/*</select>*/}
                                    <CustomDropdown
                                        options={TimeFrom}
                                        value={deliveryTimeFrom}
                                        valueSetter={setDeliveryTimeFrom}
                                        onChangeHandler={handleTimeFromSelection}
                                    />
                                </div>
                                <span>До</span>
                                <div className={"ofcDropdownWrapper"}>
                                    {/*<select className={"ofcDropdown"} onChange={handleTimeToSelection}>*/}
                                    {/*    {TimeTo.map((option, index) => (*/}
                                    {/*        <option key={index} value={option}>*/}
                                    {/*            {option}*/}
                                    {/*        </option>*/}
                                    {/*    ))}*/}
                                    {/*</select>*/}
                                    <CustomDropdown
                                        options={TimeTo}
                                        value={deliveryTimeTo}
                                        valueSetter={setDeliveryTimeTo}
                                        onChangeHandler={handleTimeToSelection}
                                    />
                                </div>
                            </div>
                            <div className={"ofcTextOr"}>
                                <span>Или</span>
                            </div>
                        </>
                    )}
                    <div className={"ofcButtonsDelivery"}>
                        <div className={!isDaytime ? 'ofcButton1DuringTheDay' : 'ofcButton1ChooseYourself'}>
                            <span onClick={handleDaytimeClick}>В течение дня</span>
                        </div>
                        <div className={!isDaytime ? 'ofcButton2DuringTheDay' : 'ofcButton2ChooseYourself'}>
                            <span onClick={handleChooseTimeClick}>выбрать самому</span>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    function OfcCommentDateOk() {
        return (
            <>
                <div className={!isDaytime
                    ? 'ofcLabelWrapper1'
                    : 'ofcLabelWrapper2'}>
                    <label>
                        <input
                            type="text"
                            onChange={handleCommentInput}
                            value={comment}
                            placeholder={"Комментарий к доставке"}
                            className={"ofcLabelInput"}/>
                    </label>
                </div>
                <div className="ofcDeliverySummaryWrapper">
                    <div className="ofcDeliverySummary">
                        {!isDaytime ? (
                            <span>
                                {deliveryDay} {ChooseMonthFromDate(deliveryMonth)}, с {deliveryTimeFrom} до {deliveryTimeTo}
                            </span>
                        ) : (
                            <span>
                                {deliveryDay} {ChooseMonthFromDate(deliveryMonth)}, в течение дня
                            </span>
                        )}
                    </div>
                    <div className={"ofcOkButton"} onClick={handleOkButtonClick}>
                        <span>Ок</span>
                    </div>
                </div>
            </>
        )
    }

    function OrderFormationCalendarModal(props:any) {
        return (
            <>
                <div className={showModal ? 'ofcCloseAreaOpen' : 'ofcCloseAreaClose'} onClick={toggleModal}/>
                <div className={showModal ? 'ofcModalWrapperOpen' : 'ofcModalWrapperClose'}>
                    <div className={"ofcModalWindow"}>
                        <div className={"ofcHeader"}>
                            <span>Когда к вам приехать?</span>
                            <div id="ofcCloseButton" onClick={toggleModal}>
                                <img src="/Pictures/Закрыть_календарь.svg"/>
                            </div>
                        </div>
                        <div className="calendar">
                            <Calendar Date={props.Date} onDateSelect={handleDateSelect}/>
                        </div>
                        <div className="ofcLineAfterCalendar">
                            <img src="/Pictures/Rectangle 1116.svg"/>
                        </div>
                        {OfcTwoDropdowns()}
                        {OfcCommentDateOk()}
                    </div>
                </div>
            </>
        )
    }

    return (
        <div>
            <div className={"order-choose-time-button"} onClick={toggleModal} style={selectedTime?{boxShadow:"0px 0px 5px #01ABA2"}: {}}>
                <span id={"order-choose-time-text"}>
                {selectedTime?
                    (
                        !isDaytime ? (
                                `${deliveryDay} ${ChooseMonthFromDate(deliveryMonth)}, с ${deliveryTimeFrom} до ${deliveryTimeTo}`
                        ) :
                            (
                                `${deliveryDay} ${ChooseMonthFromDate(deliveryMonth)}, в течение дня`
                            )
                    )
                    :
                    (
                            "Выбрать время доставки"
                    )
                }
                </span>
            </div>
            {OrderFormationCalendarModal({Date:initialFrom})}
        </div>
    );
}