import React from "react";
import '../../ProtectedPages/Customer/Cart/Cart.css'
import {useNavigate} from "react-router-dom";
import './SupplierNavigateHeader.css';

interface ICartHeader {
    trashClickHandler:()=>void;
    title:string;
    withTrash:boolean;
}

export default function SupplierNavigateHeader({title, trashClickHandler,withTrash}:ICartHeader) {
    const navigate = useNavigate();

    const onClickHandler = () => {
        navigate(-1)
    }
    return(
        <div id={"supplier-nh-title-div"}>
            <img onClick={onClickHandler} id={"supplier-nh-arrow"} src={"/Pictures/strelochka.svg"}/>
            <p className={"supplier-nh-title"}>{title}</p>
            {withTrash&&<img onClick={trashClickHandler} id={"supplier-nh-trash-icon"} src={"/Pictures/trash.svg"}/>}
        </div>
    )
}