import React, {FC, useContext, useState, useEffect, useRef} from 'react';
import { Context } from "../../../../index";
import './seller.css'
import InputMask from 'react-input-mask';
import {IDropdownOption, IRegion} from "../../../../models/models"
import { ICity } from "../../../../models/models"
import { ISellerInfo } from "../../../../models/models"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import { useNavigate } from 'react-router-dom';
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {MenuSticky} from "../../../Layout/UserLayout/MenuSticky/MenuSticky";
import AuthenticateService from "../../../../services/AuthenticateService";
import HomeService from "../../../../services/HomeService";
import {InvalidFeedback} from "../invalidFeedback";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import Checkbox01 from "../../../customUI/checkbox01/checkbox01";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import ReCAPTCHA from "react-google-recaptcha";
const {ApiRoutes: { Authenticate }} = require("../../../../Routes/apiRoutes");

const Seller: FC = () => {

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const { store } = useContext(Context)
    let initial:ISellerInfo = {
        cityId: "",
        displayedName: "",
        email: "",
        firstName: "",
        lastName: "",
        number: "",
        numberInn: "",
        orgName: "",
        orgType: "",
        password: "",
        phoneNumber: "",
        postCode: "",
        pwd: {hiCh: "", lowCh: "", numbers: "", specialSymbol: "", symbolLengths: ""},
        regionId: "",
        repwd: "",
        street: "",
        vatherName: "",
        confirmLink : window.location.origin+"/#"+SpaRoutes.EMAIL_CONFIRM,
        checkbox:''
    };
    const BeginRef = useRef<HTMLDivElement>(null);
    const [UserInfo, setUserInfo] = useState<ISellerInfo>(initial)
    const [registerError, setRegisterError] = useState<ISellerInfo>(initial)
    const [regionName,setRegionName] = useState("Регион")
    const [cityName,setCityName] = useState("Город")
    const [typeOrg,setTypeOrg] = useState("Тип организации")
    const [innMask,setInnMask] = useState("")
    const [regions, setRegions] = useState<IRegion[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);

    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [password_again, setPasswordAgain] = useState<string>('');
    const [pd_check, setpd_check] = useState<boolean>(false);
    const [clicked,setClicked] = useState<string>("not");

    const [openPopupPD, setOpenPopupPD] = useState(false)
    const refPopupPD = useRef(null)

    const [canRegister, setCanRegister] = useState(false)

    useOnClickOutside(refPopupPD, () => setOpenPopupPD(false))
    const handlerPD = async () => {
        if (!openPopupPD) setOpenPopupPD(true)
    };

    useEffect(() => {
        HomeService.GetRegions()
            .then((resp) => {
            // устанавливаем регионы
            setRegions(resp.data.regions);
        });
    }, []);

    const handleInputChange = (e: any) => {
        let { name, value } = e.target;

        if(name!="orgNameRegister"&&name!="displayedNameRegister")
            value = value.replaceAll(" ","");

        if(name === 'firstName' || name === 'lastName' || name === 'vatherName'
            || name === "orgType"  || name === "orgNameRegister"  || name === "displayedNameRegister"
            /*|| name === 'street' || name === 'number'*/)
        {
            if(!value)
                setRegisterError({...registerError, [name]:'Неверный ввод!'})
            else
                setRegisterError({...registerError, [name]:''})
        }

        if (name === "phoneNumber") {
            if (/_/.test(value)) {
                setRegisterError({...registerError, phoneNumber:'Неверно указан телефон'})
            } else {
                setRegisterError({...registerError, phoneNumber:''})
            }
        }
        if (name === "emailRegister") {
            value = value.toLowerCase();
            const reEmail = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/
            if (!reEmail.test(String(value).toLowerCase())) {
                setRegisterError({...registerError, ['email']:'Введите корректный email'})
            } else {
                setRegisterError({...registerError, ['email']:''})
            }
        }
/*        if (name === "postCode") {
            if (!/_/.test(value) || value === "") {
                setRegisterError({...registerError, postCode:''})
            } else {
                setRegisterError({...registerError, postCode:'Неверно указан индекс'})
            }
        }

 */
        if (name === "numberInn") {
            if(UserInfo.orgType==="")
                value = ""
            if (!/_/.test(value) || value === "") {
                setRegisterError({...registerError, numberInn:''})
            } else {
                setRegisterError({...registerError, numberInn:'Неверно указан ИНН'})
            }
        }
        if (name === "passwordRegister") {
            //    const mass = [['Не хватает цифр', /(?=.*[0-9])/],
            //    ['Не хватает специального символа (!"№;%:?*()_-+=.)', /(?=.*[!@#$%^&*.])/],
            //    ['Не хватает маленькой буквы', /(?=.*[a-z])/],
            //    ['Не хватает заглавной буквы', /(?=.*[A-Z])/],
            //    ['Длина пароля минимум 6 символов', /[0-9a-zA-Z!@#$%^&*.]{6,}/],
            //    ]
            value = value.toLowerCase();
            const reNum = /(?=.*[0-9])/
            // const reSym = /(?=.*[!@#$%^&*.])/
            const reLowCh = /(?=.*[a-zа-яёA-ZА-ЯЁ])/
            //const reHiCh = /(?=.*[A-ZА-ЯЁ])/
            const reLen = /[0-9a-zа-яёA-ZА-ЯЁ!@#$%^&*.]{6,}/

            setRegisterError((prevState)=>{
                let newState = {...prevState}
                if (!reNum.test(String(value))) {
                    newState.pwd.numbers = 'Не хватает цифр'
                } else {
                    newState.pwd.numbers = ''
                }
                if (!reLowCh.test(String(value))) {
                    newState.pwd.lowCh = 'Не хватает буквы'
                } else {
                    newState.pwd.lowCh = ''
                }
                if (!reLen.test(String(value))) {
                    newState.pwd.symbolLengths = 'Длина пароля минимум 6 символов'
                } else {
                    newState.pwd.symbolLengths = ''
                }                return newState
            })

            // if (!reSym.test(String(value))) {
            //     setPasswordErrorSym('Не хватает специального символа (!"№;%:?*()_-+=.)')
            // } else {
            //     setPasswordErrorSym('')
            // }
            // if (!reHiCh.test(String(value))) {
            //     setPasswordErrorHiCh('Не хватает заглавной буквы')
            // } else {
            //     setPasswordErrorHiCh('')
            // }
        }
        if (name == "passwordRegister"){
            name = "password"
        }
        if (name == "emailRegister"){
            name = "email"
        }
        if (name == "orgNameRegister"){
            name = "orgName"
        }
        if (name == "displayedNameRegister"){
            name = "displayedName"
        }
        setUserInfo({
            ...UserInfo,
            [name]: value
        });
    };

    let ArrayRegions:IDropdownOption[] = []
    regions.map((item)=>{
        ArrayRegions.push({key:item.regionId,name:item.name})
    })

    let ArrayCitys:IDropdownOption[] = []
    cities.map((item)=>{
        ArrayCitys.push({key:item.cityId,name:item.name})
    })

    const PassAgainHandler = (e: any) => {
        let repwd = e.target.value.toLowerCase().replaceAll(" ","");
        setPasswordAgain(repwd)
        if (repwd != UserInfo.password) {
            setRegisterError({...registerError, repwd:'Пароли должны совпадать'})
        } else {
            setRegisterError({...registerError, repwd:''})
        }
    }
    const onTypeOrgSelectChange = (val:string)=>{
        const typeOrg = val;
        setUserInfo({
            ...UserInfo,
            ["orgType"]: typeOrg
        });
        if (typeOrg === "ООО"){
            setInnMask("9999999999")
        }
        else{
            setInnMask("999999999999")
        }
    }

    const onRegionsSelectChange = (val:string) => {
        const RegionId = val;
        setUserInfo({
            ...UserInfo,
            ["regionId"]: RegionId,
            ["cityId"]: '',
        });
        setRegisterError({...registerError, cityId:'Необходимо выбрать город'})
        const region = regions.find(item => item.regionId === RegionId);
        const cities = region?.cities;
        if ((cities != null) && cities.length != 0) setCities(cities);
        else setCities([{ cityId: 'no', name: 'Нет городов', region: '', sorter: 0 }]);
        setCityName("Город");
    }

    const onCitySelectChange = (val:string) => {
        const CityId = val;
        setRegisterError({...registerError, cityId:''})
        setUserInfo({
            ...UserInfo,
            ["cityId"]: CityId
        });
    }


    const createFormData = (data: any) => {
        return Object.keys(data).reduce((formData, key) => {
            formData.append(key, data[key]);
            return formData;
        }, new FormData());
    };
    const refactorUserData = () => {
        let mydata: any = { ...UserInfo }
        for (const key in mydata) {
            if (mydata[key] === null || mydata[key] === undefined)
                delete mydata[key]
        }
        return mydata
    }

    const navigate = useNavigate();

    let errorBool = !(UserInfo.lastName && UserInfo.firstName && UserInfo.vatherName
        && UserInfo.email && UserInfo.phoneNumber && UserInfo.password && password_again
        && UserInfo.checkbox==="true"
        //&& !(UserInfo.regionId && !UserInfo.cityId)
        && UserInfo.orgType && UserInfo.numberInn
        && UserInfo.orgName
        //&& UserInfo.postCode
        && UserInfo.displayedName
        //&& UserInfo.street
        //&& UserInfo.number
        && (!registerError.phoneNumber)
        && (!registerError.email)
        && (!registerError.repwd)
        //&& (!registerError.postCode)
        && (!registerError.numberInn)
        && (!registerError.pwd.lowCh)
        && (!registerError.pwd.numbers)
        && (!registerError.pwd.symbolLengths)
    );

    const reg = () => {
        setClicked("yes");
        setRegisterError((prevState)=>{
            const newState = {...prevState}
            if(!UserInfo.firstName)
                newState.firstName = 'Неверный ввод!';
            if(!UserInfo.lastName)
                newState.lastName = 'Неверный ввод!';
            if(!UserInfo.vatherName)
                newState.vatherName = 'Неверный ввод!';
            if(!UserInfo.numberInn)
                newState.numberInn = 'Неверный ввод!';
            if(!UserInfo.orgType)
                newState.orgType = 'Неверный ввод!';
            if(!UserInfo.orgName)
                newState.orgName = 'Неверный ввод!';
            if(!UserInfo.checkbox)
                newState.checkbox ='Заполните согласие на обработку персональных данных';
/*            if(!UserInfo.postCode)
                newState.postCode = 'Неверно указан индекс';

 */
            if(!UserInfo.displayedName)
                newState.displayedName = 'Неверный ввод!';
/*            if(!UserInfo.number )
                newState.number = 'Неверный ввод!';
            if(!UserInfo.street)
                newState.street = 'Неверный ввод!';

 */
            if(!UserInfo.password)
                newState.pwd.symbolLengths = 'Неверный ввод!';
                return newState
        })
        if (!errorBool) {
                const data = createFormData(refactorUserData());
                store.DataLoadingON()
                AuthenticateService.register(data, Authenticate.REGISTER_DEALER)
                    .then((res) => {
                        if(res.status === 200) {
                            alert('Поздравляю, вы были успешно зарегистрированы на платформе! Пожалуйста, проверьте свою электронную почту, чтобы подтвердить свою учетную запись и иметь возможность войти в систему.')
                            navigate('/login',)
                        }
                    })
                    .catch((e)=> {
                        if(e.response.data.message === 'User already exists!') {
                            alert("Пользователь уже существует!");
                            setRegisterError({...registerError, email:'Пользователь уже существует!'})                        }
                        else
                            store.ErrorON()
                    })
                .finally(()=> {
                        store.DataLoadingOFF()
                    })
        }
        setTimeout(()=> {
            setClicked('no')
            if (BeginRef.current)
                BeginRef.current.scrollIntoView({ behavior: "smooth" });
        },500)

    }
    const onCheckHandler = (foo:boolean)=>{
        let str= foo.toString()
        setUserInfo({
            ...UserInfo,
            checkbox: str
        });
        if(foo)
            setRegisterError({...registerError, checkbox:''})
        else
            setRegisterError({...registerError, checkbox:'Заполните согласие на обработку персональных данных'})
    }

    return (
        <div ref={BeginRef}>
            <MenuSticky/>
            <div  style={{marginTop:"25px"}}>
                <CustomNavigateHeader
                   trashClickHandler={() => { }}
                   title={``}
                   withTrash={false}
               />
               <section>
                   <h1  className="register_top_header_bold">Регистрация</h1>
                   <h2 className="register_header">Поставщик</h2>
                   <div className={"register_header_bold"}>Личные данные</div>
                   <div className={"register_input_text"}>Фамилия</div>
                   <input
                       autoComplete={"off"}
                       name={"lastName"}
                       className={"register_input"}
                       type="text"
                       onChange={(e) => handleInputChange(e)}
                       value={UserInfo["lastName"]}
                       placeholder={'Фамилия'}
                       required
                   />
                   <InvalidFeedback title={registerError.lastName}/>

                   <div className={"register_input_text"}>Имя</div>
                   <input
                       autoComplete={"off"}
                       name={"firstName"}
                       className={"register_input"}
                       type="text"
                       onChange={(e) => handleInputChange(e)}
                       value={UserInfo["firstName"]}
                       placeholder={'Имя'}
                       required
                   />
                   <InvalidFeedback title={registerError.firstName}/>

                   <div className={"register_input_text"}>Отчество</div>
                   <input
                       autoComplete={"off"}
                       name={"vatherName"}
                       className={"register_input"}
                       type="text"
                       onChange={(e) => handleInputChange(e)}
                       value={UserInfo["vatherName"]}
                       placeholder={'Отчество'}
                       required
                   />
                   <InvalidFeedback title={registerError.vatherName}/>

                   <div className={"register_input_text"}>Электронная почта</div>
                   <input
                       name={"emailRegister"}
                       className={"register_input"}
                       type="text"
                       onChange={(e) => handleInputChange(e)}
                       value={UserInfo["email"]}
                       placeholder={'Email'}
                       required
                   />
                   <InvalidFeedback title={registerError.email}/>

                   <div className={"register_input_text"}>Номер телефона</div>
                   <InputMask
                       name={"phoneNumber"}
                       mask="+7 (999) 999-99-99"
                       className={"register_input"}
                       onChange={(e) => handleInputChange(e)}
                       value={UserInfo["phoneNumber"]}
                       placeholder={"+7"}
                       required
                   />
                   <InvalidFeedback title={registerError.phoneNumber}/>

                   <div className={"register_header_bold"}>Данные об организации</div>
                   {/*
                   <div className={"register_select_top_text"}>
                       <div className={"register_select_text"}>Регион</div>
                       <div className={"register_select_text"}>Город</div>
                   </div>

                   <div className={"register_select_top_text"}>
                       <div className={"register_select_component"}>
                           <CustomDropdown options={ArrayRegions} value={regionName} valueSetter={setRegionName} onChangeHandler={onRegionsSelectChange}/>
                       </div>
                       <div className={"register_select_component"}>
                           <CustomDropdown options={ArrayCitys} value={cityName} valueSetter={setCityName} onChangeHandler={onCitySelectChange}/>
                       </div>
                   </div>
                   <div style={{marginTop:"15px"}}>
                       <InvalidFeedback title={registerError.cityId}/>
                   </div>
                   <div className={"register_input_text"}>Адрес регистрации</div>

                   <div className={"register_select_top_text"}>
                       <div className={"input_street"}>
                           <input
                               className={"register_input"}
                               type="text"
                               name={"street"}
                               value={UserInfo["street"]}
                               placeholder={"Улица"}
                               onChange={(e) => handleInputChange(e)}
                               required
                           />
                       </div>
                       <div className={"input_number_box"}>
                           <input
                               className={"register_input"}
                               type="text"
                               name={"number"}
                               value={UserInfo["number"]}
                               placeholder={"Дом"}
                               onChange={(e) => handleInputChange(e)}
                               required
                           />
                       </div>
                   </div>
                   {(registerError.street || registerError.number)&&<div style={{marginTop: "15px"}}>
                       <InvalidFeedback title={'Неверный ввод!'}/>
                   </div>}

                   <div className={"register_input_text"}>Почтовый индекс</div>
                   <InputMask
                       id={"input-postCode"}
                       mask="999999"
                       className={"register_input"}
                       type="text"
                       name={"postCode"}
                       value={UserInfo["postCode"]}
                       placeholder={""}
                       onChange={(e) => handleInputChange(e)}
                       required
                   />
                   <InvalidFeedback title={registerError.postCode}/>

                   */}
                   <div className={"register_input_text"}>Тип организации</div>

                   <div className={"register_select_component"}>
                       <CustomDropdown options={[{key:"ООО",name:"ООО"},{key:"ИП",name:"ИП"},{key:"Физ. лицо",name:"Физ. лицо"}]} value={typeOrg} valueSetter={setTypeOrg} onChangeHandler={onTypeOrgSelectChange}/>
                   </div>
                   <div style={{marginTop:"15px"}}>
                       <InvalidFeedback title={registerError.orgType}/>
                   </div>

                   <div className={"register_input_text"}>Номер ИНН</div>
                   <InputMask
                       autoComplete={"nope"}
                       mask={innMask}
                       className={"register_input"}
                       type="text"
                       name={"numberInn"}
                       value={UserInfo["numberInn"]}
                       placeholder={UserInfo["numberInn"]}
                       onChange={(e) => handleInputChange(e)}
                       required
                   />
                   <InvalidFeedback title={registerError.numberInn}/>

                   <div className={"register_input_text"}>Юридическое название</div>
                   <input
                       autoComplete={"false"}
                       className={"register_input"}
                       type="text"
                       name={"orgNameRegister"}
                       value={UserInfo["orgName"]}
                       placeholder={""}
                       onChange={(e) => handleInputChange(e)}
                       required
                   />
                   <InvalidFeedback title={registerError.orgName}/>

                   <div className={"register_input_text"}>Название на нашем портале</div>
                   <input
                       autoComplete={"off"}
                       className={"register_input"}
                       type="text"
                       name={"displayedNameRegister"}
                       value={UserInfo["displayedName"]}
                       placeholder={""}
                       onChange={(e) => handleInputChange(e)}
                       required
                   />
                   <InvalidFeedback title={registerError.displayedName}/>

                   <div className={"register_header_bold"}>Придумайте пароль</div>

                   <div className={"register_input_text"}>Пароль</div>

                   <div className={"row"}>
                       <input
                           autoComplete={"off"}
                           name={"passwordRegister"}
                           className={"register_input"}
                           style={(isRevealPwd || UserInfo["password"].length===0) ? {} : {fontFamily:"Password",lineHeight:"18px",fontSize:"18px",letterSpacing:"2px"}}
                           //type={isRevealPwd ? "text" : "password"}
                           type={"text"}
                           onChange={(e) => handleInputChange(e)}
                           value={UserInfo["password"]}
                           placeholder={"Пароль"}
                           required
                       />

                       <img
                           className={"eye_img"}
                           src={isRevealPwd ? "/Pictures/eye2.svg" : "/Pictures/eye.svg"}
                           onClick={() => setIsRevealPwd(prevState => !prevState)}
                       />
                   </div>
                   <InvalidFeedback title={registerError.pwd.numbers}/>
                   <InvalidFeedback title={registerError.pwd.lowCh}/>
                   <InvalidFeedback title={registerError.pwd.symbolLengths}/>

                   <div className={"register_input_text"}>Подтвердите пароль</div>
                   <div className={"row"}>
                       <input
                           autoComplete={"off"}
                           name={"password_again"}
                           className={"register_input"}
                           style={(isRevealPwd || password_again.length===0) ? {} : {fontFamily:"Password",lineHeight:"18px",fontSize:"18px",letterSpacing:"2px"}}
                           //type={isRevealPwd ? "text" : "password"}
                           type={"text"}
                           onChange={(e) => PassAgainHandler(e)}
                           value={password_again}
                           placeholder={"Подтвердите пароль"}
                           required
                       />

                       <img
                           className={"eye_img"}
                           src={isRevealPwd ? "/Pictures/eye2.svg" : "/Pictures/eye.svg"}
                           onClick={() => setIsRevealPwd(prevState => !prevState)}
                       />
                   </div>
                   <InvalidFeedback title={registerError.repwd}/>

                   <div className={"persolan_data_text"}>
                       <Checkbox01 changeHandler={(e) => onCheckHandler(e.target.checked)}/>
                       {/*<input
                            type="checkbox"
                            onChange={(e) => onCheckHandler(e.target.checked)}
                            required />*/}
                       <div className={"register-persolan_data_button-container"}>
                           Согласие на обработку персональных данных
                           <button onClick={handlerPD} className={"register-persolan_data_button"}>
                               Посмотреть
                           </button>
                       </div>
                   </div>
                   <InvalidFeedback title={registerError.checkbox}/>

                   {/*<ReCAPTCHA*/}
                   {/*    className={"register-captcha"}*/}
                   {/*    sitekey="6Leo-H8pAAAAAMRJoR-TnygJewAShRyI2w_podVR"*/}
                   {/*    onChange={(e: any) => e !== null? setCanRegister(true) : setCanRegister(false)}*/}
                   {/*/>*/}

                   <div className="row" data-clicked={clicked}>
                       <button
                           form={"register_form_id"}
                           className="btn_register"
                           onClick={reg}
                           // disabled={!canRegister}
                       >
                           Зарегистрироваться
                       </button>
                   </div>
               </section>
           </div>
            {openPopupPD &&
                <div className={"register-popup-personal-data-bg"}>
                    <div className={"register-popup-personal-data"} ref={refPopupPD}>
                        <div className={"register-popup-header"}>
                            <div></div>
                            <div>Согласие на обработку персональных даннных</div>
                            <div onClick={() => setOpenPopupPD(false)}><img src={"/Pictures/BlackCross.svg"}/></div>
                        </div>
                        <div className={"register-popup-content"}>
                            <p>
                                Присоединяясь к настоящему Соглашению и оставляя свои данные на Сайте , (далее – Сайт),
                                путем заполнения полей при регистрации Пользователь:
                            </p>
                            <p>
                                – подтверждает, что указанные им персональные данные принадлежат лично ему; признает и
                                подтверждает, что он внимательно и в полном объеме ознакомился с настоящим Соглашением и
                                содержащимися в нем условиями обработки его персональных данных, указываемых им в полях
                                онлайн регистрации на Сайте;
                            </p>
                            <p>
                                – признает и подтверждает, что все положения настоящего Соглашения и условия обработки
                                его персональных данных ему понятны;
                            </p>
                            <p>
                                – дает согласие на обработку Сайтом предоставляемых персональных данных в целях
                                регистрации Пользователя на Сайте;
                            </p>
                            <p>
                                – выражает согласие с условиями обработки персональных данных без каких-либо оговорок и
                                ограничений.
                            </p>
                            <p>
                                Пользователь дает свое согласие на обработку его персональных данных, а именно
                                совершение действий, предусмотренных п. 3 ч. 1 ст. 3 Федерального
                                закона от 27.07.2006 N 152-ФЗ «О персональных данных», и подтверждает, что, давая такое
                                согласие, он действует свободно, своей волей и в своем интересе.
                                Согласие Пользователя на обработку персональных данных является конкретным,
                                информированным и сознательным.
                                Настоящее согласие Пользователя применяется в отношении обработки
                                следующих персональных данных:
                            </p>
                            <p>
                                – фамилия, имя, отчество;<br/>
                                – номер телефона;<br/>
                                – адрес электронной почты (E-mail);<br/>
                            </p>
                            <p>
                                Кроме того, в зависимости от роли Пользователя на Сайте, предоставляются следующие
                                дополнительные данные:
                            </p>
                            <p>
                                Косметолог:<br/>
                                – сведения диплома об образовании;<br/>
                                – адрес доставки.<br/>
                            </p>
                            <p>
                                Медицинское учреждение:<br/>
                                – ИНН организации;<br/>
                                – наименование организации;<br/>
                                – тип организации (ООО, ИП, физическое лицо);<br/>
                                – адрес доставки.<br/>
                            </p>
                            <p>
                                Поставщик:<br/>
                                – ИНН организации;<br/>
                                – наименование организации;<br/>
                                – тип организации (ООО, ИП, физическое лицо);<br/>
                                – адрес регистрации организации;<br/>
                                – адреса складов.
                            </p>
                            <p>
                                Пользователь, предоставляет сервису Прокрасивое право осуществлять следующие действия
                                (операции) с персональными данными:
                            </p>
                            <p>
                                – сбор и накопление;
                                пользования услуг Сайта Пользователем;
                                – уточнение (обновление, изменение);
                                – использование в целях регистрации Пользователя на Сайте;
                                – уничтожение;
                                – передача по требованию суда, в т.ч. третьим лицам, с соблюдением мер,
                                обеспечивающих защиту персональных данных от несанкционированного
                                доступа.
                            </p>
                            <p>
                                Указанное согласие действует бессрочно с момента предоставления данных и может быть
                                отозвано Вами путем подачи заявления администрации Сайта с указанием данных,
                                определенных ст. 14 Закона «О персональных данных».
                            </p>
                            <p>
                                Отзыв согласия на обработку персональных данных может быть осуществлен путем направления
                                Пользователем соответствующего распоряжения в простой письменной форме на адрес
                                электронной почты (E-mail) prokrasivoe@bk.ru
                            </p>
                            <p>
                                Сайт не несет ответственности за использование (как правомерное, так и неправомерное)
                                третьими лицами информации, размещенной Пользователем на Сайте, включая её
                                воспроизведение и распространение, осуществленные всеми возможными способами. Сайт имеет
                                право вносить изменения в настоящее Соглашение. При внесении изменений в актуальной
                                редакции указывается дата последнего обновления. Новая редакция Соглашения вступает в
                                силу с момента ее размещения, если иное не предусмотрено новой редакцией Соглашения.
                            </p>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Seller;