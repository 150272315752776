import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import "../../UserLayout/MenuSticky/Menu/Menu.css";
import $api from "../../../../api/axios";
import Button_4 from "../../../Supplier_UI_kit/Button_4/Button_4";
import { IUser } from "../../../../models/user/IUser";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";
import {randomizeDefaultProfileImgPath} from "../../../../functions/functions";
import {observer} from "mobx-react-lite";

interface Interface {
    unread:boolean;
}
function Header({unread}:Interface) {
    const {store} = useContext(Context)

    useEffect(() => {
        if (store.isHubOn) {
            store.hubConnection.on("UpdateCounter", (forChat: string) => {
                if(!window.location.href.includes(forChat)){
                    store.updateUnread(true);
                }
            });
        }
    }, [store.isHubOn]);


    return (
        <>
        {(!store.isError && !store.isAuthLoading)&&(
            <div className="header-div_admin">
                <Link to={"/"}>
                    <div className="img-profile-container_admin">
                        <div className={"default-profile-admin"}>
                            {
                                store.user?.image?
                                    <CustomIMG className="img-profile-menu-admin" src={store.user?.image} />:
                                    <img className="img-profile-menu-admin" src={'/Pictures/Admin-logo.png'} alt="User Logo"/>
                            }
                        </div>
                    </div>
                </Link>
                <div className="logo-and-homeButton_admin">
                    <Link className="img-logo-header_admin" to={"/"}>
                        <img src="/Pictures/Лого.svg" />
                    </Link>
                </div>

                <div className={"header-buttons_admin"}>
                    <Link to={"/"} className="cabinet_button_admin">Кабинет</Link>
                    {unread?
                        <Link className={"headerNotification"} to={SpaRoutes.ADMIN_CHAT}>

                            <img style={{width:"inherit",height:"inherit"}} src={"/Pictures/Уведомление_2.png"}/>

                        </Link>:
                        <Link className={"headerNotification"} to={SpaRoutes.ADMIN_CHAT}>

                            <img style={{width:"inherit",height:"inherit"}}  src="/Pictures/Уведомление_1.png"/>

                        </Link>
                    }
                </div>
            </div>
        )
        }
        </>
    );
}

export default observer (Header);
