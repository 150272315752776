import React, {useContext, useEffect, useRef, useState} from 'react';
import {IReklama, IReservation} from "../../../../models/models";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import {all} from "axios";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import PreviewReklama from "../../../customUI/PreviewReklama/PreviewReklama";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";


interface IReservationPopup {
    reservations: IReservation[],
    day: number,
    month: number,
    year: number,
    onClose: () => void,
    userReklama: IReklama[],
    price: { userBonuses: number, first: number, other: number }
    onUpdate: () => void,
    type: number,
    onlyMyProps: boolean,
    onlyMyProps1: boolean,
    selectRegion: string,
    selectedReklama: string[]
}

const ReservationPopup = ({
                              reservations,
                              day,
                              month,
                              year,
                              onClose,
                              userReklama,
                              onUpdate,
                              price,
                              type,
                              onlyMyProps,
                              selectRegion,
                              selectedReklama,
                              onlyMyProps1
                          }: IReservationPopup) => {

    const {store} = useContext(Context)

    const [activeReklama, setActiveReklama] = useState("")
    const [oldActiveReklama, setOldActiveReklama] = useState("")

    const [activeSlide, setActiveSlide] = useState(reservations[0]?.position ? reservations[0].position : 1)

    const [allRes, setAllRes] = useState<IReservation[]>([])
    const [oldAllRes, setOldAllRes] = useState<IReservation[]>([])

    const [approveReklama, setApproveRekalma] = useState<IReklama[]>()

    const [finishPrice, setFinishPrice] = useState(0)

    const [canUpdate, setCanUpdate] = useState(onlyMyProps)
    const [onlyMy, setOnlyMy] = useState(onlyMyProps1 ? onlyMyProps1 : onlyMyProps)

    const [previewReklama, setPreviewReklama] = useState<JSX.Element | null>(null)

    useEffect(() => {

        let mas: IReservation[] = []
        let countSlides = 0
        if (type == 0 && selectRegion !== "global") countSlides = 4
        else if (type == 1 || selectRegion === "global") countSlides = 1
        if (!onlyMy && !onlyMyProps1) {
            for (let i = 1; i <= countSlides; i++) {
                let res = reservations.find(r => r.position === i)

                if (res) {
                    let rek = userReklama.find(r => r.reklamaId == res?.reklamaId)
                    if (rek)
                        mas.push({...res, reklama: rek, isUserReklama: true, isCanUpdate: false})
                    else
                        mas.push({...res, reklama: undefined, isUserReklama: false, isCanUpdate: false})
                } else mas.push({position: i, isCanUpdate: true})
            }
        } else {
            reservations.map(res => {
                if (res.reklamaId !== "") {
                    let rek = userReklama.find(r => r.reklamaId == res?.reklamaId)
                    mas.push({...res, reklama: rek, isUserReklama: true, isCanUpdate: true})
                }
            })
        }
        setAllRes(mas)
        setOldAllRes(mas)
    }, [reservations])

    useEffect(() => {
        setApproveRekalma(userReklama.filter(r => {
            if ((r.status == 2 || r.status == 4) && r.type == type && r.reklamaId) {
                return r
            }
        }))
    }, [userReklama, activeSlide])

    useEffect(() => {
        if (activeSlide === 1 && !approveReklama?.find(r => r.reklamaId === activeReklama)) {
            setFinishPrice(price.first)
        } else {
            setFinishPrice(price.other)
        }
    }, [activeSlide])

    useEffect(() => {
        let res = allRes.find(r => r.position === activeSlide)
        if (res?.reklamaId) {
            setActiveReklama(res?.reklamaId)
        } else {
            setActiveReklama("")
        }

        res?.isCanUpdate ? setCanUpdate(true) : setCanUpdate(false)
    }, [activeSlide, allRes])

    useEffect(() => {
        let res = allRes.find(r => r.position === activeSlide)
        if (res?.reklamaId)
            setOldActiveReklama(res?.reklamaId)
        else
            setOldActiveReklama("")
    }, [activeSlide])

    useEffect(() => {
        let updatedRes = [...allRes];

        const indexToUpdate = updatedRes.findIndex(item => item.position === activeSlide);

        if (indexToUpdate !== -1) {
            let rek = approveReklama?.find(r => r.reklamaId === activeReklama)
            updatedRes[indexToUpdate] = {
                ...updatedRes[indexToUpdate],
                reklamaId: activeReklama,
                reklama: rek
            };
            if (rek && rek.status != 4)
                setAllRes(updatedRes);
        }
    }, [activeReklama])

    const reservationReklama = () => {

        if (!onlyMy) {
            let data = {
                regionId: selectRegion,
                month: month,
                year: year,
                day: day,
                type: type,
                position: activeSlide,
                reklamaId: activeReklama
            }
            store.DataLoadingON()
            CabinetSellerService.buyReservations(data)
                .then(() => {
                    onClose()
                    alert("Успешное бронирование")
                })
                .catch((e) => {
                    if (e.response.data.status === "Error_1") {
                        alert("Нет данных")
                    } else if (e.response.data.status === "Error_2") {
                        alert("Релкама не найдена")
                    } else if (e.response.data.status === "Error_3") {
                        alert("Поставщик не найден")
                    } else if (e.response.data.status === "Error_4") {
                        alert("Выбранная реклама не прошла модерацию")
                    } else if (e.response.data.status === "Error_5") {
                        alert("Выбранная реклама имеет неверный тип")
                    } else if (e.response.data.status === "Error_6") {
                        alert("У вас не хватает бонусов")
                    } else if (e.response.data.status === "Error_7") {
                        alert("Регион не найден")
                    } else if (e.response.data.status === "Error_8") {
                        alert("Выбранный слайд уже забронирован")
                    } else if (e.response.data.status === "Error_9") {
                        alert("Position exceeds limit")
                    } else if (e.response.data.status === "Error_10") {
                        alert("Position must be > 0")
                    } else alert("Что-то пошло не так")
                })
                .finally(() => {
                    store.DataLoadingOFF()
                    onUpdate()
                })
        } else {
            let reservation = reservations.find(r => r.position == activeSlide)
            let data = {
                reservationId: reservation?.id,
                reklamaId: activeReklama
            }
            store.DataLoadingON()
            CabinetSellerService.changeReservations(data)
                .then(() => {
                    alert("Бронирование успешно изменено")
                    onUpdate()
                    onClose()
                })
                .catch((e) => {
                    alert("Что-то пошло не так")
                })
                .finally(() => store.DataLoadingOFF())
        }
    }

    const ref = useRef(null)
    const refPreview = useRef(null)
    useOnClickOutside(ref, () => onClose(), [refPreview])

    return (
        <>
            <div ref={refPreview}>
                {previewReklama && previewReklama}
            </div>
            <div className={"reservationPopup"}>

                <div className={"reservationPopup-content"} ref={ref}>
                    <div className={"reservationPopup-title"}>
                        <img onClick={onClose} src={"/Pictures/strelochka.svg"}/>
                        <p>{onlyMy ? "Забронировано вами" : "Бронирование"} на {day}.{month}.{year}</p>
                    </div>

                    <div className={"reservationPopup-reservation"}>
                        <div className={"reservationPopup-reservation-slides"}>
                            {allRes.map((r, index) => {
                                return (
                                    <div className={"reservationPopup-reservation-slide"}
                                         data-sel={activeSlide === r.position}
                                         onClick={() => {
                                             setActiveSlide(r.position)
                                             setAllRes(oldAllRes)
                                         }}
                                         key={index}
                                    >
                                        <div className={"reservationPopup-reservation-slide-title"}>
                                            {
                                                selectRegion === "global" ?
                                                    ("Вся Россия") :
                                                    type == 0 ?
                                                        (
                                                            <>
                                                                Слайд
                                                                №{r.position} {(r.position === 1 && allRes.length > 1) && "- всегда первый"}
                                                                {r.reklama ? "" :
                                                                    r.isUserReklama === false ?
                                                                        <div>Забронировано</div> : ""
                                                                }
                                                            </>
                                                        ) : (<></>)
                                            }
                                        </div>
                                        <div className={"reservationPopup-reservation-slide-preview"}>
                                            {r.reklama ?
                                                r.reklama.status == 4 ?
                                                    <div style={{color: "red"}}>Ваша реклама<br/>заблокирована</div> :
                                                    r.reklama.status == 0 ?
                                                        <div>Ваша реклама<br/>не отправлена на модерацию</div> :
                                                        r.reklama.status == 1 ?
                                                            <div>Ваша реклама<br/>на модерации</div> :
                                                            r.reklama.status == 3 ?
                                                                <div>Ваша реклама<br/>отклонена</div> :
                                                                <img
                                                                    className={"reservationPopup-reservation-slide-preview-image"}
                                                                    src={r.reklama?.imageBigUrl ? store.imgServer + r.reklama.imageBigUrl : ""}/>
                                                :
                                                r.isUserReklama === false ? <div>Забронировано</div> :
                                                    <div>Свободно</div>
                                            }
                                            {(r.position == 1 && (allRes.length > 1 || (onlyMy && (selectRegion !== "global" && type !== 1)))) &&
                                                <img className={"reservationPopup-reservation-slide-preview-star"}
                                                     src={"/Pictures/starIcon.svg"}/>}
                                        </div>

                                    </div>
                                )
                            })}

                        </div>
                        <div className={"reservationPopup-reservation-userReklamas-container"}>
                            <Link to={SpaRoutes.SUPPLIER_REKLAMA + "/create"}
                                  className={"reservationPopup-createNewReklama"}>
                                <div>Создать новый баннер</div>
                            </Link>

                            <div style={{textAlign: "center"}}>Выберите баннер, который хотите разместить</div>

                            <div className={"reservationPopup-reservation-userReklamas"} data-region={selectRegion}>

                                {approveReklama?.map((r, index) => {
                                    if (r.deleted !== true) {
                                        return (
                                            <div className={"reservationPopup-reservation-userReklama"}
                                                 data-sel={activeReklama === r.reklamaId}
                                                 key={index}
                                                 data-dis={(r.reklamaId && selectedReklama.includes(r.reklamaId) && activeReklama !== r.reklamaId)}
                                            >
                                                <img src={activeReklama === r.reklamaId ?"/Pictures/checkbox2.svg": "/Pictures/checkbox-empty.png"}
                                                     onClick={() => {
                                                         (r.reklamaId && (canUpdate || onlyMy) && !selectedReklama.includes(r.reklamaId)) && setActiveReklama(r.reklamaId)
                                                     }}
                                                />
                                                <div>
                                                    {r.name}
                                                    {r.status !== 4 ?
                                                        <span>{(r.reklamaId && selectedReklama.includes(r.reklamaId) && activeReklama !== r.reklamaId) && "Уже используется на этот день"}</span>
                                                        :
                                                        <span style={{color: "red"}}>Заблокированная реклама</span>
                                                    }

                                                </div>
                                                <div className='reservstionPopup-check'>

                                                    <button className={"reservationPopup-reservation-preview-button"}
                                                            onClick={(e) => {
                                                                if (r.imageBigUrl) {
                                                                    e.stopPropagation();
                                                                    setPreviewReklama
                                                                    (<PreviewReklama key={0}
                                                                                     reklamaImg={store.imgServer + r.imageBigUrl}
                                                                                     typePreview={r.type}
                                                                                     typeDevice={"desktop"}
                                                                                     description={r.description}
                                                                                     linkName={r.linkName}
                                                                                     link={r.link}
                                                                                     onClose={() => setPreviewReklama(null)}
                                                                    />)
                                                                } else alert("Нет картинки")
                                                            }}
                                                    >
                                                        Предпросмотр

                                                    </button>
                                                </div>
                                                {/*<img
                                            src={activeReklama === r.reklamaId ? "/Pictures/invoicetablelinkWhite.svg" : "/Pictures/invoicetablelink.svg"}

                                        />*/}
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={"reservationPopup-reservation-apply"}>
                        {!onlyMy &&
                            <>
                                <div className={"reservationPopup-reservation-bonuses"}>
                                    Ваши бонусы: {price.userBonuses}
                                </div>
                                <div className={"reservationPopup-reservation-bonuses"}>
                                    {finishPrice &&
                                        `Цена за слайд: ${finishPrice}`
                                    }
                                </div>
                            </>
                        }
                        {onlyMy &&
                            <button onClick={reservationReklama}
                                    disabled={onlyMy ? false : !canUpdate}>Изменить</button>
                        }
                        {!onlyMy &&
                            <button onClick={reservationReklama}
                                    disabled={activeReklama === oldActiveReklama}>Забронировать</button>
                        }

                    </div>
                </div>
            </div>
        </>
    );
};

export default ReservationPopup;