import React from "react";
import './Carousel.css'
import './Carousel-media.css'

type CarouselButtonProps = {
    SlideHandler: () => void;
}

function CarouselButton({SlideHandler}:CarouselButtonProps) {
    return(
            <li onClick={() => {SlideHandler()}}>
                <a className={"carousel-link"}><img src={"Pictures/Rectangle 532.svg"}/></a>
            </li>
    )
}export default CarouselButton;