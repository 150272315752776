import React, {useContext, useEffect, useRef, useState} from 'react';
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IReklama} from "../../../../models/models";
import {Context} from "../../../../index";
import PreviewReklama from "../../../customUI/PreviewReklama/PreviewReklama";

interface IReklamaItems {
    reklama: IReklama,
    openProps: boolean,
    updateData: () => void
}

const ReklamaItem = ({reklama, openProps, updateData}: IReklamaItems) => {

    const input1Ref = useRef(null);
    const input2Ref = useRef(null);

    const [reklamaData, setReklamaData] = useState<IReklama>(reklama)

    const [reklamaMainFile, setReklamaMainFile] = useState<File>()
    const [reklamaMainPreview, setReklamaMainPreview] = useState<string | undefined>()
    const [dragMain, setDragMain] = useState(false)

    const [reklamaMiniFile, setReklamaMiniFile] = useState<File>()
    const [reklamaMiniPreview, setReklamaMiniPreview] = useState<string | undefined>()
    const [dragMini, setDragMini] = useState(false)

    // const [open, setOpen] = useState(false)
    let open = false;
    const [previewReklama, setPreviewReklama] = useState<JSX.Element | null>(null)

    const {store} = useContext(Context)

    useEffect(() => {
        setReklamaData(reklama)
        setReklamaMainPreview(reklama.imageBigUrl ? store.imgServer + reklama.imageBigUrl : undefined)
        setReklamaMiniPreview(reklama.imageSmallUrl ? store.imgServer + reklama.imageSmallUrl : undefined)
    }, [reklama])

    useEffect(() => {
        // setOpen(openProps)
        open = openProps
    }, [openProps])

    const onBtn1PictureClick = () => {
        // @ts-ignore
        input1Ref.current?.click();
    }

    const onBtn2PictureClick = () => {
        // @ts-ignore
        input2Ref.current?.click();
    }
    const selectMainPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setReklamaMainPreview(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setReklamaMainFile(event.target.files[event.target.files.length - 1])
    };

    const selectMiniPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setReklamaMiniPreview(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setReklamaMiniFile(event.target.files[event.target.files.length - 1])
    };

    useEffect(() => {
        if (!reklamaMainFile) {
            //setElementPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(reklamaMainFile)
        setReklamaMainPreview(objectUrl)

        let img = new Image()
        img.src = objectUrl
        img.onload = () => {
            if (img.width !== (reklama.type === 0 ? 1600 : 1800) || img.height !== (reklama.type === 0 ? 500 : 150))
                alert(`Острожно! Изображение не соответсвует размерам ${reklama.type === 0 ? "1600x600" : "1800x150"}`)
        }


        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [reklamaMainFile])

    useEffect(() => {
        if (!reklamaMiniFile) {
            //setElementPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(reklamaMiniFile)
        setReklamaMiniPreview(objectUrl)

        let img = new Image()
        img.src = objectUrl
        img.onload = () => {
            if (img.width !== (reklama.type === 0 ? 390 : 600) || img.height !== (reklama.type === 0 ? 200 : 70))
                alert(`Острожно! Изображение не соответсвует размерам ${reklama.type === 0 ? "390x200" : "600x70"}`)
        }
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [reklamaMiniFile])

    const createFormData = (data: any) => {
        return Object.keys(data).reduce((formData, key) => {
            formData.append(key, data[key]);
            return formData;
        }, new FormData());
    };
    const refactorData = (data: { [x: string]: any; }) => {
        for (const key in data) {
            if (data[key] === null || data[key] === undefined)
                delete data[key]
        }
        return data
    }

    const UpdateReklama = (status: number | undefined) => {
        if (status === 1) {
            if (!window.confirm("Рекаламу на модерации нельзя будет редактировать\nПродолжить?")) {
                return
            }
        }
        let reklama = {
            reklamaId: reklamaData.reklamaId,
            type: reklamaData.type,
            name: reklamaData.name,
            imageBig: reklamaMainFile,
            imageSmall: reklamaMiniFile,
            description: reklamaData.description,
            link: reklamaData.link,
            linkName: reklamaData.linkName,
            status: status
        }

        let data = createFormData(refactorData(reklama))
        store.DataLoadingON()
        CabinetSellerService.addUpdateEntertainment(data)
            .then(() => {
                if (status === 1)
                    alert("Реклама успешно сохранена и отправлена на модерацию")
                else
                    alert("Реклама успешно сохранена")

                updateData()
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    const getReklamaStatus = () => {
        switch (reklama.status) {
            case 0:
                return "Отправьте на модерацию"
            case 1:
                return "Данная реклама проходит модерацию"
            case 2:
                return "Данная реклама прошла модерацию"
            case 3:
                return "Данную рекламу отклонили"
            case 4:
                return "Данная реклама заблокирована"
            default:
                return ""
        }
    }

    const DeleteReklama = () => {
        if (window.confirm("Вы точно хотите удалить эту рекламу?") && reklama.reklamaId) {
            store.DataLoadingON()
            CabinetSellerService.DeleteEntertainment(reklama.reklamaId)
                .then(() => {
                    alert("Реклама была удалена")
                    updateData()
                })
                .catch(() => alert("Что-то пошло не так"))
                .finally(() => store.DataLoadingOFF())
        }
    }

    return (
        <>
            {previewReklama && previewReklama}
        <details className={"supplierReklama-reklama-container"} open={open || openProps}>
            <summary className={"supplierReklama-reklama-header"} onClick={() => open = !open}>
                <div>{reklama.name? reklama.name : "Нет названия"}</div>
                <div className={"supplierReklama-reklama-hint-status"} data-status={reklama.status}>
                    {getReklamaStatus()}
                </div>

            </summary>
            <div className={"supplierReklama-reklama"}>
                <div className={"supplierReklama-reklama-images"}>
                    <div className={"supplierReklama-reklama-preview"}>
                        <div className={"supplierReklama-reklama-hint"}>
                            Слайд для компьютера
                        </div>
                        <div
                            className={"supplierReklama-reklama-photo-image-desktop" + (dragMain ? " supplierReklama-reklama-photo-image-dragEnter" : "")}
                            onDragOver={(e) => e.preventDefault()}
                            onDragEnter={(e) => {
                                e.preventDefault();
                                if (!(reklama.status === 1))
                                    setDragMain(true)
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                if (!(reklama.status === 1))
                                    setDragMain(false)
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                if (!(reklama.status === 1)) {
                                    setReklamaMainFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
                                    setDragMain(false)
                                }
                            }}
                            style={{aspectRatio: reklama.type === 0 ? "1600/500" : "1800/150"}}
                        >
                            {reklamaMainPreview ?
                                <img src={reklamaMainPreview}
                                     style={{aspectRatio: reklama.type === 0 ? "1600/500" : "1800/150"}}/>
                                :
                                <div className={"supplierReklama-reklama-image-text"}>
                                    Поместите сюда фото {reklama.type === 0 ? "1600x500" : "1800x150"}
                                </div>
                            }
                        </div>
                        <div className={"supplierReklama-reklama-preview-buttons"}>
                            <button
                                    onClick={() => {
                                        if (reklamaMainPreview) {
                                            setPreviewReklama
                                            (<PreviewReklama key={reklama.reklamaId} reklamaImg={reklamaMainPreview}
                                                             typePreview={reklama.type}
                                                             typeDevice={"desktop"}
                                                             description={reklama.description}
                                                             linkName={reklama.linkName}
                                                             link={reklama.link}
                                                             onClose={() => setPreviewReklama(null)}
                                            />)
                                        }else alert("Нет картинки")
                                    }}
                            >
                                Предпросмотр
                            </button>
                            <input
                                ref={input1Ref}
                                style={{display: 'none'}}
                                type="file"
                                name={"profilePicture"}
                                onChange={selectMainPicture}
                                accept="image/jpeg, image/png"
                                disabled={reklama.status === 1}
                            />
                            <button onClick={onBtn1PictureClick} disabled={reklama.status === 1}></button>
                        </div>
                    </div>
                    <div className={"supplierReklama-reklama-preview"}>
                        <div className={"supplierReklama-reklama-hint"}>
                            Слайд для телефона
                        </div>
                        <div
                            data-type={reklama.type}
                            className={"supplierReklama-reklama-photo-image-mobile" + (dragMini ? " supplierReklama-reklama-photo-image-dragEnter" : "")}
                            onDragOver={(e) => e.preventDefault()}
                            onDragEnter={(e) => {
                                e.preventDefault();
                                if (!(reklama.status === 1))
                                    setDragMini(true)
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                if (!(reklama.status === 1))
                                    setDragMini(false)
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                if (!(reklama.status === 1)) {
                                    setReklamaMiniFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
                                    setDragMini(false)
                                }
                            }}
                            style={{aspectRatio: reklama.type === 0 ? "390/200" : "600/70"}}
                        >
                            {reklamaMiniPreview ?
                                <img src={reklamaMiniPreview}
                                     style={{aspectRatio: reklama.type === 0 ? "390/200" : "600/70"}}/>
                                :
                                <div className={"supplierReklama-reklama-image-text"}>
                                    Поместите сюда фото {reklama.type === 0 ? "390x200" : "600x70"}
                                </div>
                            }
                        </div>
                        <div className={"supplierReklama-reklama-preview-buttons"}>
                            <button
                                    onClick={() => {
                                        if (reklamaMiniPreview) {
                                            setPreviewReklama
                                            (<PreviewReklama key={reklama.reklamaId} reklamaImg={reklamaMiniPreview}
                                                             typePreview={reklama.type}
                                                             typeDevice={"mobile"}
                                                             description={reklama.description}
                                                             linkName={reklama.linkName}
                                                             link={reklama.link}
                                                             onClose={() => setPreviewReklama(null)}
                                            />)
                                        }else alert("Нет картинки")
                                    }}
                            >
                                Предпросмотр
                            </button>
                            <input
                                ref={input2Ref}
                                style={{display: 'none'}}
                                type="file"
                                name={"profilePicture"}
                                onChange={selectMiniPicture}
                                accept="image/jpeg, image/png"
                                disabled={reklama.status === 1}
                            />
                            <button onClick={onBtn2PictureClick} disabled={reklama.status === 1}></button>
                        </div>
                    </div>
                </div>
                <div className={"supplierReklama-reklama-settings"}>
                    <div className={"supplierReklama-reklama-setting"}>
                        <div className={"supplierReklama-reklama-hint"}>
                            Название рекламы
                            <div>Название видно только вам</div>
                        </div>
                        <CustomInput name={"name"} inputSize={"small"}
                                     value={reklamaData.name}
                                     onChange={(e) => setReklamaData({...reklamaData, name: e.target.value})}
                                     type={"text"}
                                     disabled={reklama.status === 1}
                                     placeHolder={"Название рекламы"}
                        />
                    </div>
                    <div className={"supplierReklama-reklama-setting"}>
                        <div className={"supplierReklama-reklama-hint"}>
                            Текст рекламы
                            <div>Текст рекламы виден пользователям</div>
                        </div>
                        <textarea maxLength={5000} value={reklamaData.description ? reklamaData.description : undefined}
                                  onChange={(e) => {
                                      setReklamaData({...reklamaData, description: e.target.value})
                                  }}
                                  disabled={reklama.status === 1}
                                  placeholder={"Текст рекламы"}
                        />
                    </div>
                    <div className={"supplierReklama-reklama-setting"}>
                        <div className={"supplierReklama-reklama-hint"}>
                            Название кнопки
                            <div>При помощи кнопки пользователь сможет перейти по ссылке</div>
                        </div>
                        <CustomInput name={"urlName"} inputSize={"small"}
                                     value={reklamaData.linkName}
                                     onChange={(e) => setReklamaData({
                                         ...reklamaData,
                                         linkName: e.target.value
                                     })}
                                     type={"text"}
                                     disabled={reklama.status === 1}
                        />
                    </div>
                    <div className={"supplierReklama-reklama-setting"}>
                        <div className={"supplierReklama-reklama-hint"}>
                            Ссылка
                        </div>
                        <CustomInput name={"URL"} inputSize={"small"}
                                     value={reklamaData.link}
                                     onChange={(e) => setReklamaData({...reklamaData, link: e.target.value})}
                                     type={"text"}
                                     disabled={reklama.status === 1}
                                     placeHolder={"Ссылка"}
                        />
                    </div>
                    <div className={"supplierReklama-reklama-hint-status"} data-status={reklama.status}>
                        {getReklamaStatus()}
                    </div>
                    <div className={"supplierReklama-reklama-settings-buttons"}>
                        <button className={"supplierReklama-reklama-delete-button"}
                                onClick={() => DeleteReklama()}></button>
                        <button onClick={() => UpdateReklama(undefined)}
                                disabled={reklama.status === 1}>
                            Сохранить
                        </button>
                        <button onClick={() => UpdateReklama(1)}
                                disabled={reklama.status === 1}>
                            Отправить на модерацию
                        </button>
                    </div>
                </div>
            </div>
        </details>
        </>
    );
};

export default ReklamaItem;