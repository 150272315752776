import React, {useContext, useEffect, useState} from 'react';
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {IDropdownOption, IFilter, ISubCategory} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";

interface IItemFilterAttr {
    attrId: string,
    attrSorter: number,
    attrName: string,
    onDataUpdate:()=>void
}

const ItemFilterAttribute = ({
                                 attrId,
                                 attrSorter,
                                 attrName,
                                 onDataUpdate
                         }: IItemFilterAttr) => {
    const {store} = useContext(Context)
    const [attributeName, setAttributeName] = useState(attrName)
    const [attributeOldName, setAttributeOldName] = useState(attrName)
    const [attribureSorter, setAttributeSorter] = useState(attrSorter)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const UpdateFilterAttr = () => {
        if(attributeName){

            AdminService.updateFilterAttr(attrId,attributeName,attribureSorter)
                .then(r => {
                    if (r.data.status == "Success") {
                        alert(`SubCategory (${attrName}) успешно изменена`)
                        onDataUpdate()
                    }
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    onDataUpdate()
                })
        }
        else
            alert("Пустое название")
    }

    const DeleteFilterAttr = () => {
        if (window.confirm("Вы действительно хотите удалить атрибут фильтра?")) {

            AdminService.deleteFilterAttr(attrId)
                .then(r => {
                    if (r.data.status == "Success") {
                        onDataUpdate()
                        alert(`SubCategory (${attrName}) успешно удалена`)
                    }
                })
                .finally(() => {
                    onDataUpdate()
                })
        }
    }

    return (
        <>
            <div className={"admin-categories-item-subCategory"} style={{marginTop:"10px",marginBottom:"10px"}}>
                <div className={"admin-categories-item-firstContainer"}>

                    <CustomInput
                        name = {"newFilterName"}
                        type={"text"}
                        placeHolder={"новый атрибут фильтра"}
                        inputSize={"large"}
                        value={attributeName}
                        onChange={(e) => setAttributeName(e.target.value)}
                    />
                </div>
                <div className={"admin-categories-item-secondContainer"}>
                    <CustomInput
                        name = {"newFilterSorter"}
                        type={"number"} inputSize={"large"} min={"0"}
                        value={attribureSorter}
                        onChange={(e) => {
                            if (Number(e.target.value))
                                setAttributeSorter(Number(e.target.value))
                            else
                                setAttributeSorter(0)
                        }}
                    />
                    <div className={"admin-categories-item-buttons"}>
                        <button onClick={UpdateFilterAttr} className={"admin-categories-item-buttonEdit"}/>
                        <button
                            className={"admin-categories-item-buttonDelete"}
                            onClick={DeleteFilterAttr}
                        >
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ItemFilterAttribute;