import {useNavigate, useParams} from "react-router-dom";
import "./MyOrderPage.css"
import React, {useContext, useEffect, useState} from "react";
import {IMyOrders, IMyOrdersElement} from "../../../../../models/models";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {CompareDates, getParsedDate, Ru, sendMessageAsync} from "../../../../../functions/functions";
import MyOrderPageCancel from "./MyOrderPageCancel";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";
import {Context} from "../../../../../index";

export default function MyOrderPage(){

    const {store} = useContext(Context);
    const FunctionButtonStyle:React.CSSProperties = {
        fontSize: 13,
        lineHeight: "16px",
        borderRadius: 7,
        float:"right",
    }

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const PaymentOptions:{name:string;id:number;}[] =
        [{name:"Наличные",id:1},
            {name:"Банковская карта",id:2},
            {name:"Бонусы",id:3},
            {name:"Со счета",id:4},
            {name:"QR-код",id:5},
            {name:"По терминалу",id:6}];
    const [paymentButtonText,setPaymentButtonText] = useState("Выбрать способ оплаты")
    const [DataFromMyOrders,SetDataFromMyOrders] = useState<IMyOrders>();
    const [StyleOptions, setStyleOptions] = useState<{style:string,picture:string,time:string, title:string}[]>([]);
    const navigate = useNavigate()
    const params = useParams();

    const setDataOrders = async(data:any)=>{
        SetDataFromMyOrders(data.data[0]);
        return(data.data[0])
    }

    const sendMessageToSupplier = async () => {
        await sendMessageAsync(
            store.user.id,
            store.user.displayedName,
            `${store.HashForChat}Вопрос по заказу №${DataFromMyOrders?.invoiceId}`,
            String(DataFromMyOrders?.currentSupplierId),
        )
            .catch(()=>store.showDialog('Что-то пошло не так').then(()=>{}))
            .then(()=>navigate(SpaRoutes.CHAT_DIALOG_PAGE + `/:${DataFromMyOrders?.currentSupplierId}/:1/:0`))
    };

    useEffect(()=>{
        store.DataLoadingON()
        CabinetBuyerService.postGetInvoice([params.id])
            .then((data)=>{
                if(data.status === 200){
                    setDataOrders(data)
                        .then((rez:IMyOrders)=>{
                            setStyleOptions(styleLogic(data.data[0]))
                                let counter = rez.orders[0].orderStatus;
                                let counter2 = rez.orders[0].lastStatus;
                                rez.orders.map((item) => {
                                    if (item.orderStatus?item.orderStatus:0 < (counter?counter:0))
                                        counter = item.orderStatus
                                    if (item.lastStatus > counter2)
                                        counter2 = item.lastStatus
                                })
                                for(const key2 in PaymentOptions){
                                    if(PaymentOptions[key2].id===rez.payment){
                                        setPaymentButtonText(PaymentOptions[key2].name);
                                    }
                                }
                            })

                }
            }).catch(()=> {
            store.ErrorON()
        }).finally(()=> {
            store.DataLoadingOFF()
        })
            },[])

    const styleLogic = (data:any)=>{
        let StyleOptions = [
            {style: 'myorderpage-green-status', picture: "/Pictures/VectorOpenOrder.svg", title: "Открыт", time:''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorAccepted.svg",title: "Принят", time:''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorReadyToGet.svg",title: "Готов к выдаче", time:''},
            {style: 'myorderpage-black-status', picture: "/Pictures/Доставка.svg",title: "Доставка", time:''},
            {style: 'myorderpage-black-status', picture: "/Pictures/Выдан.svg",title: "Выдан", time:''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorPayedOrder.svg",title: "Оплачен", time:''}]

        if(data){
            let last2 = data.invoiceStati.lastIndexOf(2)
            let last3 = data.invoiceStati.lastIndexOf(3)
            let last4 = data.invoiceStati.lastIndexOf(4)
            let last10 = data.invoiceStati.lastIndexOf(10)
            let last5 = data.invoiceStati.lastIndexOf(5)
            let last6 = data.invoiceStati.lastIndexOf(6)
            let last7 = data.invoiceStati.lastIndexOf(7)

            if(last2 != -1){
                StyleOptions[0].time = getParsedDate(
                    data.invoiceTimes[last2],false, true)
            }
            if(last3 != -1 && last10 < last3){
                StyleOptions[1].style = 'myorderpage-green-status'
                StyleOptions[1].time =  getParsedDate(
                    data.invoiceTimes[last3],false, true)
            }
            if(last3 != -1 && last10 > last3){
                StyleOptions[1].style = 'myorderpage-red-status'
                StyleOptions[1].time = getParsedDate(data.invoiceTimes[last10],false, true)
            }
            if(last4 != -1 && last10 < last4){
                StyleOptions[2].style = 'myorderpage-green-status'
                StyleOptions[2].time = getParsedDate(data.invoiceTimes[last4],false, true)
            }
            if(last4 != -1 && last10 > last4){
                StyleOptions[2].style = 'myorderpage-red-status'
                StyleOptions[2].time = getParsedDate(data.invoiceTimes[last10],false, true)
            }
            if(last5 != -1){
                StyleOptions[3].style = 'myorderpage-green-status'
                StyleOptions[3].time = getParsedDate(data.invoiceTimes[last5],false, true)
            }
            if(last6 != -1){
                StyleOptions[4].style = 'myorderpage-green-status'
                StyleOptions[4].time = getParsedDate(data.invoiceTimes[last6],false, true)
            }
            if(last7 != -1){
                StyleOptions[5].style = 'myorderpage-green-status'
                StyleOptions[5].time = getParsedDate(data.invoiceTimes[last7],false, true)
            }
        }

        return StyleOptions;
    }

   function PriceCounter(withDiscount:boolean){
        let sum=0;
        if(DataFromMyOrders)
            if(withDiscount)
                DataFromMyOrders.orders.map((item: IMyOrdersElement) => {
                    if (item.price1 <= item.price2) {
                        if (item.price1 === 0) sum += (item.price2 * item.amount);
                        else sum += (item.price1 * item.amount);
                    }
                    else {
                        if (item.price2 === 0) sum += (item.price1 * item.amount);
                        else sum += (item.price2 * item.amount);
                    }});
            else
                DataFromMyOrders.orders.map((item: IMyOrdersElement) => {
                    if (item.price1 >= item.price2) {
                        sum += (item.price1 * item.amount);
                    } else {
                        sum += (item.price2 * item.amount);
                    }});
        return sum;
    }

    let SumWithoutDiscount = PriceCounter(false)
    let SumWithDiscount = PriceCounter(true)

    const TotalPriceWithDiscount = Ru(SumWithDiscount);
    const TotalPriceWithoutDiscount = Ru(SumWithoutDiscount);
    const PriceDifference = SumWithoutDiscount - SumWithDiscount;
    const PriceDifferenceNumberStylized = Ru(PriceDifference);

    const dateForDelivery =
        DataFromMyOrders&&
            getParsedDate(
                CompareDates(DataFromMyOrders.orders.map((item:IMyOrdersElement)=> item.deliverFrom?item.deliverFrom:"")),
                true, false);

    const AdressForDelivery = DataFromMyOrders?.adresses[0].streetandNumber;

    const Statuslist = StyleOptions.map((item)=>
    {
        return(<div className={"myorderpage-status-list-wrapper"}>
            <div>
                <div className={`myorderpage-status-logo ${item.style}`}>
                    <img className={"myorderpage-status-logo-picture"} src={item.picture} alt=""/>
                </div>
            </div>
            <div style={{height: "35px",display: "grid",alignItems: "center"}}>
                <span className="myorderpage-status-title">{item.title}</span>
                {(item.style !== 'myorderpage-black-status' )
                    && <span className="myorderpage-status-time">{item.time}</span>}
            </div>
        </div>)
    });

    const Label =
        <CustomNavigateHeader
            title={`Заказ №${DataFromMyOrders?.invoiceId}`}
            trashClickHandler={()=>null}
            withTrash={false}
        />;

    const list = DataFromMyOrders?.orders.map((item:IMyOrdersElement) => {
        return <>
            <tr style={(item.orderStatus?item.orderStatus:0) > 9 ? {backgroundColor: "rgb(153 153 153 / 34%)"} : {}}>
                <td className={"myorderpage-columns myorderpage-first-column"}>{item.name_of_product}</td>
                <td className={"myorderpage-columns myorderpage-second-column"}>{item.amount} шт.</td>
                <td className={"myorderpage-columns myorderpage-third-column"}>
                    {Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(item.price1)}
                </td>
                <td className={"myorderpage-columns myorderpage-fourth-column"}>
                <span className="myorderpage-button-string">
                    {(DataFromMyOrders?.invoiceStati.lastIndexOf(5)==-1)?<MyOrderPageCancel
                        nameOfProduct={item.name_of_product}
                        orderId={item.orderId}
                        currentStatus={item.orderStatus ? item.orderStatus : -2}
                    />:((item.orderStatus?item.orderStatus:0) > 9&&<span className="myorderpage-button-string">
                    <div style={{color:"#8E4646",textDecoration:"none"}}>отменён</div>
                </span>)}
                </span>
                </td>
            </tr>
        </>;

    });

    return(
        <>
            {  (!store.isError && !store.isDataLoading) &&
                (<>
                    {Label}
                    <p className={"myorderpage-upper-title"}>Информация о заказе</p>
                    <div className="myorderpage-status-list">{Statuslist}</div>
                    <div className="myorderpage-main-div">
                <span className="myorderpage-string">
                    <span className="myorderpage-title-string">Дата оформления заказа:</span>
                    {dateForDelivery}</span>
                        <span className="myorderpage-string">
                    <span className="myorderpage-title-string">Название магазина:</span>
                            {DataFromMyOrders?.supply_company?DataFromMyOrders?.supply_company:"-"}</span>
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Телефон:</span>
                            {DataFromMyOrders?.supplierPhone?DataFromMyOrders?.supplierPhone:"-"}</span>
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Тип доставки:</span>
                            {DataFromMyOrders?.deliveryType!=0?"Доставка курьером":"Самовывоз"}
                        </span>
                        <span className="myorderpage-string">
                    <span className="myorderpage-title-string">{DataFromMyOrders?.deliveryType!=0?"Адрес доставки:":"Адрес пункта самовывоза:"}</span>
                            {AdressForDelivery}
                </span>
                        {/*<span className="myorderpage-string">*/}
                {/*    <span className="myorderpage-title-string">Можно забрать:</span>*/}
                {/*            {dateForDelivery}*/}
                {/*</span>*/}
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Желаемое время доставки:</span>
                                    {DataFromMyOrders?.deliveryTime?DataFromMyOrders?.deliveryTime:"-"}
                        </span>

                        <div className="myorderpage-order-list">
                            <div className="myorderpage-header-table">
                                <span className="myorderpage-title-string">Товары в заказе:</span>
                                {/*<span className="myorderpage-button-string"></span>*/}
                            </div>
                            <table className={"myorderpage-table"}>{list}</table>
                        </div>
                        <span className="myorderpage-title-string">Комментарий к заказу</span>
                        <span className="myorderpage-comment">{DataFromMyOrders?.comment?DataFromMyOrders?.comment:"Комментарий к заказу не указан"}</span>
                        {PriceDifference!=0 && (<span className="myorderpage-without-discount">{TotalPriceWithoutDiscount}</span>)}
                        <div id="myorderpage-sum-of-order">
                            <span>Сумма:</span>
                            <img id="img_dashed" src="/Pictures/Line 12.png" alt={"line"}/>
                            <span id={"myorderpage-price-of-order"}>{TotalPriceWithDiscount}</span>
                        </div>
                        {PriceDifference!=0 && <span className="myorderpage-discount">экономия от
                            <span className="myorderpage-discount-number">{PriceDifferenceNumberStylized}</span>
                        </span>}
                        <div style={{textAlign: "right",marginTop: "10px",fontSize: "11px", width:"185px", marginBottom:"8px", marginLeft:"auto"}}>Информация для поставщика о способе оплаты заказа:</div>
                        <div className="myorderpage_bottom_buttons">
                            <div className={"myorderpage-payment-button"}
                                 onClick={sendMessageToSupplier}
                            >
                                <FunctionButton text={"Начать чат с поставщиком"} onClickHandler={()=>{}} style={FunctionButtonStyle}/>
                            </div>
                            <div className={"myorderpage-payment-button"}
                                 onClick={()=>{
                                     navigate(SpaRoutes.MYORDER_PAYMENT + `/${DataFromMyOrders?.invoiceId}`,{state: { chosenOption: DataFromMyOrders?.payment}} );

                                 }}
                            >
                                <FunctionButton text={paymentButtonText} onClickHandler={()=>{}} style={FunctionButtonStyle}/>
                            </div>
                        </div>
                    </div>
                </>)}
        </>
    )
}