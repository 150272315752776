import { useNavigate } from "react-router-dom"
import {useEffect} from "react";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    return (
        <section>
            <h1>Unauthorized</h1>
            <br />
            <p>You do not have access to the requested page.</p>
            <div className="flexGrow">
                <button onClick={goBack}>Go Back</button>
            </div>
        </section>
    )
}

export default Unauthorized
