import React, {useContext, useEffect, useState} from "react";
import "./Chat.css"
import ChatWindowItem from "../../../customUI/Chat/ChatWindowItem/ChatWindowItem";
import {ChatWindowType} from "../../../../models/Chat/Chat";
import ChatService from "../../../../services/ChatService";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";

function Chat() {
    const [chatsWindows, setChatsWindows] = useState<ChatWindowType[]>([]);
    const {store} = useContext(Context);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
        store.DataLoadingON();
        ChatService.chatGeneral()
        .then((response)=>{
            setChatsWindows(response.data);
        })
            .catch(()=>{store.ErrorON();})
            .finally(()=>{
                store.DataLoadingOFF();
            })
    }, []);
    useEffect(() => {
        if (store.isHubOn) {
            store.hubConnection.on("UpdateCounter", (forChat: string) => {
                if ("group:" === forChat.substring(0,6)){
                    setChatsWindows(prevState => {
                        let newState = [...prevState]
                        newState.map(item=>{
                            if (item.infoId===forChat.slice(6) ){
                                item.counter = item.counter+1;
                            }
                            return item;
                        })
                        return newState;
                    })
                }
                else{
                    setChatsWindows(prevState => {
                        let newState = [...prevState]
                        newState.map(item=>{
                            if (item.userId===forChat ){
                                item.counter = item.counter+1;
                            }
                            return item;
                        })
                        return newState;
                    })
                }

            });
        }
    }, [store.isHubOn]);
    const sortedChats = chatsWindows.sort((a: { type: number; }, b: { type: number; }) => a.type - b.type);

    let list1:any[] = [];
    let list5:any[] = [];
    let AdminComponent:any;

    let unreadTotal = chatsWindows.reduce((count,item)=>{
        return count+item.counter
    },0)

    const chatWindowItemsElem = sortedChats.map(chat => {
        if(chat.type === 1){
            if(chat.userId === store.AdminId)
                AdminComponent = <ChatWindowItem
                    unreadTotal={unreadTotal}
                    unread={chat.counter}
                    key={chat.user.id}
                    counter={chat.counter}
                    lastMessage={chat.lastMessage}
                    user={chat.user}
                    role="Customer"
                    type ={chat.type}
                    infoText={chat.infoText}
                    infoId={chat.infoId}
                    focus={false}
                />
            else
            list1.push(
                <ChatWindowItem
                    unreadTotal={unreadTotal}
            unread={chat.counter}
            key={chat.user.id}
            counter={chat.counter}
            lastMessage={chat.lastMessage}
            user={chat.user}
            role="Customer"
            type ={chat.type}
            infoText={chat.infoText}
            infoId={chat.infoId}
            focus={false}
        />
            )
        }
        if(chat.type === 5){
            list5.push(
                <ChatWindowItem
                    unreadTotal={unreadTotal}
            unread={chat.counter}
            key={chat.user.id}
            counter={chat.counter}
            lastMessage={chat.lastMessage}
            user={chat.user}
            role="Customer"
            type ={chat.type}
            infoText={chat.infoText}
            infoId={chat.infoId}
            focus={false}
        />
            )
        }
    });

    return (
        <>
            {(!store.isError && !store.isDataLoading) && <div>
                <CustomNavigateHeader trashClickHandler={()=>{}} title={"Чаты"} withTrash={false}/>
                {(chatsWindows.length!==0) ? <menu className="chat-list ">
                    {list1.length>0 &&
                        <>
                            <div className={"customer_menu_chat_title"}>Личные чаты</div>
                            {list1.map(chat => chat)}
                        </>
                    }
                    {list5.length>0 &&
                        <>
                            <div className={"customer_menu_chat_title"}>Групповые чаты</div>
                            {list5.map(chat => chat)}
                        </>
                    }
                    {AdminComponent &&
                        <>
                            <div className={"customer_menu_chat_title"}>Администратор</div>
                            {AdminComponent}
                        </>
                    }
                </menu> : <div style={{textAlign: "center"}}>
                    <div style={{fontSize: "20px",fontWeight: "500",
                        lineHeight: "22px",marginBottom: "15px"}}
                    >У вас пока нет активных чатов
                    </div>
                    <img src={'/Pictures/chat.svg'}/>
                </div>}
            </div>}
        </>
    )
}

export default Chat