import React, {useContext, useEffect, useState} from 'react';
import "./Categories.css";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import HomeService from "../../../../services/HomeService";
import {IDropdownOption, ISubCategory, ITopCategories, ITopCategory} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import ItemTopCategory from "./ItemTopCategory";

const Categories = () => {
    const {store} = useContext(Context)

    const [topCategoryName, setTopCategoryName] = useState("")
    const [subCategoryName, setSubCategoryName] = useState("")
    const [topCategorySorter, setTopCategorySorter] = useState("")
    const [subCategorySorter, setSubCategorySorter] = useState("")

    const [topCategoryId, setTopCategoryId] = useState()
    const [topCategories, setTopCategories] = useState<ITopCategory[]>([])
    const [dropDownTopCategoryName, setDropDownTopCategoryName] = useState("No Top-Category")

    const [dataLoading, setDataLoading] = useState(false)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const UpdateData = () => {
        setTopCategories([])
        AdminService.getAllCategories()
            .then(response => {
                setTopCategories(response.data.topCategories.reverse())
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
            })
    }
    useEffect(() => {
        if (!dataLoading) {
            store.DataLoadingON()
            AdminService.getAllCategories()
                .then(response => {
                    setTopCategories(response.data.topCategories.reverse())
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    setDataLoading(true)
                    store.DataLoadingOFF()
                })
        }
    }, [dataLoading])


    let ArrayTopCategory: IDropdownOption[] = []
    topCategories.map((item) => {
        ArrayTopCategory.push({key: item.topCategoryId, name: item.name})
    });

    const addTopCategory = () => {
        if (topCategorySorter && topCategoryName) {
            let mydata = {
                Info1: topCategoryName,
                Number1: Number(topCategorySorter)
            }

            AdminService.postTopCategoryAdd(mydata)
                .then(r => {
                    if (r.data.status == "Success") {
                        setDataLoading(false)
                        setTopCategoryName("")
                        setTopCategorySorter("")
                        alert(`TopCategory (${topCategoryName}) успешно добавлена`)
                        UpdateData()
                    }
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {

                })
        }
        else
            alert(`
            ${!topCategoryName?"Пустое название\n":""}
            ${!topCategorySorter?"Вы не выбрали приоритет":""}`)
    }

    const addSubCategory = () => {
        if (dropDownTopCategoryName !== "No Top-Category" && subCategoryName && subCategorySorter) {
            let mydata = {
                Info1: subCategoryName,
                Info2: topCategoryId,
                Number1: Number(subCategorySorter)
            }
            AdminService.postSubCategoryAdd(mydata)
                .then(r => {
                    if (r.data.status == "Success") {
                        setDataLoading(false)
                        setSubCategoryName("")
                        setSubCategorySorter("")
                        setDropDownTopCategoryName("No Top-Category")
                        alert(`SubCategory (${subCategoryName}) успешно добавлена`)
                        UpdateData()
                    }
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {

                })
        } else
            alert(`
            ${dropDownTopCategoryName === "No Top-Category"?"Вы не выбрали TopCategory\n":""}
            ${!subCategoryName?"Пустое название\n":""}
            ${!subCategorySorter?"Вы не выбрали приоритет":""}`)
    }

    return (
        <>{(!store.isDataLoading) &&
            <div className={"admin-categories-main"}>
                <CustomNavigateHeader title={"Категории"} withTrash={false}/>
                <div className={"admin-categories-add-container"}>
                    <div className={"admin-categories-add-topCategory"}>
                        <CustomInput
                            value={topCategoryName}
                            name = {"topCategory"}
                            onChange={(e) => setTopCategoryName(e.target.value)}
                            type={"text"} placeHolder={"TopCategory"} inputSize={"large"}/>
                        <div className={"admin-categories-add-secondContainer"}>
                            <CustomInput
                                placeHolder={"Приоритет"}
                                value={topCategorySorter}
                                name = {"priority"}
                                onChange={(e) => {
                                    if (Number(e.target.value))
                                        setTopCategorySorter(Number(e.target.value).toString())
                                    else
                                        setTopCategorySorter("0")
                                }}
                                type={"number"} inputSize={"large"} min={"0"}/>
                            <button onClick={addTopCategory} className={"admin-categories-add-button"}>Добавить
                                TopCategory
                            </button>
                        </div>
                    </div>
                    <div className={"admin-categories-add-subCategory"}>
                        <div className={"admin-categories-add-firstContainer"}>
                            <CustomDropdown
                                icon={true} dropdownSize={"large"}
                                options={ArrayTopCategory}
                                value={dropDownTopCategoryName}
                                valueSetter={setDropDownTopCategoryName}
                                onChangeHandler={setTopCategoryId}/>
                            <CustomInput
                                name = {"subCategoryName"}
                                value={subCategoryName}
                                onChange={(e) => setSubCategoryName(e.target.value)}
                                type={"text"} placeHolder={"SubCategory"} inputSize={"large"}/>
                        </div>
                        <div className={"admin-categories-add-secondContainer"}>
                            <CustomInput
                                name = {"priority"}
                                placeHolder={"Приоритет"}
                                value={subCategorySorter}
                                onChange={(e) => {
                                    if (Number(e.target.value))
                                        setSubCategorySorter(Number(e.target.value).toString())
                                    else
                                        setSubCategorySorter("0")
                                }}
                                type={"number"} inputSize={"large"} min={"0"}/>
                            <button onClick={addSubCategory} className={"admin-categories-add-button"}>Добавить
                                SubCategory
                            </button>
                        </div>
                    </div>
                </div>
                <div className={"admin-categories-listCategories"}>
                    {topCategories?.map((topCategory) => {
                        return <ItemTopCategory
                            ArrayTopCategory={ArrayTopCategory}
                            itemTopCategoryId={topCategory.topCategoryId}
                            itemTopCategoryName={topCategory.name}
                            itemTopCategorySorter={topCategory.sorter}
                            itemFilter = {topCategory.filterAttributes}
                            itemSubCategories={topCategory.categories.reverse()}
                            onDataUpdate={() => UpdateData()}
                            canDelete={!topCategory.categories.products || !topCategory.categories.productRoots || !topCategory.categories.orders}
                        />
                    })}
                </div>
            </div>
        }</>
    );
};

export default Categories;