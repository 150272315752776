import {Loader} from "./components/customUI/Loader/Loader";
import React, {useContext} from "react";
import App from "./App";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import {ErrorPage} from "./components/customUI/ErrorPage/ErrorPage";
import Dialog from "./components/customUI/Dialog/Dialog";

const ContextProviderContainer = () => {
    const {store} = useContext(Context)

    return(
        <>
            <Dialog open={store.dialog.isDialogOpen} text={store.dialog.dialogText} options={store.dialog.dialogWithOptions} title={store.dialog.dialogTitle}/>
            <Loader load={store.isAuthLoading || store.isDataLoading} />
            <ErrorPage error={store.isError}/>
            {!store.isAuthLoading&&<App/>}
        </>
)
}
export default observer(ContextProviderContainer);
