import React, {useEffect, useState} from "react";
import "./CatalogSupplier.css"
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {statusList} from "../../../../data/catalogSuplier";
import SupplierNavigateHeader from "../../../customUI/SupplierNavigateHeader/SupplierNavigateHeader";
import {IDropdownOption, IitemArray, ISupplierCatalogItem} from "../../../../models/models";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import SupplierCustomSwiper from "../../../customUI/SupplierCustomSwiper/SupplierCustomSwiper";
import {
    removeDuplicates, removeGorod,
} from "../../../../functions/functions";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";

function Catalog() {
    //Стейт для хранения всех адресов, без повтора
    const [shops, setShops] = useState<IDropdownOption[]>([]);
    //Стейт для хранения значения фильтра о складе
    const [shop,setShop] = useState("Выберите склад");
    //Стейт для хранения значения фильтра о статусе
    const [status,setStatus] = useState("Выберите статус");
    //Стейт, куда записывается вся инфа с контроллера
    const [catalogData,setCatalogData] = useState<ISupplierCatalogItem[]>([]);
    //Стейт для хранения инфы с фильтрами
    const [neededCatalogData, setNeededCatalogData] = useState<ISupplierCatalogItem[]>(catalogData);
    //Стейт для хранения всех главных категорий, без повтора
    const [categories,setCategories] = useState<IDropdownOption[]>([]);
    //Стейт для отображения всей страницы категории товара
    const [viewAll,setViewAll] = useState<boolean>(false);
    //Стейт для хранения даты для страницы категории товара
    const [categoryData,setCategoryData] = useState<IitemArray[]>([]);

    const[loading,setLoading] = useState(false)

    function findIndexOfStatus(statusName:string){
        let neededIndex:number = 0;
        for(let i=0;i<statusList.length; i++){
            if(statusList[i].name === statusName)
                neededIndex = i;
        }
        return neededIndex;
    }

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    //Хук для подтягивания изначальной информации
    useEffect(()=>{
        CabinetSellerService.get_all_catalog().then((data) => {
                if (data.status === 200){
                    setCatalogData(data.data.list.map((item)=>{
                        item.shop = removeGorod(item.shop)
                        return item}));
                    setShops( ()=>{
                        let adresses: IDropdownOption[] = data.data.list.map((item: ISupplierCatalogItem) =>
                            ({key: item.shop, name: removeGorod(item.shop)}));

                        return removeDuplicates(adresses);
                        }
                    );
                }
            }).then(()=>{setLoading(true); setStatus("Активные")})
    },[]);

    //хук сортировки по данным из дропдаунов
    useEffect(()=>{
        setNeededCatalogData(() => {
            if(shop === "Выберите склад"){
                if(status !== "Выберите статус" && status !== "Все"){
                    return catalogData.filter((item) => (item.status ===
                        statusList[findIndexOfStatus(status)].key));
                }
                else{
                    return catalogData;
                }
            }
            else{
                let newState:ISupplierCatalogItem[] =
                    catalogData.filter(item => item.shop === shop);
                if(status !== "Выберите статус" && status!== "Все"){
                    newState = newState.filter((item)=>(item.status ===
                        statusList[findIndexOfStatus(status)].key));
                    return newState;
                }
                else {
                    return newState;
                }
            }
        })
    },[shop,status,catalogData]);

    //Хук вычисления всех категорий, возвращает массив без повторяющихся категорий
    useEffect(()=>{
        setCategories( ()=>{
            let newState:IDropdownOption[] = [];
            neededCatalogData.map((item) =>
                (newState.push({key: item.topCatId, name: item.topCatName})));
            newState = removeDuplicates(newState);
            return newState;
            }
        );
    },[neededCatalogData])

    //Вывод в рендер свайперов с отфильтрованными данными
    const swiperList =
        (categories.length===0)
            ?(  <span className="catalog-supplier-empty">
                В данный момент ничего нет
                </span>)
            :(categories.map((category)=>{
            return <SupplierCustomSwiper
                data={neededCatalogData
                    .filter((item)=>(item.topCatName===category.name))
                    .map((item)=>(item.card))}
                dataForViewAll={neededCatalogData
                    .filter((item)=>(item.topCatName===category.name))}
                title={category.name}
                checkAll={true}
                key={category.key}
                setViewAll={setViewAll}
                setCategoryData={setCategoryData}
            />
    }));

    const ListCategories:any = () => {
        let list = [];
        for (let i = 0; i < categoryData.length; i++) {
            list.push(
                <SupplierCustomSwiper
                    data={categoryData[i].cards}
                    dataForViewAll={[]}
                    title={categoryData[i].catName}
                    checkAll={false}
                    key={categoryData[i].catName}
                    setViewAll={setViewAll}
                    setCategoryData={setCategoryData}
                />
            )
        }
        return (list)
    }

    const onClickHandlerForHeader = () => {
        setViewAll(false);
    }

    return(
        <>
            {!loading?<Loader load={true} />:
                (<div className={"catalog-supplier"}>
                    {viewAll?
                        (<>
                            <div className="catalog-navigate-header">
                                <div id={"supplier-nh-title-div"}>
                                    <img onClick={onClickHandlerForHeader} id={"supplier-nh-arrow"} src={"/Pictures/strelochka.svg"}/>
                                    <p className={"supplier-nh-title"}>Каталог для выбранной категории</p>
                                </div>
                            </div>
                            <div className="catalog-content">
                                <div className="catalog-supplier-swiper catalog-supplier-swiper-checkAll">
                                    <ListCategories/>
                                </div>
                            </div>
                        </>):
                        (<>
                            <div className="catalog-navigate-header">
                                <CustomNavigateHeader trashClickHandler={()=>{}} title={"Каталог моих товаров"} withTrash={false}/>
                            </div>
                            <div className="catalog-content">
                                <>
                                    <div className="catalog-content-dropdowns">
                                        <div className="catalog-dropdown-container">
                                            <span className="catalog-dropdown-description">Склад</span>
                                            <div className="catalog-dropdown">
                                                <CustomDropdown
                                                    options={shops}
                                                    value={shop}
                                                    valueSetter={setShop}
                                                    onChangeHandler={()=>{}}
                                                    dropdownSize={"large"}
                                                />
                                               </div>
                                        </div>
                                        <div className="catalog-dropdown-container">
                                            <span className="catalog-dropdown-description">Статус</span>
                                            <div className="catalog-dropdown">
                                                <CustomDropdown
                                                    options={statusList}
                                                    value={status}
                                                    valueSetter={setStatus}
                                                    onChangeHandler={()=>{}}
                                                    dropdownSize={"large"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="catalog-supplier-swiper">
                                        {swiperList}
                                    </div>
                                </>
                            </div>
                        </>)}
                </div>)}
        </>

    )
}
export default Catalog;