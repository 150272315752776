import React, {useContext, useEffect, useState} from 'react';
import "./Filter.css";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import {IFilter} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import ItemTopCategory from "../Categories/ItemTopCategory";
import ItemSubCategory from "../Categories/ItemSubCategory";
import ItemFilterAttribute from "./ItemFilterAttribute";


const Filter_edit = () => {
    const {store} = useContext(Context)

    const [filters, setFilters] = useState<IFilter[]>([])
    const [filterGroup , setFilterGroup] = useState<any[]>()
    const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([])
    const [filterName, setFilterName] = useState<string>("")
    const [attributeName, setAttributeName] = useState<string>("")
    const [attributeSorter, setAttributeSorter] = useState<number>(0)
    const [filterSorter, setFilterSorter] = useState<number>(0)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const [dataLoading, setDataLoading] = useState(false)
    const navigate = useNavigate()
    let {id} = useParams();
    const UpdateData = () => {
        setFilters([])
        AdminService.getAllFilters()
            .then(response => {
                setFilters(response.data)
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
            })
    }

    const updateFilter = ()=>{
        AdminService.updateFilter(id?id:"-1",filterName,filterSorter)

    }
    const deleteFilter = ()=>{
        if (window.confirm("Вы действительно хотите удалить фильтр?")) {
            AdminService.deleteFilter(id ? id : "-1").finally(
                () => {
                    navigate(-1)
                }
            )
        }
    }
    const AddFilterAttr =()=>{
        AdminService.addFilterAttr(id?id:"-1",attributeName,attributeSorter).finally(()=>{
            setAttributeName("")
            setAttributeSorter(0)
            UpdateData()
        })
    }

    useEffect(() => {
        if (!dataLoading) {
            store.DataLoadingON()
            AdminService.getAllFilters()
                .then(response => {
                    setFilters(response.data)
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    setDataLoading(true)
                    store.DataLoadingOFF()
                })

        }
    }, [dataLoading])
    useEffect(() => {
        let fg:any[] = []
        filters.forEach((filt)=>{
            if (fg.every((item)=>{return item.id !== filt.filterId})&&id===filt.filterId){
                fg.push({
                    id:filt.filterId,
                    name:filt.filterName,
                    sorter:filt.filterSorter,
                    attributes:[{id:filt.attrId,name:filt.attrName,sorter:filt.attrSorter}]
                })
            }
            else{
                fg.forEach((item)=>{
                    if (item.id === filt.filterId){
                        item.attributes.push({id:filt.attrId,name:filt.attrName,sorter:filt.attrSorter})
                    }
                })
            }
        })
        fg.forEach((filt)=>{
            filt.attributes = filt.attributes.filter((item:any)=>{return item.id!=="no"}).sort((a:any,b:any)=>{
                if (a.sorter > b.sorter) return 1
                if(a.sorter < b.sorter) return -1
                return 0})
        })
        setFilterGroup(fg);
        if (fg.length>0){
            setFilterName(fg[0].name)
            setFilterSorter(fg[0].sorter.toString())
        }
    }, [filters])

    const FILTERS = filterGroup?.map((filter)=> {
        return <div>
            <div style={{display:"flex"}}>
                <div className={"admin-filter-basic"}>{filter.name}</div>

            </div>
            <div>{filter.attributes?.map((attr:any)=>{
                return <div style={{display:"flex"}}>
                    <div className={"admin-filter-basic"}>{attr.name}</div>
                    <div className={"admin-filter-basic"}>{attr.sorter}</div>
                </div>
            })}
            </div>
        </div>
    })

    return (
        <>{(!store.isDataLoading) &&
            <div className={"admin-categories-main"}>
                <CustomNavigateHeader title={"Редактировать фильтр"} withTrash={false}/>

                <div className={"admin-categories-listCategories"}>
                    {filterGroup && filterGroup.length > 0 &&
                        <div className={"admin-categories-item"}>
                            <hr/>
                            <div className={"admin-categories-item-topCategory"}>
                                <CustomInput
                                    type={"text"}
                                    name = {"topCategory"}
                                    value={filterName}
                                    onChange={(e) => setFilterName(e.target.value)}
                                    placeHolder={"фильтратрибут"} inputSize={"small"}
                                />
                                <div className={"admin-categories-item-secondContainer"}>
                                    <CustomInput
                                        name = {"filtersorter"}
                                        type={"number"} inputSize={"small"} min={"0"}
                                        value={filterSorter}
                                        onChange={(e) => {
                                            if (Number(e.target.value))
                                                setFilterSorter(Number(e.target.value))
                                            else
                                                setFilterSorter(0)
                                        }}
                                    />
                                    <div className={"admin-categories-item-buttons"}>
                                        <button style={{width:"45px",height:"45px"}} onClick={updateFilter} className={"admin-categories-item-buttonEdit"}/>
                                        <button style={{width:"45px",height:"45px"}}
                                            className={"admin-categories-item-buttonDelete"}
                                            onClick={deleteFilter}>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className={"admin-categories-item-subCategories"}>
                                {filterGroup[0].attributes.map((filtAttr: any) => {
                                    return <ItemFilterAttribute
                                        attrId={filtAttr.id}
                                        attrSorter={filtAttr.sorter}
                                        attrName={filtAttr.name}
                                        onDataUpdate={UpdateData}
                                    />
                                })}
                            </div>
                            <div className={"admin-categories-item-subCategory"} style={{marginTop:"10px",marginBottom:"50px"}}>
                                <div className={"admin-categories-item-firstContainer"}>

                                    <CustomInput
                                        type={"text"}
                                        name = {"newfilterattribute"}
                                        placeHolder={"новый атрибут фильтра"}
                                        inputSize={"small"}
                                        value={attributeName}
                                        onChange={(e) => setAttributeName(e.target.value)}
                                    />
                                </div>
                                <div className={"admin-categories-item-secondContainer"}>
                                    <CustomInput
                                        name = {"filterAttributeSorter"}
                                        type={"number"} inputSize={"small"} min={"0"}
                                        value={attributeSorter}
                                        onChange={(e) => {
                                            if (Number(e.target.value))
                                                setAttributeSorter(Number(e.target.value))
                                            else
                                                setAttributeSorter(0)
                                        }}
                                    />
                                    <div className={"admin-categories-item-buttons"}>
                                        <button style={{width:"45px",height:"45px"}} onClick={AddFilterAttr} className={"admin-categories-item-buttonEdit"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        }</>
    );
};

export default Filter_edit;