import React, { useContext, useEffect, useState } from "react"

import Searchbar from "../../../customUI/Searchbar/Searchbar"
import { ICustomerLE } from "../../../../models/models"
import { ToogleVisible } from "../../../customUI/Searchbar/functions"
import AdminService from "../../../../services/AdminService"
import CustomTable, {
  IRowElement,
  ITable,
} from "../../../customUI/CustomTable/CustomTable"
import { Link } from "react-router-dom"
import { SpaRoutes } from "../../../../Routes/spaRoutes"
import "./Suppliers.css"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader"
import { Context } from "../../../../index"
import { Popup_Apply } from "../../../Supplier_UI_kit/Popup_Apply/Popup_Apply"
import seller from "../../../PublicPages/Register/Seller/Seller"
import { AdminPopupApply } from "../../../customUI/AdminPopupApply/Popup_Apply"
import Navbar from "../Navbar/Navbar"
import { AddBonusesPopup } from "./AddBonusesPopup"
import CustomInput from "../../../customUI/CustomInput/CustomInput"
import { MinusBonusesPopup } from "./MinusBonusesPopup"

const placeholder = "Найти поставщика"

const Suppliers = () => {
  const { store } = useContext(Context)
  const [suppliersTable, setSuppliersTable] = useState<ITable>({
    tableId: "TableSuppliers",
    header: [],
    rows: [],
  })
  const [showPopup, setShowPopup] = useState(false)
  const [popupElement, setPopupElement] = useState<any>()
  const [showAddBonusesPopup, setShowAddBonusesPopup] = useState(false)
  const [AddBonusesPopupElement, setAddBonusesPopupElement] =
    useState<JSX.Element>(<></>)
  const [MinusBonusesPopupElement, setMinusBonusesPopupElement] =
    useState<JSX.Element>(<></>)
  const [showMinusBonusesPopup, setShowMinusBonusesPopup] = useState(false)

  const buttonActivate = (id: string) => {
    store
      .showDialog(
        "Вы уверены, что хотите подключить 1С у этого поставщика?",
        true
      )
      .then(() => {
        if (store.dialog.dialogResult === true) {
            store.DataLoadingON()
          AdminService.activate1SForDealer(id, true)
            .then(async (response) => {
              if (response.status === 200) {
                getTable()
                store.showDialog("Поставщик успешно подключен к 1С")
              }
            })
            .catch(() => {
              store.showDialog("Что-то пошло не так")
            })
              .finally(() => store.DataLoadingOFF())
        }
      })
  }
  const buttonDeactivate = (id: string) => {
    store
      .showDialog(
        "Вы уверены, что хотите отключить 1С у этого поставщика?",
        true
      )
      .then(() => {
        if (store.dialog.dialogResult === true) {
            store.DataLoadingON()
          AdminService.activate1SForDealer(id, false)
            .then(async (response) => {
              if (response.status === 200) {
                getTable()
                store.showDialog("Поставщик отключен от 1С")
              }
            })
            .catch(() => {
              store.showDialog("Что-то пошло не так")
            })
              .finally(() => store.DataLoadingOFF())
        }
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const openPopup = (
    id: string,
    name: string,
    imageUrl: string,
    type: string
  ) => {
    let textOnButton = ""
    let thisHandler = () => {}
    if (type === "activate") {
      textOnButton = "Активировать"
      thisHandler = () => {
        AdminService.postActivateUser(id, true)
          .then(() => alert("Пользователь активирован"))
          .catch(() => store.ErrorON())
          .finally(() => {
            setShowPopup(false)
            getTable()
          })
      }
    } else if (type === "deactivate") {
      textOnButton = "Деактивировать"
      thisHandler = () => {
        AdminService.postActivateUser(id, false)
          .then(() => alert("Пользователь деактивирован"))
          .catch(() => store.ErrorON())
          .finally(() => {
            setShowPopup(false)
            getTable()
          })
      }
    }
    setPopupElement(
      AdminPopupApply({
        title: name,
        textOnButton: textOnButton,
        handler: thisHandler,
        imageUrl: imageUrl
          ? store.imgServer + imageUrl
          : "/Pictures/supplierNoImage.svg",
        onClose: () => {
          setShowPopup(false)
        },
      })
    )
    setShowPopup(true)
  }

  const openAddBonusesPopup = (
    id: string,
    officialName: string,
    name: string
  ) => {
    setAddBonusesPopupElement(
      <AddBonusesPopup
        id={id}
        officialName={officialName}
        name={name}
        onClose={() => {
          setShowAddBonusesPopup(false)
        }}
        updateData={getTable}
      />
    )
    setShowAddBonusesPopup(true)
  }

  const openMinusBonusesPopup = (
    id: string,
    officialName: string,
    name: string
  ) => {
    setMinusBonusesPopupElement(
      <MinusBonusesPopup
        id={id}
        officialName={officialName}
        name={name}
        onClose={() => {
          setShowMinusBonusesPopup(false)
        }}
        updateData={getTable}
      />
    )
    setShowMinusBonusesPopup(true)
  }

  const getTable = () => {
    store.DataLoadingON()
    AdminService.getDealers()
      .then((response) => {
        setSuppliersTable({
          tableId: "TableSuppliers",
          header: [],
          rows: [],
        })

        let list: IRowElement[] = []
        for (const supplier of response.data) {
          list.push({
            content: {
              dealer: (
                <div
                  data-search-word={`${supplier.lastName} ${supplier.firstName} ${supplier.vatherName}`}
                >{`${supplier.lastName} ${supplier.firstName} ${supplier.vatherName}`}</div>
              ),
              officialName: (
                <div data-search-word={supplier.officialName}>
                  {supplier.officialName}
                </div>
              ),
              displayedName: (
                <div data-search-word={supplier.displayedName}>
                  {supplier.displayedName}
                </div>
              ),
              telephone: <div>{supplier.telephone}</div>,
              email: (
                <div data-search-word={supplier.email}>{supplier.email}</div>
              ),
              //regionName: <div data-search-word={supplier.regionName}>{supplier.regionName}</div>,
              //statusEmail: <div>{supplier.statusEmail ? `Активна` : `Не активна`}</div>,
              statusEmail: supplier.statusEmail ? (
                <div className={"admin-supplier-ActivateDeactivate"}>
                  <div>Активна</div>
                  <img
                    src="/Pictures/VectorRedCross.svg"
                    alt="Деактивировать"
                    onClick={() => {
                      openPopup(
                        supplier.sellerId,
                        supplier.officialName,
                        supplier.imageUrl,
                        "deactivate"
                      )
                    }}
                  />
                </div>
              ) : (
                <div className={"admin-supplier-ActivateDeactivate"}>
                  <div>Не активна</div>
                  <img
                    src="/Pictures/VectorGreenPlus.svg"
                    alt="Активировать"
                    onClick={() => {
                      openPopup(
                        supplier.sellerId,
                        supplier.officialName,
                        supplier.imageUrl,
                        "activate"
                      )
                    }}
                  />
                </div>
              ),
              bonuses: (
                <div className={"admin-supplier-bonusesCell"}>
                  <div>{supplier.totalValue}</div>
                  <img
                    src="/Pictures/VectorRedMinus.svg"
                    alt="Списать бонусы"
                    onClick={() => {
                      openMinusBonusesPopup(
                        supplier.sellerId,
                        supplier.officialName,
                        `${supplier.lastName} ${supplier.firstName} ${supplier.vatherName}`
                      )
                    }}
                  />
                  <img
                    src="/Pictures/VectorGreenPlus.svg"
                    alt="Добавить бонусы"
                    onClick={() => {
                      openAddBonusesPopup(
                        supplier.sellerId,
                        supplier.officialName,
                        `${supplier.lastName} ${supplier.firstName} ${supplier.vatherName}`
                      )
                    }}
                  />
                </div>
              ),
              days: (
                <div
                  className={`hoverChild ${
                    supplier.remainingDays <= 3 && supplier.remainingDays != -1
                      ? "admin-supplier-days-red"
                      : ""
                  }`}
                >
                  {supplier.remainingDays == -1 ? "∞" : supplier.remainingDays}
                </div>
              ),
              isConnected1S: supplier.isConnected1S ? (
                <div className={"admin-supplier-ActivateDeactivate"}>
                  <div>Активна</div>
                  <img
                    src="/Pictures/VectorRedCross.svg"
                    alt="Деактивировать"
                    onClick={() => buttonDeactivate(supplier.sellerId)}
                  />
                </div>
              ) : (
                <div className={"admin-supplier-ActivateDeactivate"}>
                  <div>Не активна</div>
                  <img
                    src="/Pictures/VectorGreenPlus.svg"
                    alt="Активировать"
                    onClick={() => buttonActivate(supplier.sellerId)}
                  />
                </div>
              ),
              linkSupplier: (
                <div style={{}}>
                  <Link
                    to={
                      SpaRoutes.ADMIN_CHAT +
                      `/:${supplier.sellerId}/:${1}/:${0}`
                    }
                  >
                    <img
                      alt={"to customer"}
                      className={"table-iconUser"}
                      src={"/Pictures/message-square.svg"}
                    />
                  </Link>
                  <Link to={SpaRoutes.ADMIN_SUPPLIER + `/${supplier.sellerId}`}>
                    <img
                      className={"table-iconUser"}
                      src={"/Pictures/Editpen12.svg"}
                      alt={"Профиль поставщика"}
                    />
                  </Link>
                </div>
              ),
            },
            filter: [],
            visible: [true, true, true],
          })
        }
        let mytable: ITable = {
          tableId: "TableSuppliers",
          header: [
            "Поставщик",
            "Название компании",
            "Название на платформе",
            "Номер телефона",
            "Электронная почта",
            //"Регион", "Статус почты",
            "Статус активации",
            "Баллы",
            "Осталось дней",
            "Подключение 1С",
            "",
          ],
          //rows:[]
          rows: list,
        }
        setSuppliersTable(mytable)
      })
      .catch(() => {
        store.ErrorON()
      })
      .finally(() => {
        store.DataLoadingOFF()
      })
  }

  useEffect(() => {
    getTable()
  }, [])

  const changeHandler = (text: string) => {
    setSuppliersTable((prevState) => {
      const newState = { ...prevState }
      newState.rows = ToogleVisible(
        text,
        ["dealer", "officialName", "displayedName"],
        ["", "", ""],
        newState.rows
      )
      return newState
    })
  }

  return (
    <>
      {!store.isError && !store.isDataLoading && (
        <div className={"admin-suppliers-main"}>
          {/*<div className={"admin-navbar-main"}>*/}
          {/*    <Navbar/>*/}
          {/*</div>*/}
          <div className={"admin-suppliers-content-main"}>
            <CustomNavigateHeader title={"Поставщики"} withTrash={false} />
            <Searchbar
              changeHandler={changeHandler}
              initialText={placeholder}
            />
            <CustomTable {...suppliersTable} />
            {showPopup && popupElement}
            {showAddBonusesPopup && AddBonusesPopupElement}
            {showMinusBonusesPopup && MinusBonusesPopupElement}
          </div>
        </div>
      )}
    </>
  )
}
export default Suppliers
