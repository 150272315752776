import React, {useContext} from "react";
import './CustomTable.css'
import {Context} from "../../../index";


export interface ITable {
    tableId:string;
    header:any[];
    rows:IRowElement[];
}
export interface IRowElement{
    intern?: any;
    visible:boolean[];
    filter:any[];
    style?:any;
    content: any;
}

const CustomTable =(props:ITable)=> {
    const {store} = useContext(Context)
    const HeadBuilder = props.header.map((headElement:any,num) =>{
        if (num === 0){
            return(
                <th className={"custom-thf"}>
                    <div>{headElement}</div>
                </th>
            )
        }
        else{
            return(
                <>
                    <th>
                        <div>
                            <div className={"table-head-line"}></div>
                        </div>
                    </th>
                    <th className={"custom-thf"}>
                        <div>
                            {headElement}
                        </div>
                    </th>
                </>
            )
        }
    })
    const Rows = props.rows.map((row:IRowElement) => {
        if (row.visible.every((item)=>item===true)){
            let Cells = [];
            for (let i=0;i<Object.keys(row.content).length-1;i++){
                Cells.push(
                    <td className={"custom-tbf"}><div>{ row.content[Object.keys(row.content)[i]] }</div></td>
                );
                Cells.push(
                    <td><div><p className={"table-body-line"}></p></div></td>
                );
            }
            Cells.push(
                <td className={"custom-tbf"}>
                    <div>
                    {row.content[Object.keys(row.content)[Object.keys(row.content).length - 1]]}</div>
                </td>
            );
            return (
                <tr className={row.visible?"searchRow":"display_none"} style={row.style}>
                    {Cells.map((item)=>item)}
                </tr>
            )
        }
        else{
            return(<></>)
        }
    })
    const generate_custom_additions = () => {
        return props.header.map((item, num) => num === 0 ? <td>
            <div></div>
        </td> : <>
            <td>
                <div></div>
            </td>
            <td>
                <div></div>
            </td>
        </>)
    }
    return (
        <>
            {!store.isAuthLoading &&
                <div className={"table-container"}>
                    <table id={props.tableId} className={"custom-table"}>
                        <thead className={"custom-table-head"}>
                            <tr>
                                {HeadBuilder}
                            </tr>
                        </thead>
                        <tbody className={"custom-table-body"}>
                            <tr className={"custom-rowAddition"}>{generate_custom_additions()}</tr>
                            {Rows}
                            <tr className={"custom-rowAddition"}>{generate_custom_additions()}</tr>
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}
export default CustomTable;

