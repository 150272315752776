import React, {useContext, useRef, useState} from 'react';
import "./RegionsSelect.css"
import {IRegion} from "../../../models/models";
import {Context} from "../../../index";
import Searchbar from "../Searchbar/Searchbar";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import itemRegion from "./ItemRegion";
import Checkbox01 from "../checkbox01/checkbox01";

interface IRegionsSelect {
    selectRegionsProps: string[];
    regions: IRegion[];
    onChange: (regions: string[]) => void;
    regionsDisabled: string[];
}

const RegionsSelect = ({regions, selectRegionsProps, onChange, regionsDisabled}: IRegionsSelect) => {
    const {store} = useContext(Context)
    const [searchword, setSearchword] = useState<string>("")
    const [openSelect, setOpenSelect] = useState(false)

    const [selectRegions, setSelectRegions] = useState(selectRegionsProps)

    const [checked, setChecked] = useState(regions.map((r) => {
        return {regionId: r.regionId, checked: selectRegionsProps.includes(r.regionId)}
    }))
    const onClickRegion = (regionId: string, name: string) => {
        //selectRegionHandler(regionId,name)

        setSearchword('')
    }

    const ref = useRef(null)

    useOnClickOutside(ref, () => setOpenSelect(false))

    return (
        <div className={"regionsSelect"} ref={ref}>
            <div className={"regionsSelect-placeHolder"} onClick={() => {
                setOpenSelect(!openSelect);
                setSearchword('')
            }}>Выберите регионы
            </div>
            <div style={openSelect ? {display: "block"} : {display: "none"}} className={"regionsSelect_box"}>
                <div className={"RegionSelect_Search"}>
                    <Searchbar clearInputBool={openSelect} initialText={"Найти регион"} changeHandler={setSearchword}/>
                </div>
                <div className={"regionsSelect-items"}>
                    {selectRegions && regions &&
                        regions.map((region) => {
                            let ch = checked.find(r => r.regionId === region.regionId)?.checked
                            return (
                                <div style={(region.name.toLowerCase().includes(searchword.toLowerCase())) ?
                                    {display: "block"} :
                                    {display: "none"}}
                                     key={region.regionId}
                                     className={`${regionsDisabled.includes(region.regionId) && "RegionDisabled"} ${ch && "RegionSelect_Name"} RegionSelect_Item`}
                                     onClick={() => {
                                         if (!regionsDisabled.includes(region.regionId)) {
                                             setChecked(checked.map(c => {
                                                 if (c.regionId === region.regionId) {
                                                     c.checked = !c.checked
                                                     return c
                                                 } else return c
                                             }))
                                             let regions = selectRegions
                                             if (!ch) {
                                                 regions.push(region.regionId)
                                             } else {
                                                 regions = regions.filter(r => r !== region.regionId)
                                             }

                                             setSelectRegions(regions)
                                             onChange(regions)
                                         }else {
                                             return undefined
                                         }
                                         //region.regionId, region.name
                                         //onChange()
                                     }}

                                >
                                    {region.name}
                                </div>)
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default RegionsSelect;