import React from "react";
import "./MyOrderPaymentModal.css";

interface ModalProps {
    children: React.ReactNode;
    title: string;
    text:string;
    onClose: () => void;
}

export function MyOrderPaymentModal(props : ModalProps) {

    return (
        <>
            <div id="mop-PayM-close-wrapper" onClick={props.onClose} />
            <div id="mop-PayM-wrapper">
                <div id="mop-PayM-container">
                    <span id="mop-PayM-text-ty-for-order">{props.title}</span>
                    <span id="mop-PayM-text-notification">{props.text}</span>
                    <div className="mop-PayM-line"></div>
                    <span className={"mop-PayM-choice-element"} onClick={props.onClose}>ок</span>
                </div>
            </div>
        </>
    )
}