import {AxiosResponse} from "axios";
import $api from "../api/axios";
import {IChatMessageInfo} from "../models/models";
const {ApiRoutes:{Chat}} = require("../Routes/apiRoutes");

export default class ChatService {

    static async chatGeneral():Promise<AxiosResponse>{
        return await $api.get(Chat.CHAT_GENERAL);
    }

    static async sendMessages(message: any): Promise<AxiosResponse> {
        return await $api.post(Chat.MESSAGES, message);
    }
    static async sendUpdateMessages(message: any): Promise<AxiosResponse> {
        return await $api.post(Chat.UPDATE_MESSAGE, message);
    }
    static async sendDeleteMessages(message: any): Promise<AxiosResponse> {
        return await $api.post(Chat.DELETE_MESSAGE, message);
    }
    static async updateMessages(id:string,text: string): Promise<AxiosResponse> {
        return await $api.post(Chat.CHAT_UPDATE_MESSAGE, {Info1:id,Info2:text});
    }
    static async deleteMessages(id: string): Promise<AxiosResponse> {
        return await $api.post(Chat.CHAT_DELETE_MESSAGE, {Info1:id});
    }

    static async chatGetMessages(message: IChatMessageInfo):Promise<AxiosResponse>{
        return await $api.post(Chat.CHAT_GET_MESSAGES, {Info2: message.to, Info4: message.infoId, Number1: message.type })
    }

    static async chatSaveMessage(message: IChatMessageInfo):Promise<AxiosResponse>{
        return await $api.post(Chat.CHAT_SAVE_MESSAGE, {Info1:message.from, Info2: message.to,Info3:message.message, Info4: message.infoId, Number1: message.type })
    }

    static async chatUpdatedMessages(message: any): Promise<AxiosResponse> {
        return await $api.post(Chat.CHAT_UPDATE_MESSAGE, { Info1: message.messageId, Info2: message.message });
    }
    static async chatDeleteMessages(message: any): Promise<AxiosResponse> {
        return await $api.post(Chat.CHAT_DELETE_MESSAGE, { Info1: message.messageId });
    }

    static async removeFromUnread(message: IChatMessageInfo):Promise<AxiosResponse>{
        return await $api.post(Chat.CHAT_REMOVE_UNREAD, {Info2:message.to, Info1: message.from, Info4: message.infoId, Number1: message.type })
    }

    static async updateCounter(toGroup:string,forChat:string):Promise<AxiosResponse>{
        return await $api.post(Chat.UPDATE_COUNTER, {Info1:toGroup, Info2: forChat })
    }
}