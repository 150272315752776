
import React, {ChangeEvent, useState} from "react";
import '../../ProtectedPages/Supplier/Invoices/invoiceTables.css'
import Filterbox, {IFilterBoxProps, IFilterbutton} from "../../customUI/FilterBox/Filterbox";



interface ISelectFilterBoxProps {
    title?:string;
    filterGroups:IFilterBoxGroups[];
    updateMainFilter:any;
    updateFilter:any;
    filterList:any;
}
export interface IFilterBoxGroups{
    title:string;
    filterbox:IFilterBoxProps
}

const SelectFilterbox = (props:ISelectFilterBoxProps)=> {
    const [indexFilter,setindexFilter] = useState<number>(-1)

    return(
            <div className={"FilterGroupContainer"}>
                {props.title&&
                    <div className={"FilterGroupParentTitle"}>{props.title}</div>
                }
                {
                    indexFilter===-1
                        ?
                        <>
                            <div className={"FilterGroupParentBox"} >

                                {props.filterGroups.map((filter,index)=>{
                                    return <>
                                        <div className={"FilterGroupParent"} onClick={()=>{
                                            props.updateMainFilter(filter.title);
                                            setindexFilter(index);
                                        }}
                                        >{filter.title}</div>

                                    </>
                                })}
                            </div>
                        </>
                        :
                        <div className={"FilterboxTopLevel"}>
                            <div className={"FilterboxTopTitleBox"}>
                                <div className={"FilterboxTopLevelTitle"}>{props.filterGroups[indexFilter].title}</div>
                                <div onClick={()=>{
                                    props.updateMainFilter("")
                                    setindexFilter(-1);props.updateFilter("-1");}
                                }
                                     className={"FilterboxTopLevelTitleImage"}>
                                    <img src={"/Pictures/Крестик.svg"}/>
                                </div>
                            </div>
                            <Filterbox {...props.filterGroups[indexFilter].filterbox} filterList={props.filterList} updateFilter={props.updateFilter} />
                        </div>
                }
            </div>
    )
}
export default SelectFilterbox;

