import React, {useState} from 'react';
import "./CustomInput.css"
import InputMask from "react-input-mask";

interface ICustomInput {
    type?: string
    name: string,
    value?: any | undefined,
    placeHolder?: string,
    onChange?: (val: any) => any,
    icon?: boolean,
    error?: boolean,
    errorMsg?: [string] | any,
    mask?: string,
    styleInputField?: string,
    disabled?: boolean,
    required?: boolean,
    inputSize?: "large" | "small",
    min?: string,
    max?: string,
    step?: string,
    numberButtons?: boolean
}

function CustomInput(
    {
        type = "text", name, value=undefined, placeHolder,
        onChange, icon, error, errorMsg, mask = "",
        styleInputField = "", disabled=false,
        required=false, inputSize, min, max, step, numberButtons = true
    }: ICustomInput) {
    const [isRevealPwd, setIsRevealPwd] = useState(false);

    return (
        <>
            {type === "text" &&
                <div className={"custom-input"} style={{borderColor: error ? "#db2020" : ""}}>
                    <input
                        required={required}
                        style={{paddingRight: icon ? error ? "75px" : "44px" : "21px"}}
                        className={"custom-input-field " + styleInputField}
                        type={"text"}
                        name={name}
                        value={value !== null ? value : undefined}
                        placeholder={placeHolder}
                        onChange={onChange}
                        disabled={disabled}
                        data-size={inputSize}
                    />
                    {error &&
                        <div className={"сustom-input-error"}>
                            <img
                                className={"сustom-input-error-image"}
                                src={"/Pictures/errorIcon.svg"}
                            />
                            <div className={"сustom-input-error-text"}>
                                {errorMsg.map((e: any) => <div>{e}</div>)}
                            </div>
                        </div>
                    }
                    {icon &&
                        <img className={"custom-input-image"}
                             src={"/Pictures/InputIcon.svg"}
                        />
                    }
                </div>
            }
            {type === "number" &&
                <div className={"custom-input"} style={{borderColor: error ? "#db2020" : ""}}>
                    <input
                        style={{paddingRight: icon ? error ? "75px" : "44px" : "21px"}}
                        className={"custom-input-field"}
                        data-numberbuttons={numberButtons}
                        type={"number"}
                        name={name}

                        value={value}
                        placeholder={placeHolder}
                        onChange={onChange}
                        disabled={disabled}
                        data-size={inputSize}
                        min={min}
                        max={max}
                        step={step}
                    />
                    {error &&
                        <div className={"сustom-input-error"}>
                            <img
                                className={"сustom-input-error-image"}
                                src={"/Pictures/errorIcon.svg"}
                            />
                            <div className={"сustom-input-error-text"}>
                                {errorMsg.map((e: any) => <div>{e}</div>)}
                            </div>
                        </div>
                    }
                    {icon &&
                        <img className={"custom-input-image"}
                             src={"/Pictures/InputIcon.svg"}
                        />
                    }
                </div>
            }
            {type === "telephone" &&
                <div className={"custom-input"} style={{borderColor: error ? "#db2020" : ""}}>
                    <InputMask
                        style={{paddingRight: icon ? error ? "75px" : "44px" : "21px"}}
                        className={"custom-input-field"}
                        mask="+7 (999) 999-99-99" //+7 (999) 999-99-99
                        name={name}
                        value={value}
                        placeholder={placeHolder}
                        onChange={onChange}
                        disabled={disabled}
                        data-size={inputSize}
                    />
                    {error &&
                        <div className={"сustom-input-error"}>
                            <img
                                className={"сustom-input-error-image"}
                                src={"/Pictures/errorIcon.svg"}
                            />
                            <div className={"сustom-input-error-text"}>
                                {errorMsg.map((e: any) => <div>{e}</div>)}
                            </div>
                        </div>
                    }
                    {icon &&
                        <img className={"custom-input-image"}
                             src={"/Pictures/InputIcon.svg"}
                        />
                    }
                </div>
            }
            {type === "inputMask" &&
                <div className={"custom-input"} style={{borderColor: error ? "#db2020" : ""}}>
                    <InputMask
                        style={{paddingRight: icon ? error ? "75px" : "44px" : "21px"}}
                        className={"custom-input-field"}
                        mask={mask}
                        name={name}
                        value={value}
                        placeholder={placeHolder}
                        onChange={onChange}
                        disabled={disabled}
                        data-size={inputSize}
                    />
                    {error &&
                        <div className={"сustom-input-error"}>
                            <img
                                className={"сustom-input-error-image"}
                                src={"/Pictures/errorIcon.svg"}
                            />
                            <div className={"сustom-input-error-text"}>
                                {errorMsg.map((e: any) => <div>{e}</div>)}
                            </div>
                        </div>
                    }
                    {icon &&
                        <img className={"custom-input-image"}
                             src={"/Pictures/InputIcon.svg"}
                        />
                    }
                </div>
            }
            {
                type === "password" &&
                <div className={"custom-input"} style={{borderColor: error ? "#db2020" : ""}}>
                    <input
                        style={{paddingRight: icon ? error ? "75px" : "44px" : "21px"}}
                        name={name}
                        className={"custom-input-field"}
                        type={isRevealPwd ? "text" : "password"}
                        onChange={onChange}
                        value={value}
                        placeholder={placeHolder}
                        disabled={disabled}
                        data-size={inputSize}
                    />
                    {error &&
                        <div className={"сustom-input-error"}>
                            <img
                                className={"сustom-input-error-image"}
                                src={"/Pictures/errorIcon.svg"}
                            />
                            <div className={"сustom-input-error-text"}>
                                {errorMsg.map((e: any) => <div>{e}</div>)}
                            </div>
                        </div>
                    }
                    {icon &&
                        <img
                            className={"custom-input-image"}
                            src={isRevealPwd ? "/Pictures/eye2.svg" : "/Pictures/eye.svg"}
                            onClick={() => setIsRevealPwd(prevState => !prevState)}
                        />
                    }
                </div>
            }
        </>
    )

};

export default CustomInput;