import $api from "../api/axios";
import {AxiosResponse} from "axios";
import {bool} from "yup";
const {ApiRoutes: {CabinetBuyer}} = require("../Routes/apiRoutes");

/*export default function CabinetBuyerService(productId:string, orderId:string, orderAmount:number){

    const BaskIns = (productId:string) => {
        $api
            .post('/CabinetBuyer/basketInsert',
                {Info1: productId});
    };

    const changeAmount = (orderId: string, newAmount: number) => {
        $api.post("/CabinetBuyer/basketUpdateAmount",
            {
                Info1: orderId,
                Number1: newAmount,
            })
    };

    const CallBasket = (productId:string) => {
        let counter = 0;
        $api
            .get('/CabinetBuyer/Basket').then((data)=>{
            for (const key in data.data.suppliersitems){
                for (const key2 in data.data.suppliersitems[key].suppliercards){
                    if (data.data.suppliersitems[key].suppliercards[key2].productId == productId){
                        counter++;
                        orderId = data.data.suppliersitems[key].suppliercards[key2].orderId;
                        orderAmount = data.data.suppliersitems[key].suppliercards[key2].orderAmount;
                        changeAmount(orderId,orderAmount+1);
                    }
                }
            }
            if(counter == 0) BaskIns(productId);
        })
    };

    return CallBasket(productId);
}*/

export default class CabinetBuyerService{
    static async basket_insert(productId:string):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.BASKET_INSERT, {Info1: productId});
    }
    static async change_amount(orderId: string, newAmount: number):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.BASKET_UPDATE_AMOUNT, {Info1: orderId, Number1: newAmount});
    }
    static async cancel_order(orderId: string):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.CANCEL_ORDER, {Info1: orderId});
    }
    static async revert_order(orderId: string):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.REVERT_ORDER,{Info1: orderId});
    }
    static async to_cart(productId:string, orderId: string, orderAmount: number):Promise<AxiosResponse>{
        if(orderId !== null) return this.change_amount(orderId, orderAmount + 1);
        else return this.basket_insert(productId);
    }
    static async getMyOrders(active:boolean):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.MY_ORDERS,{Bool:active});
    }
    static async getInfoContinueOrder():Promise<AxiosResponse>{
        return $api.get(CabinetBuyer.ORDER_FORMATION_NOT_EMPTY);
    }
    static async postSetInvoice(list:string[]):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.SET_INVOICE,{List1:list})
    }
    static async getOrderFormation():Promise<AxiosResponse>{
        return $api.get(CabinetBuyer.ORDER_FORMATION);
    }
    static async postSetStatusTwo(invoiceId: string, isDrop: boolean, adressId:string, from:string, until:string, comment:string):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.SET_STATUS_TWO,
            {
                invoiceId: invoiceId,
                isDrop: isDrop,
                adressId: adressId,
                from: from,
                until: until,
                comment: comment
            });
    }
    static async postOrderFormationDeleteInvoice(invoiceId: string):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.ORDER_FORMATION_DELETE_INVOICE, {Info1:invoiceId});
    }

    static async postGetInvoice(list:(string|undefined)[]):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.INVOICE,{List1:list})
    }

    static async BasketNotEmpty(_withCredentials: boolean):Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.BASKET_NOT_EMPTY, {withCredentials:_withCredentials})
    }

    static async historyViewed(_withCredentials: boolean):Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.HISTORY_VIEWED)
    }

    static async basketUpdateAmount(orderId: string, orderAmount: number):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.BASKET_UPDATE_AMOUNT, {
            Info1: orderId,
            Number1: orderAmount
        });
    }

    static async basketInsert(productId: string):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.BASKET_INSERT, {
            Info1: productId
        })
    }

    static async indexGet():Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.INDEX_GET);
    }

    static async indexPost(data: object):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.INDEX_POST, data,
            {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async deleteAccount():Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.DELETE_ACCOUNT);
    }

    static async favorites():Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.FAVORITES);
    }

    static async basketdeleteOrder(orderId?: string):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.BASKET_DELETE_ORDER,{Info1: orderId});
    }

    static async Basket():Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.BASKET);
    }

    static async basketdeleteAllOrders():Promise<AxiosResponse>{
        return await $api.get(CabinetBuyer.BASKET_DELETE_ALL_ORDERS);
    }

    static async watchlistSwitch(id: string):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.WATCHLIST_SWITCH, {info1: id})
    }

    static async productInfoForBasket(orderId: string, productID: string|undefined):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.PRODUCT_INFO_FOR_BASKET,
            {Info1: orderId, Info2: productID })
    }
    static async setPaymentStatus(invoiceId:string, paymentStatus: number):Promise<AxiosResponse>{
        return await $api.post(CabinetBuyer.SET_PAYMENT,
            {Info1: invoiceId, Number1: paymentStatus })
    }
    static async getTraining(): Promise<AxiosResponse> {
        return $api.get(CabinetBuyer.TRAINING);
    }
    static async postSignUpForEvent(data:any):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.SING_UP_FOR_EVENT, data, {headers: {'Content-Type': `application/json`}});
    }

    static async setAdress(invoiceId: string | undefined, isDrop: boolean, address: string):Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.OR_FO_SET_ADRESS, {Info1:invoiceId,Info2:address,Bool:isDrop});
    }

    static async setComment(invoiceId: string | undefined, comment: string) :Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.OR_FO_SET_COMMENT, {Info1:invoiceId,Info2:comment});
    }

    static async setTime(invoiceId: string | undefined, from: string, until: string) :Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.OR_FO_SET_TIME, {Info1:invoiceId,Info2:from,Info3:until});
    }

    static async updateSingleAdress(index: number, street: string, number: string, postcode: number, regionName: string, cityName: string, apartmentNumber: string, comment: string ) :Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.UPDATE_SINGLE_ADRESS, {Number1:index,Info1:cityName,Info2:regionName,Info3:postcode.toString(),Info4:street,Info5:number, Info6: apartmentNumber, Info7: comment});
    }

    static updateSingleAdressDelete(index: number) :Promise<AxiosResponse>{
        return $api.post(CabinetBuyer.UPDATE_SINGLE_ADRESS_DELETE, {Number1:index});
    }
}