import React, {useContext, useEffect, useState} from "react";
import "../../Supplier/Chat/SupplierChat.css";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";
import {useNavigate, useParams} from "react-router-dom";
import ChatService from "../../../../services/ChatService"
import ChatWindowItem from "../../../customUI/Chat/ChatWindowItem/ChatWindowItem";
import {ChatWindowType} from "../../../../models/Chat/Chat"
import ChatWindow from "../../../customUI/Chat/ChatWindow/ChatWindow";
import {SpaRoutes} from "../../../../Routes/spaRoutes";

function AdminChat(){
    const {store} = useContext(Context);
    const navigate = useNavigate()
    const [chatWindowItems, setChatWindowItems] = useState<ChatWindowType[]>([]);
    let {id, type, infoId} = useParams();
    if(id === undefined) id=""
    if(type === undefined) type="0"
    if(infoId === undefined) infoId=""

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
        store.DataLoadingON();
        ChatService.chatGeneral().then(result => {
            setChatWindowItems(result.data)
        })
            .catch(()=>{
                store.ErrorON();
            })
            .finally(()=>{
                store.DataLoadingOFF();
            })
    }, []);

    useEffect(() => {
        if (store.isHubOn) {
            store.hubConnection.on("UpdateCounter", (forChat: string) => {
                if ("group:" === forChat.substring(0,6)){
                    if(!window.location.href.includes(forChat.slice(6))) {
                        setChatWindowItems(prevState => {
                            let newState = [...prevState]
                            newState.map(item => {
                                if (item.infoId === forChat.slice(6) && item.infoId !== infoId?.slice(1)) {
                                    item.counter = item.counter + 1;
                                }
                                return item;
                            })
                            return newState;
                        })
                    }
                }
                else{
                    if(!window.location.href.includes(forChat)) {
                        setChatWindowItems(prevState => {
                            let newState = [...prevState]
                            newState.map(item=>{
                                if (item.userId===forChat && item.userId !== id?.slice(1)){
                                    item.counter = item.counter+1;
                                }
                                return item;
                            })
                            return newState;
                        })
                    }
                }

            });
        }
    }, [store.isHubOn]);

    let unreadTotal = chatWindowItems.reduce((count,item)=>{
        return count+item.counter
    },0)

    const clickChatWindowItem = (chat: ChatWindowType) => {
        setChatWindowItems(prevState => {
            let newState = [...prevState]
            newState.map(item=>{
                item.focus =
                    item.userId === chat.userId &&
                    item.type === chat.type &&
                    item.infoId === chat.infoId;
                if (item.focus === true){
                    item.counter = 0;
                }
                return item;

            })
            return newState;
        })
        navigate(SpaRoutes.ADMIN_CHAT + `/:${chat.userId}/:${chat.type}/:${chat.infoId}`)
    }
    const sortedChats = chatWindowItems.sort((a: { counter: number; }, b: { counter: number; }) => b.counter - a.counter);

    const chatWindowItemsElem = sortedChats.map((chat,item) => (
        <div className="supplier_ChatWindowItem">
            <ChatWindowItem
                style={item===0?{paddingTop:"30px"}:{}}
                unreadTotal={unreadTotal}
                unread={chat.counter}
                key={chat.user.id}
                counter={chat.counter}
                lastMessage={chat.lastMessage}
                user={chat.user}
                role = "Dealer"
                onClick={() => clickChatWindowItem(chat)}
                focus={chat.focus}
                infoText={chat.infoText}
                infoId={chat.infoId}
                type={chat.type}
            />
        </div>
    ));

    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <>
                    <div style={{height: "calc(100vh - 82px)"}} className="supplier_chat_window">
                        <div className="supplier_left_menu_chat">
                            <div id={'supplier-chat-scrollbar'} className="supplier_ChatWindowItem_container">
                                <div id={'supplier-chat-scrollbar'} className="supplier_ChatWindowItem_container">
                                    {chatWindowItemsElem}
                                </div>
                            </div>
                        </div>
                        <div className="supplier_chat_right_container">
                            <div className={"supplier-chatwindow-container"}>
                                <ChatWindow
                                    id={id}
                                    type={type}
                                    infoId={infoId}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default observer(AdminChat);
