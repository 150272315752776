import useOnClickOutside from "../../../hooks/useOnClickOutside";
import {useContext, useRef} from "react";
import {Context} from "../../../index";
import "./Dialog.css";


interface IDialogComponent{
    open: boolean;
    text: string;
    options?: boolean;
    title?:string;
}


export default function Dialog(props:IDialogComponent){

    const {store} = useContext(Context);

    const ref = useRef(null);

    const CloseCancelHandler = () => (
        props.options ? store.setDialogResult(false): store.setDialogClose()
    );

    useOnClickOutside(ref, () => CloseCancelHandler());

    return(
        <>
            {
                props.open && <div className={props.open ? "dialog_wrapper active" : "dialog_wrapper"} ref={ref}>
                    <div className={props.open ? "dialog_container active" : "dialog_container"}>
                        <button className="dialog_close" tabIndex={1} onClick={CloseCancelHandler}>
                            <img src="/Pictures/Close.svg" alt=""/>
                        </button>
                        {props.title && <div className={"dialog_title"}>
                            {props.title}
                        </div>}
                        <div className={"dialog_text"}>
                            {props.text}
                        </div>
                        {
                            props.options === true && <div className={"dialog_options"}>
                                <button className="dialog_option_no" tabIndex={2} onClick={CloseCancelHandler}>отмена</button>
                                <button className="dialog_option_yes" tabIndex={3} onClick={()=>store.setDialogResult(true)}>ок</button>
                            </div>
                        }
                    </div>
                </div>
            }
        </>

    )
}