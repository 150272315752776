import {useParams} from "react-router-dom";
import ChatWindow from "../../../customUI/Chat/ChatWindow/ChatWindow";
import './Chat.css'

export const CustomerDialogPage = () => {
    let {id, type, infoId} = useParams();
    if(id === undefined) id=""
    if(type === undefined) type="0"
    if(infoId === undefined) infoId=""
  return(
      <div style={{height: "50px"}}>
          <ChatWindow
              id={id}
              type={type}
              infoId={infoId}
          />
      </div>
  )
}