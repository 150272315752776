import React, {useContext, useEffect, useState} from 'react';
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import {IReklama} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import "./ModerationReklama.css"
import ModerationReklamaItem from "./ModerationReklamaItem";

const ModerationReklama = () => {

    const {store} = useContext(Context)

    const [moderationReklams, setModerationReklams] = useState<JSX.Element[]>([])
    const [dataReklama, setDataReklama] = useState<IReklama[]>([])
    const [openAll, setOpenAll] = useState(false)

    const getData = () => {
        store.DataLoadingON()
        AdminService.getEntertainment4Moderation()
            .then((r) => setDataReklama(r.data))
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setModerationReklams(dataReklama.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
            .map((r,index) => {
                return <ModerationReklamaItem reklama={r} openProps={openAll} updateData={getData} key={r.reklamaId}/>
            }))
    }, [dataReklama, openAll])

    return (
        <div className={"moderationReklama"}>
            <CustomNavigateHeader title={"Модерация рекламы"} withTrash={false}/>
            <div className={"moderationReklama-openAll"}>
                <button data-active={openAll} onClick={() => setOpenAll(!openAll)}>{openAll? "Скрыть все баннеры" : "Показать все баннеры"}</button>
            </div>
            <div className={"moderationReklama-content"}>
                {moderationReklams}
            </div>
        </div>
    );
};

export default ModerationReklama;