import React, {useState, useEffect} from "react"
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown"
import Searchbar from "../../../customUI/Searchbar/Searchbar"
import "./SelectProduct.css"
import {IDropdownOption} from "../../../../models/models"
import HomeService from "../../../../services/HomeService";
import {SPCategories} from "../../../../models/SelectProduct/SPCategories";
import {SPCard} from "../../../../models/SelectProduct/SPCard";
import CustomSwiperSupplier from "../../../Supplier_UI_kit/CustomSwiperSupplier/CustomSwiperSupplier";
import {ToogleVisible} from "../../../customUI/Searchbar/functions";
import {Link, useNavigate} from "react-router-dom";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {SPQuestionsCard} from "../../../../models/SelectProduct/SPQuestionsCard";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import ProductCardSupplier from "../../../Supplier_UI_kit/CustomSwiperSupplier/ProductCardSupplier/ProductCardSupplier";
import {SwiperSlide} from "swiper/react";

export default function SelectProduct() {

    // Состояния категории
    const [CategoryArray, setCategoryArray] = useState<SPCategories>()
    const [dropdownCategory, setDropdownCategory] = useState<IDropdownOption[]>([])
    const [selectedDropdownCategory, setSelectedDropdownCategory] = useState("")
    const [allQuestionProducts, setAllQuestionProducts] = useState<SPQuestionsCard[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    //Состояния подкатегории
    const [subDropdownCategory, setSubDropdownCategory] = useState<IDropdownOption[]>([])
    const [selectedSubDropdownCategory, setSelectedSubDropdownCategory] = useState("")
    const [allProducts, setAllProducts] = useState<any[]>([]);

    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    //Вывод категорий
    useEffect(() => {
        let list: IDropdownOption[] = []
        HomeService.getCategories()
            .then((category) => {
                list.push(({
                    key: "Все категории",
                    name: "Все категории",
                    visible: true
                }))
                category.data.categories.map((item: any) => {
                    list.push({
                        key: item.topCatId,
                        name: item.topCatName,
                        visible: true
                    })
                })
                setCategoryArray(category.data)
                setDropdownCategory(list)
                setSelectedDropdownCategory(list[0].name)
                setSelectedSubDropdownCategory("Все подкатегории")
                CabinetSellerService.getRootProducts()
                    .then((products) => {
                        let list: SPCard[] = []
                        products.data.map((item: any) => {
                            list.push({
                                topCatId: item.topCatId,
                                subCatId: item.subCatId,
                                rootId: item.rootId,
                                title: item.title,
                                pictureUrl: item.pictureUrl,
                                magazines: item.magazines,
                                visibleList: [true, true, true],
                                visible: true,
                            })
                        })
                        setAllProducts(list);
                    })
                CabinetSellerService.getMyRootQuestions()
                    .then((product) => {
                        let newData = product.data.map((item: any) => {
                            if (item.pictureUrl) {
                                return {
                                    questionId: item.id,
                                    pictureUrl: item.pictureUrl,
                                    title: item.title,
                                    description: item.description,
                                    categoryId: null,
                                    magazine: item.magazine,
                                    questionStatus: item.status,
                                    visible: [true]
                                }
                            } else {
                                return {
                                    questionId: item.id,
                                    noImage: '/Pictures/supplierNoImage.svg',
                                    title: item.title,
                                    description: item.description,
                                    categoryId: null,
                                    magazine: item.magazine,
                                    questionStatus: item.status,
                                    visible: [true]
                                }
                            }
                        })
                        setAllQuestionProducts(newData);
                    })
                    .finally(() => setloading(true))
            })
    }, [])

    useEffect(() => {
        let list: IDropdownOption[] = [{key: "Все подкатегории", name: "Все подкатегории", visible: true}]
        CategoryArray?.categories.map((category) => {
            if (selectedDropdownCategory == category.topCatName) {
                category.categories.map((subcategories) => {
                    list.push({
                        key: subcategories.subCatId,
                        name: subcategories.subCatName,
                        visible: true
                    })
                })
            }
        })

        setSubDropdownCategory(list)
        if (list[0]) {
            setSelectedSubDropdownCategory("Все подкатегории")
        } else {
            setSelectedSubDropdownCategory("Нет подкатегорий")
        }
    }, [selectedDropdownCategory])

    const handleSelectProductCategory = (topCatId: string) => {
        let allCategories = topCatId === "Все категории";
        setAllProducts(prevState => {
            let newState = [...prevState]
            newState.map(product => {
                product.visibleList[1] = (!allCategories ?
                    (product.topCatId === topCatId) :
                    allCategories);
                product.visible = product.visibleList.every((item: any) => item)
            })
            return newState;
        })
    }
    const handleSelectProductSubCategory = (subCatId: string) => {
        let allSubCategories = subCatId === "Все подкатегории";
        setAllProducts(prevState => {
            let newState = [...prevState]
            newState.map(product => {
                product.visibleList[2] = !allSubCategories ?
                    (product.subCatId === subCatId) :
                    allSubCategories;
                product.visible = product.visibleList.every((item: any) => item)
            })
            return newState;
        })
    }
    const changeHandler = (text: string) => {
        setAllProducts((prevState) => {
            let Array: { content: any; visible: boolean[] }[] = []
            let newState = [...prevState];
            newState.map(product => {
                Array.push({
                    content: product,
                    visible: product.visibleList
                })
            })
            Array = ToogleVisible(text, ["title"], [""], Array)
            newState = Array.map(item => {
                return {
                    ...item.content,
                    visibleList: item.visible,
                    visible: item.visible.every(i => i)
                }
            })
            return newState
        })
    }

    return (
        <div className={"selectProduct"}>
            <CustomNavigateHeader title={"Создать карточку товара"} withTrash={false}/>
            <h2>Выберите товар из каталога</h2>
            <label className="label_text">Поиск по названию</label>
            <div className={"selectProduct-searchbar"}>
                <Searchbar
                    initialText={"Название"}
                    changeHandler={changeHandler}
                />
            </div>

            <div className="SearchbarSP">
                <div className="frame_category">
                    <div className="frame">
                        <span className="label_text">Категория</span>
                        <CustomDropdown
                            options={dropdownCategory}
                            value={selectedDropdownCategory}
                            dropdownSize={"medium"}
                            valueSetter={setSelectedDropdownCategory}
                            onChangeHandler={handleSelectProductCategory}
                        />
                    </div>
                    <div className="frame">
                        <span className="label_text">Подкатегория</span>
                        <CustomDropdown
                            options={subDropdownCategory}
                            dropdownSize={"medium"}
                            value={selectedSubDropdownCategory}
                            valueSetter={setSelectedSubDropdownCategory}
                            onChangeHandler={handleSelectProductSubCategory}
                        />
                    </div>
                    <div className="frame">
                        <Link to={SpaRoutes.PRODUCT_DETAILS + `/0/0/0`}>
                            <button className={"btn_NewProduct"}>Создать новый товар +</button>
                        </Link>
                    </div>
                </div>
            </div>

            {!loading ? <Loader load={true}/> :
                <>
                    {
                        allQuestionProducts.filter((rq) => {
                            return rq.questionStatus === 1
                        }).length > 0 ?

                            <details className={"selectProduct-dropBlock"}>
                                <summary style={{fontSize: "16px", fontWeight: "600"}} className="label_text">
                                    На модерации
                                    (
                                    <span style={{color: "red"}}>
                                    {allQuestionProducts.filter((rq) => {
                                        return rq.questionStatus === 1
                                    }).length}
                                    </span>
                                    )
                                </summary>
                                <CustomSwiperSupplier data={allQuestionProducts.filter((rq) => {
                                    return rq.questionStatus === 1
                                })}/>
                            </details> : <></>
                    }
                    {
                        allQuestionProducts.filter((rq) => {
                            return rq.questionStatus === 0
                        }).length > 0 ?

                            <details className={"selectProduct-dropBlock"}>
                                <summary style={{fontSize: "16px", fontWeight: "600"}} className="label_text">
                                    Черновик
                                    (
                                    <span style={{color: "red"}}>
                                    {allQuestionProducts.filter((rq) => {
                                        return rq.questionStatus === 0
                                    }).length}
                                    </span>
                                    )
                                </summary>
                                <CustomSwiperSupplier data={allQuestionProducts.filter((rq) => {
                                    return rq.questionStatus === 0
                                })}/>
                            </details> : <></>
                    }
                    <label className="label_text">Доступные товары</label>
                    {/*<CustomSwiperSupplier data={allProducts}/>*/}

                    <div className={"selectProduct-allProducts"}>
                        {allProducts.map((card) => {
                            if ("visible" in card ? card.visible : true) {
                                return (
                                    <ProductCardSupplier
                                        key={"rootId" in card ? card.rootId : "id"
                                        in card ? card.id : "productId"
                                        in card ? card.productId : "0"}
                                        item={card}
                                        rootId={"rootId" in card ? card.rootId : "0"}
                                        productId={"productId" in card ? card.productId : "0"}
                                        questionId={"questionId" in card ? card.questionId : "0"}
                                        linkToReview={"linkToReview" in card ? card.linkToReview : undefined}
                                    />
                                )
                            }
                        })}
                    </div>
                </>
            }
        </div>
    )
}
