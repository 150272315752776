import React, {useEffect, useState} from "react";
import "./MyOrdersElement.css";
import {IMyOrders} from "../../../../../models/models";
import {Link} from "react-router-dom";
import MyOrdersDropdown from "../../../../customUI/MyOrdersDropdown/MyOrdersDropdown";
import {getParsedDate,CompareDates} from "../../../../../functions/functions";
import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";

interface Interface {
    props:IMyOrders;
}
export default function MyOrdersElement({props}:Interface){
    const FunctionButtonStyle:React.CSSProperties = {
        fontSize: 10,
        borderRadius: 7,
        float:"right",
    }
    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const [paymentButtonText,setPaymentButtonText] = useState("Выбрать способ оплаты")
    const Statuslist:string[] =
        ['','',"Открыт","Принят","Готов к выдаче","Доставка","Выдан",
            "Оплачен",'Возврат','','Отменен','Подтверждение отмены',''];
    const PaymentOptions:{name:string;id:number;}[] =
        [{name:"Наличные",id:1},
            {name:"Банковская карта",id:2},
            {name:"Бонусы",id:3},
            {name:"Со счета",id:4},
            {name:"QR-код",id:5},
            {name:"По терминалу",id:6}];
    const price =
        new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(PriceCounter());
    //const dateUnderStatus = CompareDates(props.orders.map((item)=> item.lastChanged));
    const dateForDelivery = CompareDates(props.orders.map((item)=> item.deliverFrom?item.deliverFrom:""));
    const location = props.adresses[0].streetandNumber;
    const image = (props.image_supply_company !== null)?
        (<CustomIMG className={"myorders-supply-img"} src={props.image_supply_company}/>):
        (<></>);

    useEffect(() => {
        let counter = props.orders[0]?.orderStatus;
        for(const key in props.orders){
            if(counter!== undefined){
                if(props.orders[key].orderStatus?props.orders[key].orderStatus:0 < counter)
                    counter = props.orders[key].orderStatus
            }
        }
        for(const key2 in PaymentOptions){
            if(PaymentOptions[key2].id===props.payment){
                setPaymentButtonText(PaymentOptions[key2].name);
            }
        }

    },[])

    function PriceCounter(){
        let sum=0;
        props.orders.map((item) => sum+=(item.price1*item.amount));
        return sum;
    }

    return(
        <>
            <div className={"myorders-main-div"}>
                <span className={"myorders-location"}>{location}</span>
                <span className={"myorders-location"}>Заказ №{props.invoiceId}</span>
            </div>
            <div className={"myorders-main-div"}>
                <div className={` myorders-status-red 
                ${(props.invoiceStati.lastIndexOf(7)===-1 &&
                    props.invoiceStati.lastIndexOf(10)===-1 &&
                    props.invoiceStati.lastIndexOf(11)===-1 &&
                    props.invoiceStati.lastIndexOf(8)===-1
                )
                &&"myorders-status-green"}`}>
                    {props.invoiceStati&&Statuslist[props.invoiceStati[props.invoiceStati.length - 1]]}
                </div>
                <Link
                    to={SpaRoutes.MYORDER + `/${props.invoiceId}`}
                >
                    <span className={"myorders-cancel"}>Отменить заказ</span>
                </Link>
            </div>
            <div className={"myorders-main-div"}>
                <span className={"myorders-date-item-string"}>
                    {/*{getParsedDate(dateUnderStatus,false,false)}*/}
                </span>
                <span className={"myorders-amount"}>{price}</span>
            </div>
            <div className={"myorders-supplier-div"}>
                <div className={"myorders-supply-company"}>{props.supply_company}
                    {image}
                </div>
                <Link to={SpaRoutes.MYORDER_PAYMENT + `/${props.invoiceId}`} state={{ john:"john", price:price, chosenOption:props.payment}}>
                    <div className={"myorders-payment-button"}>
                        <FunctionButton text={paymentButtonText} onClickHandler={()=>{}} style={FunctionButtonStyle}/>
                    </div>
                </Link>
            </div>
            <div className={"myorders-main-div"}>
                <MyOrdersDropdown
                    propsnext={props}
                    title={(props.orders.length == 1)?("позиция"):("позиции(й)")}/>
                <Link
                    to={SpaRoutes.MYORDER + `/${props.invoiceId}`}
                    state={props}>
                    <span className={"myorders-more"}>Подробнее</span>
                </Link>
            </div>
            <span className={"myorders-date"}>Дата заказа: {getParsedDate(dateForDelivery, true,false)}</span>
        </>
    );
}