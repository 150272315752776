import React, {FC, useContext, useEffect, useState} from "react";
import { Context } from "../../../index";
import {Link, useLocation, useNavigate} from "react-router-dom";
import { observer } from "mobx-react-lite";
import "./login.css";
import {MenuSticky} from "../../Layout/UserLayout/MenuSticky/MenuSticky";
import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {SpaRoutes} from "../../../Routes/spaRoutes";

const Login: FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const {store} = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  useEffect(()=> {
    window.scrollTo(0, 0)
  },[])
  const [coincidenceLogin, setCoincidenceLogin] = useState(false);
  const [coincidencePassword, setCoincidencePassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showLink,setShowLink] = useState<boolean>(false)
  const [enableButton,setEnableButton] = useState(true)

  const PassHandler = (e: any) => {
    e = e.toLowerCase().replaceAll(" ","");
    setPassword(e);
    const mass = [
    /(?=.*[0-9])/,
    // /(?=.*[!@#$%^&*.])/,
    /(?=.*[a-zа-яёA-ZА-ЯЁ])/,
    // /(?=.*[A-ZА-ЯЁ])/,
    /[0-9a-zа-яёA-ZА-ЯЁ!@#$%^&*.]{6,}/,
    ];
    for (let i = 0; i < mass.length; i++) {
      const reg = new RegExp(mass[i]);
      if (!reg.test(String(e))) {
        setCoincidencePassword(true);
        setErrMsg("Введите корректный email или пароль");
        break;
      } else {
        setCoincidencePassword(false);
        setErrMsg("");

      }
    }
  };

  const handlerLogin = () => {
    if(!errorBool){
      setEnableButton(false)
      store.DataLoadingON()
      store.login(email, password)
          .then(async (r) => {

            switch (r) {
              case 200:
                  navigate(from, {replace: true});
                break;
              case "Error_1":
                setErrMsg('Вы не активировали свою учетную запись. Пожалуйста, проверьте свою электронную почту, включая папку "Спам".');
                setShowLink(true);
                break;
              case "Error_2":
                setErrMsg("ваш аккаунт заблокирован. пожалуйста, свяжитесь со службой поддержки для получения дополнительной информации.");
                break;
              default:
                setErrMsg("Что-то пошло не так.");
            }
            setEnableButton(true)
            // errorBool = false;
          })
          .catch(()=> {
            store.ErrorON()
          })
          .finally(()=> {
            store.DataLoadingOFF()
          })
    }
  };

  const emailHandler = (e: any) => {
    e = e.toLowerCase().replaceAll(" ","");
    setEmail(e);
    const reEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/;
    if (!reEmail.test(String(e).toLowerCase())) {
      setCoincidenceLogin(true);
      setErrMsg("Введите корректный email или пароль");
    } else {
      setCoincidenceLogin(false);
      setErrMsg("")
    }
  };

  let errorBool = !email || !password || coincidenceLogin || coincidencePassword;

  return (
      <>
        <MenuSticky/>
        <div  style={{marginTop:"25px"}}>
          <CustomNavigateHeader
              trashClickHandler={() => { }}
              title={``}
              withTrash={false}
          />
          <div className={"login_form"} id={"login_form_id"}>
            <div className="v2_53">
              <div className="">
                <span className="greetings-h1">Вход</span>
                <span className="greetings">
            Приветствуем вас на нашей платформе!
          </span>
              </div>
              <div className="v2_57">
                <div className="v2_58">
                  <div className="v2_59">
                    <div className="v2_60">
                      <span className="login_span">Электронная почта</span>
                      <div className="v2_62">
                        <input
                            name={"loginEmail"}
                            className={"login_input"}
                            autoComplete={"loginEmail"}
                            type="text"
                            onChange={(e) => emailHandler(e.target.value)}
                            value={email}
                            placeholder={"Email"}
                            required
                        />
                      </div>
                    </div>
                    <div className="v2_64">
                      <div className="v2_65">
                        <span className="login_span">Пароль</span>
                      </div>
                      <div className="v2_67">
                        <div className={"row login_pass_container"}>
                          <input
                              name={"loginPassword"}
                              autoComplete={"loginParol"}
                              className={"login_pass_input"}
                              type={isRevealPwd ? "text" : "password"}
                              onChange={(e) => PassHandler(e.target.value)}
                              value={password}
                              placeholder={"Пароль"}
                              required
                          />

                          <img
                              className={"login_pass_input_eye"}
                              src={isRevealPwd ? "/Pictures/Eye01.png" : "/Pictures/EyeClosed01.png"}
                              onClick={() => setIsRevealPwd(!isRevealPwd)}
                          />
                        </div>
                        {(errMsg) && (
                            <div style={{ color: "red",maxWidth:"260px" }}>{errMsg}</div>
                        )}
                        {showLink &&<div style={{maxWidth:"260px"}}>
                          <Link to={SpaRoutes.EMAIL_CONFIRM+"/0/0/0"}> Повторно отправить подтверждение по электронной почте </Link>
                        </div>}

                      </div>
                    </div>
                  </div>
                  <button
                      style={{opacity:errorBool?"60%":""}}
                      disabled={!enableButton}
                      form={"login_form_id"}
                      className={"logincomponent_button login_button"}
                      onClick={handlerLogin}
                  >
                    Войти
                  </button>
                  <a
                      className={"logincomponent_button reg_button"}
                      href={"#/register"}
                  >
                    Зарегистрироваться
                  </a>
                </div>
                <div className="v2_74">
                  <p>
                    <Link to={SpaRoutes.EMAIL_CONFIRM+"/0/0/2"} className="login_a">
                      Забыли пароль
                    </Link>
                  </p>
                  {/*<p>
                    <Link to={SpaRoutes.EMAIL_CONFIRM+"/0/0/0"} className="login_a">
                      Повторно отправить ссылку активации
                    </Link>
                  </p>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
}
export default observer(Login);
