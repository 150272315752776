import React, {useState} from "react";
import './AnimatedButtonMarginDown.css'

interface Interface {
    onclick?:()=>void;
    text:string;
    style?:any;
    disabled?:boolean;
}

export const AnimatedButtonMarginDown = ({onclick,text,style,disabled}:Interface) => {
    return(
        <button disabled={disabled} style={style}
             className={"btn btn-1 btn-sep icon-info"}
             onClick={onclick}>
            {text}
        </button>
    )
}