import './../checkbox01/checkbox01.css'

interface Interface {
    title?:string;
    name:string;
    checked:boolean;
    handleClick:any;
    disabled?:boolean;
}

function Boxitem({title, name, checked, handleClick, disabled}:Interface) {
    return(
        <label id={"checkbox-label"}>
            <input
                type={"checkbox"}
                name={name}
                onChange={handleClick}
                checked={checked}
                className={"real-checkbox"}
                // disabled={disabled}
            />
            <span className={`${!disabled&&"custom-checkbox"}`}></span>
            {title}
        </label>
    )
}export default Boxitem;