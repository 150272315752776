import {Link} from "react-router-dom";
import React, {useContext} from "react";
import {Context} from "../../../../../index";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";

export default function SuppliersCompanyItem(company:any){
    const {store} = useContext(Context);
    return(
        <>
            <div key={company.phone} className={"SupplierData"}>
                <div style={{width:"40%"}}>
                    <span id={"SName"}>{company.displayedName}</span>
                    <span id={"SAdress"}>{company.adress}</span>
                </div>
                <div style={{width:"35%"}}>
                    {/*<span id={"SContacts"}>Контакты:</span>*/}
                    <span id={"STelephone"}>{company.phone}</span>
                </div>
                <Link
                    to={SpaRoutes.CHAT_DIALOG_PAGE + `/:${company.id}/:1/:0`}
                >
                    {(store.roles.find((role) => role.includes('Customer')))&&
                        <span id={"SChat"}>Чат</span>
                    }
                </Link>
            </div>
            <hr className={"SHr"}/>
        </>
    )
}