import React, { useContext, useEffect, useState } from "react";

import CustomTable, {IRowElement} from "../../../../customUI/CustomTable/CustomTable";
import {ITable} from "../../../../customUI/CustomTable/CustomTable";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import Navbar from "../../../Admin/Navbar/Navbar";
import {ToogleVisible} from "../../../../customUI/Searchbar/functions";
import Searchbar from "../../../../customUI/Searchbar/Searchbar";
import {Link, useParams} from "react-router-dom";
import {ToogleFilter} from "../../../../customUI/FilterBox/functions";
import Filterbox, {IFilterbutton} from "../../../../customUI/FilterBox/Filterbox";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CartEmpty from "../../../../customUI/NoProductsPage/NoProductsPage";
import Boxitem from "../../../../customUI/multipleCheckboxes/boxitem";
import SupplierCard from "../../../Customer/Cart/SupplierCard/SupplierCard";
import CartFooter from "../../../Customer/Cart/CartFooter";
import BottomCartButton from "../../../../customUI/BottomCartButton/BottomCartButton";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import '../invoiceTables.css'
import SelectFilterbox, {IFilterBoxGroups} from "../../../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {BeatLoader} from "react-spinners";
import {createFilterGroupsForMagazines, removeGorod} from "../../../../../functions/functions";


const placeholder = 'Найти ...'

const titleList = ['','','Новые заказы','Взяты в работу','Готовы к выдаче','В доставке','Выдан']
const headerDateList = ['','','Создан заказ','В работе','Готов','В доставке','Отправлен']
//TODO rewrite this sites for new updated design further testing
function Invoice_Status_2_6() {
    const[loading,setLoading] = useState(false)
    const[buttonblock,setButtonblock] =useState(false)
    const [invoiceTable, setInvoiceTable] = useState<ITable>({
        tableId:"InvoiceTable",
        header:[],
        rows:[]
    });
    const[filterGroups,setFiltergroups] = useState<IFilterBoxGroups[]>([])
    const [shopFilter,setShopFilter] = useState<string[]>([])
    let {id} = useParams();
    let numberId = Number(id);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(()=>{
        CabinetSellerService.getMagazines()
            .then(response => {
                setFiltergroups(createFilterGroupsForMagazines(response.data.magazines))

            })
            .then(()=>
                CabinetSellerService.getInvoices(numberId,""))
            .then(response => {
                let list: IRowElement[] = [];

                for (const invoice of response.data.data) {
                    let date = invoice.statusDates[invoice.invoiceStati.lastIndexOf(numberId)] +" в "+ invoice.statusTimes[invoice.invoiceStati.lastIndexOf(numberId)];
                    let shop = invoice.magazine.city?invoice.magazine.city:"-";
                    shop += ", ";
                    shop += invoice.magazine.streetAndNumber?invoice.magazine.streetAndNumber:"-";
                    list.push(
                        {
                            content: {
                                InvoiceId: <div className={"invoiceTableDetailcontainer"} data-search-word={invoice.invoiceId} style={{display:"flex"}}>
                                    № {invoice.invoiceId}
                                    <div className={"invoiceTableDetailBox"}>
                                        <Link to={SpaRoutes.SUP_ORDER_PAGE + "/:"+invoice.invoiceId} state={{ price:invoice.priceSum, chosenOption:invoice.paymentOption}}>
                                            <div className={"invoiceTableDetailImage"}><img className={"darkhoverbg"} src={"/Pictures/invoicetablelink.svg"}/></div>
                                        </Link>
                                    </div>
                                </div>,
                                Magazine: <div className={"invoiceTableShop"}>{removeGorod(shop)}</div>,
                                StatusTime: date,
                                Customer: <div data-search-word={invoice.customerName}><Link to={SpaRoutes.CUSTOMER_INVOICES + `/${invoice.customerId}`}> {invoice.customerName} </Link></div>,
                                Sum: invoice.priceSum,
                                PayType:<div>
                                    <Link to={SpaRoutes.INVOICE_PAYMENT +`/${invoice.invoiceId}`} state={{ price:invoice.priceSum, chosenOption:invoice.paymentOption}}>
                                        <div className={"invoiceTablePaymentImage"}>
                                            <img src={(invoice.paymentOption===0)?("/Pictures/invoicetablelink.svg"):("/Pictures/invoicetablelink-green.svg")}/>
                                        </div>
                                    </Link>
                                </div>,
                                DeliveryTime: <div style={{display:"flex",flexDirection:"column",marginTop:"-8px"}}>
                                    <div>
                                        {invoice.deliveryDate}
                                    </div>
                                    <div style={{fontSize:"12px"}}>
                                        с {invoice.deliveryTimeFrom} до {invoice.deliveryTimeTo}
                                    </div>
                                </div>,
                                Adress: invoice.adress?invoice.adress:"",
                                Comment:
                                    <div data-search-word={invoice.comment} className={"TableLongInfo"}>
                                        <div className={"TableLongText"}>{invoice.comment ? invoice.comment : "-"}</div>
                                        <div className={"TableLongPopUp"}>{invoice.comment ? invoice.comment : "-"}</div>
                                    </div>,
                                StatusLink: <div onClick={()=>{changeStatus(invoice.invoiceId,numberId+1)}}><img className={"darkhoverbg"} src={"/Pictures/arrow-right.svg"}/></div>
                            },
                            filter:[{Shop:invoice.magazine.dropAdressId},{City:removeGorod(invoice.magazine.city)},{Id:invoice.invoiceId}],
                            visible:[true,true,true]
                        }
                    )
                }
                let mytable:ITable = {
                    tableId:"InvoiceTable",
                    header:["Заказ","Адрес склада",headerDateList[numberId],"Заказчик","Сумма (₽)","Оплата","Время доставки","Адрес доставки","Комментарий","Статус"],
                    //rows:[]
                    rows:list
                }
                setInvoiceTable(mytable);
            })
            .then(()=>{setLoading(true)})
    }, [id])


    const changeHandler = (text:string) => {
        setInvoiceTable((prevState)=>{
                const newState = {...prevState}
                newState.rows = ToogleVisible(text,["InvoiceId","Adress","Customer","Comment"],["","","",""],newState.rows)
                return newState;
            }
        )
    }
    const filterHandler = (filterList:string[]) =>{
        setInvoiceTable((prevState)=>{
            const newState ={...prevState}
            newState.rows = ToogleFilter(filterList,1,"Shop",newState.rows)
            return newState;
        })
    }
    const changeStatus=(invoiceId:string,status:number)=>{
        if (buttonblock===false){
            setButtonblock(true);
            CabinetSellerService.updateInvoiceStatus(invoiceId, status).then(
                response =>{
                    if (response.status===200){
                        setInvoiceTable((prevState)=>{
                            const newState ={...prevState}
                            newState.rows = newState.rows.filter(function(row) {
                                return row.filter.find((element)=>{ return Object.keys(element)[0]==="Id"})["Id"] !== invoiceId;
                            });
                            return newState;
                        })
                    }
                }).then(()=>{setButtonblock(false)});
        }
    }
    const updateCityFilter=(filter:string)=>{
        if(filter===""){
            setInvoiceTable((prevState)=>{
                const newState ={...prevState}
                newState.rows = ToogleFilter([],2,"City",newState.rows)
                return newState;
            })
        }
        else{
            setInvoiceTable((prevState)=>{
                const newState ={...prevState}
                newState.rows = ToogleFilter([filter],2,"City",newState.rows)
                return newState;
            })
        }
    }
    const updateShopFilter=(filter:string)=>{
        if (filter === "-1"){
            setShopFilter([]);
            filterHandler([]);
        }
        else{
            let newFilter = shopFilter;
            if (shopFilter.indexOf(filter) === -1){
                newFilter.push(filter);
                setShopFilter(newFilter);
                filterHandler(newFilter);
            }
            else{
                newFilter = shopFilter.filter((item)=>{return item !== filter});
                setShopFilter(newFilter);
                filterHandler(newFilter);
            }
        }
    }
    let AllBtn:IFilterbutton = {
        button:<><div className={"FilterText"}>{"All"}</div></>,
        value: "-1"}

    return(
        <>

                <div id={"InvoiceTables"}>
                    <CustomNavigateHeader title={titleList[numberId]} trashClickHandler={()=>{}} withTrash={false}/>
                    <div id={""}>
                        <div className={"SearchbarTitle"}>Поиск</div>
                        <Searchbar
                            changeHandler={changeHandler}
                            initialText={placeholder}
                        />
                        {!loading ? <BeatLoader color="black" loading={true} /> :
                            <>
                                <SelectFilterbox
                                    title="Склады"
                                    filterGroups={filterGroups}
                                    updateMainFilter={updateCityFilter}
                                    updateFilter={updateShopFilter}
                                    filterList={shopFilter}
                                />
                                <CustomTable {...invoiceTable}/>
                            </>
                        }
                    </div>
                </div>

        </>


    )
}
export default Invoice_Status_2_6;

