import styled , {css} from 'styled-components';
import './Carousel.css'
import './Carousel-media.css'

interface Interface {
    img1?:string;
    img2?:string;
    onClick: () => void
}

const SContainer = styled.div`
  align-items: center;
  display: flex;
`;

const CustomImg = styled.img<Interface>`
  width: 100%;
  ${(props)=>
          css`
            border-image-source: url(${props.img1});
            border-image-slice: 0 fill;

            @media only screen and (max-width : 450px) {
              border-image-source: url(${props.img2});
              height: 200px
            }
            @media only screen and (min-width : 451px)  and (max-width : 650px){
              height: 250px;
            }
            @media only screen and (min-width : 651px)  and (max-width : 850px){
              height: 300px;
            }
            @media only screen and (min-width : 851px)  and (max-width : 1050px){
              height: 400px;
            }
            @media only screen and (min-width : 1051px){
              height: 500px;
            }
          `
}
`;

export default function CustomSlide ({img1, img2, onClick}:Interface){
    return (
        <SContainer>
            <CustomImg img1={img1} img2={img2} onClick={onClick} />
        </SContainer>
    )
}
