import {Inavbarmenu} from "../../models/models";
import {SpaRoutes} from "../../Routes/spaRoutes";


export const navbarMenu:Inavbarmenu[]=
    [
        {
            icon: "/Pictures/user.svg",
            name: "Мой кабинет",
            link: "/",
        },
        {
            icon: "/Pictures/catalog.svg",
            name: "Каталог платформы",
            link: SpaRoutes.CATALOG_INDEX,
        },
        {
            icon: "/Pictures/list.svg",
            name: "Косметологи",
            link: SpaRoutes.ADMIN_BUYERS,
        },
        {
            icon: "/Pictures/clipboard.svg",
            name: "Поставщики",
            link: SpaRoutes.ADMIN_SUPPLIERS,
        },
        {
            icon: "/Pictures/help-circle.svg",
            name: "Коренные товары",
            link: SpaRoutes.ADMIN_ROOT_PREVIEW + "/0",
        },
        {
            icon: "/Pictures/message-square.svg",
            name: "Необработанные коренные товары",
            link: SpaRoutes.UNPROCESSED_PRODUCTS,
        },
        {
            icon: "/Pictures/book-open.svg",
            name: "Обучение",
            link: SpaRoutes.TRAINING,
        },
        {
            icon: "/Pictures/settings1.svg",
            name: "Базовые настройки",
            link: SpaRoutes.ADMIN_MAIN_SETTINGS,
        },
        {
            icon: "/Pictures/chat-20.svg",
            name: "Чат администратора",
            link: SpaRoutes.ADMIN_CHAT,
        },
        {
            icon: "/Pictures/invoicetablelink.svg",
            name: "Модерация рекламы",
            link: SpaRoutes.ADMIN_MODERATION_REKLAMA,
        },
        {
            icon: "/Pictures/invoicetablelink.svg",
            name: "Календарь бронирования",
            link: SpaRoutes.ADMIN_REKLAMA,
        },
        {
            icon: "/Pictures/points.svg",
            name: "История оплаты",
            link: SpaRoutes.ADMIN_CARDS_PAYMENT_HISTORY,
        },
    ]