import React, {useContext, useEffect, useState} from "react";
import HomeService from "../../../../services/HomeService";
import {IRegion} from "../../../../models/Regions/Region";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import "./SuppluersPage.css"
import {OverviewDealerResponse} from "../../../../models/response/OverviewDealerResponse";
import SuppliersCompanyList from "./SuppliersPageComponents/SuppliersCompanyList";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {IDropdownOption} from "../../../../models/models";
import {Context} from "../../../../index";
import {useCookies} from "react-cookie";

export default function SuppliersPage() {
    const {store} = useContext(Context);
    const [cookies, setCookie, removeCookie] = useCookies(['RegionId']);
    const [dataHomeRegions, setDataHomeRegions] = useState<IRegion[]>([])
    const [selectedRegion, setSelectedRegion] = useState<IRegion>();
    const [dataOverviewDealer, setDataOverviewDealer] = useState<OverviewDealerResponse[]>([])

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    async function GetDataRegions() {
        const dataHomeRegions = await HomeService.GetRegionsForSellers();
        return dataHomeRegions.data;
    }

    async function GetDataMyCity() {
        const dataMyCity = await HomeService.MyCity();
        return dataMyCity.data;
    }

    useEffect(() => {
        let bufObj:any
        store.DataLoadingON()
        GetDataRegions()
            .then((DataRegions) => {
                setDataHomeRegions(DataRegions.regions)
                bufObj = DataRegions.regions
            })
            .then(()=>{
                if (cookies.RegionId) {
                    for (let i = 0; i < bufObj.length; i++) {
                        if (bufObj[i].regionId == cookies.RegionId) {
                            initRegion(bufObj[i].regionId, bufObj)
                        }
                    }
                }
                else{
                    GetDataMyCity()
                        .then((DataMyCity) => {
                        setDataSuppliers(DataMyCity.regionId);
                        for (let i = 0; i < bufObj.length; i++) {
                            if (bufObj[i].regionId == DataMyCity?.regionId) {
                                initRegion(bufObj[i].regionId, bufObj)
                            }
                        }
                    })}
            })
            .catch(()=> {
                store.ErrorON()
            })
            .finally(()=> {
                store.DataLoadingOFF()
            })
    }, [])

    let ArrayRegions:IDropdownOption[] = []
    dataHomeRegions.map((item)=>{
        ArrayRegions.push({key:item.regionId,name:item.name})
    })


    function setDataSuppliers(regionId:string){
        HomeService.overviewDealer(regionId).then((DataOverviewDealer) => {
            setDataOverviewDealer(DataOverviewDealer.data)
        })
    }

    const initRegion=(id:any, data:any)=>{
        const selectedRegion = data?.find((region:any) => region.regionId === id);
        if(selectedRegion){
            setSelectedRegion(selectedRegion);
            setDataSuppliers(selectedRegion.regionId)
        }
    }

    const handleRegionChange = (val: any) => {
        const regionId = val;
        const selectedRegion = dataHomeRegions?.find((region) => region.regionId === regionId);
        if(selectedRegion){
            setSelectedRegion(selectedRegion);
            setDataSuppliers(selectedRegion.regionId)
        }

    }

    const clearOverviewDealer = async () => {
        await setDataOverviewDealer([])
    }

    return (
        <>
            {(!store.isError && !store.isDataLoading)&&
                <>
                    <CustomNavigateHeader
                        trashClickHandler ={() => {}}
                        title="Поставщики"
                        withTrash={false}
                    />
                    <div className={"SuppliersDropdownWrapper"}>
                        <CustomDropdown
                            options={ArrayRegions}
                            value={selectedRegion?.name}
                            valueSetter={setSelectedRegion}
                            onChangeHandler={handleRegionChange}
                        />
                    </div>
                    <div className={"SuppliersCitiesWrapper"}>
                        {SuppliersCompanyList(dataOverviewDealer)}
                    </div>
                    {dataOverviewDealer.length===0?<div>В этом регионе еще нет поставщиков</div>:<div></div>}
                </>
            }

        </>
    )
}

