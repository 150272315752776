import React, {useEffect, useState, useContext} from "react";
import "./Favorites.css";
import './../../../../index.css'
import {Context} from "../../../../index";
import FavoritesEmpty from "./FavoritesEmpty";
import FavoriteProductCard from "../../../customUI/favoritesProductCard/FavoriteProductCard";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";

function Favorites() {
    const [dataArray,setDataArray] = useState<any>([])
    const [numberOfProducts , setNumberOfProducts] = useState<number>(0)
    const[loading,setLoading] = useState(false)
    const {store} = useContext(Context);

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    function handlerForShow(level2:string,id:string){
        setDataArray((prevState: any[]) => {
            const newState = [...prevState];
            for(let i=0; i<newState.length; i++){ //итерация и сплайс
                if(newState[i].productId){
                    if(newState[i]?.productId === id)
                        newState.splice(i,1);
                }
            }
            return newState;
        })
        setNumberOfProducts((prevState:number) => {
            const newState = prevState-1;
            return newState;
        })
    };

    useEffect(() => {
            CabinetBuyerService.favorites().then(data => {
                    if (data.status === 200){
                        setDataArray(data.data);
                        setNumberOfProducts(data.data.length);
                    }
                }).then(()=>{setLoading(true)})
    }, [])

    const ShowList = dataArray.map((item:any) => (
        <div className="favorites-elem">
            <FavoriteProductCard
                item={item}
                isAuth={store.roles.includes('Customer')}
                style={"product_wrap-favorites"}
                handlerForShow={handlerForShow}
            />
        </div>
    ))

    const Label = <div className={"favorites-title-div"}>
        <CustomNavigateHeader
            title={`Избранное`}
            trashClickHandler={()=>null}
            withTrash={false}
        />
    </div>;

    return(
        <>
            {!loading?<Loader load={true} />:
                (numberOfProducts===0)?(
                    <>
                        {Label}
                        <FavoritesEmpty/>
                    </>
                ):(
                    <>
                        {Label}
                        <div className={"favorites-container"}>
                            {ShowList}
                        </div>
                    </>
                )}
        </>
    )
}export default Favorites