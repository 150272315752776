import './Searchbar.css'
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {ref} from "yup";

interface ISearchbarProps {
    initialText: string;
    changeHandler: (str: string) => void;
    value?: string;
    clearInputBool?: boolean
}

function Searchbar(props: ISearchbarProps) {
    const [text, setText] = useState(props.value ? props.value : "")
    const refInput = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (props.clearInputBool)
            setText('')
    }, [props.clearInputBool])

    const onChangeHandler = async (str: string) => {
        setText(str)
        props.changeHandler(str)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            refInput.current?.blur();
        }
    };

    return (
        <div className={"Searchbar"}>
            <div className={"SearchbarInner"}>
                <img className={"SearchbarLogo2"} alt={""} src={'/Pictures/search.svg'}/>
                <input className={"SearchbarInput"}
                       enterKeyHint={"search"}
                       value={text}
                       placeholder={props.initialText}
                       id={"adminSearch"}
                       onChange={(event) => {onChangeHandler(event.target.value)}}
                       type={"search"}
                       ref={refInput}
                       onKeyDown={(event) => handleKeyDown(event)}
                />
                {text&&
                    <img className={"SearchbarLogo1"} alt={""} src={'/Pictures/Close.svg'} onClick={(event) => onChangeHandler("")} />
                }
            </div>
        </div>
    )
}

export default Searchbar;

