import UserMenuAuth from "./UserMenuAuth/UserMenuAuth";
import UserMenu from "./UserMenu/UserMenu";
import Menu from "./Menu/Menu";
import React, {useContext} from "react";
import {Context} from "../../../../index";
import './MenuSticky.css'

export const MenuSticky = () => {
    const {store} = useContext(Context)
  return(
      <div className={'sticky-div'}>
          {
              !store.isAuthLoading?
                  store.isAuth ?
                      <UserMenuAuth/> :
                      <UserMenu/>
                  :
                  <UserMenu/>
          }
          <Menu/>
      </div>
  )
}