import React, {useContext, useEffect, useRef, useState} from 'react';
import {IReklama, IReservation} from "../../../../models/models";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import {all} from "axios";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import AdminService from "../../../../services/AdminService";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import PreviewReklama from "../../../customUI/PreviewReklama/PreviewReklama";

interface IReservationPopup {
    reservations: IReservation[],
    day: number,
    month: number,
    year: number,
    onClose: () => void,
    userReklama: IReklama[],
    price: { userBonuses: number, first: number, other: number }
    onUpdate: () => void,
    type: number,
    selectRegion: string
}

const AdminReservationPopup = ({
                                   reservations,
                                   day,
                                   month,
                                   year,
                                   onClose,
                                   userReklama,
                                   onUpdate,
                                   price,
                                   type,
                                   selectRegion
                               }: IReservationPopup) => {

    const navigate = useNavigate()

    const {store} = useContext(Context)

    const [activeReklama, setActiveReklama] = useState("")

    const [activeSlide, setActiveSlide] = useState(1)

    const [allRes, setAllRes] = useState<IReservation[]>([])

    const [previewReklama, setPreviewReklama] = useState<JSX.Element | null>(null)

    useEffect(() => {
        let mas: IReservation[] = []
        let countSlides = 0
        if (type == 0 && selectRegion !== "global") countSlides = 4
        else if (type == 1 || selectRegion === "global") countSlides = 1

        reservations.map(res => {

            let rek = userReklama.find(r => r.reklamaId == res?.reklamaId)


            mas.push({...res, reklama: rek, isUserReklama: true, isCanUpdate: true})
        })

        setAllRes(mas.sort((r, r1) => {
            if (r.position < r1.position) {
                return -1
            }
            if (r.position > r1.position) {
                return 1
            } else {
                return 0
            }
        }))
    }, [reservations])

    useEffect(() => {
        let res = allRes.find(r => r.position === activeSlide)
        if (res?.reklamaId) {
            setActiveReklama(res?.reklamaId)

        } else {
            setActiveReklama("")
        }
    }, [activeSlide, allRes])

    const blockReklama = () => {
        if (window.confirm("Вы точно хотите заблокировать эту рекламу?")) {
            store.DataLoadingON()
            AdminService.blockEntertainment(activeReklama)
                .then(() => alert("Реклама была заблокирована"))
                .catch(() => alert("Что-то пошло не так"))
                .finally(() => {
                    store.DataLoadingOFF()
                    onClose()
                    onUpdate()
                })
        }
    }

    const unblockReklama = () => {
        if (window.confirm("Вы точно хотите заблокировать эту рекламу?")) {
            store.DataLoadingON()
            AdminService.approveEntertainment(activeReklama)
                .then(() => alert("Реклама была разблокирована"))
                .catch(() => alert("Что-то пошло не так"))
                .finally(() => {
                    store.DataLoadingOFF()
                    onClose()
                    onUpdate()
                })
        }
    }

    const ref = useRef(null)
    const refPreview = useRef(null)
    useOnClickOutside(ref, () => onClose(), [refPreview])

    return (
        <>
            <div ref={refPreview}>
                {previewReklama && previewReklama}
            </div>
            <div className={"adminReservationPopup"}>
                <div className={"adminReservationPopup-content"} ref={ref}>
                    <div className={"adminReservationPopup-title"}>
                        <img onClick={onClose} src={"/Pictures/strelochka.svg"}/>
                        <p>Бронирование на {day}.{month}.{year}</p>
                    </div>
                    <div className={"adminReservationPopup-reservation"}>

                        {allRes.map((r, index) => {
                            return (
                                <div className={"adminReservationPopup-reservation-slide"}
                                     data-sel={activeSlide === r.position}
                                     onClick={() => {
                                         setActiveSlide(r.position)
                                     }}
                                     key={index}
                                >
                                    <div className={"adminReservationPopup-reservation-preview"}>
                                        <div className={"adminReservationPopup-reservation-slide-title"}>
                                            Слайд {r.position}
                                            {r.reklama ? "" :
                                                r.isUserReklama === false ? <div>Забронировано</div> : ""
                                            }
                                        </div>
                                        <div className={"adminReservationPopup-reservation-slide-preview"}>
                                            {r.reklama &&
                                            r.reklama?.status === 4 ? <div style={{color: "red"}}>Зaблокировано</div> :
                                                r.reklama?.imageBigUrl ?
                                                    <img src={store.imgServer + r.reklama.imageBigUrl}/> :
                                                    <div>Фото</div>
                                            }
                                        </div>
                                    </div>
                                    <div className={"adminReservationPopup-reservation-reklama"}>
                                        <div className={"adminReservationPopup-reservation-reklama-title"}>
                                            {r.reklama?.name}
                                        </div>
                                        <div className={"adminReservationPopup-reservation-reklama-buttons"}>
                                            <button onClick={r.reklama?.status === 4 ? unblockReklama : blockReklama}>
                                                {r.reklama?.status === 4 ? "Разблокировать" : "Заблокировать"}
                                            </button>
                                            <button
                                                onClick={() => navigate(SpaRoutes.ADMIN_SUPPLIER + `/${r.reklama?.ownerId}`)}>
                                                Поставщик
                                            </button>
                                            <img src={"/Pictures/invoicetablelink.svg"}
                                                 onClick={() => {
                                                     if (r.reklama?.imageBigUrl) {
                                                         setPreviewReklama
                                                         (<PreviewReklama key={0}
                                                                          reklamaImg={store.imgServer + r.reklama.imageBigUrl}
                                                                          typePreview={r.reklama.type}
                                                                          typeDevice={"desktop"}
                                                                          description={r.reklama.description}
                                                                          linkName={r.reklama.linkName}
                                                                          link={r.reklama.link}
                                                                          onClose={() => setPreviewReklama(null)}
                                                         />)
                                                     } else alert("Нет картинки")
                                                 }}
                                            />
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminReservationPopup;