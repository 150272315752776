import React from 'react';
import {IBonusTransaction} from "../../../../models/models";
import {dateTransformForChat} from "../../../../functions/functions";

interface IBonusTransactionElem{
    transactions: IBonusTransaction[] | undefined;
}
const BonusTransaction = ({transactions}:IBonusTransactionElem) => {
    if (transactions?.length != 0 && transactions) {
        return <>{transactions.map((item) => {
            return (
                <div className={"bonuses-historyOperations-operation"}>
                    <div className={"bonuses-historyOperations-operation-date"}>{item.date}</div>
                    <div className={"bonuses-historyOperations-operation-name-bonus"}>
                        <div className={"bonuses-historyOperations-operation-name"}>{item.info}</div>
                        <div
                            className={"bonuses-historyOperations-operation-bonus"}>{item.value > 0 ? "+" : ""}{item.value.toFixed(2)} бон.
                        </div>
                    </div>
                </div>
            )
        })
        }</>
    } else {
        return <>
            <div className={"bonuses-historyOperations-operation"}>
                <div className={"bonuses-historyOperations-operation-noOperation"}>
                    Нет операций
                </div>
            </div>
        </>
    }
}

export default BonusTransaction;