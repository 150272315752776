import React, {useContext, useRef, useState} from 'react';
import {Context} from '../../../../index';
import './message.css';
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";
import {dateTransformForChat} from "../../../../functions/functions";
import MessageEditPopup from "../EditPopUp/MessageEditPopup";
import {ChatWindowType} from "../../../../models/Chat/Chat";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface Props {
    chatRef: any;
    id: string;
    message: string;
    fromUserId: string;
    name: string;
    Date?: string;
    updateChatMessage: (id:string,newText:string)=>void;
    deleteChatMessage: (id:string)=>void;
}

const Message: React.FC<Props> = ({ chatRef , id , message, fromUserId, name, Date,updateChatMessage,deleteChatMessage}) => {
    const {store} = useContext(Context);
    let [popupVisible, setPopupVisible] = useState<boolean>(false);

    const messageClassName = fromUserId === store.user.id
        ? 'message userMessage'
        : 'message supplierMessage';


    function checkOrderNumber(str: string) {
        let str1: string = str.substring(store.HashForChat.length);
        const regex = /Вопрос по заказу №(\d+)/;
        const match = regex.exec(str1);
        if (match && match[1]) {
            return parseInt(match[1]);
        }
        return 0;
    }

    function isStringURL(str: string) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        if (urlRegex.test(str)) {
            let parts = str.split(urlRegex)
            return (
                <span className='message_span'>
                    {parts.map((part, index) => (
                        urlRegex.test(part) ?
                            <a className='message_link' key={index} href={part} target="_blank" >{part}</a> : part))}
                </span>
            )
        } else {
            return (
                <span className='message_span'>
                    {str}
                </span>
            )
        }
    }

    //const[messageElem, setMessageElem] = useState<JSX.Element>(isStringURL(message))

    const messageElem: JSX.Element = isStringURL(message)

    const showPopup = ()=>{
        if (fromUserId === store.user.id)
            setPopupVisible(true);
    }
    const hidePopup = ()=>{
        setPopupVisible(false);
    }

    const updateMessage = (newText:string)=>{
        updateChatMessage(id,newText);
        hidePopup();
    }
    const deleteMessage = () =>{
        store.showDialog("Вы действительно хотите удалить сообщение?", true).then(()=> {
            if (store.dialog.dialogResult === true) {
                deleteChatMessage(id);
                hidePopup();
            }
        });
    }

    return ((message && message.toLowerCase().includes(store.HashForChat)) ?
            (!store.roles.includes('Customer') ?
                <Link to={`${SpaRoutes.SUP_ORDER_PAGE}/:${checkOrderNumber(message)}`}>
                    <div className={"messages_notification"}>
                        {message.substring(store.HashForChat.length)}
                    </div>
                </Link>
                :
                <></>)
            :
        <>
            {popupVisible?
                <MessageEditPopup id={id} text={message} onUpdate={updateMessage} onDelete={deleteMessage}
                                  onCancel={hidePopup}/>:
            <div className={messageClassName}>
                {Date && <div className={`${messageClassName}_time`}>
                    {`${dateTransformForChat(Date)}`}
                </div>}

                    <div className='name_message' onClick={showPopup}>

                        <span onClick={showPopup} className="name">{name}</span>
                        {messageElem}
                    </div>
            </div>
            }
        </>

    );
};

export default Message;
