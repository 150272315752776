import React, { useContext, useEffect, useState } from "react"

import CustomTable, {
    IRowElement,
} from "../../../customUI/CustomTable/CustomTable"
import { ITable } from "../../../customUI/CustomTable/CustomTable"
import CabinetSellerService from "../../../../services/CabinetSellerService"
import Navbar from "../../Admin/Navbar/Navbar"
import { ToogleVisible } from "../../../customUI/Searchbar/functions"
import Searchbar from "../../../customUI/Searchbar/Searchbar"
import { Link, useParams } from "react-router-dom"
import { ToogleFilter } from "../../../customUI/FilterBox/functions"
import Filterbox, { IFilterbutton } from "../../../customUI/FilterBox/Filterbox"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader"
import CartEmpty from "../../../customUI/NoProductsPage/NoProductsPage"
import Boxitem from "../../../customUI/multipleCheckboxes/boxitem"
import SupplierCard from "../../Customer/Cart/SupplierCard/SupplierCard"
import CartFooter from "../../Customer/Cart/CartFooter"
import BottomCartButton from "../../../customUI/BottomCartButton/BottomCartButton"
import CabinetBuyerService from "../../../../services/CabinetBuyerService"
import {
    createFilterGroupsForMagazines,
    DifferenceDates,
    removeGorod,
} from "../../../../functions/functions"
import "./productTable.css"
import SelectFilterbox, {
    IFilterBoxGroups,
} from "../../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox"
import { SpaRoutes } from "../../../../Routes/spaRoutes"
import { IDropdownOption } from "../../../../models/models"
import { ICard } from "../../../../models/product/productCard"
import { Popup_Apply } from "../../../Supplier_UI_kit/Popup_Apply/Popup_Apply"
import { BeatLoader } from "react-spinners"
import { Loader } from "../../../customUI/Loader/Loader"
import { Context } from "../../../.."

const placeholder = "Найти ..."

function SupplierProducts() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [loading, setLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [productTableBox, setProductTableBox] = useState<
        { name: string; table: ITable }[]
    >([])
    const [popupElement, setPopupElement] = useState<any>()
    const [counterActive, setCounterActive] = useState<number>()
    const [counterInactive, setCounterInactive] = useState<number>()
    const [hasToPay, sethasToPay] = useState<number>()
    const [filterGroups, setFiltergroups] = useState<IFilterBoxGroups[]>([])
    const [shopFilter, setShopFilter] = useState<string[]>([])

    const { store } = useContext(Context)
    const buttonDelete = (id: string) => {
            store.showDialog("Вы точно хотите удалить этот товар?", true).then(() => {
                if(store.dialog.dialogResult && id){
                    store.DataLoadingON()
                    CabinetSellerService.myProductDelete(id)
                        .then((r) => {
                            if(r.status == 200) {
                                store.showDialog("Товар был удален")
                                getData()
                            }
                        })
                        .catch(() => {
                            store.showDialog("Что-то пошло не так")
                        })
                        .finally(() => store.DataLoadingOFF())
                }
            })
    }

    const getData = () => {
        CabinetSellerService.getMagazines()
            .then((response) => {
                setFiltergroups(
                    createFilterGroupsForMagazines(response.data.magazines)
                )
            })
            .then(() => CabinetSellerService.getProducts())
            .then((response) => {
                let mytablebox: any[] = []
                let activeCount = 0
                let inactiveCount = 0
                sethasToPay(response.data.hasToPay)
                for (const cat of response.data.categories) {
                    let list: IRowElement[] = []
                    for (const product of response.data.products.sort((a: any, b: any) => a.title.localeCompare(b.title))) {
                        if (cat.topCatId === product.topCategoryId){
                            let isCheapText = product.discountPrice;
                            let card: ICard = {
                                productId: product.id,
                                orderId: "",
                                image: product.imageUrl,
                                name_of_product: product.title,
                                price1: product.price,
                                price2: product.discountPrice,
                                suppliers: [],
                                supply_company: response.data.sellerInfo.name,
                                image_supply_company:
                                response.data.sellerInfo.logo,
                                amount: product.amount,
                                currentSupplierId: response.data.sellerInfo.id,
                                orderAmount: 0,
                                discount: product.discount,
                                isConnected1S: product.isConnected1S,
                            }
                            if (product.isActivated) {
                                activeCount += 1
                            } else {
                                if (product.hasToPay) {
                                    inactiveCount += 1
                                }
                            }

                            list.push({
                                content: {
                                    Title: (
                                        <div
                                            data-search-word={product.title}
                                            className={"TableLongInfo"}
                                        >
                                            <div className={"TableLongText"}>
                                                {product.title}
                                            </div>
                                            <div
                                                className={
                                                    "TableLongPopUpStart"
                                                }
                                            >
                                                {product.title}
                                            </div>
                                        </div>
                                    ),
                                    ID: product.id,
                                    Price: (
                                        <div className={"supplierProducts-price"}>
                                            {product.price}
                                            {product.visibleMaskPrice
                                                && <img className={"productDetails-hint-image"}
                                                        src={"/Pictures/eye.svg"}
                                                />}
                                        </div>
                                    ),
                                    Amount: (
                                        <div className={"supplierProducts-amount"}>
                                            {product.amount}
                                            {product.visibleMaskAmount
                                                && <img className={"productDetails-hint-image"}
                                                        src={"/Pictures/eye.svg"}
                                                />}
                                        </div>
                                    ),
                                    Active: product.isActivated ? (
                                        <div className={"hoverChild"}>
                                            <img src={"/Pictures/ok.svg"} />
                                            <div
                                                onClick={() => {
                                                    openPopUp(card, "close")
                                                }}
                                                className={
                                                    "child4hover backgroundRed"
                                                }
                                            >
                                                Отключить
                                            </div>
                                        </div>
                                    ) : product.hasToPay ? (
                                        <div className={"hoverChild"}>
                                            <img
                                                className={""}
                                                src={"/Pictures/notokColor.svg"}
                                            />
                                            <div
                                                onClick={() => {
                                                    openPopUp(card, "open")
                                                }}
                                                className={
                                                    "child4hover backgroundGreen"
                                                }
                                            >
                                                Активировать
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={"hoverChild"}>
                                            <img
                                                className={""}
                                                src={"/Pictures/notok.svg"}
                                            />
                                            <div
                                                onClick={() => {
                                                    openPopUp(card, "open")
                                                }}
                                                className={
                                                    "child4hover backgroundGreen"
                                                }
                                            >
                                                Активировать
                                            </div>
                                        </div>
                                    ),
                                    Discount: product.discount ? (
                                        <div className={"hoverChild"}>
                                            <div
                                                className={"bdproTab_disField"}
                                            >
                                                {isCheapText}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    openPopUp(
                                                        card,
                                                        "noDiscount"
                                                    )
                                                }}
                                                className={
                                                    "child4hover backgroundRed"
                                                }
                                            >
                                                Отключить
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={"hoverChild"}>
                                            <div
                                                className={"bdproTab_disField"}
                                            >
                                                <img
                                                    className={""}
                                                    src={"/Pictures/notok.svg"}
                                                />
                                            </div>
                                            <div
                                                onClick={() => {
                                                    openPopUp(card, "discount")
                                                }}
                                                className={
                                                    "child4hover backgroundGreen"
                                                }
                                            >
                                                Активировать
                                            </div>
                                        </div>
                                    ),
                                    ButtonField: (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    lineHeight: "5px",
                                                    gap: "6px",
                                                }}
                                            >
                                                <Link
                                                    to={
                                                        SpaRoutes.PRODUCT_DETAILS +
                                                        `/0/${product.id}/0`
                                                    }
                                                    className={"bdproTab_edit"}
                                                >
                                                    Редактировать
                                                </Link>

                                                <button
                                                    className={
                                                        "supplierProducts-supplier-buttonDelete"
                                                    }
                                                    data-deleted={false}
                                                    onClick={() =>
                                                        buttonDelete(product.id)
                                                    }
                                                    /*style={buttonDeleteStyle}*/
                                                ></button>
                                            </div>
                                        </>
                                    ),
                                },
                                filter: [
                                    { Shop: product.magazineId },
                                    { City: removeGorod(product.city) },
                                    { Category: product.topCategoryId },
                                    { Id: product.id },
                                ],
                                visible: [true, true, true, true],
                            })
                        }
                    }
                    let mytable: ITable = {
                        tableId: "ProductTable" + cat.topCatId,
                        header: [
                            "Продукт",
                            "ID",
                            "Цена",
                            "Количество",
                            "Активность",
                            "Скидка",
                            "Действия",
                        ],
                        rows: list,
                    }
                    mytablebox.push({
                        name: cat.topCatName,
                        table: mytable,
                    })
                }
                setCounterActive(activeCount)
                setCounterInactive(inactiveCount)
                setProductTableBox(mytablebox)
            })
            .then(() => {
                setLoading(true)
            })
    }

    useEffect(() => {
        getData()
    }, [])

    const changeHandler = (text: string) => {
        setProductTableBox((prevState) => {
            let newState = prevState.map((ele) => {
                ele.table.rows = ToogleVisible(
                    text,
                    ["Title", "ID"],
                    ["", ""],
                    ele.table.rows
                )
                return ele
            })
            return newState
        })
    }
    const filterHandler = (filterList: string[]) => {
        setProductTableBox((prevState) => {
            let newState = prevState.map((ele) => {
                ele.table.rows = ToogleFilter(
                    filterList,
                    1,
                    "Shop",
                    ele.table.rows
                )
                return ele
            })
            return newState
        })
    }
    const updateCityFilter = (filter: string) => {
        if (filter === "") {
            setProductTableBox((prevState) => {
                let newState = prevState.map((ele) => {
                    ele.table.rows = ToogleFilter([], 2, "City", ele.table.rows)
                    return ele
                })
                return newState
            })
        } else {
            setProductTableBox((prevState) => {
                let newState = prevState.map((ele) => {
                    ele.table.rows = ToogleFilter(
                        [filter],
                        2,
                        "City",
                        ele.table.rows
                    )
                    return ele
                })
                return newState
            })
        }
    }
    const updateShopFilter = (filter: string) => {
        if (filter === "-1") {
            setShopFilter([])
            filterHandler([])
        } else {
            let newFilter = shopFilter
            if (shopFilter.indexOf(filter) === -1) {
                newFilter.push(filter)
                setShopFilter(newFilter)
                filterHandler(newFilter)
            } else {
                newFilter = shopFilter.filter((item) => {
                    return item !== filter
                })
                setShopFilter(newFilter)
                filterHandler(newFilter)
            }
        }
    }
    const SplitTable = () => {
        return productTableBox.map((tbox) => {
            if (
                tbox.table.rows
                    .map((ele) => ele.visible.every((item) => item === true))
                    .every((item) => item === false)
            ) {
                return <></>
            }
            return (
                <>
                    <div className={"bdproTab_subHeader"}>{tbox.name}</div>
                    <CustomTable {...tbox.table} />
                </>
            )
        })
    }

    const changeActive = (id: string, card: ICard, bool: boolean) => {
        setProductTableBox((prevState) => {
            let newState = prevState.map((ele) => {
                ele.table.rows = ele.table.rows.map(function (row) {
                    if (
                        row.filter.find((element) => {
                            return Object.keys(element)[0] === "Id"
                        })["Id"] === id
                    ) {
                        row.content["Active"] = bool ? (
                            <div className={"hoverChild"}>
                                <img src={"/Pictures/ok.svg"} />
                                <div
                                    onClick={() => {
                                        openPopUp(card, "close")
                                    }}
                                    className={"child4hover backgroundRed"}
                                >
                                    Отключить
                                </div>
                            </div>
                        ) : (
                            <div className={"hoverChild"}>
                                <img
                                    className={""}
                                    src={"/Pictures/notokColor.svg"}
                                />
                                <div
                                    onClick={() => {
                                        openPopUp(card, "open")
                                    }}
                                    className={"child4hover backgroundGreen"}
                                >
                                    Активировать
                                </div>
                            </div>
                        )
                    }
                    return row
                })
                return ele
            })
            return newState
        })
    }
    const changeActiveLoader = (id: string) => {
        setProductTableBox((prevState) => {
            let newState = prevState.map((ele) => {
                ele.table.rows = ele.table.rows.map(function (row) {
                    if (
                        row.filter.find((element) => {
                            return Object.keys(element)[0] === "Id"
                        })["Id"] === id
                    ) {
                        row.content["Active"] = <BeatLoader />
                    }
                    return row
                })
                return ele
            })
            return newState
        })
    }
    const changeDiscount = (id: string, card: ICard, bool: boolean) => {
        setProductTableBox((prevState) => {
            let isCheapText =
                (100.0 - (card.price2 * 100.0) / card.price1).toFixed() + " %"
            let newState = prevState.map((ele) => {
                ele.table.rows = ele.table.rows.map(function (row) {
                    if (
                        row.filter.find((element) => {
                            return Object.keys(element)[0] === "Id"
                        })["Id"] === id
                    ) {
                        row.content["Discount"] = bool ? (
                            <div className={"hoverChild"}>
                                <div className={"bdproTab_disField"}>
                                    {isCheapText}
                                </div>
                                <div
                                    onClick={() => {
                                        openPopUp(card, "noDiscount")
                                    }}
                                    className={"child4hover backgroundRed"}
                                >
                                    Отключить
                                </div>
                            </div>
                        ) : (
                            <div className={"hoverChild"}>
                                <div className={"bdproTab_disField"}>
                                    <img
                                        className={""}
                                        src={"/Pictures/notok.svg"}
                                    />
                                </div>
                                <div
                                    onClick={() => {
                                        openPopUp(card, "discount")
                                    }}
                                    className={"child4hover backgroundGreen"}
                                >
                                    Активировать
                                </div>
                            </div>
                        )
                    }
                    return row
                })
                return ele
            })
            return newState
        })
    }

    const openPopUp = (proCard: ICard, popCase: string) => {
        let thisHandler = () => {
            setShowPopup(false)
        }
        let buttonTitle = ""
        let text = ""
        if (popCase === "open") {
            buttonTitle = "Активировать товар?"
            thisHandler = async () => {
                setShowPopup(false)
                changeActiveLoader(proCard.productId)
                CabinetSellerService.activateProduct(
                    proCard.productId,
                    true
                ).then((response) => {
                    if (response.status === 200) {
                        changeActive(proCard.productId, proCard, true)
                        //console.log(counterActive)
                        setCounterActive((prevState) => {
                            return prevState ? prevState + 1 : 0
                        })
                    }
                })
            }
        }
        if (popCase === "close") {
            buttonTitle = "Отключить товар?"
            text =
                "Карточка деактивирована, но вам ещё нужно оплатить за размещение следующих двух дней"
            thisHandler = () => {
                setShowPopup(false)
                changeActiveLoader(proCard.productId)
                CabinetSellerService.activateProduct(
                    proCard.productId,
                    false
                ).then((response) => {
                    if (response.status === 200) {
                        changeActive(proCard.productId, proCard, false)
                        setCounterInactive((prevState) => {
                            return prevState ? prevState + 1 : 1
                        })
                        setCounterActive((prevState) => {
                            return prevState ? prevState - 1 : 0
                        })
                    }
                })
            }
        }
        if (popCase === "discount") {
            buttonTitle = "Активировать скидку?"
            thisHandler = () => {
                setShowPopup(false)
                CabinetSellerService.discountProduct(
                    proCard.productId,
                    true
                ).then((response) => {
                    if (response.status === 200) {
                        changeDiscount(proCard.productId, proCard, true)
                    }
                })
            }
        }
        if (popCase === "noDiscount") {
            buttonTitle = "Отключить скидку?"
            thisHandler = () => {
                setShowPopup(false)
                CabinetSellerService.discountProduct(
                    proCard.productId,
                    false
                ).then((response) => {
                    if (response.status === 200) {
                        changeDiscount(proCard.productId, proCard, false)
                    }
                })
            }
        }
        setPopupElement(
            Popup_Apply({
                title: buttonTitle,
                text: text,
                textOnButton: "Подтвердить",
                handler: thisHandler,
                item: proCard,
                onClose: () => {
                    setShowPopup(false)
                },
            })
        )
        setShowPopup(true)
    }
    return (
        <>
            {!loading ? (
                <Loader load={true} />
            ) : (
                <div id={"InvoiceTables"}>
                    <CustomNavigateHeader
                        title={"Данные моих товаров"}
                        trashClickHandler={() => {}}
                        withTrash={false}
                    />
                    <div id={""}>
                        <div
                            style={{
                                marginLeft: "10px",
                                display: "flex",
                                fontSize: "16px",
                                fontWeight: "500",
                                marginTop: "10px",
                            }}
                        >
                            <div>Оплата за день: </div>
                            <div
                                style={{
                                    marginLeft: "10px",
                                    fontWeight: "700",
                                }}
                            >
                                {" "}
                                {counterActive}шт +{" "}
                                {counterInactive &&
                                    counterInactive.toString() + "шт"}{" "}
                                ~{" "}
                                {(hasToPay ? hasToPay.toFixed(2) : 0) +
                                    " баллов"}
                            </div>
                        </div>
                        <div className={"SearchbarTitle"}>Поиск</div>
                        <Searchbar
                            changeHandler={changeHandler}
                            initialText={placeholder}
                        />
                        <SelectFilterbox
                            title="Склады"
                            filterGroups={filterGroups}
                            updateMainFilter={updateCityFilter}
                            updateFilter={updateShopFilter}
                            filterList={shopFilter}
                        />
                        <>{SplitTable()}</>
                        {showPopup && popupElement}
                    </div>
                </div>
            )}
        </>
    )
}
export default SupplierProducts
