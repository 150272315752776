import React, {useContext, useEffect, useState} from 'react';
import './Burger.css';
import {Link} from "react-router-dom";
import {burger_menu} from "../../../../../data/UserBurgerMenu/burger_menu";
import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";
import AuthenticateService from "../../../../../services/AuthenticateService";
import {IUserInfo} from "../../../../../models/response/UserInfoResponse";
import {Context} from "../../../../../index";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {BurgerItem} from "./BurgerItem";
import {randomizeDefaultProfileImgPath} from "../../../../../functions/functions";

const Burger = () => {
    const {store} = useContext(Context)

    const [isOpen, setIsOpen] = useState(false);
    const [userInfo, setUserInfo] = useState<IUserInfo>();

    const toggleMenu = (name?: string) => {
        setIsOpen(!isOpen);
        if(name === "Каталог"){
            store.FiltersSave("-1","","-1","",[])
        }
    };

        //формирование списка бургера
    var list = [];
    list.push(
        <div key={'ФИО косметолога'}>
            <div onClick={() => toggleMenu()}
                id={"burger-close"}>
                <img id={"burger-close-img"} src="/Pictures/Close.svg"/>
            </div>
            {store.isAuth &&
                <li>
                    <div className="burgerProfile">
                        {
                            store.user?.image?
                                <CustomIMG id="burgerProfilePictures" src={store.user?.image} />:
                                <img className={'burgerProfilePictures'} src={randomizeDefaultProfileImgPath(store.user.displayedName)} alt="User Logo"/>
                        }
                        <div className="burgerFullName">
                            <span>{store.user?.lastName}</span>
                            <div className="burgerNameAndOtchestvo">
                                <span>{store.user?.firstname}</span>
                                <span id="BurgerSpanOtchestvo">{store.user?.vatherName}</span>
                            </div>
                        </div>
                    </div>
                </li>
            }
        </div>
    )

    for (let i=0;i<burger_menu.length;i++) {
        if(burger_menu[i].link === SpaRoutes.LOGOUT)
            if(!store.isAuth)
                continue

        if(burger_menu[i].link === SpaRoutes.LOGIN)
            if(store.isAuth)
                continue

        list.push(
            <BurgerItem
                key={burger_menu[i].name}
                link={burger_menu[i].link}
                title={burger_menu[i].name}
                handler={() => toggleMenu(burger_menu[i].name)}
            />)
    }

    return (
        <div className='burger-menu'>
            {/*Значок бургера*/}
            <div className='burgerIcon' onClick={() => toggleMenu()}>
                <img src="/Pictures/Бургер.svg" />
            </div>
            {/*Содержимое бургера*/}
            <div className={isOpen ? "burgerBackendGrey" : "burgerBackendTransparent"} onClick={() => toggleMenu()}></div>
            <div className={isOpen ? "burgerWrapperOpen" : "burgerWrapperClose"}>
                <div className={"burgerPadding"}>
                    <div id={"user-burger-scroll"}>
                        {list}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Burger;