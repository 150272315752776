import React, {useState} from "react";
import "./Popup_SupplierChangeImage.css";

interface ModalProps {
    title: string;
    image?: string;
    textOnButton: string;
    onChangeImage?:  () => void;
    openDirectory?: () => void;
    onClose: () => void;
    children?: any
}

export function Popup_SupplierChangeImage(props: ModalProps) {

    const OnClickHandler = () => {
        if(props.onChangeImage)
            props.onChangeImage();
        props.onClose();
    }
    const [isHover, setIsHover] = useState(false)
    return (
        <>
            <div className={"supplier-popup-close-wrapper"} onClick={props.onClose}/>
            <div className={"supplier-popup-wrapper"}>
                <div className={"supplier-popup-container"}>
                    <span className={"supplier-popup-title"}>{props.title}</span>
                        <img className={"supplier-popup-image"}
                             onClick={props.openDirectory}
                             onMouseEnter={() => setIsHover(true)}
                             onMouseLeave={() => setIsHover(false)}
                             src={isHover ? "/Pictures/SupplierHoverImage.svg" : props.image !== undefined ? props.image : "/Pictures/supplierNoImage.svg"}

                        />
                    <span className={"supplier-popup-button"} onClick={OnClickHandler}>
                        {props.textOnButton}</span>
                    {props.children}
                </div>
            </div>
        </>
    )
}