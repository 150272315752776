import React, {useContext, useState} from 'react';
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {IDropdownOption, IFilter, ISubCategory} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";
import ItemMultiFilter from "./ItemMultiFilter";

interface IItemSubCategory {
    ArrayTopCategory: IDropdownOption[],
    itemTopCategoryId: string,
    itemTopCategoryName: string,
    itemSubCategoryId: string,
    itemFilter: IFilter[],
    itemSubCategoryName: string,
    itemSubCategorySorter: number,
    onDataUpdate: () => void,
    canDelete: boolean
}

const ItemSubCategory = ({
                             ArrayTopCategory,
                             itemTopCategoryId,
                             itemTopCategoryName,
                             itemSubCategoryId,
                             itemSubCategoryName,
                             itemSubCategorySorter,
                             itemFilter,
                             onDataUpdate,
                             canDelete
                         }: IItemSubCategory) => {
    const {store} = useContext(Context)
    const [subCategoryName, setSubCategoryName] = useState(itemSubCategoryName)
    const [subCategoryOldName, setSubCategoryOldName] = useState(itemSubCategoryName)
    const [subCategoryId, setSubCategoryId] = useState(itemSubCategoryId)
    const [subCategorySorter, setSubCategorySorter] = useState(itemSubCategorySorter.toString())
    const [topCategoryId, setTopCategoryId] = useState(itemTopCategoryId)
    const [dropDownTopCategoryName, setDropDownTopCategoryName] = useState(itemTopCategoryName)

    const UpdateSubCategory = () => {
        if(subCategoryName){
            let mydata = {
                Info1: subCategoryId,
                Info2: subCategoryName,
                Info3: topCategoryId,
                Number1: Number(subCategorySorter)
            }

            AdminService.postSubCategoryUpdate(mydata)
                .then(r => {
                    if (r.data.status == "Success") {
                        alert(`SubCategory (${subCategoryName}) успешно изменена`)
                        onDataUpdate()

                    }
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {

                })
        }
        else
            alert("Пустое название")
    }

    const DeleteSubCategory = () => {
        if (window.confirm("Вы действительно хотите удалить SubCategory?")) {
            let mydata = {
                Info1: itemSubCategoryId,
            }

            AdminService.postSubCategoryDelete(mydata)
                .then(r => {
                    if (r.data.status == "Success") {
                        onDataUpdate()
                        alert(`SubCategory (${subCategoryName}) успешно удалена`)
                    }
                })
                .catch((r) => {
                    if(r.response.data.status === "Error_1") alert("Такой подкатегории нет")
                    else if(r.response.data.status === "Error_2") alert("Нельзя удалить подкатегорию с товарами")
                })
                .finally(() => {

                })
        }
    }
    return (
        <>
            <div className={"admin-categories-item-subCategory-id"}>id for {subCategoryOldName}: {subCategoryId}
            </div>
            <div className={"admin-categories-item-subCategory"}>
                <div className={"admin-categories-item-firstContainer"}>
                    <CustomDropdown icon={true} dropdownSize={"large"}
                                    options={ArrayTopCategory}
                                    value={dropDownTopCategoryName}
                                    valueSetter={setDropDownTopCategoryName}
                                    onChangeHandler={setTopCategoryId}/>
                    <CustomInput
                        type={"text"}
                        name = {"subCategory"}
                        placeHolder={"SubCategory"}
                        inputSize={"large"}
                        value={subCategoryName}
                        onChange={(e) => setSubCategoryName(e.target.value)}
                    />
                </div>
                <div className={"admin-categories-item-secondContainer"}>
                    <CustomInput
                        name = {"subCategorySorter"}
                        type={"number"} inputSize={"large"} min={"0"}
                        value={subCategorySorter}
                        onChange={(e) => {
                            if (Number(e.target.value))
                                setSubCategorySorter(Number(e.target.value).toString())
                            else
                                setSubCategorySorter("0")
                        }}
                    />
                    <div className={"admin-categories-item-buttons"}>
                        <button onClick={UpdateSubCategory} className={"admin-categories-item-buttonEdit"}/>
                        <button
                            className={"admin-categories-item-buttonDelete"}
                            onClick={DeleteSubCategory}
                            disabled={!canDelete}
                            data-canDelete={canDelete}
                        >
                            {!canDelete&&
                                <div className={"admin-categories-item-buttonDelete-canDelete"}>
                                    Нельзя удалить, есть коренные товары
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
            <div style={{marginTop: "10px",marginLeft:"40px"}}>
                {
                    itemFilter&&itemFilter.length>0&&<ItemMultiFilter
                        itemFilter={itemFilter}/>}

            <Link to={SpaRoutes.FILTERS + `/${0}` + `/${itemSubCategoryId}`}>
                <div style={{ width: "170px", padding: "10px" }} className={"admin-categories-add-button"}>Изменить фильтры</div>
            </Link>
            </div>
        </>
    );
};

export default ItemSubCategory;