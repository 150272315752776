import * as React from "react";
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import CarouselButton from "./CarouselButton";
import SwiperCore, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Autoplay]);

const CarouselWrapper = styled.div`
    display: block;
    overflow: hidden;
    position: relative;
    .swiper{
        padding: 0;
    }
`;

const CarouselSlide = styled.div`
    flex: 0 0 auto;
    width: 100%;
`;

interface IProps {
    children: JSX.Element[];
}

const Carousel = ({ children }: IProps) => {
    const [swiper, setSwiper] = React.useState<any>(null);

    React.useEffect(() => {
        if (swiper) {
            swiper.on('transitionStart', () => {
                if (swiper.autoplay && swiper.autoplay.running) {
                    swiper.autoplay.stop();
                }
            });

            swiper.on('transitionEnd', () => {
                if (swiper.autoplay && !swiper.autoplay.running) {
                    swiper.autoplay.start();
                }
            });
        }
    }, [swiper]);

    const handlePrevSlide = () => {
        if (swiper) {
            swiper.slidePrev();
        }
    };

    const handleNextSlide = () => {
        if (swiper) {
            swiper.slideNext();
        }
    };

    return (
        <>
            <CarouselWrapper>
                <Swiper
                    onSwiper={setSwiper}
                    slidesPerView={1}
                    spaceBetween={10}
                    loop={true}
                    autoplay={{ delay: 6000 }}
                    pagination={{ clickable: true }}
                >
                    {children.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <CarouselSlide>{slide}</CarouselSlide>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <ul className="carousel-indicators">
                    <CarouselButton SlideHandler={handlePrevSlide} />
                    <CarouselButton SlideHandler={handleNextSlide} />
                </ul>
            </CarouselWrapper>
        </>
    );
};

export default Carousel;