import React, {useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Context} from "../../../index";
import CustomDropdown from "../../customUI/CustomDropdown/CustomDropdown";
import {IDropdownOption, IFilter, ISubCategory, ITopCategory} from "../../../models/models";
import {SPCategories} from "../../../models/SelectProduct/SPCategories";
import FilterDropDowns from "../../customUI/CustomDropdown/FilterDropDowns";
import AdminService from "../../../services/AdminService";
import HomeService from "../../../services/HomeService";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import {bool} from "yup";
interface Interface {
    searchword?:string;
    triggerResetFilter?: boolean
    onUpdate: () => void
}

const FilterForCatalog = ({searchword, triggerResetFilter, onUpdate}:Interface) => {
    const {store} = useContext(Context)
    const navigate = useNavigate()

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const [filterArray , setFilterArray] = useState<any[]>([])
    const [selectedIds, setSelectedIds] = useState<string[]>([])
    const [notBlockedIds, setNotBlockedIds] = useState<string[]>([])
    const [arrayTopCat, setArrayTopCat] = useState<IDropdownOption[]>([])
    const [arraySupCat, setArraySubCat] = useState<IDropdownOption[]>([])
    const [topCategoryName, setTopCategoryName] = useState("Категория")
    const [subCategoryName, setSubCategoryName] = useState("Подкатегория")
    const [topCategories, setTopCategories] = useState<ITopCategory[]>([]);
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
    const [subCatId,setSubCatId] = useState<string>("-1")
    const [topCatId,setTopCatId] = useState<string>("-1")
    const [checkMassiv,setCheckMassiv] = useState<any[]>([])
    const [dealerFilter,setDealerFilter] = useState<IFilter[]>([])
    const [loadetData,setLoadedData] = useState<boolean>(false)
    const [filterArrayIsReady,setFilterArrayIsReady] = useState<boolean>(false)
    const [displayPopup,setDisplayPopup] = useState<boolean>(false)
    useEffect(() => {
        store.DataLoadingON()
            HomeService.getAllCategories()
                .then(response => {
                    setTopCategories(response.data.topCategories.sort( (a:any, b:any)=> {
                        if (a.name < b.name) {
                            return -1;
                        }
                        if (a.name > b.name) {
                            return 1;
                        }
                        return 0;
                    }))
                    setDealerFilter(response.data.dealerFilter)
                    setCheckMassiv(response.data.catalogCheckFilterMassiv)
                    store.FilterLoad()
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {
                    //store.DataLoadingOFF()
                    onUpdate()
                    setLoadedData(true)
                })
    }, [])

    useEffect(() => {
        if (topCategories&&checkMassiv) {
            let newArrayTopCat:IDropdownOption[] = []
            topCategories.map((item) => {
                if(checkMassiv.some((massItem)=>{
                    return (massItem.includes("Top_"+item.topCategoryId))
                })){
                    newArrayTopCat.push({key: item.topCategoryId, name: item.name})
                }
            });
            let newArrayTopCatSorted = newArrayTopCat.sort((a,b)=>{
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })

            setArrayTopCat(newArrayTopCatSorted)
            clearSelectedIds()
        }
    }, [topCategories,checkMassiv])

    useEffect(() => {
        if (subCategories&&checkMassiv) {
            let newArraySubCat:IDropdownOption[] = []
            subCategories.map((item) => {
                if(checkMassiv.some((massItem)=>{
                    return (massItem.includes("Sub_"+item.categoryId))
                })){
                    newArraySubCat.push({key: item.categoryId, name: item.name})
                }
            });
            let newArraySubCatSorted = newArraySubCat.sort((a,b)=>{
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            })
            setArraySubCat(newArraySubCatSorted)
            clearSelectedIds()
        }
    }, [subCategories,checkMassiv])

    const clearSelectedIds = ()=>{
        if (loadetData){

            let newSelection:string[] = []
            selectedIds.forEach((id:string)=>{
                if(filterArray.some((filter)=>{
                    return filter.attrId === id
                })){
                    newSelection.push(id)
                }
            })
            setSelectedIds(newSelection)
        }
    }

    useEffect(()=>{
        if (loadetData){

            let filtarr:IFilter[] = []
            if (dealerFilter){
                filtarr = filtarr.concat(dealerFilter)
            }
            if(topCategories && topCategories.length>0 ) {
                topCategories.map((c) => {
                    if (c.topCategoryId === topCatId) {
                        filtarr = filtarr.concat(c.filterAttributes)
                    }
                })
            }
            if(subCategories && subCategories.length>0){
                subCategories.map((c)=>{
                    if (c.categoryId === subCatId ){ //|| subCatId==="-1"){ <--this changes filter for subcat all vs only selected
                        filtarr = filtarr.concat(c.filterAttributes)
                    }
                })
            }
            let uniqueArray:IFilter[] = []
            filtarr.map( (filt) => {
                if (uniqueArray.every((item)=>item.attrId !==filt.attrId)){
                    uniqueArray.push(filt)
                }
            })
            setFilterArray(uniqueArray)
            setFilterArrayIsReady(true)
        }
    },[topCatId,subCatId,dealerFilter,loadetData, store.filterInfo])

    useEffect(() => {
        if (loadetData){
            // calculate new notBlockedIds
            let notblocked:any[] = []
            notblocked.push({group:"subCat",ids:[]})
            notblocked.push({group:"topCat",ids:[]})
            filterArray.forEach((filter)=>{
                //for TopCat
                if(checkMassiv.some((item)=>{
                    return (item.includes(filter.attrId) && item.includes("Top_"+topCatId))||topCatId==="-1"
                })){
                    //add attrId to notblocked
                    if (notblocked.length>0 && notblocked.some((item)=>{return item.group==="topCat"})){
                        notblocked[notblocked.findIndex((item:any)=>{return item.group==="topCat"})].ids.push(filter.attrId)
                    }
                }
                //for SubCat
                if(checkMassiv.some((item)=>{
                    return (item.includes(filter.attrId) && item.includes("Sub_"+subCatId))||subCatId==="-1"
                })){
                    //add attrId to notblocked
                    if (notblocked.length>0 && notblocked.some((item)=>{return item.group==="subCat"})){
                        notblocked[notblocked.findIndex((item:any)=>{return item.group==="subCat"})].ids.push(filter.attrId)
                    }
                }

                selectedIds.forEach((selectedId)=>{
                    let selectedFilter = filterArray.find((fil)=>{return fil.attrId==selectedId})
                    if (selectedFilter && filter.filterId != selectedFilter.filterId){
                        if(checkMassiv.some((item)=>{
                            return item.includes(filter.attrId) && item.includes(selectedId)
                        })){
                            //add attrId to notblocked
                            if (notblocked.length>0 && notblocked.some((item)=>{return item.group===selectedFilter.filterId})){
                                if (notblocked[notblocked.findIndex((item:any)=>{return item.group===selectedFilter.filterId})].ids.every((id:string)=>{
                                    return id !== filter.attrId
                                })){
                                    notblocked[notblocked.findIndex((item:any)=>{return item.group===selectedFilter.filterId})].ids.push(filter.attrId)
                                }
                            }
                            else{
                                notblocked.push({group:selectedFilter.filterId,ids:[filter.attrId]})
                            }
                        }
                    }
                    else{
                        if (selectedFilter){
                            //add attrId to notblocked
                            if (notblocked.length>0 && notblocked.some((item)=>{return item.group===selectedFilter.filterId})){
                                notblocked[notblocked.findIndex((item:any)=>{return item.group===selectedFilter.filterId})].ids.push(filter.attrId)
                            }
                            else{
                                notblocked.push({group:selectedFilter.filterId,ids:[filter.attrId]})
                            }
                        }
                    }
                })
            })
            if (notblocked.length>0){
                let notBlocking:string[]=[]
                filterArray.forEach((filter)=>{
                    if(notblocked.every((item)=>{return item.ids.includes(filter.attrId)})){
                        notBlocking.push(filter.attrId)
                    }
                })
                setNotBlockedIds(notBlocking)
            }
        }
    }, [selectedIds,subCatId,topCatId,filterArray,loadetData])

    const goToLink = (topCatId: string | undefined, subCatId: string | undefined) => {
        if (topCatId==="-1" && subCatId==="-1"){
            if (searchword){
                navigate(SpaRoutes.CATALOG_INDEX+"/:"+ (searchword?searchword:""))
            }
            else{
                navigate(SpaRoutes.CATALOG_INDEX)
            }
            onUpdate()
        }
        else{
            if (subCatId==="-1"){
                navigate(SpaRoutes.CATEGORY+"/"+topCatId+"/:"+ (searchword?searchword:""))
            }
            else{
                navigate(SpaRoutes.SUB_CATEGORY+"/"+subCatId+"/:"+ (searchword?searchword:""))
            }
        }
    }

    const sendToStore = ()=>{
        store.FiltersSave(topCatId,topCategoryName,subCatId,subCategoryName,selectedIds)
        goToLink(topCatId,subCatId)

        setDisplayPopup(false)
    }

    const resetStore = async () => {
        if (dealerFilter) {
            setFilterArray(dealerFilter)
        } else {
            setFilterArray([])
        }
        setSubCatId("-1")
        setTopCatId("-1")
        setTopCategoryName("Категория")
        setSubCategoryName("Подкатегория")
        setSubCategories([])
        setSelectedIds([])
        await store.FiltersSave("-1", "", "-1", "", [])
        goToLink("-1", "-1")
    }

    useEffect(() => {
        if(triggerResetFilter){
            resetStore()
        }
    }, [triggerResetFilter]);

    const closePopup = ()=>{
        setDisplayPopup(false)
    }

    useEffect(()=>{
        if (loadetData && !filterArrayIsReady){
            let fi = store.filterInfo
            if (store.filterInfo.topId!=="-1"){
                onTopCategorySelectChange(store.filterInfo.topId)
                if (store.filterInfo.subId!=="-1"){
                    onSubCategorySelectChange(store.filterInfo.subId)
                    setSubCategoryName(store.filterInfo.subCatName)
                }
            }
            updateSelectedIds(store.filterInfo.selectedIds);
        }
        if (loadetData && filterArrayIsReady){
            setSelectedIds(store.filterInfo.selectedIds)
        }
    },[loadetData,filterArray, store.filterInfo])

    const onTopCategorySelectChange = (val: string) => {
        const TopCategoryId = val;
        const topCategory = topCategories.find(item => item.topCategoryId === TopCategoryId);
        if (topCategory){
            const subCategories = topCategory?.categories;
            if ((subCategories != null) && subCategories.length != 0) setSubCategories(subCategories);
            else setSubCategories([{categoryId: 'no', name: 'Нет подкатегорий',sorter:0,topCategoryId:val,filterAttributes:[]}]);
            setSubCategoryName("Подкатегория");
            setTopCatId(val)
            setTopCategoryName(topCategory.name)
            setSubCatId("-1")
        }
    }

    const onSubCategorySelectChange = (subCatId:string) => {
        setSubCatId(subCatId)
    }

    const updateSelectedIds = (ids:string[])=>{
        setSelectedIds(ids)
        //calculate notBlocked
        setNotBlockedIds([])
    }

    const getNumFilters = () => {
        let defFilter = {
            topId: "-1",
            topCatName: "Категория",
            subId: "-1",
            subCatName: "Подкатегория",
            selectedIds: []
        }
        let num = 0
        for (const filter in store.filterInfo) {
            if(filter === "selectedIds"){
                if(store.filterInfo.selectedIds.length > 0) num += store.filterInfo.selectedIds.length >= 4? 4 : store.filterInfo.selectedIds.length
            }else {
                // @ts-ignore
                if(store.filterInfo[filter] !== defFilter[filter] && filter != "topCatName" && filter != "subCatName"){
                    num += 1
                }
            }
        }
        return num
    }

    return (
        <>{(!store.isError && !store.isDataLoading) &&
            <>
                <div className={"filter-container"}>
                    <img className={"filter-button-image"} src={"/Pictures/filter.svg"}/>
                    <div className={"admin-categories-filter-button filterPopupButton"} onClick={()=>{setDisplayPopup(true)}}>ФИЛЬТР</div>
                    {getNumFilters() > 0 &&
                        <>
                            <div className={"filter-sel-index"}>{getNumFilters()}</div>
                            <button className={"filter-sel-resetBtn"} onClick={() => resetStore()}>
                                <img src={"/Pictures/broomstickBlack.svg"}/>
                                Очистить фильтр
                            </button>
                        </>
                    }
                </div>
                {/*{displayPopup &&*/}
                <div className={`${displayPopup?"filter-open":"filter-close"} filterPopupElement`}>
                    <div className={"filterPopupElement-container"}>
                        <div className={`admin-filter-for-caralog-button-close`} onClick={()=>closePopup()}>X</div>
                        <div className={"filterForCatalog-categories-container"}>
                            <div className={"filterForCatalog-category"}>
                                <div className={"filterForCatalog-hint-text"}>Категория</div>
                                <div className={"filterForCatalog-dropDown"}>
                                    <CustomDropdown
                                        options={arrayTopCat}
                                        dropdownSize={"small"}
                                        value={topCategoryName}
                                        valueSetter={setTopCategoryName}
                                        onChangeHandler={onTopCategorySelectChange}
                                    />
                                </div>
                                <>{//topCategoryName!=="Категория"&&
                                    //<div style={{marginTop: "-10px", marginBottom: "22px", width: "fit-content"}} className={"admin-filter-basic"}>{topCategoryName}</div>
                                }
                                </>

                            </div>
                            <div className={"filterForCatalog-subcategory"}>
                                <div className={"filterForCatalog-hint-text"}>Подкатегория</div>
                                <div className={"filterForCatalog-dropDown"}>
                                    <CustomDropdown
                                        dropdownSize={"small"}
                                        options={arraySupCat}
                                        value={subCategoryName}
                                        valueSetter={setSubCategoryName}
                                        onChangeHandler={onSubCategorySelectChange}
                                    />
                                </div>
                                {//subCategoryName!=="Подкатегория"&&
                                    //<div style={{marginTop: "-10px", marginBottom: "22px", width: "fit-content"}} className={"admin-filter-basic"}>{subCategoryName}</div>
                                }
                            </div>
                        </div>
                        <div className={"filterSelectionBoxForCatalog"}>
                            <div style={{marginTop: "-14px"}}>
                                {filterArray && filterArray.length > 0 && <FilterDropDowns
                                    filters={filterArray}
                                    selectedIds={selectedIds}
                                    notBlockedIds={notBlockedIds}
                                    updateSelectedIds={updateSelectedIds}
                                />}
                            </div>
                        </div>

                        <div style={{display:"flex"}} className={"filterButtonBoxForCatalog"}>
                            <div className={"admin-categories-add-button admin-filter-for-caralog-button-1"} onClick={()=>closePopup()}>Свернуть</div>
                            <div className={"admin-categories-add-button admin-filter-for-caralog-button-1"} onClick={()=>resetStore()}>Очистить</div>
                            <div className={"admin-categories-add-button admin-filter-for-caralog-button-2"} onClick={()=>sendToStore()}>Применить</div>
                        </div>
                    </div>
                </div>
                {/*}*/}
            </>

        }</>
    )
}
export default FilterForCatalog;