import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import "./Bonuses.css"
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IBaseInformation, IBonusTransaction} from "../../../../models/models";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {Context} from "../../../../index";
import HomeService from "../../../../services/HomeService";
import BonusTransaction from "./BonusTransaction";

const requisitesText =
    "Полное наименование: \n" +
    "Общество с ограниченной ответственностью «Звезда» \n" +
    "\n" +
    "Краткое наименование ООО «Звезда» \n" +
    "Генеральный директор Иванов Вадим Юрьевич\n" +
    "\n" +
    "Юридический адрес\n" +
    " 124533, Россия, Рязанская область, г. Рязань, ул. Ленина, 52, корп. 1, \n" +
    "оф. 5 \n" +
    "\n" +
    "Почтовый адрес\n" +
    " 124533, Россия, Рязанская область, г. Рязань, ул. Ленина, 52, корп. 1, оф. 5\n" +
    " \n" +
    "Телефон/e-mail:     +7 (4912) 21-66-99 Zvezdaooo@mail.ru \n" +
    "ИНН 0278000222 \n" +
    "КПП 027811001 \n" +
    "ОГРН 1030224552966 \n" +
    "ОКВЭД 22.21 \n" +
    "ОКПО 02428896 \n" +
    "ОКАТО 61401000000 \n" +
    "Расчетный счет 40602810206000050025 \n" +
    "\n" +
    "Полное наименование банка \n" +
    "ОАО «Уралторгбанк России», г. Рязань.\n" +
    " \n" +
    "БИК      046526969 \n" +
    "Корреспондентский счет банка      30101810600002500957\n"

const Bonuses = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [bonusBalance, setBonusBalance] = useState<number>(0)
    const [dataBonusTransactions, setDataBonusTransactions] = useState<IBonusTransaction[]>()
    const [transactions, setTransactions] = useState<IBonusTransaction[]>()
    const [isLastNegative, setIsLastNegative] = useState(false)
    const [isLastPositive, setIsLastPositive] = useState(false)
    const [pricingTable, setPricingTable] = useState<any[]>()
    const [cardCount, setCardCount] = useState(0)
    const [cardSingleP1, setCardSingleP1] = useState(0)
    const [cardCost2, setCardCost2] = useState(0)
    const [cardSingleP2, setCardSingleP2] = useState(0)
    const [cardCountCost, setCardCountCost] = useState(0)
    const [cardCountStep, setCardCountStep] = useState(0)
    const [cardCountPercentage, setCardCountPercentage] = useState(0)
    const [baseInfo, setBaseInfo] = useState<IBaseInformation>()
    const [requisitesButtonText, setRequisitesButtonText] = useState("Скопировать реквизиты")

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
        store.DataLoadingON()
        CabinetSellerService.getBonusBalance()
            .then(response => {
                setBonusBalance(response.data.balance)
                HomeService.getBaseInformation()
                    .then((response) => setBaseInfo(response.data))
                    .catch(error => store.ErrorON())
                CabinetSellerService.GetCardPricingTable()
                    .then(response => {
                        setPricingTable(response.data)
                    }).then(()=>{
                CabinetSellerService.getBonusTransaction({"Number1": "6"})
                    .then(response => {
                        setDataBonusTransactions(response.data)
                    })
                    .catch(error => store.ErrorON())

                            .then(() => {
                                setLoading(true)
                            })
                            .catch(error => store.ErrorON())
                    }).catch(error => store.ErrorON())
            })
            .catch(error => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }, [])

    const parseDateFromString = (input: any) => {
        input = input.split(" ")
        let date = new Date;
        date.setDate(parseInt(input[0].slice(0, 2)));
        date.setMonth(parseInt(input[0].slice(3, 5)) - 1);
        date.setFullYear(parseInt(input[0].slice(6, 10)));
        date.setHours(parseInt(input[1].slice(0, 2)));
        date.setMinutes(parseInt(input[1].slice(3, 5)));
        date.setSeconds(parseInt(input[1].slice(6, 10)));
        return date;
    }

    const getLast = () => {
        if (dataBonusTransactions) {
            const arr = dataBonusTransactions.slice()
            setTransactions(arr
                .sort(function (a, b): any {
                    var dateA = new Date(parseDateFromString(a.date)).getTime()
                    var dateB = new Date(parseDateFromString(b.date)).getTime()
                    return dateB - dateA
                }).slice(0, 6)
            )
        }
    }

    useEffect(() => {
        if (isLastNegative) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value < 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else if (isLastPositive) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value > 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else {
            getLast()
        }
    }, [isLastNegative, isLastPositive])

    useEffect(() => {
        getLast()
    }, [loading])




    const divRef = useRef(null);


    const requisitesButtonClick = () => {
        // @ts-ignore
        const text = divRef.current.map().textContent;
        navigator.clipboard.writeText(text)
        setRequisitesButtonText("Реквизиты скопированы")
        setTimeout(function () {
            setRequisitesButtonText("Скопировать реквизиты")
        }, 5000);
    }

    const calculateCosts = (value: number) => {

        let lastPrice = 0
        let lastCount = 0
        let foundPrice = false
        pricingTable?.forEach((item => {
            if (value <= item.count && !foundPrice) {
                foundPrice = true
                let a = (item.price - lastPrice) / (item.count - lastCount)
                let b = lastPrice - a * lastCount
                let pricePerMonth = a * value + b
                setCardCountCost(pricePerMonth / 30)
                setCardCountStep(item.count+1)
                setCardCountPercentage(item.percentage)
                setCardSingleP1((pricePerMonth / 30)/value)
                calculateCosts2(item.count+1)
            }
            lastPrice = item.price * (100 - item.percentage) / 100
            lastCount = item.count;
        }))
    };

    const calculateCosts2 = (value: number) => {

        let lastPrice = 0
        let lastCount = 0
        let foundPrice = false
        pricingTable?.forEach((item => {
            if (value <= item.count && !foundPrice) {
                foundPrice = true
                let a = (item.price - lastPrice) / (item.count - lastCount)
                let b = lastPrice - a * lastCount
                let pricePerMonth = a * value + b
                setCardCost2(pricePerMonth / 30)
                setCardSingleP2((pricePerMonth / 30)/value)
            }
            lastPrice = item.price * (100 - item.percentage) / 100
            lastCount = item.count;
        }))
    };

    const handleInputChange = (e: any) => {
        let {name, value} = e.target;
        setCardCount(value)
        //calculateCosts(value)
    };

    const calculateCostsButton = () => {
        calculateCosts(cardCount)
    };
    function createMarkup(a:any) {
        if(a)
            return {__html: a};
        else
            return {__html: ""}
    }
    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <>
                    <div className={"bonuses-mainContainer"}>
                        <div className={"bonuses-navigate-container"}>
                            <img className={"bonuses-navigate-image"} onClick={() => navigate(-1)}
                                 src={"/Pictures/strelochka.svg"}/>
                            <div className={"bonuses-navigate-title"}>Баланс бонусов</div>
                        </div>

                        <div className={"bonuses-firstContainer"}>
                            <div className={"bonuses-quantity-rules-container"}>
                                <div className={"bonuses-quantityCard"}>
                                    <div className={"bonuses-quantityCard-quantity"}>{bonusBalance.toFixed(2)}</div>
                                    <div className={"bonuses-quantityCard-text"}>бонусов</div>
                                </div>
                                <div className={"bonuses-rulesDeposit-title"}>Условия пополнения</div>
                                <div className={"bonuses-rulesDeposit"}
                                     dangerouslySetInnerHTML={createMarkup(baseInfo?.cardPricingText)}
                                >

                                </div>
                            </div>

                            <div className={"bonuses-historyOperations-container"}>
                                <div className={"bonuses-historyOperations-header"}>
                                    История операций:
                                    <div className={"bonuses-historyOperations-buttons"}>
                                        <button className={"bonuses-historyOperations-button"}
                                                data-isActive={isLastNegative}
                                                onClick={() => {
                                                    setIsLastNegative(!isLastNegative)
                                                    setIsLastPositive(false)
                                                }
                                                }>Расходы
                                            {isLastNegative && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                                        </button>
                                        <button className={"bonuses-historyOperations-button"}
                                                data-isActive={isLastPositive}
                                                onClick={() => {
                                                    setIsLastPositive(!isLastPositive)
                                                    setIsLastNegative(false)
                                                }
                                                }>Зачисления
                                            {isLastPositive && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                                        </button>
                                    </div>
                                </div>

                                <div className={"bonuses-historyOperations-operations"}>
                                    <BonusTransaction transactions={transactions}/>
                                </div>
                                {transactions?.length != 0 &&
                                    <div className={"bonuses-historyOperations-watchAll"}>
                                        <Link to={"/bonusesTransactionHistory"}>
                                            Смотреть все
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={"bonuses-secondContainer"}
                             style={{marginTop: transactions?.length === 0 ? "100px" : ""}}>
                            <div className={"bonus_calc_header"}>
                                Калькулятор
                            </div>
                            <div className={"bonus_calc_text"}>
                                Сколько будет активных карточек:
                            </div>
                            <div className={"bonus_calc_input_box"}>
                                <div className={"bonus_calc_input"}>
                                    <CustomInput
                                        name={"cardCount"}
                                        type={"number"}
                                        value={Number(cardCount).toString()}
                                        icon={false}
                                        onChange={(event) => {
                                            handleInputChange(event)
                                        }}
                                        inputSize={"large"}
                                    />
                                </div>
                                <div className={"bonus_calc_button"}>
                                    <button className={"bonuses_calculator_button"}
                                            onClick={() => calculateCostsButton()}
                                    >Расчитать
                                    </button>
                                </div>
                            </div>
                            <div className={"bonus_calc_answer1"}>
                                Итоговая стоимость: {cardCountCost.toFixed(2)} баллов ({(cardSingleP1).toFixed(2)} баллов за карточку)
                            </div>
                            <div className={"bonus_calc_answer2"}>
                                <div className={"bonus_calc_answer2_1"}>
                                    Вы можете повысить количество карточек на
                                </div>
                                <div className={"bonus_calc_answer2_2"}>
                                    {cardCountStep}
                                </div>
                            </div>
                            <div className={"bonus_calc_answer2"}>
                                <div className={"bonus_calc_answer2_1"}>
                                    и получить выгоду в
                                </div>
                                <div className={"bonus_calc_answer2_2"}>
                                    {cardCost2.toFixed(2)} баллов ({(cardSingleP2).toFixed(2)} баллов за карточку)
                                </div>
                            </div>
                        </div>
                        <div className={"bonuses-secondContainer"}
                             style={{marginTop: transactions?.length === 0 ? "100px" : ""}}>
                            Пополнение баланса
                            <div className={"bonuses-manual-requisites"}>
                                <div className={"bonuses-manual"}>
                                    <div className={"bonuses-manual-header"}>Инструкция:</div>
                                    <div className={"bonuses-manual-text"}>
                                        Для пополнения баланса, переведите средства по реквизитам справа и свяжитесь с
                                        администраторы. Средства поступят в течении нескольких часов.
                                    </div>
                                </div>
                                <div className={"bonuses-requisites-container"}>
                                    <div className={"bonuses-requisites"}>
                                        <div className={"bonuses-requisites-header"} >Реквизиты</div>
                                        <div className={"bonuses-requisites-text"} ref={divRef}
                                             dangerouslySetInnerHTML={createMarkup(baseInfo?.requisitiText)}
                                        >
                                        </div>
                                    </div>
                                    {/*<button className={"bonuses-requisites-button"}*/}
                                    {/*        onClick={() => requisitesButtonClick()}*/}
                                    {/*>{requisitesButtonText}</button>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default Bonuses;