import {useContext} from "react";
import {Context} from "../../../index";

interface Interface {
    src?:string;
    noImage?:string;
    id?:string;
    className?:string;
    onClick?:()=>any;
    style?:any;
    alt?:string;
    customize?:boolean;
}

export const CustomIMG = (props:Interface) => {
    const { store } = useContext(Context)
    let imgs = [
        store.imgServer + props.src
     ];
    if (!props.src || props.src===""){
        imgs[0] = '/Pictures/supplierNoImage.svg'
    }
    if (props.noImage){
        imgs[0] = props.noImage
    }
    if (props.src && props.src.substring(0,5)==="blob:"){
        imgs[0] = props.src
    }
  return(
      <img
          style={props.style}
          onClick={props.onClick}
          src={imgs[0]}
          id={props.id}
          className={props.className}
          alt={props.alt}
      />
  )
}