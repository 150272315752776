import React, {useContext, useEffect, useState} from 'react';
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";

interface ModalProps {
    onClose: () => void;
    addData?: (count: number, price: number, percentage: number) => void;
    editData?: (index: number, count: number, price: number, percentage: number) => void;
    quantity?: number;
    price?: number;
    percentage?: number;
    index?: number;
}

export function AddPricePopup(props: ModalProps) {
    //const [date, setDate] = useState("")
    const {store} = useContext(Context)
    const [quantity, setQuantity] = useState(props.quantity? props.quantity : 0)
    const [price, setPrice] = useState(props.price? props.price : 0)
    const [percentage, setPercentage] = useState(props.percentage? props.percentage : 0)
    const [canSend, setCanSend] = useState(false)

    const update = () => {
        if(canSend){
            props.onClose()
            if(props.editData && props.index)
                props.editData(props.index, quantity, price, percentage)
            else if(props.addData)
                props.addData(quantity, price, percentage)
        }
    }

    useEffect(() => {
        if(quantity && price && percentage)
            setCanSend(true)
        else
            setCanSend(false)
    }, [quantity, price, percentage])

    return (
        <>
            <div className={"addPrice-popup-close-wrapper"} onClick={props.onClose}/>
            <div className={"addPrice-popup-wrapper"}>
                <div className={"addPrice-popup-container"}>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>Количество</div>
                        <CustomInput
                            name = {"priceCardAmount"}
                            type={"number"}
                            placeHolder={"Количество"}
                            value={quantity}
                            onChange={
                                (event) => setQuantity(event.target.value)
                            }
                            inputSize={"large"}
                        />
                    </div>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>Цена</div>
                        <CustomInput
                            name = {"priceCardPrice"}
                            type={"number"}
                            placeHolder={"Цена"}
                            value={price}
                            onChange={
                                (event) => setPrice(event.target.value)
                            }
                            inputSize={"large"}
                        />
                    </div>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>% скидки</div>
                        <CustomInput
                            name = {"priceCountDiscount"}
                            type={"number"}
                            placeHolder={"% скидки"}
                            value={percentage}
                            min={"0"}
                            max={"100"}
                            onChange={
                                (event) => event.target.value >= 0 && event.target.value <=100? setPercentage(event.target.value) : undefined
                            }
                            inputSize={"large"}
                        />
                    </div>

                    <button className={`admin-mainSetting-button ${!canSend&&"admin-mainSetting-button-disabled"}`} onClick={update}>
                        {props.editData && props.index ? "Сохранить" : "Добавить"}
                    </button>
                </div>
            </div>
        </>
    )
}

