import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CustomTable, {IRowElement, ITable} from "../../../customUI/CustomTable/CustomTable";
import {ToogleVisible} from "../../../customUI/Searchbar/functions";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import React, {useContext, useEffect, useState} from 'react';
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import {Link} from "react-router-dom";
import "./UnprocessedProducts.css"
import {SpaRoutes} from "../../../../Routes/spaRoutes";

const header = ["Название","Статус", "Поставщик", "ID", "Категория", "Подкатегория", "-"];
const placeholder = "Найти...";

export const UnprocessedProducts = () => {
    const {store} = useContext(Context);

    const [unprocessedTable, setUnprocessedTable] = useState<ITable>({
        tableId: "UnprocessedTable",
        header: [],
        rows: []
    });

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const getUnprocessedTable = () => {
        store.DataLoadingON();
        AdminService.getRootQuestionList()
            .then((response) => {
                let list: IRowElement[] = [];
                for (const product of response.data) {
                    list.push(
                        {
                            content: {
                                title:
                                    <div data-search-word={product.title}>
                                        {product.title}
                                    </div>,
                                status:<div>
                                    {product.status === 0?"в ходе выполнения":product.status === 1?"готов к проверке":""}
                                    </div>
                                ,
                                supplier:
                                    <div data-search-word={product.createdBy}>
                                        {product.createdBy}
                                    </div>,
                                idRoot:
                                    <div data-search-word={product.questionId}>
                                        {product.questionId}
                                    </div>,
                                category:
                                    <div>
                                        {product.topCategoryName}
                                    </div>,
                                subCategory:
                                    <div>
                                        {product.subCategoryName}
                                    </div>,
                                linkEdit:
                                    <Link to={`${SpaRoutes.ADMIN_SUPPLIER_QP + "/" + product.questionId}`}>
                                        <img src={"/Pictures/edit.svg"} alt={"edit"}/>
                                    </Link>
                            },
                            filter: [],
                            visible: [true]
                        }
                    )
                }
                let myTable: ITable = {
                    tableId: "UnprocessedTable",
                    header: header,
                    rows: list
                }
                setUnprocessedTable(myTable);
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    useEffect(() => {
        getUnprocessedTable();
    }, []);

    const changeHandler = (text: string) => {
        setUnprocessedTable((prevState) => {
                const newState = {...prevState}
                newState.rows = ToogleVisible(text, ["title", "supplier", "idRoot"], ["", "", ""], newState.rows)
                return newState;
            }
        )
    }

    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <div className={"unprocessedProducts-wrapper"}>
                    <div className={"unprocessedProducts-container"}>
                        <CustomNavigateHeader title={"Необработанные коренные товары"} withTrash={false}/>

                        <Searchbar
                            changeHandler={changeHandler}
                            initialText={placeholder}
                        />
                        <CustomTable {...unprocessedTable}/>
                    </div>
                </div>
            }
        </>
    );
};