import React, {useContext, useState} from 'react';
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import {IDropdownOption, IFilter, ISubCategory, ITopCategories} from "../../../../models/models";
import ItemSubCategory from "./ItemSubCategory";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import categories from "./Categories";
import Categories from "./Categories";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";
import ItemMultiFilter from "./ItemMultiFilter";

interface IItemTopCategory{
    ArrayTopCategory:IDropdownOption[],
    itemTopCategoryId: string,
    itemTopCategoryName: string,
    itemTopCategorySorter: number,
    itemFilter: IFilter[],
    itemSubCategories: ISubCategory[],
    onDataUpdate: () => void,
    canDelete: boolean
}

const ItemTopCategory = ({ArrayTopCategory, itemTopCategoryId, itemTopCategoryName, itemTopCategorySorter, itemFilter, itemSubCategories, onDataUpdate, canDelete}:IItemTopCategory) => {
    const {store} = useContext(Context)
    let oldTopCategoryName = itemTopCategoryName
    const [topCategoryName, setTopCategoryName] = useState(itemTopCategoryName)
    const [topCategorySorter, setTopCategorySorter] = useState(itemTopCategorySorter.toString())
    const UpdateTopCategory = () => {
        if(topCategoryName !== ""){
            let mydata = {
                Info1: itemTopCategoryId,
                Info2: topCategoryName,
                Number1: Number(topCategorySorter)
            }

            AdminService.postTopCategoryUpdate(mydata)
                .then(r => {
                    if(r.data.status == "Success"){
                        alert(`TopCategory (${oldTopCategoryName}) успешно изменена`)
                        onDataUpdate()
                    }
                })
                .catch(() => {
                    store.ErrorON()
                })
                .finally(() => {

                })
        }
        else
            alert("Пустое название")
    }

    const DeleteTopCategory = () => {
        if (window.confirm("Вы действительно хотите удалить TopCategory?")) {
            let mydata = {
                Info1: itemTopCategoryId,
            }

            AdminService.postTopCategoryDelete(mydata)
                .then(r => {
                    if (r.data.status == "Success") {
                        onDataUpdate()
                        alert(`TopCategory (${topCategoryName}) успешно удалена`)
                    }
                })
                .catch((r) => {
                    if(r.response.data.status === "Error_1") alert("Такой категории нет")
                    else if(r.response.data.status === "Error_2") alert("Нельзя удалить категорию с подкатегориями")
                })
                .finally(() => {

                })
        }
    }

    return (
        <div className={"admin-categories-item"}>
            <hr/>
            <div className={"admin-categories-item-topCategory"}>
                <CustomInput
                    type={"text"}
                    name = {"topCategory"}
                    value={topCategoryName}
                    onChange={(e) => setTopCategoryName(e.target.value)}
                    placeHolder={"TopCategory"} inputSize={"large"}
                />
                <div className={"admin-categories-item-secondContainer"}>
                    <CustomInput
                        name = {"categorySorter"}
                        type={"number"} inputSize={"large"} min={"0"}
                        value={topCategorySorter}
                        onChange={(e) => {
                            if (Number(e.target.value))
                                setTopCategorySorter(Number(e.target.value).toString())
                            else
                                setTopCategorySorter("0")
                        }}
                    />
                    <div className={"admin-categories-item-buttons"}>
                        <button onClick={UpdateTopCategory} className={"admin-categories-item-buttonEdit"}/>
                        <button
                            className={"admin-categories-item-buttonDelete"}
                            onClick={DeleteTopCategory}
                            disabled={!canDelete}
                            data-canDelete={canDelete} >
                            {!canDelete&&
                                <div className={"admin-categories-item-buttonDelete-canDelete"}>
                                    Нельзя удалить, есть SubCategory
                                </div>
                            }
                        </button>
                    </div>
                </div>
            </div>
            <div style={{marginTop: "10px"}}>
                {
                    itemFilter&&itemFilter.length>0&&<ItemMultiFilter
                    itemFilter={itemFilter}/>}
            </div>
            <Link to={SpaRoutes.FILTERS + `/${1}` + `/${itemTopCategoryId}`}>
                <div style={{ width: "170px", padding: "10px" }} className={"admin-categories-add-button"}>Изменить фильтры</div>
            </Link>
            <div className={"admin-categories-item-subCategories"}>
                {itemSubCategories.map((subCategory) => {
                    return <ItemSubCategory
                        ArrayTopCategory={ArrayTopCategory}
                        itemTopCategoryId={subCategory.topCategoryId}
                        itemTopCategoryName={oldTopCategoryName}
                        itemSubCategoryId={subCategory.categoryId}
                        itemFilter = {subCategory.filterAttributes}
                        itemSubCategoryName={subCategory.name}
                        itemSubCategorySorter={subCategory.sorter}
                        onDataUpdate={onDataUpdate}
                        canDelete={!subCategory.products || !subCategory.productRoots || !subCategory.orders}
                    />
                })}
            </div>
        </div>
    );
};

export default ItemTopCategory;