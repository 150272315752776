import {observer} from "mobx-react-lite";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Context} from "../../../../index";
import ProductCard from "../../../customUI/ProductCard/ProductCard";
import SwiperCore, {FreeMode, Navigation, Pagination} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";

function HistoryViewed() {
    const [history, setHistory] = useState({})
    const {store} = useContext(Context)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(  () => {
        store.DataLoadingON()
        if (store.roles.find((role: any) => role.includes('Customer')))
            CabinetBuyerService.historyViewed(true).then(data => {
                setHistory(data.data)
            })
                .then(()=> {

                })
            .catch(()=> {
                store.ErrorON()
            })
            .finally(()=> {
                store.DataLoadingOFF()
            })
        else {

        }

    }, [])



    SwiperCore.use([Navigation]);

    function HistorySwiper(props: any) {
        const { history } = props;
        const historyArray = Object.values(history);

        return (
            <Swiper
                spaceBetween={10}
                slidesPerView={2.5}
                freeMode={true}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
            >
                {historyArray.map((history: any) => {
                    return (
                        <SwiperSlide key={history.productId}>
                            <ProductCard
                                isAuth={store.isAuth}
                                item={history}
                            />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        );
    }

    return (
        <>
            {(!store.isError && !store.isDataLoading ) &&
                <>
                    <div className="navigate">
                        <CustomNavigateHeader
                            title={"История просмотров"}
                            trashClickHandler={()=>null}
                            withTrash={false}
                        />
                    </div>
                    {store.roles.find((role) => role.includes('Customer'))&&<>
                        <div style={{marginTop:"30px"}} className="historyPage-container">
                            <h3 className="history-head">Раннее вы смотрели:</h3>
                            <HistorySwiper history={history}/>
                        </div>
                    </>
                    }
                </>
            }
        </>
)
}

export default observer(HistoryViewed)