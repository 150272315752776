import "./Training.css"
import React, { useContext, useEffect, useState } from "react"
import Calendar from "../../../Supplier_UI_kit/Calendar/Calendar"
import { TrainingCart } from "./TrainingCart/TrainingCart"
import CabinetSellerService from "../../../../services/CabinetSellerService"
import {
    IAttenders,
    ICalendarEvents,
    IDropdownOption,
    IRegion,
    ITraining,
} from "../../../../models/models"
import {
    getParsedDate,
    getParsedDateMonthAndYear,
} from "../../../../functions/functions"
import { Link, useNavigate } from "react-router-dom"
import { SpaRoutes } from "../../../../Routes/spaRoutes"
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown"
import { Context } from "../../../../index"
import CabinetBuyerService from "../../../../services/CabinetBuyerService"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader"
import AdminService from "../../../../services/AdminService"
import { PopUpTrainingCart } from "./TrainingCart/PopUpTrainingCart"
import PopUpTrainingAttenders from "./TrainingCart/PopUpTrainingAttenders"
import { observer } from "mobx-react-lite"

function Training() {
    const { store } = useContext(Context)
    /* Calendar */
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [calendarEvents, setCalendarEvents] = useState<ICalendarEvents[]>([])

    const [allEvents, setAllEvents] = useState<ITraining[]>([])
    const [otherTrainingList, setOtherTrainingList] = useState<ITraining[]>([])
    const [myTrainingList, setMyTrainingList] = useState<ITraining[]>([])

    const [regionName, setRegionName] = useState("Вся Россия")
    const [regions, setRegions] = useState<IRegion[]>([])
    const [formatName, setFormatName] = useState("Любой формат")
    const [formats, setFormats] = useState<string[]>([])
    const [organisatorName, setOrganisatorName] = useState("Любой организатор")
    const [organisators, setOrganisators] = useState<string[]>([])
    const [ArrayOrganisators, setArrayOrganisators] = useState<
        IDropdownOption[]
    >([])
    const [ArrayRegions, setArrayRegions] = useState<IDropdownOption[]>([])
    const [thisMonth, setThisMonth] = useState<number>()
    const [thisYear, setThisYear] = useState<number>()
    const [currentDate, setCurrentDate] = useState(
        new Date().toLocaleDateString("ru-RU")
    )
    const [popUpDisplay, setPopUpDisplay] = useState<boolean>(false)
    const [PopupData, setPopupData] = useState<JSX.Element | null>(null)
    const openPopUp = (trainingList: ITraining[], item: ITraining) => {
        setPopUpDisplay(true)
    }
    const closePopUp = () => {
        setPopUpDisplay(false)
    }
    const [filterCalendar, setFilterCalendar] = useState({
        regionName: "",
        format: "",
        organisator: "",
    })
    const [loadedTrainingData, setLoadedTrainingData] = useState(false)

    const TransCalendarEvents = (events: any): ICalendarEvents[] => {
        let _events = []
        // events.sort(
        //     function (a: any, b: any): any {
        //         var dateA = new Date(a.startDate).getTime()
        //         var dateB = new Date(b.startDate).getTime()
        //         return dateB - dateA
        //     })

        for (let i = 0; i < events.length; i++) {
            if (events[i] && events[i].startDate) {
                _events[i] = {
                    day: new Date(events[i].startDate).getDate(),
                    month: new Date(events[i].startDate).getMonth(),
                    year: new Date(events[i].startDate).getFullYear(),
                    event: true,
                    eventCount: 1,
                    date: getParsedDate(events[i].startDate, true, false),
                    regionName: events[i].regionName,
                    format: events[i].format,
                    organisator: events[i].organisator,
                    id: events[i].id,
                    title: events[i].title,
                    cityName: events[i].cityName,
                    description: events[i].description,
                    startTime: events[i].startTime,
                    endTime: events[i].endTime,
                    imageUrl: events[i].imageUrl,
                }

                for (let j = i + 1; j < events.length; j++) {
                    if (events[j] && events[j].startDate) {
                        let a = getParsedDate(events[i].startDate, true, false)
                        let b = getParsedDate(events[j].startDate, true, false)
                        if (a == b) {
                            _events[i] = {
                                ..._events[i],
                                eventCount: _events[i].eventCount + 1,
                            }
                            events[j] = undefined
                        }
                    }
                }
            }
        }

        return _events
    }

    useEffect(() => {
        if (!loadedTrainingData) {
            store.DataLoadingON()
            let userRegion = ""
            let heute = new Date(Date.now())
            setThisMonth(heute.getMonth() + 1)
            setThisYear(heute.getFullYear())
            if (
                store.roles.includes("Dealer") &&
                !store.roles.includes("Admin")
            ) {
                CabinetSellerService.getTraining()
                    .then((value) => {
                        setFormats(value.data.formats)
                        //setRegions(value.data.regions);
                        //setOrganisators(value.data.organisators);

                        setAllEvents(value.data.events)
                        if(value.data.userRegion) userRegion = value.data.userRegion
                        setOtherTrainingList(
                            value.data.events
                                .map((item: any) => item)
                                .filter(
                                    (item: any) =>
                                        item.startDate &&
                                        currentDate ==
                                            getParsedDate(
                                                item.startDate,
                                                true,
                                                false
                                            )
                                )
                        )
                    })
                    .catch(() => {
                        store.ErrorON()
                    })
                    .finally(() => {
                        CabinetSellerService.getAllUpcomingEvents()
                            .then((value) =>
                                setMyTrainingList(value.data.event_)
                            )
                            .catch(() => {
                                store.ErrorON()
                            })
                            .finally(() => {
                                if(userRegion) {
                                    setRegionName(userRegion)
                                }
                                regionHasChanged(userRegion)
                                updateDropdown(
                                    heute.getMonth() + 1,
                                    heute.getFullYear()
                                )
                                store.DataLoadingOFF()
                            })
                    })
            }
            if (
                store.roles.includes("Customer") &&
                !store.roles.includes("Admin")
            ) {
                CabinetBuyerService.getTraining()
                    .then((value) => {
                        setFormats(value.data.formats)
                        //setRegions(value.data.regions);
                        //setOrganisators(value.data.organisators);
                        setAllEvents(value.data.events)
                        if(value.data.userRegion) userRegion = value.data.userRegion
                        setOtherTrainingList(
                            value.data.events
                                .map((item: any) => item)
                                .filter(
                                    (item: any) =>
                                        item.startDate &&
                                        currentDate ==
                                            getParsedDate(
                                                item.startDate,
                                                true,
                                                false
                                            )
                                )
                        )
                    })
                    .catch(() => {
                        store.ErrorON()
                    })
                    .finally(() => {
                        updateDropdown(
                            heute.getMonth() + 1,
                            heute.getFullYear()
                        )
                        if(userRegion) {
                            setRegionName(userRegion)
                        }
                        regionHasChanged(userRegion)
                        store.DataLoadingOFF()
                    })
            }
            if (store.roles.includes("Admin")) {
                AdminService.getTraining()
                    .then((value) => {
                        setFormats(value.data.formats)
                        //setRegions(value.data.regions);
                        //setOrganisators(value.data.organisators);
                        setAllEvents(value.data.events)
                        let dataEvents = value.data.events.map(
                            (item: any) => item
                        )
                        setCalendarEvents(TransCalendarEvents(dataEvents))
                        setOtherTrainingList(
                            value.data.events
                                .map((item: any) => item)
                                .filter(
                                    (item: any) =>
                                        item.startDate &&
                                        currentDate ==
                                            getParsedDate(
                                                item.startDate,
                                                true,
                                                false
                                            )
                                )
                        )
                    })
                    .catch(() => {
                        store.ErrorON()
                    })
                    .finally(() => {
                        updateDropdown(
                            heute.getMonth() + 1,
                            heute.getFullYear()
                        )
                        if(userRegion) {
                            setRegionName(userRegion)
                        }
                        regionHasChanged(userRegion)
                        store.DataLoadingOFF()
                    })
            }
        }
    }, [loadedTrainingData])

    const compareObjectKeys = (comparator: any, item: any) => {
        return (
            (filterCalendar.regionName === "" || item.regionName === filterCalendar.regionName) &&
            (filterCalendar.format === "" || item.format === filterCalendar.format) &&
            (filterCalendar.organisator === "" || item.organisator === filterCalendar.organisator)
        )
    }

    useEffect(() => {
        getCalendarEvents()
    }, [filterCalendar])

    const getCalendarEvents = () => {
        let arrEvents = allEvents.map((item) => item)
        let arrOtherEvents = allEvents.map((item) => item)
        if (
            filterCalendar.regionName !== "" ||
            filterCalendar.format !== "" ||
            filterCalendar.organisator !== ""
        ) {
            setCalendarEvents(
                TransCalendarEvents(
                    arrEvents.filter((item) => {
                        return compareObjectKeys(filterCalendar, item)
                    })
                )
            )

            setOtherTrainingList(
                arrOtherEvents
                    .filter(
                        (item) =>
                            item.startDate &&
                            currentDate ==
                            getParsedDate(item.startDate, true, false)
                    )
                    .filter((item) => {
                        return compareObjectKeys(filterCalendar, item)
                    })
            )
        } else {
            setCalendarEvents(TransCalendarEvents(arrEvents))
            getOtherEvents(currentDate)
        }
    }

    const getOtherEvents = (date: any) => {
        setCurrentDate(date)
        let arrEvents = allEvents.map((item) => item)
        if (
            filterCalendar.regionName !== "" ||
            filterCalendar.format !== "" ||
            filterCalendar.organisator !== ""
        ) {
            setOtherTrainingList(
                arrEvents
                    .filter(
                        (item) =>
                            item.startDate &&
                            date == getParsedDate(item.startDate, true, false)
                    )
                    .filter((item) => {
                        return compareObjectKeys(filterCalendar, item)
                    })
            )
        } else {
            console.log(
                arrEvents.filter(
                    (item) =>
                        item.startDate &&
                        date == getParsedDate(item.startDate, true, false)
                )
            )
            setOtherTrainingList(
                arrEvents.filter(
                    (item) =>
                        item.startDate &&
                        date == getParsedDate(item.startDate, true, false)
                )
            )
        }
    }
    useEffect(() => {
        let newArrayRegions: IDropdownOption[] = [
            { key: "-1", name: "Вся Россия" },
        ]
        regions.map((item) => {
            newArrayRegions.push({ key: item.name, name: item.name })
        })
        setArrayRegions(newArrayRegions)
    }, [regions])

    const ArrayFormats: IDropdownOption[] = [
        { key: "-1", name: "Любой формат" },
    ]
    formats.map((item) => {
        ArrayFormats.push({ key: item, name: item })
    })
    useEffect(() => {
        let newArrayOrganisators: IDropdownOption[] = [
            { key: "-1", name: "Любой организатор" },
        ]
        organisators.map((item) => {
            newArrayOrganisators.push({ key: item, name: item })
        })
        setArrayOrganisators(newArrayOrganisators)
    }, [organisators])

    useEffect(() => {
        let today = new Date(Date.now())
        updateDropdown(today.getMonth() + 1, today.getFullYear())
    }, [store.isDataLoading])

    const updateDropdown = (month: number, year: number, newFilter?: any) => {
        setThisMonth(month)
        setThisYear(year)
        //setOtherTrainingList([])
        let neededRegions: string[] = []
        let neededFormats: string[] = []
        let neededOrganisators: string[] = []
        let filtOrg = filterCalendar.organisator
        let filtReg = filterCalendar.regionName
        let filtFormat = filterCalendar.format
        if (newFilter) {
            filtOrg = newFilter.organisator
            filtReg = newFilter.regionName
            filtFormat = newFilter.format
        }
        allEvents.map((event) => {
            let mAy = getParsedDateMonthAndYear(
                event.startDate ? event.startDate : ""
            )
            if (mAy[0] === month && mAy[1] === year) {
                if (event.regionName) {
                    if (filtOrg === "" || filtOrg === event.organisator) {
                        if (
                            !neededRegions.some((reg) => {
                                return reg === event.regionName
                            })
                        ) {
                            neededRegions.push(event.regionName)
                        }
                    }
                }
                if (event.organisator) {
                    if (
                        (filtReg === "" || filtReg === event.regionName) &&
                        (filtFormat === "" || filtFormat === event.format)
                    ) {
                        if (
                            !neededOrganisators.some((org) => {
                                return org === event.organisator
                            })
                        ) {
                            neededOrganisators.push(event.organisator)
                        }
                    }
                }
                if (event.format) {
                    if (filtOrg === "" || filtOrg === event.organisator) {
                        if (
                            !neededFormats.some((reg) => {
                                return reg === event.format
                            })
                        ) {
                            neededFormats.push(event.format)
                        }
                    }
                }
            }
        })
        setRegions(
            neededRegions.map((reg) => {
                let newRegion: IRegion = {
                    name: reg,
                    regionId: "",
                    cities: [],
                }
                return newRegion
            })
        )
        setOrganisators(neededOrganisators)
        setFormats(neededFormats)
    }
    const setItemBlocked = (val: boolean, id: string) => {
        setOtherTrainingList((prevState) => {
            let newState = prevState
            newState = newState.map((item) => {
                if (id === item.id) {
                    item.blocked = val
                }
                return item
            })
            return newState
        })
        setAllEvents((prevState) => {
            let newState = prevState
            newState = newState.map((item) => {
                if (id === item.id) {
                    item.blocked = val
                }
                return item
            })
            return newState
        })
        setMyTrainingList((prevState) => {
            let newState = prevState
            newState = newState.map((item) => {
                if (id === item.id) {
                    item.blocked = val
                }
                return item
            })
            return newState
        })
    }
    const regionHasChanged = async (val: string) => {
        console.log(val)
        if (val === "-1") {
            val = ""
        }
        let newFilter = { ...filterCalendar, regionName: val }
        /*if (val !== "") {
            newFilter = { ...newFilter, format: "Очно" }
            setFormatName("Очно")
        }*/
        newFilter = { ...newFilter, organisator: "" }
        setOrganisatorName("Любой организатор")
        console.log(newFilter)
        setFilterCalendar(newFilter)
        if (thisMonth && thisYear) {
            updateDropdown(thisMonth, thisYear, newFilter)
        }
    }

    const formatHasChanged = async (val: string) => {
        if (val === "-1") {
            val = ""
        }
        let newFilter = { ...filterCalendar, format: val }
        /*if (val !== "Очно") {
            newFilter = { ...newFilter, regionName: "" }
            setRegionName("Вся Россия")
            newFilter = { ...newFilter, organisator: "" }
            setOrganisatorName("Любой организатор")
        }*/
        setFilterCalendar(newFilter)
        if (thisMonth && thisYear) {
            updateDropdown(thisMonth, thisYear, newFilter)
        }
    }

    const setAttending = (id: string) => {
        let newtraininglist = [...allEvents]
        newtraininglist.map((item) => {
            if (item.id === id) {
                if (item.attenders) {
                    let newAttenders = [...item.attenders]
                    newAttenders.push({ userId: store.user.id })
                    item.attenders = newAttenders
                } else item.attenders = [{ userId: store.user.id }]
            }
            return item
        })

        setAllEvents(newtraininglist)

        // setOtherTrainingList((prevState)=>{
        //     let newState = prevState
        //     newState.map((item)=>{
        //         if (item.id===id){
        //
        //
        //             if(item.attenders){
        //                 let newAttenders = [...item.attenders]
        //                 newAttenders.push({ userId: store.user.id })
        //                 item.attenders = newAttenders
        //             }
        //             else
        //                 item.attenders = [{userId: store.user.id }]
        //         }
        //         return item
        //     })
        //     return newState
        // })
    }

    const organisatorHasChanged = async (val: string) => {
        if (val === "-1") {
            val = ""
        }
        let newFilter = { ...filterCalendar, organisator: val }
        await setFilterCalendar(newFilter)

        if (thisMonth && thisYear) {
            updateDropdown(thisMonth, thisYear, newFilter)
        }
    }

    //   useEffect(()=>{
    //     setRegionName()
    //   },[currentDate])

    return (
        <>
            {!store.isError && !store.isDataLoading && (
                <div className="training-wrapper">
                    {popUpDisplay && PopupData ? PopupData : <></>}

                    <div className="training-container">
                        <img
                            className={"training-navigateBack"}
                            onClick={() => navigate(-1)}
                            src={"/Pictures/strelochka.svg"}
                        />
                        {/*<CustomNavigateHeader title={"Обучение"} withTrash={false}/>*/}

                        <div className="training-dropdowns">
                            <CustomDropdown
                                icon={true}
                                options={ArrayRegions}
                                value={regionName}
                                dropdownSize={"large"}
                                valueSetter={setRegionName}
                                styleDropdownDisplay={" training-dropDown"}
                                styleDropdownList1={" training-dropDown-list"}
                                styleDropdownList2={" training-dropDown-list2"}
                                onChangeHandler={regionHasChanged}
                            />
                            <CustomDropdown
                                styleDropdownDisplay={" training-dropDown"}
                                styleDropdownList1={" training-dropDown-list"}
                                styleDropdownList2={" training-dropDown-list2"}
                                icon={true}
                                dropdownSize={"large"}
                                options={ArrayFormats}
                                value={formatName}
                                valueSetter={setFormatName}
                                onChangeHandler={formatHasChanged}
                            />

                            <CustomDropdown
                                styleDropdownDisplay={" training-dropDown"}
                                styleDropdownList1={" training-dropDown-list"}
                                styleDropdownList2={" training-dropDown-list2"}
                                icon={true}
                                dropdownSize={"large"}
                                options={ArrayOrganisators}
                                value={organisatorName}
                                valueSetter={setOrganisatorName}
                                onChangeHandler={organisatorHasChanged}
                            />
                        </div>

                        <div className="training-calendar">
                            {calendarEvents && (
                                <Calendar
                                    calendarEvent={calendarEvents}
                                    itemEventClick={getOtherEvents}
                                    updateDropdown={updateDropdown}
                                    itemClick={() => setOtherTrainingList([])}
                                />
                            )}
                        </div>

                        <div className="training-footer">
                            <div className="training-footer-btn">
                                {store.roles[0] === "Dealer" && (
                                    <Link to={SpaRoutes.ADDTRAINING + "/0"}>
                                        Добавить обучение
                                    </Link>
                                )}
                            </div>

                            {otherTrainingList[0] && (
                                <>
                                    <div className="training-header">
                                        <p>
                                            {" "}
                                            События на{" "}
                                            {getParsedDate(
                                                otherTrainingList[0].startDate
                                                    ? otherTrainingList[0]
                                                          .startDate
                                                    : "",
                                                true,
                                                false
                                            )}
                                        </p>
                                    </div>
                                    {otherTrainingList.map((item: any) => (
                                        <TrainingCart
                                            key={item.id}
                                            id={item.id}
                                            title={item.title}
                                            cityName={item.cityName}
                                            blocked={item.blocked}
                                            setBlocked={setItemBlocked}
                                            description={item.description}
                                            startDate={item.startDate}
                                            endDate={item.endDate}
                                            imageUrl={item.imageUrl}
                                            organisator={item.organisator}
                                            organisatorId={item.organisatorId}
                                            editable={item.editable}
                                            attending={item.attenders?.some(
                                                (att: any) => {
                                                    return (
                                                        att.userId ===
                                                        store.user.id
                                                    )
                                                }
                                            )}
                                            setAttending={setAttending}
                                            openPopUpAttenders={() => {
                                                openPopUp(
                                                    otherTrainingList,
                                                    item.id
                                                )
                                                setPopupData(
                                                    <PopUpTrainingAttenders
                                                        attenders={
                                                            item.attenders
                                                        }
                                                        closePopUp={closePopUp}
                                                        organizatorId={
                                                            item.organisatorId
                                                        }
                                                    />
                                                )
                                            }}
                                            openPopUp={() => {
                                                openPopUp(
                                                    otherTrainingList,
                                                    item.id
                                                )
                                                setPopupData(
                                                    <PopUpTrainingCart
                                                        id={
                                                            item.id
                                                                ? item.id
                                                                : ""
                                                        }
                                                        title={
                                                            item.title
                                                                ? item.title
                                                                : ""
                                                        }
                                                        blocked={
                                                            item.blocked
                                                                ? item.blocked
                                                                : false
                                                        }
                                                        cityName={
                                                            item.cityName
                                                                ? item.cityName
                                                                : ""
                                                        }
                                                        startDate={
                                                            item.startDate
                                                                ? item.startDate
                                                                : ""
                                                        }
                                                        endDate={
                                                            item.endDate
                                                                ? item.endDate
                                                                : ""
                                                        }
                                                        description={
                                                            item.description
                                                                ? item.description
                                                                : ""
                                                        }
                                                        imageUrl={
                                                            item.imageUrl
                                                                ? item.imageUrl
                                                                : ""
                                                        }
                                                        attending={item.attenders?.some(
                                                            (att: any) => {
                                                                return (
                                                                    att.userId ===
                                                                    store.user
                                                                        .id
                                                                )
                                                            }
                                                        )}
                                                        setAttending={
                                                            setAttending
                                                        }
                                                        setBlocked={
                                                            setItemBlocked
                                                        }
                                                        closePopUp={closePopUp}
                                                        organisator={
                                                            item.organisator
                                                        }
                                                        organisatorId={
                                                            item.organisatorId
                                                        }
                                                        editable={item.editable}
                                                        openPopUpAttenders={() => {
                                                            openPopUp(
                                                                otherTrainingList,
                                                                item.id
                                                            )
                                                            setPopupData(
                                                                <PopUpTrainingAttenders
                                                                    attenders={
                                                                        item.attenders
                                                                    }
                                                                    closePopUp={
                                                                        closePopUp
                                                                    }
                                                                    organizatorId={
                                                                        item.organisatorId
                                                                    }
                                                                />
                                                            )
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    ))}
                                </>
                            )}

                            {myTrainingList[0] &&
                                store.roles[0] === "Dealer" && (
                                    <>
                                        <div className="training-header">
                                            <p>Предстоящие события</p>
                                        </div>
                                        {myTrainingList.map(
                                            (item: any, index) => (
                                                <TrainingCart
                                                    id={item.id}
                                                    key={index}
                                                    title={item.title}
                                                    cityName={item.cityName}
                                                    blocked={item.blocked}
                                                    setBlocked={setItemBlocked}
                                                    description={
                                                        item.description
                                                    }
                                                    startDate={item.startDate}
                                                    endDate={item.endDate}
                                                    imageUrl={item.imageUrl}
                                                    organisator={
                                                        item.organisator
                                                    }
                                                    editable={item.editable}
                                                    onUpdateData={() => {
                                                        setMyTrainingList([])
                                                        setLoadedTrainingData(
                                                            false
                                                        )
                                                    }}
                                                    openPopUp={() => {
                                                        openPopUp(
                                                            otherTrainingList,
                                                            item.id
                                                        )
                                                        setPopupData(
                                                            <PopUpTrainingCart
                                                                id={
                                                                    item.id
                                                                        ? item.id
                                                                        : ""
                                                                }
                                                                title={
                                                                    item.title
                                                                        ? item.title
                                                                        : ""
                                                                }
                                                                blocked={
                                                                    item.blocked
                                                                        ? item.blocked
                                                                        : false
                                                                }
                                                                cityName={
                                                                    item.cityName
                                                                        ? item.cityName
                                                                        : ""
                                                                }
                                                                startDate={
                                                                    item.startDate
                                                                        ? item.startDate
                                                                        : ""
                                                                }
                                                                endDate={
                                                                    item.endDate
                                                                        ? item.endDate
                                                                        : ""
                                                                }
                                                                description={
                                                                    item.description
                                                                        ? item.description
                                                                        : ""
                                                                }
                                                                imageUrl={
                                                                    item.imageUrl
                                                                        ? item.imageUrl
                                                                        : ""
                                                                }
                                                                attending={item.attenders?.some(
                                                                    (
                                                                        att: any
                                                                    ) => {
                                                                        return (
                                                                            att.userId ===
                                                                            store
                                                                                .user
                                                                                .id
                                                                        )
                                                                    }
                                                                )}
                                                                setAttending={
                                                                    setAttending
                                                                }
                                                                setBlocked={
                                                                    setItemBlocked
                                                                }
                                                                closePopUp={
                                                                    closePopUp
                                                                }
                                                                organisator={
                                                                    item.organisator
                                                                }
                                                                organisatorId={
                                                                    item.organisatorId
                                                                }
                                                                editable={
                                                                    item.editable
                                                                }
                                                                openPopUpAttenders={() => {
                                                                    openPopUp(
                                                                        otherTrainingList,
                                                                        item.id
                                                                    )
                                                                    setPopupData(
                                                                        <PopUpTrainingAttenders
                                                                            attenders={
                                                                                item.attenders
                                                                            }
                                                                            closePopUp={
                                                                                closePopUp
                                                                            }
                                                                            organizatorId={
                                                                                item.organisatorId
                                                                            }
                                                                        />
                                                                    )
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                    openPopUpAttenders={() => {
                                                        openPopUp(
                                                            otherTrainingList,
                                                            item.id
                                                        )
                                                        setPopupData(
                                                            <PopUpTrainingAttenders
                                                                attenders={
                                                                    item.attenders
                                                                }
                                                                closePopUp={
                                                                    closePopUp
                                                                }
                                                                organizatorId={
                                                                    item.organisatorId
                                                                }
                                                            />
                                                        )
                                                    }}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default observer(Training)
