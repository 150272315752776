import {AxiosResponse} from "axios";
import {AuthResponse} from "../models/response/AuthResponse";
import $api from "../api/axios";
import {IUserInfo} from "../models/response/UserInfoResponse";
const {ApiRoutes: { Authenticate }} = require("../Routes/apiRoutes");


export default class AuthenticateService {
    //Login function
    static async login(email:string, password:string):Promise<AxiosResponse<AuthResponse>>{
        return $api.post<AuthResponse>(Authenticate.LOGIN, {email, password})
    }
    //Registration

    static async register(userinfo: any, URL: string) {

        return $api.post(URL, userinfo, { headers: { "Content-Type": "multipart/form-data" } })
    }
    //Logout
    static async logout():Promise<void>{
        return $api.post(Authenticate.LOGOUT)
    }

    static async refreshToken(_withCredentials: boolean):Promise<AxiosResponse<AuthResponse>>{
        return await $api.get<AuthResponse>(Authenticate.REFRESH_TOKEN,
            {withCredentials:_withCredentials})
    }

    static async userInfo():Promise<AxiosResponse<IUserInfo>>{
        return await $api.get(Authenticate.USER_INFO);
    }

    static async activateEmail(userId: string, code: string) {
        return await $api.post(Authenticate.ACTIVATE_EMAIL, {Info1:userId,Info2:code})
    }
    static async sendLinkForMail(email: string,confimLink:string) {
        return await $api.post(Authenticate.RESEND_EMAIL_ACTIVATION, {Info1:email,Info2:confimLink})
    }
    static async sendLinkForParol(email: string,confimLink:string) {
        return await $api.post(Authenticate.RESEND_PAROL_ACTIVATION, {Info1:email,Info2:confimLink})
    }
    static async changeParol(userId: string, code: string,parol:string) {
        return await $api.post(Authenticate.CHANGE_PAROL, {Info1:userId,Info2:code,Info3:parol})
    }
}