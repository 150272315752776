import {Link} from "react-router-dom";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import React, {useContext} from "react";
import {Context} from "../../../../index";
import {IChatUser} from "../../../../models/Chat/Chat";
import './ChatWindowItem.css'

interface Interface {
    counter:number;
    onClick?:()=>void;
    user:IChatUser;
    type:number;
    infoId?:string;
    lastMessage?: {message:string};
    role:string;
    focus:boolean;
    infoText?:string;
    unread:number;
    unreadTotal:number;
    style?:any;
}

function ChatWindowItem(props: Interface) {
    const { store } = useContext(Context)

    const SwitchMessage = () =>{
        switch (props.type) {
            case 0:
                return 'Чат с Администратором'
            case 1:
                return ''
            case 2:
                return props.infoText?props.infoText:''
            case 3:
                return `${props.infoText}, Заказ№${props.infoText}`
            case 4:
                return `Заказ№${props.infoId}`
            case 5:
                return props.infoText?props.infoText:''
        }
    }
    let SmallMessage = SwitchMessage()

    const ChatWindowItemComponent = ( {focus, onClick, user, unread}: Interface) => {
        async function onClickHandler() {
            if (props.unreadTotal - props.counter === 0) {
                await store.updateUnread(false);
            }
            onClick && onClick()
        }

        let sampleStyle = {...props.style}
            if(window.location.href.includes(props.user.id))
                Object.assign(sampleStyle,{backgroundColor:"#ECECEC",position:"relative"})

        return (
            <div style={sampleStyle} className={"chat-card-unread"} onClick={onClickHandler}>
                <div className="chat-image">
                    {!user.logo ? (
                        <img className={'chat-profile-img'} src={user.displayedName.length%2==0?"/Pictures/DefaultAvatar1.svg":"/Pictures/DefaultAvatar2.svg"} alt="User Logo"/>
                    ) : (
                        <img className={'chat-profile-img'} src={store.imgServer + user.logo} alt="User Logo"/>
                    )}
                </div>
                <div style={{width:"60%"}}>
                    <h2 className="chat-name">{user.displayedName}</h2>
                    {/*<span className="chat-message">
                        {SmallMessage}
                        {`${String(props.lastMessage?.message).split(' ').slice(0, 5).join(' ')}...`}}
                    </span>*/}
                </div>
                {(unread!=0&&unread)&&
                    <div
                        className={"chat-unread-messages"}
                    >
                        {unread<=9?unread:"9+"}
                    </div>}
                {(window.location.href.includes(props.user.id))&&<img style={{position:"absolute",right:"20px",width:"20px",height:"20px"}} src={'/Pictures/Arrow-white.svg'}/>}
            </div>
        );
    };

    return (
        <div className={'chat-window-item-div'}>
            {props.role === "Dealer" ? (
                <ChatWindowItemComponent {...props} />
            ) : (
                <Link to={`${SpaRoutes.CHAT_DIALOG_PAGE}/:${props.user.id}/:${props.type}/:${props.infoId}`}>
                    <ChatWindowItemComponent {...props}/>
                </Link>
            )}
        </div>
    );
}

export default ChatWindowItem;
