import React, {useContext, useEffect, useState} from "react";

import AdminService from "../../../../services/AdminService";
import CustomTable, {IRowElement, ITable} from "../../../customUI/CustomTable/CustomTable";
import "../../Supplier/CardsPaymentHistory/SupplierCardsHistory.css";
import "./AllSuppliersCardsPayHistory.css";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import {Transition} from "react-transition-group";
import {calculateDateDifference, getParsedDate, removeDuplicates2} from "../../../../functions/functions";
import {ToogleVisible} from "../../../customUI/Searchbar/functions";


interface FirstResponseFor116{
    totalBonuses: number;
    listOfDates: ListOfDates[];
}
interface ListOfDates{
    date: string;
    listOfSupplierDropPoints: ListOfSupplierDropPoints[];
}
interface ListOfSupplierDropPoints{
    adressOfDropPoint: string;
    dropPointId: string;
    totalMoney:number;
    amount: number;
}


//First controller for task_118.
//Sends only list of dates and list of suppliers
interface ListOfDates118{
    date: string;
    suppliersCount:number;
    listOfSupplier:ListOfSuppliers118[];
}
interface ListOfSuppliers118{
    userName:string;
    userId:string;
    amountOfDropPoints:number;
    cardCount: number;
    totalPrice: number;
}

interface TableWithShowModes{
    supplierShowMode: boolean[];
    main:ITable;
}



interface ListOfCategories2{
    topCategoryName:string;
    topCategoryId:string;
}
interface ListOfProducts2{
    productId: string;
    title: string;
    price: string;
    amount: string;
    discountPrice: string;
    wasCheap: boolean;
    isActivated: boolean;
    lastActive: string;
    lastDeactive: string;
    bonusPoints: string;
    magazineName: string;
    magazineId: string;
    subCategoryId: string;
    subCategoryName: string;
    topCategoryId: string;
    topCategoryName: string;
}


interface ParsedDataFor2Table{
    topCategoryId: string;
    topCategoryName: string;
    listOfProducts:ListOfProducts2[];
}

interface SupplierInfo {
    name:string;
    supplierId:string;
    totalBonuses:number;
}

export default function AdminCardsHistory(){

    const {store} = useContext(Context);

    //стейт для выбора таблицы (если true, то 2 страница, если false, то первая)
    const [showInfo, setShowInfo] = useState(false);

    //Вспомогательный стейт
    const [nextStep, setNextStep] = useState(false);

    //Вспомогательный стейт
    const [supplierInfoObject, setSupplierInfoObject] = useState<SupplierInfo>({
        name:"",
        supplierId:"",
        totalBonuses:0,
    });

    //стейт для хранения количества бонусов
    const [totalSum,setTotalSum] = useState<number>(0);

    //стейт для хранения значения открытия для каждой строки
    const [showModeForEachRow, setShowModeForEachRow] = useState<boolean[]>([]);

    //стейт для хранения первоначальной информации
    const [dataForAdmin,setDataForAdmin] = useState<ListOfDates118[]>([]);

    //стейт для хранения первоначальной информации
    const [secondDataForAdmin,setSecondDataForAdmin] = useState<FirstResponseFor116>();


    //стейт для хранения данных первой таблицы
    const [FirstTable, setFirstTable] = useState<TableWithShowModes>({
        supplierShowMode:[],
        main:{
            tableId: "InfoTable",
            header: [],
            rows: [],
        }
    });
    const [SecondTable, setSecondTable] = useState<ITable>({
        tableId: "SecondTable",
        header: [],
        rows: [],
    });

    //стейты для хранения информации для второй таблицы
    const [dataForSecondTable,setDataForSecondTable] = useState<ListOfProducts2[]>([]);
    const [parsedDataForSecondTable,setParsedDataForSecondTable] = useState<ParsedDataFor2Table[]>([]);
    const [listCategoriesForSecondTable, setListCategoriesForSecondTable] = useState<ListOfCategories2[]>([]);

    //стейт для хранения данных второй таблицы (для совокупности таблиц)
    const [listOfTables, setListOfTables] = useState<ITable[]>([]);

    const [dateInfo,setDateInfo] = useState<any>(<></>);
    const [supplierInfo,setSupplierInfo] = useState<any>(<></>);

    //функция сортировки дат по времени
    function sortListOfDates(data: ListOfDates118[]): ListOfDates118[] {
        const sortedListOfDates = data.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime(); // Сортируем по возрастанию даты
        });

        return sortedListOfDates;
    }

    const AverageCost = (amount:number,totalPrice:number):number => {
        return totalPrice/amount;
    }

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    //функция для переворота даты
    function convertDateFormat(date:string) {
        const parts = date.split('.'); // Разделяем строку на части по точке
        const day = parts[2]; // День
        const month = parts[1]; // Месяц
        const year = parts[0]; // Год
        return `${day}.${month}.${year}`; // Создаем новую строку с переставленными частями
    }


    //хендлер для открытия второй таблицы для админа
    const HandlerForOpenInfo = async (date:string,dropPointAddress:string,dropPointId:string, supplierId:string) => {
        store.DataLoadingON();
        setDateInfo(
            <div className={"supplier_scph_second_page_description"}>
                <div>Дата:
                    <div className="supplier_scph_second_page_description_date">
                        {convertDateFormat(date)}
                    </div>
                </div>
                <div>Адрес склада:
                    <div className="supplier_scph_second_page_description_date">
                        {dropPointAddress}
                    </div>
                </div>
            </div>)
        await AdminService.postDailyPayInfoSingle(date, dropPointId,supplierId).then((response) => {
            if (response.data.length > 0) {
                let categories: ListOfCategories2[] = [];
                setDataForSecondTable(response.data);
                for (const product of response.data) {
                    categories.push({
                        topCategoryName: product.topCategoryName,
                        topCategoryId: product.topCategoryId,
                    })
                }
                categories = removeDuplicates2(categories);
                setListCategoriesForSecondTable(categories);
            }

        })
            .catch(() => {
                store.ErrorON();
            })
            .finally(() => {
                store.DataLoadingOFF();
                setShowInfo(true)
            })
    }

    //хендлер для закрытия второй таблицы
    const HandlerForCloseInfo = () => {
        setShowInfo(false);
    }

    const HandlerForCloseSupplierInfo = () => {
        setShowSupplierInfo(false);
    }


    //хендлер серчбара
    const changeHandler = (text:string) => {
        setListOfTables((prevState)=>{
                let newState = prevState.map((ele=>{
                    ele.rows = ToogleVisible(text,["Title","ID"],["",""],ele.rows)
                    return ele
                }))
                return newState;
            }
        )
    }

    const SplitTables = () =>{
        return listOfTables.map((tbox, i)=>{
            if (tbox.rows.map((ele)=> ele.visible.every((item)=>item===true)).every((item)=>item===false)){
                return <></>
            }
            return <div className={`supplier_scph_table_container_2 ${i===0?("supplier_scph_table_container_first"):("")}`}>
                <div className={"bdproTab_subHeader supplier_scph_table_header"}>{listCategoriesForSecondTable[i].topCategoryName}</div>
                <CustomTable {...tbox}/>
            </div>
        });
    }

    useEffect(()=>{
        let result:ParsedDataFor2Table[] = [];
        let list:ListOfProducts2[] = [];

        if(listCategoriesForSecondTable && listCategoriesForSecondTable.length>0){
            for(const category of listCategoriesForSecondTable){
                for(const product of dataForSecondTable){
                    if(product.topCategoryId === category.topCategoryId){
                        list.push(product);
                    }
                }
                result.push({
                    topCategoryId: category.topCategoryId,
                    topCategoryName: category.topCategoryName,
                    listOfProducts: list,
                })
                list = [];
            }
        }

        setParsedDataForSecondTable(result);
    },[listCategoriesForSecondTable])

    useEffect(()=>{
        let Tables:ITable[] = [];
        let list:IRowElement[] = [];

        for(let i=0; i < parsedDataForSecondTable.length; i++){
            for(const product of parsedDataForSecondTable[i].listOfProducts){
                let isCheapText:string = `${(100.0 - Number(product.discountPrice) * 100.0/ Number(product.price)).toFixed()} %`;
                list.push({
                    content:{
                        Title:
                            <div data-search-word={product.title} className={"TableLongInfo"}>
                                <div className={"TableLongText"}>{product.title?product.title:"-"}</div>
                            </div>,
                        ID:
                            <div data-search-word={product.productId}>
                                <div>{product.productId?product.productId:"-"}</div>
                            </div>,
                        Price:product.price,
                        Amount:product.amount,
                        Active:product.isActivated?
                            <div>
                                <img alt={"ок"} src={"/Pictures/ok.svg"}/>
                            </div>
                            :<div>
                                <img alt={"not ok"} src={"/Pictures/notok.svg"}/>
                            </div>,
                        // TimeActive:
                        //     <div>
                        //         {calculateDateDifference(product.lastDeactive,product.lastActive)}
                        //     </div>,
                        Discount:product.wasCheap?
                            <div className={"bdproTab_disField"}>{isCheapText}</div>
                            :<div className={"bdproTab_disField"}>
                                <img alt={"not ok"} className={""} src={"/Pictures/notok.svg"}/>
                            </div>,
                    },
                    filter:[],
                    visible:[true],
                })
            }
            Tables.push({
                tableId: "ProductTable" + parsedDataForSecondTable[i].topCategoryId,
                header: ["Продукт", "ID", "Цена", "Количество", "Активность",
                        //"Был активен",
                        "Скидка"],
                rows: list,
            })
            list = [];

        }
        setListOfTables(Tables);
    },[parsedDataForSecondTable])






    // ----------- Для таблицы со складами поставщика --------------------------------------------- //


    const ParseDataForOneSupplier = (data:FirstResponseFor116, supplierId:string) => {
        let sumSpentPerDay:number = 0;
        let counter:number = 0;
        let rowsToAdd: IRowElement[] = [];


        for(let i=0; i< data.listOfDates[0].listOfSupplierDropPoints.length; i++){
            counter++;
            let ReversedParsedDate = getParsedDate(data.listOfDates[0].date, false, false, true);
            rowsToAdd.push(
                {
                    content: {
                        placeholder:<div
                            className={"TableLongInfo"}
                            onClick={data.listOfDates[0].listOfSupplierDropPoints[i].amount > 0?
                                (()=>{HandlerForOpenInfo(ReversedParsedDate,data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint,data.listOfDates[0].listOfSupplierDropPoints[i].dropPointId,supplierId)}):
                                (()=>{})}>
                            <div className={"TableLongText"}>{`Склад ${counter}`}</div>
                        </div>,
                        amount:
                            <div
                                onClick={data.listOfDates[0].listOfSupplierDropPoints[i].amount > 0?
                                    (()=>{HandlerForOpenInfo(ReversedParsedDate,data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint,data.listOfDates[0].listOfSupplierDropPoints[i].dropPointId,supplierId)}):
                                    (()=>{})}
                            >
                                {data.listOfDates[0].listOfSupplierDropPoints[i].amount?data.listOfDates[0].listOfSupplierDropPoints[i].amount:0}
                            </div>,
                        price: <div onClick={data.listOfDates[0].listOfSupplierDropPoints[i].amount > 0?
                            (()=>{HandlerForOpenInfo(ReversedParsedDate,data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint,data.listOfDates[0].listOfSupplierDropPoints[i].dropPointId,supplierId)}):
                            (()=>{})}>
                            {`${data.listOfDates[0].listOfSupplierDropPoints[i].amount>0?
                                AverageCost(data.listOfDates[0].listOfSupplierDropPoints[i].amount,data.listOfDates[0].listOfSupplierDropPoints[i].totalMoney).toFixed(2):
                                "0"} б.`}
                        </div>,
                        total: <div onClick={data.listOfDates[0].listOfSupplierDropPoints[i].amount > 0?
                            (()=>{HandlerForOpenInfo(ReversedParsedDate,data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint,data.listOfDates[0].listOfSupplierDropPoints[i].dropPointId,supplierId)}):
                            (()=>{})}>
                            {`${data.listOfDates[0].listOfSupplierDropPoints[i].totalMoney?data.listOfDates[0].listOfSupplierDropPoints[i].totalMoney.toFixed(2):"0"} б.`}
                        </div>,
                        address: <div className={"TableLongInfo"}
                                      onClick={data.listOfDates[0].listOfSupplierDropPoints[i].amount > 0?
                                          (()=>{HandlerForOpenInfo(ReversedParsedDate,data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint,data.listOfDates[0].listOfSupplierDropPoints[i].dropPointId,supplierId)}):
                                          (()=>{})}>
                            <div className={"TableLongText"}>{data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint?data.listOfDates[0].listOfSupplierDropPoints[i].adressOfDropPoint:"-"}</div>
                        </div>,
                    },
                    filter: [],
                    visible: [true]
                }
            )
            sumSpentPerDay += data.listOfDates[0].listOfSupplierDropPoints[i].totalMoney;
        }
        setTotalSum(sumSpentPerDay)

        return rowsToAdd;
    }

    useEffect(()=>{
        if(secondDataForAdmin && supplierInfoObject.supplierId !== ""){
            let list:IRowElement[] = ParseDataForOneSupplier(secondDataForAdmin,supplierInfoObject.supplierId);
            let mySecondTable: ITable = {
                tableId: "SecondTable",
                header: ["Склад","Количество", "Цена за шт.", "Итог", "Адрес"],
                rows: list,
            }
            setSecondTable(mySecondTable);
            store.DataLoadingOFF()
        }
    },[secondDataForAdmin,supplierInfoObject])


    const GetSecondDataForAdmin = (supplierId:string, date:string, supplierName:string) => {
        store.DataLoadingON();
        setSupplierInfo(
            <div className={"supplier_scph_second_page_description"}>
                <div>Дата:
                    <div className="supplier_scph_second_page_description_date">
                        {convertDateFormat(date)}
                    </div>
                </div>
                <div>Поставщик:
                    <div className="supplier_scph_second_page_description_date">
                        {supplierName}
                    </div>
                </div>
            </div>)
        AdminService.postDailyPayInfo(supplierId,date)
            .then((response) => {
                setSecondDataForAdmin(response.data);
                setSupplierInfoObject({
                    name:supplierName,
                    totalBonuses: response.data.totalBonuses,
                    supplierId:supplierId,
                });
            })
            .catch(() => {
                store.ErrorON();
            })
            .finally(() => {
                store.DataLoadingOFF();
                setShowSupplierInfo(true);
            })
    }





    // ---------------------------- Для таблицы с датами ------------------------------------- //

    //хендлер для открытия и закрытия строк дат
    const HandlerForSupplierUnderRows = (date:string, index:number) => {
        setFirstTable((actual:TableWithShowModes)=>{
            let newState = {...actual};
            let foundDate:boolean = false;

            newState.supplierShowMode[index] = !newState.supplierShowMode[index];

            newState.main.rows = newState.main.rows.map(obj => {
                if(Object.hasOwn(obj.content, 'date')){
                    if (obj.content.date.props.children[0] === date){
                        foundDate = true;
                        return obj;
                    }
                    else foundDate = false;
                }
                else if (foundDate && obj.visible[0] !== newState.supplierShowMode[index]) {
                    obj.visible[0] = newState.supplierShowMode[index];
                }
                return obj;
            });
            foundDate = false;

            return newState;
        });
    }

    //парсинг данных с контроллера (растасовка для поставщика)
    function ParseDataForOneDate(data:ListOfDates118[]):IRowElement[]{

        let list: IRowElement[] = [];
        let ShowModeList:boolean[] = [];
        let totalCountsList:any[] = [];

        let dailyCardsCount:number = 0;
        let dailyPrice:number = 0;

        for(let i=0; i<data.length; i++){
            for(let j=0; j<data[i].listOfSupplier.length; j++){
                dailyCardsCount += data[i].listOfSupplier[j].cardCount;
                dailyPrice += data[i].listOfSupplier[j].totalPrice;
            }
            totalCountsList.push({
                cards: dailyCardsCount,
                total: dailyPrice.toFixed(2),
                mean: dailyCardsCount>0?(dailyPrice/dailyCardsCount).toFixed(2):0,
            })
            dailyPrice = 0
            dailyCardsCount = 0
        }

        for(let i=0; i<data.length; i++){
            ShowModeList.push(false);
            let date:string = convertDateFormat(data[i].date);
            list.push(
                {
                    content: {
                        date:
                            <div
                                style={{position:"relative"}}
                                className={"supplier_scph_drop_img_container"}
                                onClick={data[i].suppliersCount>0?(()=>{HandlerForSupplierUnderRows(date, i)}):(()=>{})}
                            >{data[i].date?date:"00.00.0000"}
                                {data[i].suppliersCount>0 &&
                                    <>
                                        <input
                                            id={data[i].date}
                                            type="checkbox"
                                            className={"supplier_scph_drop_img_checkbox"}
                                        />
                                        <img
                                            alt={"open"}
                                            src={"/Pictures/dropDownIcon.svg"}
                                            className={"supplier_scph_drop_img admin_scph_img_container"}>
                                        </img>
                                    </>}
                            </div>,
                        amount: <div>{totalCountsList[i].cards}</div>,
                        price: <div>{totalCountsList[i].mean}</div>,
                        total: <div>{totalCountsList[i].total}</div>,
                        suppliers:
                            <div>{data[i].suppliersCount>0?"Все":"Пусто"}</div>,
                    },
                    filter: [],
                    visible: [true]
                }
            )
            if(Object.hasOwn(data[i], 'listOfSupplier')){
                for(let j=0; j<data[i].listOfSupplier.length; j++){
                    if(data[i].listOfSupplier[j].amountOfDropPoints > 0){
                        list.push(
                            {
                                content:{
                                    nameOfSupplier:
                                        <div onClick={()=>{GetSecondDataForAdmin(data[i].listOfSupplier[j].userId,data[i].date,data[i].listOfSupplier[j].userName)}}
                                            className={"TableLongInfo"}>
                                            <div className={"TableLongText"}>
                                                {data[i].listOfSupplier[j].userName?data[i].listOfSupplier[j].userName:"-"}
                                            </div>
                                        </div>,
                                    amount: <div onClick={()=>{GetSecondDataForAdmin(data[i].listOfSupplier[j].userId,data[i].date,data[i].listOfSupplier[j].userName)}}>
                                        {data[i].listOfSupplier[j].cardCount}</div>,
                                    price: <div onClick={()=>{GetSecondDataForAdmin(data[i].listOfSupplier[j].userId,data[i].date,data[i].listOfSupplier[j].userName)}}>
                                        {data[i].listOfSupplier[j].amountOfDropPoints > 0 ? (data[i].listOfSupplier[j].cardCount > 0 ? (data[i].listOfSupplier[j].totalPrice/data[i].listOfSupplier[j].cardCount).toFixed(2) : "0") :"-"}</div>,
                                    total: <div onClick={()=>{GetSecondDataForAdmin(data[i].listOfSupplier[j].userId,data[i].date,data[i].listOfSupplier[j].userName)}}>
                                        {data[i].listOfSupplier[j].amountOfDropPoints > 0 ?(data[i].listOfSupplier[j].totalPrice).toFixed(2):"-"}</div>,
                                    dropPoints:(data[i].listOfSupplier[j].amountOfDropPoints >0 ?
                                        <div
                                            style={{position:"relative"}}
                                            className={"supplier_scph_drop_img_container"}
                                            onClick={()=>{GetSecondDataForAdmin(data[i].listOfSupplier[j].userId,data[i].date,data[i].listOfSupplier[j].userName)}}
                                        >

                                            <input
                                                id={(i*data[i].listOfSupplier.length + j + 1).toString()}
                                                name={data[i].date + data[i].listOfSupplier[j].userName}
                                                type="checkbox"
                                                className={"supplier_scph_drop_img_checkbox"}
                                            />
                                            <img
                                                alt={"open"}
                                                src={"/Pictures/dropDownIcon.svg"}
                                                className={"supplier_scph_drop_img"}>
                                            </img>
                                        </div>: <div>-</div>),
                                },
                                filter: [],
                                visible: [false]
                            }
                        )
                    }
                }
            }
        }
        setShowModeForEachRow(ShowModeList);
        return list;
    }

    useEffect(()=>{
        if(dataForAdmin){
            let list = ParseDataForOneDate(sortListOfDates(dataForAdmin));
            let mytable: TableWithShowModes = {
                supplierShowMode: showModeForEachRow,
                main:{
                    tableId: "SuppliersCardsTable",
                    header: ["Число","Количество", "Цена за шт.", "Итог", "Склады"],
                    rows: list
                }
            }
            setFirstTable(mytable);
        }
        if (nextStep === true) store.DataLoadingOFF();

    },[dataForAdmin,nextStep])

    const GetFirstDataForAdmin = () => {
        store.DataLoadingON();
        AdminService.getDailyPayDates()
            .then((response)=>{
                setDataForAdmin(sortListOfDates(response.data));
                setNextStep(true);
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    // ----------- Конец растасовки данных для админа ---------------------------------------------- //





    //основной парсинг данных (связь с сервером, Первоначальные запросы)
    useEffect(() => {
        GetFirstDataForAdmin();
    }, [])


    const [showSupplierInfo, setShowSupplierInfo] = useState<boolean>(false);

    return (
        <>{
            ((!store.isError && !store.isDataLoading) &&
                    <div className="admin_scph_main_container">
                        <div className={"supplier_scph_main"}>{
                            (FirstTable.main.rows.length > 0) ?
                                (
                                    <div className={"supplier_scph_first_page"}>
                                        <CustomNavigateHeader title={"Оплата за размещение карточек"} withTrash={false}/>
                                        <div className="supplier_scph_first_page_table">
                                            <CustomTable {...FirstTable.main}/>
                                        </div>
                                        <Transition
                                            in={showSupplierInfo}
                                            timeout={500}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            {state => <div className={`supplier_scph_second_page_container ${state}`}>
                                                <img
                                                    src="/Pictures/Закрыть_календарь.svg"
                                                    alt="close"
                                                    onClick={HandlerForCloseSupplierInfo}
                                                />
                                                <div className="catalog-navigate-header">
                                                    <div id={"supplier-nh-title-div"}>
                                                        <p className={"supplier-nh-title"}>Данные товаров поставщика</p>
                                                    </div>
                                                </div>
                                                <div>{supplierInfo}</div>
                                                {SecondTable.rows.length > 0 &&
                                                    <div className="supplier_scph_table_container_2 admin_scph_table_container_2">
                                                        <CustomTable {...SecondTable}/>
                                                    </div>}
                                                <div className="admin_scph_dop_supplier_info">

                                                </div>
                                                <div className={"supplier_scph_second_page_description"}>
                                                    <div>Всего потрачено:
                                                        <div className="supplier_scph_second_page_description_date">
                                                            {`${totalSum.toFixed(2)} б.`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="supplier_scph_balance">
                                                    <div className="supplier_scph_balance_title">
                                                        Остаток поставщика:
                                                    </div>
                                                    <div className="supplier_scph_total">
                                                        {`${supplierInfoObject.totalBonuses} бонуса(-ов)`}
                                                    </div>
                                                </div>
                                            </div>}
                                        </Transition>
                                        <Transition
                                            in={showInfo}
                                            timeout={500}
                                            mountOnEnter
                                            unmountOnExit
                                        >
                                            {state => <div style={{zIndex:"4"}} className={`supplier_scph_second_page_container ${state}`}
                                            >
                                                <img
                                                    src="/Pictures/Закрыть_календарь.svg"
                                                    alt="close"
                                                    onClick={HandlerForCloseInfo}
                                                />
                                                <div className="catalog-navigate-header">
                                                    <div id={"supplier-nh-title-div"}>
                                                        <p className={"supplier-nh-title"}>{`Данные товаров ${supplierInfoObject.name}`}</p>
                                                    </div>
                                                </div>
                                                <div>{dateInfo}</div>
                                                {(listOfTables.length > 0) ?
                                                    (
                                                        <div className="supplier_scph_second_page">
                                                            <Searchbar
                                                                changeHandler={changeHandler}
                                                                initialText={"Найти товар"}
                                                            />
                                                            {(!listOfTables.every((table)=>(table.rows.every((row)=>(row.visible[0] === false)))))?
                                                                (
                                                                    <>
                                                                        {SplitTables()}
                                                                    </>
                                                                ):
                                                                (
                                                                    <div className="supplier_scph_empty_search">
                                                                        Такого товара не обнаружено
                                                                    </div>
                                                                )}

                                                        </div>
                                                    ):
                                                    (
                                                        <div className="supplier_scph_empty">
                                                            Записей нет
                                                        </div>
                                                    )}
                                            </div>}
                                        </Transition>
                                    </div>
                                ):
                                (
                                    <div className={"supplier_scph_first_page"}>
                                        <CustomNavigateHeader title={"Оплата за размещение карточек"} withTrash={false}/>
                                        <div className="supplier_scph_empty">
                                            Записей нет
                                        </div>
                                    </div>
                                )
                        }
                        </div>
                    </div>
            )
        }</>
    )
};