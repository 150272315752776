import React, {useState} from "react";

export function ToogleFilter(filterValues:string[], boolIndex:number, filterAttribute:string ,state:any[]){
    const newState = [...state];

    newState.forEach((item)=>{
        if (filterValues.length === 0 || Object.values(item["filter"]).map((ele:any)=>{return Object.keys(ele)[0]}).indexOf(filterAttribute) === -1)
            item["visible"][boolIndex]=true;
        else
        {
            item["visible"][boolIndex] = false;
            if (filterValues.some((felem)=>felem===Object.values(item["filter"].filter((ele:any)=>{return Object.keys(ele)[0] === filterAttribute})[0])[0])){
                item["visible"][boolIndex] = true;
            }
        }
    })
    return newState;
}