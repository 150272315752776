import {Link, useParams} from "react-router-dom"
import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {useEffect} from "react";

interface Interface {
    isComingSoon?:boolean
}

const Missing = ({isComingSoon = false}:Interface) => {
    const id = useParams()
    let title:string

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    if (isComingSoon)
        title = "Страница еще в разработке..."
    else
        title = "К сожалению, такого маршрута нет..."
    return (
        <>
            <CustomNavigateHeader title={""} withTrash={false} trashClickHandler={()=>{}}/>
            <div style={{padding:"30px 33px",textAlign: "center"}}>
                <div style={{fontSize:"24px", fontWeight:"700", lineHeight: "1.3"}}>{title}</div>
                <img style={{marginTop: "33px"}} src={'/Pictures/Gear.svg'}/>
            </div>
        </>
    )
}

export default Missing
