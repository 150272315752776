import React, {useContext} from "react"
import {Context} from "../../../index"
import {ICard, IPreviewCard} from "../../../models/product/productCard"
import "./ProductCard_1.css"
import {Ru} from "../../../functions/functions"
import CabinetBuyerService from "../../../services/CabinetBuyerService"
import {Link} from "react-router-dom"

import {SpaRoutes} from "../../../Routes/spaRoutes"
import {CustomIMG} from "../../customUI/CustomIMG/CustomIMG";

interface ISwiperItemProps {
    item: IPreviewCard
    style?: string,
    link?: boolean
}

function ProductCard_1({item, style, link}: ISwiperItemProps) {

    return (
        <>
            <div className={style ? style : "product_wrap-slider"}>
                <div id="product_and_heart">
                    <Link to={link ? `/ProductPage/:${item.productId}` : ""} style={{cursor: link ? "" : "default"}}>
                        <CustomIMG id="img_of_product" src={item.image}/>
                    </Link>
                </div>

                <div id="description_containter">
                        <span id="description_of_product">
                            {item.name_of_product}{" "}
                        </span>
                </div>

                <div className={"price-div"}>
                    <div className={"productCard-price-amount-container"}>
                        <div className={"productCard-price-amount"}>
                            {item.visibleMaskPrice ?
                                <div style={{maxWidth: 70}}>уточняйте цену</div>
                                :
                                <div className={"productCard-price"}>
                                    <div>
                                        {item.discount
                                            ? Ru(item.price2 ?? 0)
                                            : Ru(item.price1 ?? 0)}
                                    </div>
                                    <div className={"productCard-discount"}>
                                        {item.discount && !item.visibleMaskPrice && Ru(item.price1 ?? 0)}
                                    </div>
                                </div>
                            }

                            {item.visibleMaskAmount ?
                                <div style={{textAlign: "right", maxWidth: 52}}>товар под заказ</div>
                                :
                                <div>{item.amount} шт.</div>
                            }

                        </div>
                    </div>
                </div>
                <div id={"supply-company-div"}>
                    <img
                        id={"productCard-1SConnected-img"}
                        src={`${
                            item.isConnected1S
                                ? "/Pictures/CheckGreen.svg"
                                : "/Pictures/ExclamRed.svg"
                        }`}
                    />
                    <span id="text_supply_company">
                            {item.supply_company}
                        </span>

                    {item.image_supply_company && (
                        <CustomIMG
                            id="img_supply_company"
                            src={item.image_supply_company}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

export default ProductCard_1
