import React, {useContext, useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import HomeService from "../../../services/HomeService";
import {Context} from "../../../index";
import CustomInput from "../../customUI/CustomInput/CustomInput";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import "./EmailConfirm.css"
import {InvalidFeedback} from "../Register/invalidFeedback";
import {IBuyerInfo} from "../../../models/models";
import {Interface} from "readline";
import './../Register/Buyer/buyer.css'
import AuthenticateService from "../../../services/AuthenticateService";

function EmailConfirm() {

  const {store} = useContext(Context)
  const [email, setEmail] = useState("");
  let {userId, code, page} = useParams();


    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
  let initText = ''
  switch (page) {
      case '0':
      {
          initText = 'Пожалуйста, введите почту чтобы активировать ваш аккаунт';
          break;
      }
      case '1':
      {
          initText = 'Подождите. Активируем ваш аккаунт'
          break;
      }
      case '2':
      {
          initText = 'Пожалуйста, введите почту чтобы сбросить ваш пароль'
          break;
      }
      case '3':
      {
          initText = 'Пожалуйста, введите ваш новый пароль'
          break;
      }
  }
    interface init{
        email:string;
        password:string;
        pwd: {hiCh: string, lowCh: string, numbers: string, specialSymbol: string, symbolLengths: string},
        repwd: string,
        confirmLink : string
    }
    let initial:init
    initial= {
        email: "",
        password: "",
        pwd: {hiCh: "", lowCh: "", numbers: "", specialSymbol: "", symbolLengths: ""},
        repwd: "",
        confirmLink : window.location.origin+"/#"+SpaRoutes.EMAIL_CONFIRM
    };

    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [registerError, setRegisterError] = useState<init>(initial)
    const [UserInfo, setUserInfo] = useState<init>(initial)
    const [textMessage, SetTextMessage] = useState(initText)
    const [password_again, setPasswordAgain] = useState<string>('');
    const [visible, SetVisible] = useState(false)
    const [thisPage,SetThisPage] = useState(page)


    useEffect(()=>{
    if (userId&&code&&page==='1'){
        {
            store.DataLoadingON()
            AuthenticateService.activateEmail(userId,code)
                .then((response)=>{
                        SetTextMessage('Ваша почта была успешно подтверждена в приложении "ПроКрасивое". Теперь перейдите на страницу авторизации и введите свои учетные данные')
                })
                .catch((e)=>{
                    SetTextMessage('Что-то пошло не так, попробуйте еще раз')
                    SetVisible(true)
                })
                .finally(()=>{
                    store.DataLoadingOFF()
                })
        }
    }else{
        SetVisible(true)
    }
  },[userId,code,page])

  const resendLink = ()=>{
      let confirmLink = window.location.origin+"/#"+SpaRoutes.EMAIL_CONFIRM
      AuthenticateService.sendLinkForMail(email,confirmLink)
      SetTextMessage('Мы отправили вам письмо активации на указанную почту')
      SetVisible(false)
  }
  const sendPsswordVerificationLink=()=>{
      let confirmLink = window.location.origin+"/#"+SpaRoutes.EMAIL_CONFIRM
      AuthenticateService.sendLinkForParol(email,confirmLink)
      SetTextMessage('Мы отправили вам письмо активации на указанную почту')
      SetVisible(false)
  }

    let errorBool = !(UserInfo.password && password_again&&
        (!registerError.email) &&
        (!registerError.repwd) &&
        (!registerError.pwd.lowCh)&&
        (!registerError.pwd.numbers)&&
        (!registerError.pwd.symbolLengths));

    const changePassword=()=>{
      setRegisterError((prevState)=>{
          const newState = {...prevState}
          if(!UserInfo.password)
              newState.pwd.symbolLengths = 'Неверный ввод!';
          return newState
      })
      if (!errorBool &&userId&&code) {
          //ToDo
          store.DataLoadingON()
          AuthenticateService.changeParol(userId,code,UserInfo.password)
              .then((response)=>{
                  SetTextMessage('Ваш пароль был успешно изменен')
                  SetVisible(false)
              })
              .catch((e)=>{
                  SetTextMessage('Что-то пошло не так, попробуйте еще раз')
                  SetVisible(true)
                  SetThisPage("2")
              })
              .finally(()=>{
                  store.DataLoadingOFF()
              })
      }
  }

    const onSendHandler=()=>{
      switch (page) {
          case '0'||'1':
          {
              resendLink()
              break;
          }
          case '2':
          {
              sendPsswordVerificationLink()
              break;
          }
          case '3':
          {
              changePassword()
              break;
          }
      }
  }

    const handleInputChange = (e: any) => {
        let { name, value } = e.target;
        value = value.toLowerCase().replaceAll(" ","");
        if (name === "email") {
            const reEmail = /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/
            if (!reEmail.test(String(value).toLowerCase())) {
                setRegisterError({...registerError, ['email']:'Введите корректный email'})
            } else {
                setRegisterError({...registerError, ['email']:''})
            }
        }
        if (name === "password") {
            const reNum = /(?=.*[0-9])/
            const reLowCh = /(?=.*[a-zа-яёA-ZА-ЯЁ])/
            const reLen = /[0-9a-zа-яёA-ZА-ЯЁ!@#$%^&*.]{6,}/

            setRegisterError((prevState)=>{
                let newState = {...prevState}
                if (!reNum.test(String(value))) {
                    newState.pwd.numbers = 'Не хватает цифр'
                } else {
                    newState.pwd.numbers = ''
                }
                if (!reLowCh.test(String(value))) {
                    newState.pwd.lowCh = 'Не хватает буквы'
                } else {
                    newState.pwd.lowCh = ''
                }
                if (!reLen.test(String(value))) {
                    newState.pwd.symbolLengths = 'Длина пароля минимум 6 символов'
                } else {
                    newState.pwd.symbolLengths = ''
                }                return newState
            })
        }

        setUserInfo({
            ...UserInfo,
            [name]: value
        });
    };

    const PassAgainHandler = (e: any) => {
        let repwd = e.target.value.toLowerCase().replaceAll(" ","");
        setPasswordAgain(repwd)
        if (repwd != UserInfo.password) {
            setRegisterError({...registerError, repwd:'Пароли должны совпадать'})
        } else {
            setRegisterError({...registerError, repwd:''})
        }
    }


    return (
      <>
          <>
              <div className={"emailConfirm-textblock"}>
                  {textMessage}
              </div>
          </>
        {/*{store.isDataLoading&&*/}

            <>
                {visible&&
                    <>
                        {thisPage!='3'&&<CustomInput
                            name={"mail"}
                            placeHolder={"Email"}
                            value={email}
                            styleInputField={"emailConfirm-inputField"}
                            onChange={(e) => setEmail(e.target.value.toLowerCase().replaceAll(" ",""))}
                            inputSize={"small"}
                        />}
                        {thisPage==='3'&&
                            <>
                                <div className={"row"}>
                                    <input
                                        name={"password"}
                                        className={"register_input"}
                                        type={isRevealPwd ? "text" : "password"}
                                        onChange={(e) => handleInputChange(e)}
                                        value={UserInfo["password"]}
                                        placeholder={"Пароль"}
                                        required
                                    />

                                    <img
                                        className={"eye_img"}
                                        src={isRevealPwd ? "/Pictures/eye2.svg" : "/Pictures/eye.svg"}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                    />
                                </div>
                                <InvalidFeedback title={registerError.pwd.numbers}/>
                                <InvalidFeedback title={registerError.pwd.lowCh}/>
                                <InvalidFeedback title={registerError.pwd.symbolLengths}/>

                                <div className={"register_input_text"}>Подтвердите пароль</div>
                                <div className={"row"}>
                                    <input
                                        name={"password_again"}
                                        className={"register_input"}
                                        type={isRevealPwd ? "text" : "password"}
                                        onChange={(e) => PassAgainHandler(e)}
                                        value={password_again}
                                        placeholder={"Подтвердите пароль"}
                                        required
                                    />

                                    <img
                                        className={"eye_img"}
                                        src={isRevealPwd ? "/Pictures/eye2.svg" : "/Pictures/eye.svg"}
                                        onClick={() => setIsRevealPwd(prevState => !prevState)}
                                    />
                                </div>
                                <InvalidFeedback title={registerError.repwd}/>
                            </>
                        }
                    <div
                        style={{textTransform:'uppercase',cursor:"pointer"}}
                        className={"emailConfirm-sendEmailButton"}
                        onClick={()=>{onSendHandler()}}
                    >
                        Подтвердить
                    </div>
                </>
                }

                    <div style={{display:"flex",justifyContent:"center"}}>
                        <Link className={"emailConfirm-sendEmailButton"} to={SpaRoutes.LOGIN}>Перейти на страницу авторизации</Link>
                        {/*<Link style={{marginLeft:" 20px"}} className={"emailConfirm-sendEmailButton"} to="/">Домой</Link>*/}
                    </div>




            </>


        {/*}*/}
      </>
  );
}
export default EmailConfirm;
