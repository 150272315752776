import React, {useContext} from "react";
import {Context} from "../../../../../index";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";

interface IDeleteOrderProps {
    orderId?:string;
    deleteCardhandler:(level2name:string, productId:string)=>Promise<any>;
    level2name:string;
    productId:string;
}

 const DeleteProductButton = (props:IDeleteOrderProps) => {
        const {store} = useContext(Context);
        const handleDeleteCard = async () => {
            store.showDialog("вы действительно хотите удалить товар?", true).then(()=>{
                if (store.dialog.dialogResult === true)
                    if (props.orderId) {
                        CabinetBuyerService.basketdeleteOrder(props.orderId).then((response)=>{
                            if (response?.status == 200)
                                props.deleteCardhandler(props.level2name, props.productId)
                                    .then(() => {
                                        store.cartNumber()
                                    });
                        });
                    }
            })
        }

    return(
        <img
            onClick={handleDeleteCard}
            className={"trash-img"}
            src={"/Pictures/trash.svg"}
        />
        )
    }

export default DeleteProductButton