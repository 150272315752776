import React, {useContext, useEffect, useState} from 'react';
import "./MainSettings.css"
import {Context} from "../../../../index";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import CustomTable, {IRowElement, ITable} from "../../../customUI/CustomTable/CustomTable";
import AdminService from "../../../../services/AdminService";
import {Link} from "react-router-dom";
import {ICardPricingTable, IMainSettings, IReklamaPricing} from "../../../../models/models";
import {AddPricePopup} from "./AddPricePopup";
import RegionSelector from "../../../customUI/RegionSelector/RegionSelector";
import RegionsSelect from "../../../customUI/RegionsSelect/RegionsSelect";

const MainSettings = () => {
    const {store} = useContext(Context)
    const [settingData, setSettingsData] = useState<IMainSettings>({})
    const [oldSettingData, setOldSettingsData] = useState<IMainSettings>({})
    const [pricesTableData, setPricesTableData] = useState<ICardPricingTable[]>([])
    const [oldPricesTableData, setOldPricesTableData] = useState<ICardPricingTable[]>([])
    const [reklamaPricingData, setReklamaPricingData] = useState<IReklamaPricing>({})
    const [oldReklamaPricingData, setOldReklamaPricingData] = useState<IReklamaPricing>({})
    const [pricesTable, setPricesTable] = useState<ITable>({
        tableId: "PricesTable",
        header: ["Количество", "Цена", "%", ""],
        rows: []
    });

    const [showAddPricePopup, setShowAddPricePopup] = useState(false)
    const [showEditPricePopup, setShowEditPricePopup] = useState(false)
    const [canUpdateBasic, setCanUpdateBasic] = useState(false)
    const [canUpdatePrices, setCanUpdatePrices] = useState(false)
    //const [canUpdateReklama, setCanUpdateReklama] = useState(false)

    const [reklamaGroupOne, setReklamaGroupOne] = useState<IReklamaPricing>({})
    const [reklamaGroupTwo, setReklamaGroupTwo] = useState<IReklamaPricing>({})
    const [reklamaGroupThree, setReklamaGroupThree] = useState<IReklamaPricing>({})

    const [oldReklamaGroupOne, setOldReklamaGroupOne] = useState<IReklamaPricing>({})
    const [oldReklamaGroupTwo, setOldReklamaGroupTwo] = useState<IReklamaPricing>({})
    const [oldReklamaGroupThree, setOldReklamaGroupThree] = useState<IReklamaPricing>({})

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getSettingsData = () => {
        store.DataLoadingON()
        AdminService.getBaseSettingsInfo()
            .then((response) => {
                setSettingsData(response.data)
                setPricesTableData(response.data.cardPricingTable.sort((a: any, b: any) => a.count - b.count))
                setReklamaPricingData(response.data.reklamaPricing)
                setOldSettingsData(response.data)
                setOldPricesTableData(response.data.cardPricingTable)
                setOldReklamaPricingData(response.data.reklamaPricing)
                setReklamaGroupOne(response.data.pricingGroups[0])
                setReklamaGroupTwo(response.data.pricingGroups[1])
                setReklamaGroupThree(response.data.pricingGroups[2])
                setOldReklamaGroupOne(response.data.pricingGroups[0])
                setOldReklamaGroupTwo(response.data.pricingGroups[1])
                setOldReklamaGroupThree(response.data.pricingGroups[2])
            })
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }

    const deleteRowsPricing = (row: number) => {
        let mas = pricesTableData
        mas.splice(row, 1)
        setPricesTableData(mas)
        getPricingTable()
        setCanUpdatePrices(true)
    }

    const getPricingTable = () => {
        setPricesTable({
            tableId: "PricesTable",
            header: [],
            rows: []
        })

        let list: IRowElement[] = [];
        pricesTableData.forEach((prices, index) => {
            list.push(
                {
                    content: {
                        count: <div
                            onClick={() => editPricePopup(index, prices.count, prices.price, prices.percentage)}>{prices.count}</div>,
                        price: <div
                            onClick={() => editPricePopup(index, prices.count, prices.price, prices.percentage)}>{prices.price}</div>,
                        percentage: <div
                            onClick={() => editPricePopup(index, prices.count, prices.price, prices.percentage)}>{index + 1 == pricesTableData.length || index == 0 ? "-" : `${prices.percentage}%`}</div>,
                        delete: index + 1 == pricesTableData.length || index == 0 ? "" :
                            <img className={"admin-mainSetting-pricesTable-delete"}
                                 onClick={() => deleteRowsPricing(index)} src={"/Pictures/Trash-black.svg"}
                                 alt={"delete"}/>
                    },
                    filter: [],
                    visible: [true, true, true]
                }
            )
        })
        let mytable: ITable = {
            tableId: "PricesTable",
            header: ["Количество", "Цена", "%", ""],
            rows: list
        }
        setPricesTable(mytable);
    }

    const addPrice = (count: number, price: number, percentage: number) => {
        let mas = pricesTableData
        mas.push({"id": String(pricesTableData.length + 1), "count": count, "price": price, "percentage": percentage})
        mas.sort((a, b) => a.count - b.count)
        setPricesTableData(mas)
        getPricingTable()
        setCanUpdatePrices(true)
    }
    const editPrice = (index: number, count: number, price: number, percentage: number) => {
        let mas = pricesTableData
        mas[index] = {"id": String(pricesTableData[index].id), "count": count, "price": price, "percentage": percentage}
        mas.sort((a, b) => a.count - b.count)
        setPricesTableData(mas)
        getPricingTable()
        setCanUpdatePrices(true)
    }
    const [EditPopupElement, setEditPopupElement] = useState(<></>)
    const editPricePopup = (index: number, count: number, price: number, percentage: number) => {
        setEditPopupElement(<AddPricePopup
            index={index}
            quantity={count} price={price} percentage={percentage}
            onClose={() => setShowEditPricePopup(false)}
            editData={(index: number, count: number, price: number, percentage: number) => editPrice(index, count, price, percentage)}/>)
        setShowEditPricePopup(true)
    }

    useEffect(() => {
        getPricingTable()
    }, [pricesTableData])

    useEffect(() => {
        getSettingsData()
    }, [])

    const updateBasicSettings = () => {
        let data = {
            "CompanyName": settingData["companyName"],
            "PrivacyText": settingData["privacyText"],
            "RequisitiText": settingData["requisitiText"],
            "AboutUsText": settingData["aboutUsText"],
            "ContactText": settingData["contactText"],
            "HelpNumber": settingData["helpNumber"],
            "CardPricingText": settingData["cardPricingText"]
        }
        AdminService.updateBaseSettings(data)
            .then(() => {
                AdminService.updateBaseSettingsUpdatePriceLPMP({"ListOfProductsForMainPage": settingData["listOfProductsForMainPage"]})
                    .then(() => {
                        alert("Базовые настройки обновлены")
                        setCanUpdateBasic(false)
                        setOldSettingsData(settingData)
                    })
                    .catch((error) => {
                        alert("Что-то пошло не так")
                    })
            })

            .catch((error) => {
                alert("Что-то пошло не так")
            })

    }

    useEffect(() => {
        if (
            settingData["contactText"] !== oldSettingData["contactText"]
            || settingData["listOfProductsForMainPage"] !== oldSettingData["listOfProductsForMainPage"]
            || settingData["requisitiText"] !== oldSettingData["requisitiText"]
            || settingData["helpNumber"] !== oldSettingData["helpNumber"]
            || settingData["cardPricingText"] !== oldSettingData["cardPricingText"]
        )
            setCanUpdateBasic(true)
        else
            setCanUpdateBasic(false)
    }, [settingData, oldSettingData])

    const updatePriceTable = () => {
        let canSend = true
        for (let i = 1; i < pricesTableData.length; ++i) {
            if (pricesTableData[i].count == pricesTableData[i - 1].count) {
                canSend = false
                break;
            }
        }
        if (canSend) {
            AdminService.updateBaseSettingsUpdatePriceTable({
                "CardPricingTable": pricesTableData.map((e: any) => {
                    return {
                        "id": e.id,
                        "count": e.count,
                        "price": e.price,
                        "percentage": e.percentage
                    }
                })
            })
                .then(() => {
                    alert("Таблица с ценами обновлена")
                    setCanUpdatePrices(false)
                    setOldPricesTableData(pricesTableData)
                })
                .catch((error) => alert("Что-то пошло не так"))
        } else {
            alert("Не могут быть одинаковые количества")
        }
    }

    const canUpdateReklama = (type: string) => {
        switch (type) {
            case "localeTopFirst":
                return reklamaPricingData["localeTopFirst"] != oldReklamaPricingData["localeTopFirst"]
            case "localeTopOther":
                return reklamaPricingData["localeTopOther"] != oldReklamaPricingData["localeTopOther"]
            case "localeBottomFirst":
                return reklamaPricingData["localeBottomFirst"] != oldReklamaPricingData["localeBottomFirst"]
            case "localeBottomOther":
                return reklamaPricingData["localeBottomOther"] != oldReklamaPricingData["localeBottomOther"]
            case "globalTop":
                return reklamaPricingData["globalTop"] != oldReklamaPricingData["globalTop"]
            case "globalBottom":
                return reklamaPricingData["globalBottom"] != oldReklamaPricingData["globalBottom"]
        }
    }

    const updateReklamaPricing = (data: any, type: string) => {
        AdminService.updateBaseSettingsUpdateReklama(data, type)
            .then(() => {
                alert("Данные обновлены")
                let prices = oldReklamaPricingData
                switch (type) {
                    case "localeTopFirst":
                        prices.localeTopFirst = data
                        break
                    case "localeTopOther":
                        prices.localeTopOther = data
                        break
                    case "localeBottomFirst":
                        prices.localeBottomFirst = data
                        break
                    case "localeBottomOther":
                        prices.localeBottomOther = data
                        break
                    case "globalTop":
                        prices.globalTop = data
                        break
                    case "globalBottom":
                        prices.globalBottom = data
                        break
                }
                setOldReklamaPricingData({...prices})
            })
            .catch(() => alert("Что-то пошло не так"))
    }

    const getDisabledRegions = (regions1: string[] | undefined, regions2: string[] | undefined) => {
        let mas: string[] = []
        if (regions1 && regions2)
            mas = regions1.concat(regions2)
        return mas
    }

    const updateGroupOnePrice = (group:IReklamaPricing, showAlert: boolean) => {
        AdminService.baseSettingsUpdateReklamaGroup(group)
            .then(() => {
                showAlert&&alert("Данные обновлены")
                setOldReklamaGroupOne(group)
            })
    }

    const updateGroupTwoPrice = (group:IReklamaPricing, showAlert: boolean) => {
        AdminService.baseSettingsUpdateReklamaGroup(group)
            .then(() => {
                showAlert&&alert("Данные обновлены")
                setOldReklamaGroupTwo(group)
            })
    }

    const updateGroupThreePrice = (group:IReklamaPricing, showAlert: boolean) => {
        AdminService.baseSettingsUpdateReklamaGroup(group)
            .then(() => {
                showAlert&&alert("Данные обновлены")
                setOldReklamaGroupThree(group)
            })
    }

    return (
        <>
            {(!store.isError && !store.isDataLoading) &&
                <div className={"admin-mainSetting-container"}>
                    <CustomNavigateHeader title={"Базовые настройки"} withTrash={false}/>

                    <div className={"admin-mainSetting-settings-container"}>
                        <div className={"admin-mainSetting-input"}>
                            <div className={"admin-mainSetting-hint-text-small"}>Текст с контактами</div>
                            <textarea
                                className={"admin-mainSetting-textarea-small"}
                                placeholder={"Текст с контактами"}
                                value={settingData["contactText"]}
                                onChange={
                                    (event) => setSettingsData({...settingData, "contactText": event.target.value})
                                }
                            />
                        </div>

                        <div className={"admin-mainSetting-input"}>
                            <div className={"admin-mainSetting-hint-text-small"}>ID продуктов для главной страницы</div>
                            <CustomInput
                                name={"idForPOnMainPage"}
                                placeHolder={"Текст"}
                                value={settingData["listOfProductsForMainPage"]}
                                onChange={
                                    (event) => setSettingsData({
                                        ...settingData,
                                        "listOfProductsForMainPage": event.target.value
                                    })
                                }
                                inputSize={"small"}
                            />
                        </div>

                        <div className={"admin-mainSetting-input"}>
                            <div className={"admin-mainSetting-hint-text-small"}>Текст с реквизитами</div>
                            <textarea
                                className={"admin-mainSetting-textarea-small"}
                                placeholder={"Текст с контактами"}
                                value={settingData["requisitiText"]}
                                onChange={
                                    (event) => setSettingsData({...settingData, "requisitiText": event.target.value})
                                }
                            />
                        </div>

                        <div className={"admin-mainSetting-input"}>
                            <div className={"admin-mainSetting-hint-text-small"}>Телефон поддержки</div>
                            <CustomInput
                                name={"telefonForCallingHelp"}
                                placeHolder={"Текст"}
                                value={settingData["helpNumber"]}
                                onChange={
                                    (event) => setSettingsData({...settingData, "helpNumber": event.target.value})
                                }
                                inputSize={"small"}
                            />
                        </div>

                        <div className={"admin-mainSetting-input"}>
                            <div className={"admin-mainSetting-hint-text-small"}>Условия пополнения</div>
                            <textarea
                                className={"admin-mainSetting-textarea-small"}
                                placeholder={"Условия пополнения"}
                                value={settingData["cardPricingText"]}
                                onChange={
                                    (event) => setSettingsData({...settingData, "cardPricingText": event.target.value})
                                }
                            />
                        </div>
                        <button className={"admin-mainSetting-button-small"}
                                onClick={updateBasicSettings}
                                data-isCanUpdate={canUpdateBasic}
                                disabled={!canUpdateBasic}
                        >
                            Обновить
                        </button>
                    </div>
                    <div className={"admin-mainSetting-pricesTable-container"}>
                        <CustomTable {...pricesTable}/>
                        <div className={"admin-mainSetting-pricesTable-buttons"}>
                            <button className={"admin-mainSetting-button"} onClick={() => setShowAddPricePopup(true)}>
                                Добавить
                            </button>
                            <button className={"admin-mainSetting-button"}
                                    onClick={updatePriceTable}
                                    data-isCanUpdate={canUpdatePrices}
                                    disabled={!canUpdatePrices}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>

                    <div className={"admin-mainSetting-reklama-container"}>
                        <details className={"admin-mainSetting-reklama"}>
                            <summary className={"admin-mainSetting-reklama-header"}>Стандартная цена для региональной
                                рекламы
                            </summary>
                            <div className={"admin-mainSetting-reklama-price"}>
                                <div className={"admin-mainSetting-reklama-setting"}>
                                    <div className={"admin-mainSetting-input"}>
                                        <div className={"admin-mainSetting-hint-text"}>Первая верхняя</div>
                                        <CustomInput
                                            name={"reklameErsteOben"}
                                            type={"number"}
                                            numberButtons={false}
                                            placeHolder={"Первая верхняя"}
                                            value={reklamaPricingData["localeTopFirst"]}
                                            onChange={
                                                (event) => setReklamaPricingData({
                                                    ...reklamaPricingData,
                                                    "localeTopFirst": event.target.value
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    </div>
                                    <button
                                        className={"admin-mainSetting-button"}
                                        onClick={() => updateReklamaPricing(reklamaPricingData["localeTopFirst"], "localeTopFirst")}
                                        data-isCanUpdate={canUpdateReklama("localeTopFirst")}
                                        disabled={!canUpdateReklama("localeTopFirst")}
                                    >
                                        Сохранить
                                    </button>
                                </div>

                                <div className={"admin-mainSetting-reklama-setting"}>
                                    <div className={"admin-mainSetting-input"}>
                                        <div className={"admin-mainSetting-hint-text"}>Верх</div>
                                        <CustomInput
                                            name={"ReklamaOben"}
                                            type={"number"}
                                            numberButtons={false}
                                            placeHolder={"Верх"}
                                            value={reklamaPricingData["localeTopOther"]}
                                            onChange={
                                                (event) => setReklamaPricingData({
                                                    ...reklamaPricingData,
                                                    "localeTopOther": event.target.value
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    </div>
                                    <button className={"admin-mainSetting-button"}
                                            onClick={() => updateReklamaPricing(reklamaPricingData["localeTopOther"], "localeTopOther")}
                                            data-isCanUpdate={canUpdateReklama("localeTopOther")}
                                            disabled={!canUpdateReklama("localeTopOther")}
                                    >
                                        Сохранить
                                    </button>
                                </div>

                                <div className={"admin-mainSetting-reklama-setting"}>
                                    <div className={"admin-mainSetting-input"}>
                                        <div className={"admin-mainSetting-hint-text"}>Первая нижняя</div>
                                        <CustomInput
                                            name={"ReklamaErsteUnten"}
                                            type={"number"}
                                            numberButtons={false}
                                            placeHolder={"Первая нижняя"}
                                            value={reklamaPricingData["localeBottomFirst"]}
                                            onChange={
                                                (event) => setReklamaPricingData({
                                                    ...reklamaPricingData,
                                                    "localeBottomFirst": event.target.value
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    </div>
                                    <button className={"admin-mainSetting-button"}
                                            onClick={() => updateReklamaPricing(reklamaPricingData["localeBottomFirst"], "localeBottomFirst")}
                                            data-isCanUpdate={canUpdateReklama("localeBottomFirst")}
                                            disabled={!canUpdateReklama("localeBottomFirst")}
                                    >
                                        Сохранить
                                    </button>
                                </div>

                                <div className={"admin-mainSetting-reklama-setting"}>
                                    <div className={"admin-mainSetting-input"}>
                                        <div className={"admin-mainSetting-hint-text"}>Нижняя</div>
                                        <CustomInput
                                            name={"ReklamaUnter"}
                                            type={"number"}
                                            numberButtons={false}
                                            placeHolder={"Нижняя"}
                                            value={reklamaPricingData["localeBottomOther"]}
                                            onChange={
                                                (event) => setReklamaPricingData({
                                                    ...reklamaPricingData,
                                                    "localeBottomOther": event.target.value
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    </div>
                                    <button className={"admin-mainSetting-button"}
                                            onClick={() => updateReklamaPricing(reklamaPricingData["localeBottomOther"], "localeBottomOther")}
                                            data-isCanUpdate={canUpdateReklama("localeBottomOther")}
                                            disabled={!canUpdateReklama("localeBottomOther")}
                                    >
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </details>

                        <details className={"admin-mainSetting-reklama"}>
                            <summary className={"admin-mainSetting-reklama-header"}>
                                Цена для федеральной рекламы
                            </summary>
                            <div className={"admin-mainSetting-reklama-price"}>
                                <div className={"admin-mainSetting-reklama-setting"}>
                                    <div className={"admin-mainSetting-input"}>
                                        <div className={"admin-mainSetting-hint-text"}>Верхняя</div>
                                        <CustomInput
                                            name={"reklamaGlobalOben"}
                                            type={"number"}
                                            numberButtons={false}
                                            placeHolder={"Верхняя"}
                                            value={reklamaPricingData["globalTop"]}
                                            onChange={
                                                (event) => setReklamaPricingData({
                                                    ...reklamaPricingData,
                                                    "globalTop": event.target.value
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    </div>
                                    <button className={"admin-mainSetting-button"}
                                            onClick={() => updateReklamaPricing(reklamaPricingData["globalTop"], "globalTop")}
                                            data-isCanUpdate={canUpdateReklama("globalTop")}
                                            disabled={!canUpdateReklama("globalTop")}
                                    >
                                        Сохранить
                                    </button>
                                </div>

                                <div className={"admin-mainSetting-reklama-setting"}>
                                    <div className={"admin-mainSetting-input"}>
                                        <div className={"admin-mainSetting-hint-text"}>Нижняя</div>
                                        <CustomInput
                                            name={"reklamaGrobalUnten"}
                                            type={"number"}
                                            numberButtons={false}
                                            placeHolder={"Нижняя"}
                                            value={reklamaPricingData["globalBottom"]}
                                            onChange={
                                                (event) => setReklamaPricingData({
                                                    ...reklamaPricingData,
                                                    "globalBottom": event.target.value
                                                })
                                            }
                                            inputSize={"large"}
                                        />
                                    </div>
                                    <button className={"admin-mainSetting-button"}
                                            onClick={() => updateReklamaPricing(reklamaPricingData["globalBottom"], "globalBottom")}
                                            data-isCanUpdate={canUpdateReklama("globalBottom")}
                                            disabled={!canUpdateReklama("globalBottom")}
                                    >
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </details>

                        <details className={"admin-mainSetting-reklama"}>
                            <summary className={"admin-mainSetting-reklama-header"}>
                                Индивидуальная цена по 1 группе
                            </summary>
                            <div className={"admin-mainSetting-individual-reklama-container"}>
                                <div className={"admin-mainSetting-reklama-price"}>
                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Первая верхняя</div>
                                            <CustomInput
                                                name={"reklameErsteOben"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Первая верхняя"}
                                                value={reklamaGroupOne["localeTopFirst"]}
                                                onChange={
                                                    (event) => setReklamaGroupOne({
                                                        ...reklamaGroupOne,
                                                        "localeTopFirst": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button
                                            className={"admin-mainSetting-button"}
                                            onClick={() => updateGroupOnePrice(reklamaGroupOne, true)}
                                            data-isCanUpdate={reklamaGroupOne["localeTopFirst"] != oldReklamaGroupOne["localeTopFirst"]}
                                            disabled={!(reklamaGroupOne["localeTopFirst"] != oldReklamaGroupOne["localeTopFirst"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Верх</div>
                                            <CustomInput
                                                name={"ReklamaOben"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Верх"}
                                                value={reklamaGroupOne["localeTopOther"]}
                                                onChange={
                                                    (event) => setReklamaGroupOne({
                                                        ...reklamaGroupOne,
                                                        "localeTopOther": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupOnePrice(reklamaGroupOne, true)}
                                                data-isCanUpdate={reklamaGroupOne["localeTopOther"] != oldReklamaGroupOne["localeTopOther"]}
                                                disabled={!(reklamaGroupOne["localeTopOther"] != oldReklamaGroupOne["localeTopOther"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Первая нижняя</div>
                                            <CustomInput
                                                name={"ReklamaErsteUnten"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Первая нижняя"}
                                                value={reklamaGroupOne["localeBottomFirst"]}
                                                onChange={
                                                    (event) => setReklamaGroupOne({
                                                        ...reklamaGroupOne,
                                                        "localeBottomFirst": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupOnePrice(reklamaGroupOne, true)}
                                                data-isCanUpdate={reklamaGroupOne["localeBottomFirst"] != oldReklamaGroupOne["localeBottomFirst"]}
                                                disabled={!(reklamaGroupOne["localeBottomFirst"] != oldReklamaGroupOne["localeBottomFirst"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Нижняя</div>
                                            <CustomInput
                                                name={"ReklamaUnter"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Нижняя"}
                                                value={reklamaGroupOne["localeBottomOther"]}
                                                onChange={
                                                    (event) => setReklamaGroupOne({
                                                        ...reklamaGroupOne,
                                                        "localeBottomOther": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupOnePrice(reklamaGroupOne, true)}
                                                data-isCanUpdate={reklamaGroupOne["localeBottomOther"] != oldReklamaGroupOne["localeBottomOther"]}
                                                disabled={!(reklamaGroupOne["localeBottomOther"] != oldReklamaGroupOne["localeBottomOther"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                                <div className={"admin-mainSetting-reklama-regions"}>

                                    <RegionsSelect
                                        selectRegionsProps={reklamaGroupOne.regionIds ? reklamaGroupOne.regionIds : []}
                                        regions={settingData.regions ? settingData.regions : []}
                                        onChange={(regions) => {
                                            updateGroupOnePrice({...reklamaGroupOne, regionIds: regions}, false)
                                            setReklamaGroupOne({
                                                ...reklamaGroupOne,
                                                regionIds: regions
                                            })
                                        }}
                                        regionsDisabled={getDisabledRegions(reklamaGroupTwo.regionIds, reklamaGroupThree.regionIds)}
                                    />
                                    <div className={"admin-mainSetting-reklama-regions-list"}>
                                        {reklamaGroupOne.regionIds && reklamaGroupOne.regionIds?.length > 0 ?
                                            settingData.regions?.map((r) => {
                                                if (reklamaGroupOne.regionIds?.includes(r.regionId)) {
                                                    return (
                                                        <div>
                                                            {r.name}
                                                        </div>
                                                    )
                                                }
                                            })
                                            :
                                            "Нет регионов"
                                        }

                                    </div>
                                </div>
                            </div>
                        </details>

                        <details className={"admin-mainSetting-reklama"}>
                            <summary className={"admin-mainSetting-reklama-header"}>Индивидуальная цена по 2 группе
                            </summary>
                            <div className={"admin-mainSetting-individual-reklama-container"}>
                                <div className={"admin-mainSetting-reklama-price"}>
                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Первая верхняя</div>
                                            <CustomInput
                                                name={"reklameErsteOben"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Первая верхняя"}
                                                value={reklamaGroupTwo["localeTopFirst"]}
                                                onChange={
                                                    (event) => setReklamaGroupTwo({
                                                        ...reklamaGroupTwo,
                                                        "localeTopFirst": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button
                                            className={"admin-mainSetting-button"}
                                            onClick={() => updateGroupTwoPrice(reklamaGroupTwo, true)}
                                            data-isCanUpdate={reklamaGroupTwo["localeTopFirst"] != oldReklamaGroupTwo["localeTopFirst"]}
                                            disabled={!(reklamaGroupTwo["localeTopFirst"] != oldReklamaGroupTwo["localeTopFirst"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Верх</div>
                                            <CustomInput
                                                name={"ReklamaOben"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Верх"}
                                                value={reklamaGroupTwo["localeTopOther"]}
                                                onChange={
                                                    (event) => setReklamaGroupTwo({
                                                        ...reklamaGroupTwo,
                                                        "localeTopOther": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupTwoPrice(reklamaGroupTwo, true)}
                                                data-isCanUpdate={reklamaGroupTwo["localeTopOther"] != oldReklamaGroupTwo["localeTopOther"]}
                                                disabled={!(reklamaGroupTwo["localeTopOther"] != oldReklamaGroupTwo["localeTopOther"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Первая нижняя</div>
                                            <CustomInput
                                                name={"ReklamaErsteUnten"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Первая нижняя"}
                                                value={reklamaGroupTwo["localeBottomFirst"]}
                                                onChange={
                                                    (event) => setReklamaGroupTwo({
                                                        ...reklamaGroupTwo,
                                                        "localeBottomFirst": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupTwoPrice(reklamaGroupTwo, true)}
                                                data-isCanUpdate={reklamaGroupTwo["localeBottomFirst"] != oldReklamaGroupTwo["localeBottomFirst"]}
                                                disabled={!(reklamaGroupTwo["localeBottomFirst"] != oldReklamaGroupTwo["localeBottomFirst"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Нижняя</div>
                                            <CustomInput
                                                name={"ReklamaUnter"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Нижняя"}
                                                value={reklamaGroupTwo["localeBottomOther"]}
                                                onChange={
                                                    (event) => setReklamaGroupTwo({
                                                        ...reklamaGroupTwo,
                                                        "localeBottomOther": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupTwoPrice(reklamaGroupTwo, true)}
                                                data-isCanUpdate={reklamaGroupTwo["localeBottomOther"] != oldReklamaGroupTwo["localeBottomOther"]}
                                                disabled={!(reklamaGroupTwo["localeBottomOther"] != oldReklamaGroupTwo["localeBottomOther"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                                <div className={"admin-mainSetting-reklama-regions"}>
                                    <RegionsSelect
                                        selectRegionsProps={reklamaGroupTwo.regionIds ? reklamaGroupTwo.regionIds : []}
                                        regions={settingData.regions ? settingData.regions : []}
                                        onChange={(regions) => {
                                            updateGroupTwoPrice({...reklamaGroupTwo, regionIds: regions}, false)
                                            setReklamaGroupTwo({
                                                ...reklamaGroupTwo,
                                                regionIds: regions
                                            })
                                        }}
                                        regionsDisabled={getDisabledRegions(reklamaGroupOne.regionIds, reklamaGroupThree.regionIds)}
                                    />
                                    <div className={"admin-mainSetting-reklama-regions-list"}>
                                        {reklamaGroupTwo.regionIds && reklamaGroupTwo.regionIds?.length > 0 ?
                                            settingData.regions?.map((r) => {
                                                if (reklamaGroupTwo.regionIds?.includes(r.regionId)) {
                                                    return (
                                                        <div>
                                                            {r.name}
                                                        </div>
                                                    )
                                                }
                                            })
                                            :
                                            "Нет регионов"
                                        }

                                    </div>
                                </div>
                            </div>
                        </details>

                        <details className={"admin-mainSetting-reklama"}>
                            <summary className={"admin-mainSetting-reklama-header"}>Индивидуальная цена по 3 группе
                            </summary>
                            <div className={"admin-mainSetting-individual-reklama-container"}>
                                <div className={"admin-mainSetting-reklama-price"}>
                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Первая верхняя</div>
                                            <CustomInput
                                                name={"reklameErsteOben"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Первая верхняя"}
                                                value={reklamaGroupThree["localeTopFirst"]}
                                                onChange={
                                                    (event) => setReklamaGroupThree({
                                                        ...reklamaGroupThree,
                                                        "localeTopFirst": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button
                                            className={"admin-mainSetting-button"}
                                            onClick={() => updateGroupThreePrice(reklamaGroupThree, true)}
                                            data-isCanUpdate={reklamaGroupThree["localeTopFirst"] != oldReklamaGroupThree["localeTopFirst"]}
                                            disabled={!(reklamaGroupThree["localeTopFirst"] != oldReklamaGroupThree["localeTopFirst"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Верх</div>
                                            <CustomInput
                                                name={"ReklamaOben"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Верх"}
                                                value={reklamaGroupThree["localeTopOther"]}
                                                onChange={
                                                    (event) => setReklamaGroupThree({
                                                        ...reklamaGroupThree,
                                                        "localeTopOther": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupThreePrice(reklamaGroupThree, true)}
                                                data-isCanUpdate={reklamaGroupThree["localeTopOther"] != oldReklamaGroupThree["localeTopOther"]}
                                                disabled={!(reklamaGroupThree["localeTopOther"] != oldReklamaGroupThree["localeTopOther"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Первая нижняя</div>
                                            <CustomInput
                                                name={"ReklamaErsteUnten"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Первая нижняя"}
                                                value={reklamaGroupThree["localeBottomFirst"]}
                                                onChange={
                                                    (event) => setReklamaGroupThree({
                                                        ...reklamaGroupThree,
                                                        "localeBottomFirst": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupThreePrice(reklamaGroupThree, true)}
                                                data-isCanUpdate={reklamaGroupThree["localeBottomFirst"] != oldReklamaGroupThree["localeBottomFirst"]}
                                                disabled={!(reklamaGroupThree["localeBottomFirst"] != oldReklamaGroupThree["localeBottomFirst"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>

                                    <div className={"admin-mainSetting-reklama-setting"}>
                                        <div className={"admin-mainSetting-input"}>
                                            <div className={"admin-mainSetting-hint-text"}>Нижняя</div>
                                            <CustomInput
                                                name={"ReklamaUnter"}
                                                type={"number"}
                                                numberButtons={false}
                                                placeHolder={"Нижняя"}
                                                value={reklamaGroupThree["localeBottomOther"]}
                                                onChange={
                                                    (event) => setReklamaGroupThree({
                                                        ...reklamaGroupThree,
                                                        "localeBottomOther": Number(event.target.value)
                                                    })
                                                }
                                                inputSize={"large"}
                                            />
                                        </div>
                                        <button className={"admin-mainSetting-button"}
                                                onClick={() => updateGroupThreePrice(reklamaGroupThree, true)}
                                                data-isCanUpdate={reklamaGroupThree["localeBottomOther"] != oldReklamaGroupThree["localeBottomOther"]}
                                                disabled={!(reklamaGroupThree["localeBottomOther"] != oldReklamaGroupThree["localeBottomOther"])}
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                                <div className={"admin-mainSetting-reklama-regions"}>
                                    <RegionsSelect
                                        selectRegionsProps={reklamaGroupThree.regionIds ? reklamaGroupThree.regionIds : []}
                                        regions={settingData.regions ? settingData.regions : []}
                                        onChange={(regions) => {
                                            updateGroupThreePrice({...reklamaGroupThree, regionIds: regions}, false)
                                            setReklamaGroupThree({
                                                ...reklamaGroupThree,
                                                regionIds: regions
                                            })
                                        }}
                                        regionsDisabled={getDisabledRegions(reklamaGroupOne.regionIds, reklamaGroupTwo.regionIds)}
                                    />
                                    <div className={"admin-mainSetting-reklama-regions-list"}>
                                        {reklamaGroupThree.regionIds && reklamaGroupThree.regionIds?.length > 0 ?
                                            settingData.regions?.map((r) => {
                                                if (reklamaGroupThree.regionIds?.includes(r.regionId)) {
                                                    return (
                                                        <div>
                                                            {r.name}
                                                        </div>
                                                    )
                                                }
                                            })
                                            :
                                            "Нет регионов"
                                        }

                                    </div>
                                </div>
                            </div>
                        </details>

                    </div>
                    {showAddPricePopup && <AddPricePopup onClose={() => setShowAddPricePopup(false)}
                                                         addData={(count: number, price: number, percentage: number) => addPrice(count, price, percentage)}/>}
                    {showEditPricePopup && EditPopupElement}
                </div>
            }
        </>
    );
};

export default MainSettings;