import React from "react";
import "./Popup_Apply.css";
import ProductCard_1 from "../ProductCard_1/ProductCard_1";
import {ICard} from "../../../models/product/productCard";


{/*
Чтобы подключить попап необходимо:

1)Стейты:
    const [openPopup,setOpenPopup] = useState(false);
    const {modal, open, close} = useContext(ModalContext);

2)Хендлеры открытия и закрытия:
    const OpenPopupHandler = () => {
        setOpenPopup(true);
        open();
    }
    const ClosePopupHandler = () => {
        setOpenPopup(false);
        close();
    }

3)Подтянуть дату для карточки внутри попапа (карточка использует item:ICard):
Пример:
        const [dataArray,setDataArray] = useState<any>([])

        useEffect(() => {
        $api
            .get('/CabinetBuyer/favorites')
            .then(data => {
                if (data.status === 200){
                    setDataArray(data.data);
                }
            })
        }, [])

4)Использование внутри рендера:
Пример:
    async function use(){
        CabinetBuyerService.getMyOrders().then((data)=>{
            if(data.status===200) alert("успех");});
    }

    <span onClick={OpenPopupHandler}></span>
    {openPopup && <Popup_Apply
        title={"Активировать товар?"}
        textOnButton={"Подтвердить"}
        handler={use}
        item={dataArray[8]}
        onClose={ClosePopupHandler}/>}
*/}


interface ModalProps{
    title: string;
    text?: string;
    textOnButton:string;
    handler:any;
    item?:ICard;
    onClose: () => void;
}

export function Popup_Apply(props:ModalProps) {

    const OnClickHandler = () => {
        props.handler();
        props.onClose();
    }
    return (
        <>
            <div id="popup-close-wrapper" onClick={props.onClose} />
            <div id={"popup-wrapper"} className={props.item?"":"popup-wrapper-nocard"}>
                <div id="popup-container">
                    <span id="popup-title">{props.title}</span>
                    {props.item&&
                        <div id="popup-product-card">
                            <ProductCard_1 item={props.item}/>
                        </div>}
                    {props.text&&<span id="popup-text">{props.text}</span>}
                    <span id="popup-button" onClick={OnClickHandler}>
                        {props.textOnButton}</span>
                </div>
            </div>
        </>
    )
}