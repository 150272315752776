import React, {useRef, useState} from "react";
import "../CustomSwiperSupplier.css";
import "./ProductCardSupplier.css";
import {Link, useNavigate} from "react-router-dom";
import {SPCard} from "../../../../models/SelectProduct/SPCard";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";
import {SPQuestionsCard} from "../../../../models/SelectProduct/SPQuestionsCard";
import {ICard} from "../../../../models/product/productCard";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {RootPreviewCard} from "../../../ProtectedPages/Admin/RootCardsPreview/RootPreview";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";

interface ISwiperItemProps {
    item: SPCard | SPQuestionsCard | ICard | RootPreviewCard;
    style?: string;
    rootId?: string;
    productId?: string;
    questionId?: string;
    linkToReview?: string;
}

function ProductCardSupplier({item, style, productId, rootId, questionId, linkToReview}: ISwiperItemProps) {
    const navigate = useNavigate()

    const [showPopup, setShowPopup] = useState(false)
    const refPopup = useRef(null)
    useOnClickOutside(refPopup, () => setShowPopup(false))
    const handler = () => {
        if (item.magazines && item.magazines.length > 0) {
            setShowPopup(true)
        } else {
            navigate((linkToReview) ? (linkToReview) : (SpaRoutes.PRODUCT_DETAILS + `/${rootId}/${productId}/${questionId}`))
        }
    }

    return (
        <>
            <div className="product_card_container" onClick={handler}>
                <div className={style ? style : "product_card"}>
                    <div className={"img_cont"}>
                        {item.magazines && item.magazines.length > 0 &&
                            <div className={"product_card_isUse"}>
                                Используется ({item.magazines.length})
                            </div>
                        }
                        {("pictureUrl" in item || "image" in item) ?
                            <CustomIMG id="img_of_card"
                                       src={"pictureUrl" in item ? item.pictureUrl : "image" in item ? item.image : ""}/>
                            :
                            <CustomIMG id="img_of_card" noImage={"noImage" in item ? item.noImage : ""}/>
                        }
                    </div>
                    <div id="text_cont">
                        <span id="text_of_card">{"title" in item ? item.title : item.name_of_product} </span>
                    </div>
                </div>
            </div>
            {showPopup && item.magazines &&
                <div className={"productCardSupplier-popupMagazines-container"} ref={refPopup}>
                    <div className={"productCardSupplier-popupMagazines"}>
                        <CustomNavigateHeader title={"Склады:"} onClick={() => setShowPopup(false)}/>
                        <div className={"productCardSupplier-popupMagazine-items"}>
                            {item.magazines.map((magazine) => {
                                return (
                                    <div className={"productCardSupplier-popupMagazine-item"}>
                                        {magazine}
                                    </div>
                                )
                            })}
                        </div>
                        <div className={"productCardSupplier-popupMagazines-button"}
                             onClick={() => {
                                 navigate((linkToReview) ?
                                     (linkToReview) :
                                     (SpaRoutes.PRODUCT_DETAILS + `/${rootId}/${productId}/${questionId}`))
                             }}
                        >
                            Прикрепить к складу
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ProductCardSupplier;