import CustomInput from "../../../../customUI/CustomInput/CustomInput";
import React from "react";
import {IAttenders} from "../../../../../models/models";
import './PopUpTrainingAttenders.css'

interface IPopUpTrainingAttendersProps {
	attenders: IAttenders[],
	closePopUp: () => void,
	organizatorId: string,
}


const PopUpTrainingAttenders = ({attenders, closePopUp, organizatorId} : IPopUpTrainingAttendersProps) => {

	const attendersOnly = attenders.filter(({userId}) => userId !== organizatorId)


	return (
		<>
			{/*<div className={"popUpTrainingAttenders-close-wrapper"} />*/}
			<div className="popUpTrainingAttenders-wrapper">
				<div className="popUpTrainingAttenders-container">
					<div className="popUpTrainingAttenders-title">
						<img id={"arrow-img"} src={"/Pictures/strelochka.svg"} onClick={closePopUp}/>
						<p className="cart-title">Участники мероприятия</p>
					</div>
					{attendersOnly.length > 0 ? (
						<ul className="popUpTrainingAttenders-list">
							{attendersOnly.map((attender, index) => (
								<li className="popUpTrainingAttenders-item" key={attender.userId}>
									<span>{index + 1}</span><span className="popUpTrainingAttenders-item-name">{attender.userLastName} {attender.userFirstName} </span>
									<span>{attender.phoneNumber}</span>
								</li>
							))}
						</ul>
					) : <div className="popUpTrainingAttenders-empty">Нет участников</div>}

				</div>
			</div>
		</>
	)
}

export default PopUpTrainingAttenders;
