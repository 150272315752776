import { Swiper, SwiperSlide } from "swiper/react";

// Import CustomSwiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./SupplierCustomSwiper.css"

// import required modules
import { FreeMode, Pagination } from "swiper";
import ProductCard_1 from "../../Supplier_UI_kit/ProductCard_1/ProductCard_1";
import {ISupplierCatalogItem, IitemArray} from "../../../models/models";
import {ICard} from "../../../models/product/productCard";

interface ISuplierCustomSwiperInterface{
    data:ICard[];
    dataForViewAll:ISupplierCatalogItem[],
    title:string;
    checkAll:boolean;
    setViewAll:(viewAll:boolean)=>void;
    setCategoryData:(categoryData:IitemArray[])=>void;
}

export default function SupplierCustomSwiper(
    {data, dataForViewAll, title, checkAll, setViewAll,setCategoryData}:ISuplierCustomSwiperInterface) {


    function getGroupedItems(items: ISupplierCatalogItem[]): IitemArray[]{
        let result:IitemArray[] = [];
        let IdCategories:string[] = [items[0].catId];
        let NameCategories:string[] = [items[0].catName];
        let IdCounter:number = 0;

        for(let i=0; i<items.length; i++){
            let newId:string = items[i].catId;
            IdCounter = 0;
            for(let j=0; j<IdCategories.length; j++){
                if(newId===IdCategories[j]) IdCounter++;
            }
            if(IdCounter===0){
                IdCategories.push(newId);
                NameCategories.push(items[i].catName);
            };
        }

        let resultContinue:IitemArray = {
            catName:"",
            catId:0,
            cards:[],
        };

        for(let i=0; i<IdCategories.length; i++){
            let resultContinue1:IitemArray = {
                catName:NameCategories[i],
                catId:Number(IdCategories[i]),
                cards: items
                    .filter((item)=>(item.catId===IdCategories[i]))
                    .map((item)=>(item.card)),
            };
            resultContinue = resultContinue1;
            result.push(resultContinue);
        }
        return result;
    }

    const onClickHandler = () => {
        let res = getGroupedItems(dataForViewAll);
        setCategoryData(res);
        setViewAll(true);
    }

    let ListCards:any = [];
    if(data)
        if(data.length!=0)
            ListCards = data.map((card)=>{
                return (
                    <>
                        <SwiperSlide style={{width:"auto",height:"auto"}} key={card.productId}>
                            <ProductCard_1
                                key={card.productId}
                                item={card}
                                link={true}
                            />
                        </SwiperSlide>)
                    </>
                )
            })

    return (<>
            {data&&data.length!=0&&
                <>
                    <div>
                        <div className="supplier-swiper-tag-name-div">
                            <span className={"supplier-swiper-tag-name"}>{title}</span>
                            {checkAll&&<span className="supplier-swiper-checkAll"
                                             onClick={onClickHandler}>смотреть все
                            </span>}
                        </div>
                    </div>
                    <Swiper
                        breakpoints={{
                            // when window width is <1100px (480px)
                            0: {
                                slidesPerView: 2.233333, //подобрано для ширины окна 140px;
                            },
                            // when window width is 1100px<w<1600px (1366px)
                            1100: {
                                slidesPerView: 3.637, //Подобрано таким образом, чтобы div одного слайда был 225px
                            },
                            1200: {
                                slidesPerView: 4.037, //Подобрано таким образом, чтобы div одного слайда был 225px
                            },
                            1300: {
                                slidesPerView: 4.437,
                            },
                            1400: {
                                slidesPerView: 4.837,
                            },
                            1500: {
                                slidesPerView: 5.237,
                            },
                            // when window width is 1600px<w<1920px (1920px)
                            1600: {
                                slidesPerView: 5.637,
                            },
                            1700: {
                                slidesPerView: 6, //Подобрано таким образом, чтобы div одного слайда был 225px
                            },
                            // when window width is 1600px<w<1920px (1920px)
                            1800: {
                                slidesPerView: 6.4,
                            },
                            1900: {
                                slidesPerView: 6.8, //Подобрано таким образом, чтобы div одного слайда был 225px
                            },
                            // when window width is 1600px<w<1920px (1920px)
                            2000: {
                                slidesPerView: 7.2,
                            },
                            2100: {
                                slidesPerView: 7.6, //Подобрано таким образом, чтобы div одного слайда был 225px
                            },
                            // when window width is 1600px<w<1920px (1920px)
                            2200: {
                                slidesPerView: 8,
                            },
                            2300: {
                                slidesPerView: 8.4,
                            }
                        }}
                        spaceBetween={7}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="mySwiper"
                    >
                        {ListCards}
                    </Swiper>
                </>
            }
        </>
    );
}