import React, { useContext, useEffect, useRef, useState } from "react"
import "./AddTraining.css"
import CabinetSellerService from "../../../../../services/CabinetSellerService"
import {
  ICity,
  IDropdownOption,
  IRegion,
  ITraining,
} from "../../../../../models/models"
import HomeService from "../../../../../services/HomeService"
import CustomDropdown from "../../../../customUI/CustomDropdown/CustomDropdown"
import CustomInput from "../../../../customUI/CustomInput/CustomInput"
import { useNavigate, useParams } from "react-router-dom"
import { SpaRoutes } from "../../../../../Routes/spaRoutes"
import { Context } from "../../../../../index"
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader"
import * as dayjs from "dayjs"

export default function AddUpdateTraining() {
  const navigate = useNavigate()
  const { store } = useContext(Context)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [drag, setDrag] = useState(false)
  const [eventFile, setEventFile] = useState<File>()
  const [eventPreview, setEventPreview] = useState<string>()
  const inputRef = useRef(null)
  const [regionName, setRegionName] = useState("Регион")
  const [cityName, setCityName] = useState("Город")
  const [regions, setRegions] = useState<IRegion[]>([])
  const [cities, setCities] = useState<ICity[]>([])
  const [trainingData, setTrainingData] = useState<ITraining>({})
  const [formatName, setFormatName] = useState("Формат")
  const [isOnline, setIsOnline] = useState(false)

  const [loadedTrainingData, setLoadedTrainingData] = useState(false)
  const [loadedRegions, setLoadedRegions] = useState(false)

  const [oldStartDate, setOldStartDate] = useState("")

  const dayjs = require("dayjs")

  let { eventId } = useParams()
  if (eventId === undefined) {
    eventId = "0"
  }

  useEffect(() => {
    if (!loadedTrainingData) {
      if (eventId != "0") {
        store.DataLoadingON()
        CabinetSellerService.getCalendarEvent({ Info1: eventId })
          .then((response) => {
            setTrainingData({
              ...response.data,
              startDate: response.data.startDate.split("T")[0],
              startTime: response.data.startDate.split("T")[1],
              endDate: response.data.endDate.split("T")[0],
              endTime: response.data.endDate.split("T")[1],
            })
            setOldStartDate(response.data.startDate.split("T")[0])
            setRegionName(response.data.regionName)
            setCityName(response.data.cityName)
            setFormatName(response.data.format)
            if (response.data.format === "Онлайн") {
              setIsOnline(true)
            }
            setEventPreview(
              response.data["imageUrl"]
                ? store.imgServer + response.data["imageUrl"]
                : ""
            )
          })
          .catch(() => store.ErrorON())
          .finally(() => {
            store.DataLoadingOFF()
            setLoadedTrainingData(true)
          })
      } else {
        setFormatName("Очно")
        setIsOnline(false)
        setTrainingData({
          startDate: dayjs().format("YYYY-MM-DD"),
          startTime: dayjs().format("HH:mm:00"),
        })
      }
    }
  }, [])

  const createFormData = (data: any) => {
    return Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key])
      return formData
    }, new FormData())
  }
  const refactorUserData = (data: { [x: string]: any }) => {
    for (const key in data) {
      if (data[key] === null || data[key] === undefined) delete data[key]
    }
    return data
  }

  const handleTrainingClick = async (e: any) => {
    e.preventDefault()
    store.DataLoadingON()

    if (eventId === "0") {
      let mydata = {
        title: trainingData["title"],
        regionId: formatName === "Онлайн" ? "" : trainingData["regionId"],
        cityId: formatName === "Онлайн" ? "" : trainingData["cityId"],
        format: formatName,
        description: trainingData["description"],
        startDate: `${trainingData["startDate"]}T${trainingData["startTime"]}`,
        endDate: `${trainingData["endDate"]}T${trainingData["endTime"]}`,
        image: eventFile,
      }
      store.DataLoadingON()
      await CabinetSellerService.postCreateNewCalendarEvent(mydata)
        .then(() => {
          alert("Обучение добавлено")
          navigate(SpaRoutes.TRAINING)
        })
        .catch((error) => {
          alert("Что-то пошло не так")
        })
        .finally(() => {
          store.DataLoadingOFF()
        })
    } else {
      let mydata = {
        id: eventId,
        title: trainingData["title"],
        regionId: formatName === "Онлайн" ? "" : trainingData["regionId"],
        cityId: formatName === "Онлайн" ? "" : trainingData["cityId"],
        format: formatName,
        description: trainingData["description"],
        startDate: `${trainingData["startDate"]}T${trainingData["startTime"]}`,
        endDate: `${trainingData["endDate"]}T${trainingData["endTime"]}`,
        image: eventFile,
        organisator: trainingData["organisator"],
        organisatorId: trainingData["organisatorId"],
      }
      store.DataLoadingON()
      const data = createFormData(refactorUserData(mydata))
      await CabinetSellerService.postUpdateNewCalendarEvent(data)
        .then(() => {
          alert("Обучение изменено")
        })
        .catch((error) => {
          alert("Что-то пошло не так")
        })
        .finally(() => {
          store.DataLoadingOFF()
        })
    }
  }

  useEffect(() => {
    if (!loadedRegions) {
      HomeService.GetRegions()
        .then((response) => {
          setRegions(response.data.regions)
        })
        .then(() => setLoadedRegions(true))
    }
  }, [loadedTrainingData])

  useEffect(() => {
    if (loadedRegions) {
      let regionId = trainingData["regionId"]
      if (regionId != undefined) {
        const region = regions.find((item) => item.regionId === regionId)

        if (region != undefined) {
          setRegionName(region.name)
        }
        const cities = region?.cities

        if (cities != null && cities.length != 0) {
          setCities(cities)
          let cityId = trainingData["cityId"]
          if (cityId != undefined) {
            const city = cities.find((item) => item.cityId === cityId)
            if (city != undefined) {
              setCityName(city.name)
            }
          }
        } else
          setCities([
            { cityId: "no", name: "Нет городов", region: "", sorter: 0 },
          ])
      }
    }
  }, [regions, loadedRegions, loadedTrainingData])

  let ArrayRegions: IDropdownOption[] = []
  regions.map((item) => {
    ArrayRegions.push({ key: item.regionId, name: item.name })
  })

  let ArrayCities: IDropdownOption[] = []
  cities.map((item) => {
    ArrayCities.push({ key: item.cityId, name: item.name })
  })

  let ArrayFormats: IDropdownOption[] = [
    { key: "1", name: "Очно" },
    { key: "2", name: "Онлайн" },
  ]

  const onRegionsSelectChange = (val: string) => {
    const RegionId = val
    setTrainingData({
      ...trainingData,
      ["regionId"]: RegionId,
    })
    const region = regions.find((item) => item.regionId === RegionId)
    const cities = region?.cities
    if (cities != null && cities.length != 0) setCities(cities)
    else
      setCities([{ cityId: "no", name: "Нет городов", region: "", sorter: 0 }])
    setCityName("Город")
  }

  const onCitySelectChange = (val: string) => {
    let CityId = val
    setTrainingData({
      ...trainingData,
      ["cityId"]: CityId,
    })
  }

  useEffect(() => {
    if (!eventFile) {
      setEventFile(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(eventFile)
    setEventPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [eventFile])

  const selectEventPicture = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      setEventPreview(undefined)
      return
    }
    // I've kept this example simple by using the first image instead of multiple
    setEventFile(event.target.files[event.target.files.length - 1])
  }

  const onBtnPictureClick = () => {
    // @ts-ignore
    inputRef.current?.click()
  }

  const dragOver = (e: any) => {
    e.preventDefault()
  }

  const dragEnter = (e: any) => {
    e.preventDefault()
    setDrag(true)
  }

  const dragLeave = (e: any) => {
    e.preventDefault()
    setDrag(false)
  }

  const fileDrop = (e: any) => {
    e.preventDefault()
    setEventFile(e.dataTransfer.files[e.dataTransfer.files.length - 1])
    setDrag(false)
  }
  const updateUI = (val: string) => {
    if (val === "2") {
      setIsOnline(true)
    } else {
      setIsOnline(false)
    }
  }

  return (
    <div className="addTraining-wrapper">
      <form
        className="addTraining-container"
        onSubmit={(e) => handleTrainingClick(e)}
      >
        <CustomNavigateHeader
          title={eventId === "0" ? "Добавить обучение" : "Изменить обучение"}
          withTrash={false}
        />

        <div className="addTraining-inputs">
          {!isOnline && (
            <>
              <div className="addTraining-dropdown">
                <p>Регион</p>
                <CustomDropdown
                  styleDropdownDisplay={" training-dropDown"}
                  styleDropdownList1={" training-dropDown-list"}
                  styleDropdownList2={" training-dropDown-list2"}
                  dropdownSize={"large"}
                  icon={true}
                  options={ArrayRegions}
                  value={regionName}
                  valueSetter={setRegionName}
                  onChangeHandler={onRegionsSelectChange}
                />
              </div>

              <div className="addTraining-dropdown">
                <p>Город</p>
                <CustomDropdown
                  styleDropdownDisplay={" training-dropDown"}
                  styleDropdownList1={" training-dropDown-list"}
                  styleDropdownList2={" training-dropDown-list2"}
                  dropdownSize={"large"}
                  icon={true}
                  options={ArrayCities}
                  value={cityName}
                  valueSetter={setCityName}
                  onChangeHandler={onCitySelectChange}
                />
              </div>
            </>
          )}

          <div className="addTraining-dropdown">
            <p>Формат</p>
            <CustomDropdown
              styleDropdownDisplay={" training-dropDown"}
              styleDropdownList1={" training-dropDown-list"}
              styleDropdownList2={" training-dropDown-list2"}
              dropdownSize={"large"}
              icon={true}
              options={ArrayFormats}
              value={formatName}
              valueSetter={setFormatName}
              onChangeHandler={updateUI}
            />
          </div>

          <div className="addTraining-input">
            <p>Название</p>
            <CustomInput
              required={true}
              name={"title"}
              icon={false}
              value={trainingData["title"]}
              placeHolder={"Название"}
              onChange={(event) => {
                setTrainingData({ ...trainingData, title: event.target.value })
              }}
              inputSize={"large"}
            />
          </div>
        </div>

        <div className="addTraining-description">
          <p>Описание</p>
          <textarea
            value={trainingData["description"]}
            onChange={(event) =>
              setTrainingData({
                ...trainingData,
                description: event.target.value,
              })
            }
          />
        </div>

        <div className="addTraining-choiceTime">
          <div className="addTraining-choiceTime-item">
            <p>Дата начала</p>
            <input
              type={"date"}
              required
              min={
                eventId === "0" ? new Date().toLocaleDateString("fr-ca") : ""
              }
              value={trainingData["startDate"]}
              onChange={(event) =>
                setTrainingData({
                  ...trainingData,
                  startDate: event.target.value,
                  endDate: "",
                })
              }
            />
          </div>

          <div className="addTraining-choiceTime-item">
            <p>Время начала</p>
            <input
              type={"time"}
              required
              value={trainingData["startTime"]}
              onChange={(event) =>
                setTrainingData({
                  ...trainingData,
                  startTime: `${event.target.value}:00`,
                })
              }
            />
          </div>

          <div className="addTraining-choiceTime-item">
            <p>Дата окончания</p>
            <input
              type={"date"}
              required
              min={
                trainingData["startDate"]
                  ? trainingData["startDate"]
                  : new Date().toLocaleDateString("fr-ca")
              }
              value={trainingData["endDate"] ? trainingData["endDate"] : ""}
              disabled={false}
              onChange={(event) =>
                setTrainingData({
                  ...trainingData,
                  endDate: event.target.value,
                })
              }
            />
          </div>

          <div className="addTraining-choiceTime-item">
            <p>Время окончания</p>
            <input
              type={"time"}
              required
              value={trainingData["endTime"] ? trainingData["endTime"] : ""}
              onChange={(event) =>
                setTrainingData({
                  ...trainingData,
                  endTime: `${event.target.value}:00`,
                })
              }
            />
          </div>
        </div>

        <div className="addTraining-uploadPhoto">
          <p>Обложка мероприятия</p>

          <div
            className={
              "addTraining-photo-image" +
              (drag ? " addTraining-photo-image-dragEnter" : "")
            }
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            {eventPreview ? (
              <img src={eventPreview} alt={"product picture"} />
            ) : (
              <div className={"addTraining-photo-image-text"}>
                Поместите сюда фото
              </div>
            )}
          </div>

          <div className="addTraining-uploadPhoto-overview">
            <input
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
              name={"profilePicture"}
              onChange={selectEventPicture}
              accept="image/*"
            />

            <div
              className={"addTraining-uploadPhoto-button"}
              onClick={onBtnPictureClick}
            />
          </div>
        </div>

        <div>
          <div className="addTraining-footer-btn">
            <input
              type={"submit"}
              value={
                eventId === "0" ? "Добавить обучение" : "Изменить обучение"
              }
            />
          </div>
        </div>
      </form>
    </div>
  )
}
