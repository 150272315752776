import React, {useState} from "react";
import "./MyOrdersDropdown.css";
import {IMyOrders} from "../../../models/models";

interface Interface {
    propsnext: IMyOrders;
    title: string;
}

function MyOrdersDropdown(propsnext:Interface){

    const [details, setDetails] = useState(false);
    const label = propsnext.propsnext.orders.length + " " + propsnext.title;
    //вывод списка
    const list = propsnext.propsnext.orders.map((item)=>(
        <tr className={"myorders-table-row"}>
            <td className={"myorders-columns myorders-first-column"}>·</td>
            <td className={"myorders-columns myorders-second-column"}>{item.name_of_product}</td>
            <td className={"myorders-columns myorders-third-column"}>{item.amount} шт.</td>
            <td className={"myorders-columns myorders-fourth-column"}>{Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(item.price1)}</td>
        </tr>
    ));

    return(
        <div className= "myorders-dropdown-wrapper">
            < div className = "myorders-header-wrapper" >
                <div className={"myorders-header-button-wrapper"} onClick={() => setDetails(prev => !prev)}>
                    <span className={"myorders-header-item-title"}>{label}</span>
                    <img className={"myorders-header-button"} src={"/Pictures/Vector 8.svg"}/>
                </div>
            </ div >
            {details && <div className ={"myorders-main-wrapper"} >
                <table className={"myorders-table"}>{list}</table>
            </ div >}
        </div>
    )
}export default MyOrdersDropdown