import {Ru} from "../../../../../functions/functions";
import {useLocation, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import "./InvoicePaymentOption.css";
import SupplierNavigateHeader from "../../../../customUI/SupplierNavigateHeader/SupplierNavigateHeader";


export default function InvoicePaymentOption(){

    const params = useParams();
    const InvoiceId = params.id;
    const price = Ru(useLocation().state.price);
    const chosenOption = useLocation().state.chosenOption;

    const PaymentOptions:{name:string;id:number;}[] =
        [{name:"Наличные",id:1},
            {name:"Банковская карта",id:2},
            {name:"Бонусы",id:3},
            {name:"Со счета",id:4},
            {name:"QR-код",id:5},
            {name:"По терминалу",id:6}];

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const PaymentOptionsList = (chosenOption:number) =>
        PaymentOptions.map((payment_option) => {
            if (payment_option.id !== chosenOption) return (
                <div className={"invoice-detail-payment-option"}>
                    <span className={"custom-checkbox invoice-detail-checkbox"}></span>
                    <span className={"invoice-detail-payment-option-description"}>{payment_option.name}</span>
                    <span className={"invoice-detail-payment-option-price"}>{price}</span>
                </div>
            )
            else return (
                <div className={"invoice-detail-payment-option"}>
                    <img className={"invoice-detail-checkbox1"} src={"/Pictures/checkbox.svg"} alt={"checkbox"}/>
                    <span className={"invoice-detail-payment-option-description"}>{payment_option.name}</span>
                    <span className={"invoice-detail-payment-option-price"}>{price}</span>
                </div>)
        });


    return(
    <div id={"invoice-detail-payOption-wrapper"}>
        <div id="invoice-detail-navigate-header">
            <SupplierNavigateHeader trashClickHandler={()=>{}} title={`Заказ №${InvoiceId}`} withTrash={false}/>
        </div>
        <div>{PaymentOptionsList(chosenOption)}</div>
    </div>)
}