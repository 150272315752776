import $api from "../api/axios";
import {AxiosResponse} from "axios";
import {IReklama, ISupplierCatalogData} from "../models/models";
import {isBooleanObject} from "util/types";
import {CabinetResponse} from "../models/response/CabinetResponse";
const { ApiRoutes: { CabinetSeller } } = require("../Routes/apiRoutes");

export default class CabinetSellerService {
    static async getInvoices(statusId: number, buyerId: string): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.INVOICE_LISTS,
            {
                Number1: statusId,
                Info1: buyerId
            });
    }
    static async getMagazines(): Promise<AxiosResponse> {
        return $api.get(CabinetSeller.SHOPS);
    }
    static async getMagazinesForCreateProduct(rootId:string): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.SHOPS_FOR_CREATE_PRODUCT,{Info1: rootId});
    }
    static async getAllMagazinesInfo(): Promise<AxiosResponse> {
        return $api.get(CabinetSeller.SHOPS_EXTRA);
    }
    static async getSingleMagazineInfo(magazineId: string): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.SHOPS_INFO,
            {
                Info1: magazineId
            });
    }
    static async deleteMagazine(magazineId: string): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.SHOPS_DELETE,
            {
                Info1: magazineId
            });
    }
    static async updateMagazine(
            magazineId: string,
            cityId: string | undefined,
            street: string | undefined,
            number: string | undefined,
            postcode: number | undefined,
            isVirtual:boolean|undefined,
            forAdress:string|undefined,
            virtualRegionId:string|undefined
            ): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.SHOPS_UPDATE,
            {
                Info1:magazineId,
                Info2:cityId,
                Info3:street,
                Info4:number,
                Number1:postcode,
                Bool:isVirtual,
                Info5:forAdress,
                Info6:virtualRegionId
            });
    }
    static async getProducts():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.PRODUCTS);
    }
    static async get_all_catalog():Promise<AxiosResponse<ISupplierCatalogData>>{
        return $api.get(CabinetSeller.GET_CATALOG);
    }
    static async getBonusesRemainingDays():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.GET_BONUS_REMAINING_DAYS);
    }
    static async getDailyPayInfo():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.DAILY_PAY_INFO);
    }
    static async postDailyPayInfoSingle(date:string,dropPointId:string):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.DAILY_PAY_INFO_SINGLE,
            {
                Info1:date,
                Info2:dropPointId,
            })
    }
    static async activateProduct(id:string,bool:boolean):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.ACTIVATE_PRODUCT,
            {
                Info1:id,
                Bool1:bool
            })
    }
    static async deleteProduct(id:string):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.DELETE_PRODUCT,
            {
                Info1:id
            })
    }

    static async myProductDelete(id:string):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.MY_PRODUCT_DELETE,
            {
                Info1:id
            })
    }

    static async discountProduct(id:string,bool:boolean):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.DISCOUNT_PRODUCT,
            {
                Info1:id,
                Bool1:bool
            })
    }

    static async updateInvoiceStatus(id:string,status:number):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.UPDATE_INVOICE_STATUS,
            {
                Number1: status,
                Info1:id
            });
    }

    static async getProfileData():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.PROFILE_INFO);
    }

    static async uploadProfileAvatar(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PROFILE_AVATAR, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async uploadProfileLogo(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PROFILE_LOGO, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async uploadProfilePersonalData(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PROFILE_PERSONAL_DATA, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async uploadProfileCompanyData(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PROFILE_COMPANY_DATA, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async uploadProfileNewParol(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PROFILE_CHANGE_PAROL, data);
    }

    static async getRootQuestionInfo(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.ROOT_QUESTION_INFO, data);
    }

    static async addRootQuestion(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.ROOT_QUESTION_ADD, data);
    }

    static async updateRootQuestion(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.ROOT_QUESTION_UPDATE, data ,{headers: {'Content-Type': `multipart/form-data`}});
    }

    static async getProductInfo(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PRODUCT_INFO, data);
    }

    static async addProduct(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PRODUCT_CREATE_FROM_ROOT, data, {headers: {'Content-Type': `application/json`}});
    }

    static async updateProduct(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.PRODUCT_UPDATE, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async getRootProductInfo(data: object):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.ROOT_PRODUCT_INFO, data);
    }

    static async getCabinet(): Promise<AxiosResponse<CabinetResponse>> {
        return $api.get(CabinetSeller.CABINET);
    }

    static async getBonusTransaction(data: any):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.GET_BONUS_TRANSACTIONS, data, {headers: {'Content-Type': `application/json`}});
    }

    static async GetCardPricingTable():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.GET_CARD_PRICING);
    }

    static async getBonusBalance():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.GET_BONUS_BALANCE);
    }

    static async getRootProducts():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.MYROOT_PRODUCTS);
    }
    static async getMyRootQuestions():Promise<AxiosResponse>{
        return $api.get(CabinetSeller.MYROOT_QUESTIONS)
    }
    static async postInvoice(OrderId: (string)[]): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.INVOICE,
            {
                List1: OrderId
            });
    }

    static async getTraining(): Promise<AxiosResponse> {
        return $api.get(CabinetSeller.TRAINING);
    }

    static async getCalendarEvent(data: object): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.GET_CALENDAR_EVENT, data);
    }

    static async getAllUpcomingEvents(): Promise<AxiosResponse> {
        return $api.get(CabinetSeller.GET_ALL_UPCOMING_EVENTS);
    }

    static async postSignUpForEvent(data:any):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.SING_UP_FOR_EVENT, data, {headers: {'Content-Type': `application/json`}});
    }
    static async postCreateNewCalendarEvent(data:any):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.CREATE_NEW_CALENDAR_EVENT, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async postUpdateNewCalendarEvent(data:any):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.UPDATE_CALENDAR_EVENT, data, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async postDeleteCalendarEvent(data:any):Promise<AxiosResponse>{
        return $api.post(CabinetSeller.DELETE_CALENDAR_EVENT, data, {headers: {'Content-Type': `application/json`}});
    }

    static async postReturnDone(OrderId: string):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.RETURN_DONE,
            {
                Info1: OrderId
            });
    }
    static async postCancelSeen(OrderId: string): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.CANCEL_SEEN,
            {
                Info1: OrderId
            });
    }
    static async postCancelDone(OrderId: string): Promise<AxiosResponse> {
        return $api.post(CabinetSeller.CANCEL_DONE,
            {
                Info1: OrderId
            });
    }

    static async confirmCancel(invoiceId: string, orderId: string):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.CONFIRM_CANCEL, {Info1:invoiceId,Info2:orderId} );
    }

    static async addUpdateEntertainment(reklama: FormData):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.UPDATE_ENTERTAINMENT, reklama, {headers: {'Content-Type': `multipart/form-data`}});
    }

    static async getMyEntertainment():Promise<AxiosResponse> {
        return $api.get(CabinetSeller.GET_MY_ENTERTAINMENT);
    }

    static async DeleteEntertainment(id: string):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.DELETE_ENTERTAINMENT, {ReklamaId: id});
    }

    static async getRegions4Reservations(type: number):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.GET_REGIONS_4_RESERVATIONS, {type});
    }

    static async getReservations(data: object):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.GET_RESERVATIONS, data);
    }

    static async getEntertainment4Reservations():Promise<AxiosResponse> {
        return $api.get(CabinetSeller.GET_ENTERTAINMENT_4_RESERVATIONS);
    }

    static async buyReservations(data: object):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.BUY_RESERVATIONS, data);
    }

    static async changeReservations(data: object):Promise<AxiosResponse> {
        return $api.post(CabinetSeller.CHANGE_RESERVATIONS, data);
    }
}