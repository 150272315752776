import React, {useContext, useEffect, useState} from 'react';
import "./Filter.css";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import {IFilter} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import MultiSelectDropDown from "../../../customUI/CustomDropdown/MultiSelectDropDown";


const Filter = () => {
    const {store} = useContext(Context)
    const [filterGroup , setFilterGroup] = useState<any[]>()
    const [filters, setFilters] = useState<IFilter[]>([])
    const [selectedFilters, setSelectedFilters] = useState<IFilter[]>([])

    const [dataLoading, setDataLoading] = useState(false)
    let {topCat, catId} = useParams();
    const navigate = useNavigate()

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const addFilter = () => {
        AdminService.addFilter("новый фильтр",0)
            .finally(()=>{
                UpdateData()
            })
    }

    const saveChanges = ()=>{
        if (topCat==="1"){
            AdminService.updateFilter4TopCat(catId?catId:"-1",selectedFilters.map((filter)=>{return filter.attrId}))
                .finally(()=>{
                    UpdateData()
                    navigate(SpaRoutes.ADMIN_CATEGORIES)
                })
        }
        else{
            AdminService.updateFilter4SubCat(catId?catId:"-1",selectedFilters.map((filter)=>{return filter.attrId}))
                .finally(()=>{
                    UpdateData()
                    navigate(SpaRoutes.ADMIN_CATEGORIES)
                })
        }
    }

    const UpdateData = () => {
        setFilters([])
        AdminService.getAllFilters()
            .then(response => {
                setFilters(response.data)
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
            })
    }

    useEffect(() => {
        if (!dataLoading) {
            store.DataLoadingON()
            AdminService.getAllFilters()
                .then(response => {
                    setFilters(response.data);
                    (topCat==="1")?
                        AdminService.getFilter4TopCat(catId?catId:"-1")
                            .then(
                                response=>{
                                    setSelectedFilters(response.data)
                                }
                            )
                            :
                        AdminService.getFilter4SubCat(catId?catId:"-1")
                            .then(
                                response=>{
                                    setSelectedFilters(response.data)
                                }
                            )

                })
                .catch(() => {
                store.ErrorON()
            }).finally(() => {
                setDataLoading(true)
                store.DataLoadingOFF()
            })

        }
    }, [dataLoading])

    useEffect(() => {
        let fg:any[] = []
        filters.forEach((filt)=>{
            if (fg.every((item)=>{return item.id !== filt.filterId})){
                fg.push({
                    id:filt.filterId,
                    name:filt.filterName,
                    sorter:filt.filterSorter,
                    attributes:[{id:filt.attrId,name:filt.attrName,sorter:filt.attrSorter}]
                })
            }
            else{
                fg.forEach((item)=>{
                    if (item.id === filt.filterId){
                        item.attributes.push({id:filt.attrId,name:filt.attrName,sorter:filt.attrSorter})
                    }
                })
            }
        })
        fg.forEach((filt)=>{
            filt.attributes = filt.attributes.filter((item:any)=>{return item.id!=="no"}).sort((a:any,b:any)=>{
                if (a.sorter > b.sorter) return 1
                if(a.sorter < b.sorter) return -1
                return 0})
        })
        setFilterGroup(fg.sort((a:any,b:any)=>{
            if (a.sorter > b.sorter) return 1
            if(a.sorter < b.sorter) return -1
            return 0}));
    }, [filters])

    const changeSelection = (key:string) => {
        filters.forEach((fil)=>{
            if(fil.attrId===key){
                if (selectedFilters.some((sefil)=>{return sefil.attrId === key})) {
                    setSelectedFilters(selectedFilters.filter((filfil)=>{return filfil.attrId !== key}))
                }
                else{
                    setSelectedFilters(selectedFilters.concat([fil]))
                }
            }
        })
    }

    const FILTERS = filterGroup?.map((filter)=> {
        return <div style={{marginBottom:"10px"}}>
            <div style={{display:"flex",width: "600px",marginBottom:"9px"}}>
                    <MultiSelectDropDown
                        options={filter.attributes.map((item: any) => {
                            return {key: item.id, value:item.name ,name: item.name}
                        })}
                        selectedOptions={filter.attributes.map((item: any) => {
                            if (selectedFilters.some((ele) => {
                                return ele.attrId === item.id
                            })) return {key: item.id, value: item.name, name: item.name}
                            else return {}
                        }).filter((elem:any)=>{return elem.key?true:false})}
                        displayedName={filter.name}
                        onChangeHandler={changeSelection}
                        dropdownSize={'small'}
                    />
                <Link to={SpaRoutes.FILTER_EDIT + `/${filter.id}`}>
                    <img style={{width:"60px",height:"25px",paddingTop: "6px"}} alt={"to filter"} className={"table-iconUser"}
                         src={"/Pictures/NoteCustomer.svg"}/>
                </Link>
            </div>
            <div style={{display:"flex"}}>{filter.attributes?.map((attr:any)=>{

                return <div style={selectedFilters.some((filter)=>{return filter.attrId === attr.id})?{display:"block"}:{display:"none"}}>
                <div className={"admin-filter-basic"}>{attr.name}</div>
                </div>

            })}
            </div>
        </div>
    })

    return (
        <>{(!store.isDataLoading) &&
            <div className={"admin-categories-main"}>
                <CustomNavigateHeader title={"Выберите фильтры"} withTrash={false}/>
                <hr/>
                <div className={"admin-filters-container"}>
                    {FILTERS}
                </div>
                <div>
                    <div className={"admin-categories-add-button admin-filter-button-1"} onClick={addFilter}>Добавить фильтр</div>
                </div>
                <div style={{display:"flex"}}>
                    <div className={"admin-categories-add-button admin-filter-button-2"} onClick={UpdateData}>Отменить</div>
                    <div className={"admin-categories-add-button admin-filter-button-2"} onClick={saveChanges}>Применить</div>
                </div>
            </div>
        }</>
    );
};

export default Filter;