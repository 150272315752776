import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import './Advertising.css';
import {advertisement} from "../../../../data/advertisement";
import axios from "axios";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";

interface IAdvertising {
    url?:string;
    link:string;
    onClick: () => void
}

export default function Advertising({url,link, onClick}:IAdvertising){
    return(
            <div id="wrapper_advertising" onClick={onClick}>
                {/*<span>{url}</span>*/}
                    <CustomIMG src={url} id={"img_advertising"} />
            </div>
    )
}