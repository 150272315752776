import React, { useContext, useEffect, useState } from "react";

import CustomTable, {IRowElement} from "../../../customUI/CustomTable/CustomTable";
import {ITable} from "../../../customUI/CustomTable/CustomTable";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import Navbar from "../../Admin/Navbar/Navbar";
import {ToogleVisible} from "../../../customUI/Searchbar/functions";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import {Link, useParams} from "react-router-dom";
import {ToogleFilter} from "../../../customUI/FilterBox/functions";
import Filterbox, {IFilterbutton} from "../../../customUI/FilterBox/Filterbox";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CartEmpty from "../../../customUI/NoProductsPage/NoProductsPage";
import Boxitem from "../../../customUI/multipleCheckboxes/boxitem";
import SupplierCard from "../../Customer/Cart/SupplierCard/SupplierCard";
import CartFooter from "../../Customer/Cart/CartFooter";
import BottomCartButton from "../../../customUI/BottomCartButton/BottomCartButton";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {DifferenceDates} from "../../../../functions/functions";
import './magazineTable.css'
import SelectFilterbox, {IFilterBoxGroups} from "../../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {IDropdownOption} from "../../../../models/models";
import {ICard} from "../../../../models/product/productCard";
import {Popup_Apply} from "../../../Supplier_UI_kit/Popup_Apply/Popup_Apply";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";

const placeholder = 'Найти ...'

function SupplierMagazines() {
    const [isVisible, setIsVisible] = useState(true)
    let {supplierId} = useParams();
    const {store} = useContext(Context)
    const[loading,setLoading] = useState(false)
    const[showPopup,setShowPopup] = useState(false)
    const[magazineTableBox, setMagazineTableBox] = useState<{name:string,table:ITable}[]>([]);
    const[popupElement,setPopupElement] = useState<any>();
    const [shopFilter,setShopFilter] = useState<string[]>([])

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(()=>{
        if(store.roles.includes('Admin') && supplierId){
            setIsVisible(false)
        }
        const GetSupplierMagazines = store.roles.includes('Admin') && supplierId? AdminService.getSupplierMagazines(supplierId) : CabinetSellerService.getAllMagazinesInfo()
        GetSupplierMagazines
            .then(response => {

                let mytablebox: any[] =[];
                for (const city of response.data.cities) {
                    let list: IRowElement[] = [];
                    for (const magazine of response.data.shops) {
                        if (city.id === magazine.cityId && !magazine.isVirtual){
                            let contentAdmin = {
                                Id:magazine.dropAdressId,
                                Address:magazine.streetAndNumber
                            }
                            let content = {
                                Id:magazine.dropAdressId,
                                Address:magazine.streetAndNumber,
                                ButtonField:<>
                                    <div style={{display:"flex",flexDirection:"row",lineHeight:"5px",width:"200px",marginRight:"auto"}}>
                                        <Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${-1}` + `/${magazine.dropAdressId}`}>
                                            <div className={"bdmagTab_edit"}>создать виртуальный</div>
                                        </Link>
                                        <Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${magazine.dropAdressId}`+ `/${-1}`}>
                                            <div className={"bdmagTab_edit"}>Редактировать</div>
                                        </Link>
                                        <div className={"bdmagTab_delete"} onClick={()=>{openPopUp(magazine.dropAdressId,"delete")}}><img src={"/Pictures/trashWhite.svg"}/><img src={"/Pictures/trashRed.svg"}/></div>
                                    </div>
                                </>
                            }
                            list.push(
                                {
                                    content: store.roles.includes('Admin') && supplierId? contentAdmin : content,
                                    filter:[{},{Id:magazine.dropAdressId}],
                                    visible:[true,true,true,true]
                                }
                            )
                            for (const virtMagazine of response.data.shops) {
                                if (virtMagazine.forMagazineId === magazine.dropAdressId && virtMagazine.isVirtual) {
                                    let contentAdmin = {
                                        Id: virtMagazine.dropAdressId,
                                        Address: virtMagazine.streetAndNumber
                                    }
                                    let content = {
                                        Id: virtMagazine.dropAdressId,
                                        Address: "виртуальный для " + virtMagazine.virtualRegion,
                                        ButtonField: <>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                lineHeight: "5px",
                                                width: "200px",
                                                marginLeft: "auto",
                                                marginRight: "auto"
                                            }}>
                                                <Link
                                                    to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${virtMagazine.dropAdressId}` + `/${magazine.dropAdressId}`}>
                                                    <div className={"bdmagTab_edit"}>Редактировать</div>
                                                </Link>
                                                <div className={"bdmagTab_delete"} onClick={() => {
                                                    openPopUp(virtMagazine.dropAdressId, "delete")
                                                }}><img src={"/Pictures/trashWhite.svg"}/><img
                                                    src={"/Pictures/trashRed.svg"}/></div>
                                            </div>
                                        </>
                                    }
                                    list.push(
                                        {
                                            content: store.roles.includes('Admin') && supplierId ? contentAdmin : content,
                                            filter: [{}, {Id: magazine.dropAdressId}],
                                            visible: [true, true, true, true]
                                        }
                                    )

                                }
                            }
                        }
                    }
                    let header = [
                        "№ склада",
                        "Адрес склада",
                        ""
                    ]
                    let headerAdmin = [
                        "№ склада",
                        "Адрес склада"
                    ]

                    let mytable:ITable = {
                        tableId:"MagazineTable" + city.id,
                        header: store.roles.includes('Admin') && supplierId? headerAdmin : header,
                        rows:list
                    }
                    mytablebox.push(
                        {
                            name:city.name,
                            table:mytable
                        }
                    )

                }
                setMagazineTableBox(mytablebox)
            })
            .then(()=>{setLoading(true)})
    }, [])


    const changeHandler = (text:string) => {
        setMagazineTableBox((prevState)=>{
            let newState = prevState.map(ele=>{
                ele.table.rows = ToogleVisible(text,["Id","Address"],["",""],ele.table.rows)
                return ele
            });
            return newState;
            }
        )
    }

    const SplitTable = () =>{
        return magazineTableBox.map((tbox)=>{
            if (tbox.table.rows.map((ele)=> ele.visible.every((item)=>item===true)).every((item)=>item===false)){
                return <></>
            }
            return <>
                <div className={"bdmagTab_subHeader"}>{tbox.name}</div>
                <CustomTable {...tbox.table}/>
            </>
        });
    }

    const removeMagazine = (id:string)=>{
        setMagazineTableBox((prevState)=>{
            let newState = prevState.map((ele=>{
                ele.table.rows = ele.table.rows.filter(function(row) {
                    return row.content.Id !== id;
                });
                return ele
            }))
            return newState;
        })
    }


    const openPopUp = (popMagId:string,popCase:string)=>{
        let thisHandler = ()=>{
            setShowPopup(false);
        }
        let buttonTitle = "";
        if (popCase === "delete"){
            buttonTitle = "Удалить склад?"
            thisHandler = ()=>{
                setShowPopup(false);
                CabinetSellerService.deleteMagazine(popMagId)
                    .then(response => {
                        if(response.status === 200){
                            response.data.ids.map( (item:string)=>{
                                removeMagazine(item)
                                }
                            )

                        }
                    })
            }
        }
        setPopupElement(Popup_Apply({
            title: buttonTitle,
            textOnButton:"Подтвердить",
            handler:thisHandler,
            onClose: () => {setShowPopup(false)}
        }))
        setShowPopup(true);
    }
    return(
        <>
            {!loading?<Loader load={true} />:
                <div id={"MagazineTables"} data-isAdmin={!isVisible}>
                    <CustomNavigateHeader title={"Склады"} trashClickHandler={()=>{}} withTrash={false}/>

                        <div className={"SearchbarTitle"}>Поиск</div>
                        <Searchbar
                            changeHandler={changeHandler}
                            initialText={placeholder}
                        />
                    <Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${-1}`+ `/${-1}`}>
                        <div className={"bdmagTab_edit_header"}>Добавить склад</div>
                    </Link>
                    <div id={"magazine_spacing"}>
                        <>{SplitTable()}</>
                        {showPopup && popupElement}
                    </div>
                </div>

            }
        </>


    )
}
export default SupplierMagazines;

