import React, {useContext} from "react";
import '../../ProtectedPages/Customer/Cart/Cart.css'
import {useNavigate} from "react-router-dom";
import './CustomNavigateHeader.css'
import './CustomNavigateHeader-media.css'
import {Context} from "../../../index";

interface ICartHeader {
	trashClickHandler?: () => void;
	title: string;
	withTrash?: boolean;
	onClick?: () => void;
}

export default function CustomNavigateHeader({title, trashClickHandler, withTrash, onClick}: ICartHeader) {
	const navigate = useNavigate();
	const {store} = useContext(Context)
	const onClickHandler = () => {
		if (onClick)
			onClick()
		else
			navigate(-1)
	}
	return (
		<div
			id={"navigate-title-div"}
			onClick={() => {
				store.ErrorOFF()
			}}
		>
			<img id={"arrow-img"} onClick={onClickHandler} src={"/Pictures/strelochka.svg"}/>
			<p className={"cart-title"}>{title}</p>
			{withTrash && <img onClick={trashClickHandler ? trashClickHandler : () => {
			}} id={"trash-icon"} src={"/Pictures/trash.svg"}/>}
		</div>
	)
}