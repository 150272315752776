import React from "react";
import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";

interface IDropdawnitemProps {
    handleClick:()=>void;
    suppliername:string;
    supplierlogo:string;
    isdropdownitem:boolean
    style?:string;
    showdropdown?:boolean
}

export function ChangeSupplierDropdawn(props:IDropdawnitemProps) {
    return(
        <div onClick={props.handleClick} className={`supply-company-div ${props.style}`}>
            <p className={"card-item-company"}>{props.suppliername}</p>
            {/*{props.supplierlogo&&!props.showdropdown ?*/}
            {/*    <CustomIMG*/}
            {/*        className={"card-item-supply-logo"}*/}
            {/*        src={props.supplierlogo}*/}
            {/*    /> :*/}
            {/*    <div className="void"></div>*/}
            {/*}*/}
            {!props.isdropdownitem&&
                (
                    <button className={"change-supplier"}>{
                        // props.style=="first-element"&&props.showdropdown&&props.supplierlogo ?
                        //     <CustomIMG
                        //         className={"card-item-supply-logo"}
                        //         src={props.supplierlogo}
                        //     /> :
                            "Изменить"
                    }
                    </button>
                )
            }
        </div>
    )
}





