import {makeAutoObservable, observable, runInAction} from "mobx";
import {IUser} from "../models/user/IUser";
import * as signalR from '@microsoft/signalr';
import AuthenticateService from "../services/AuthenticateService";
import CabinetBuyerService from "../services/CabinetBuyerService";
import {IDialog} from "../models/Dialog/Dialog";

const {ApiRoutes: {hubs}} = require("../Routes/apiRoutes");


export default class Store {

    user = {} as IUser;
    @observable isAuth = false;
    @observable isAuthLoading = true;
    @observable unread = false;
    @observable isDataLoading = false;
    @observable isError = false;
    @observable dialog: IDialog = {
        isDialogOpen: false,
        dialogResult: false,
        dialogWithOptions: false,
        dialogText: "",
        dialogTitle: undefined,
        resolveFunc: undefined
    }
    @observable regionId = '0';
    @observable roles: string[] = [];
    @observable CartItemsNumber = 0;
    @observable isHubOn = false;
    @observable filterInfo = {
        topId: "-1",
        topCatName: "",
        subId: "-1",
        subCatName: "",
        selectedIds: [],
        searchWord: ""
    }
    @observable hubConnection =
        new signalR.HubConnectionBuilder()
            .withUrl(process.env.NODE_ENV === "development" ? "https://localhost:5001" + hubs.CHAT : hubs.CHAT)
            .withAutomaticReconnect()
            .build();
    @observable Logout = false;
    @observable imgServer = 'https://pictureservice.ru/';
    @observable helpNumber: string = '';
    @observable AdminId = 'e1369729-d969-403f-8389-15a340e00061';
    @observable HashForChat = 'vritiojsfs33405hw0d0u_39jf';


    constructor() {
        makeAutoObservable(this)
    }

    joinHubGroup(name: string) {
        runInAction(() => {
            if (this.hubConnection.state !== "Connected") {
                this.hubConnection.start()
                    .then(() => {
                        console.log('Connected to HUB!');
                        this.hubConnection.invoke('AddToGroup', name)
                            .then(() => {
                                console.log('Connected to group ' + name + '!');
                                this.setHubOn(true)
                            })
                            .catch(e => console.log('Connected to ' + name + ' failed', e))
                    })
                    .catch(e => console.log('Connection failed: ', e));
            } else {
                console.log('Already connected to HUB!');
                this.hubConnection.invoke('AddToGroup', name)
                    .then(() => {
                        console.log('Connected to group ' + name + '!');
                        this.setHubOn(true)
                    })
                    .catch(e => console.log('Connected to ' + name + ' failed', e))
            }
        })
    }

    leaveHubGroup(name: string) {
        runInAction(() => this.hubConnection.invoke("LeaveGroup", name).then(() => this.setHubOn(false))
        )
    }

    setCartNumber(number: number) {
        runInAction(() => {
            this.CartItemsNumber = number
        })
    }

    setHubOn(bool: boolean) {
        runInAction(() => {
            this.isHubOn = bool
        })
    }

    setAuth(bool: boolean) {
        runInAction(() => {
            this.isAuth = bool
        })
    }

    setUser(user: IUser) {
        runInAction(() => {
            this.user = user
        })
    }

    setUserUnread(val: boolean) {
        runInAction(() => {
            this.user.unread = val
        })
    }

    setAuthLoading(bool: boolean) {
        runInAction(() => {
            this.isAuthLoading = bool
        })
    }

    setDataLoading(bool: boolean) {
        runInAction(() => {
            this.isDataLoading = bool
        })
    }

    setError(bool: boolean) {
        runInAction(() => {
            this.isError = bool
        })
    }

    setRoles(rols: string[]) {
        runInAction(() => {
            this.roles = rols
        })
    }

    setLogout(bool: boolean) {
        runInAction(() => {
            this.Logout = bool
        })
    }

    setRegionChanged(str: string) {
        runInAction(() => {
            this.regionId = str
        })
    }

    setFilterInfo(filterInfo: any) {
        runInAction(() => {
            this.filterInfo = filterInfo
        })
    }

    setHelpNumber(str: string) {
        runInAction(() => {
            this.helpNumber = str
        })
    }

    setUnread(val: boolean) {
        runInAction(() => {
            this.unread = val
        })
    }

    setDialogOpen(text: string, options?: boolean, title?: string) {
        runInAction(() => {
            this.dialog.dialogText = text;
            if (options) this.dialog.dialogWithOptions = options;
            if (title) this.dialog.dialogTitle = title;
            this.dialog.isDialogOpen = true;
            this.dialog.dialogResult = false;
        })
    }

    setDialogClose() {
        runInAction(() => {
            if (this.dialog.resolveFunc) this.dialog.resolveFunc();
            this.dialog.isDialogOpen = false;
            this.dialog.dialogWithOptions = false;
            this.dialog.dialogTitle = undefined;
        })
    }

    setDialogResult(result: boolean) {
        runInAction(() => {
            this.dialog.dialogResult = result;
            this.setDialogClose();
        })
    }

    setDialogResolveFunc(func: any) {
        runInAction(() => {
            this.dialog.resolveFunc = func;
        })
    }

    async showDialog(text: string, options?: boolean, title?: string): Promise<void> {
        this.setDialogOpen(text, options, title);

        return new Promise((resolve) => {
            this.setDialogResolveFunc(resolve);
        });
    }

    async login(email: string, password: string) {
        try {
            this.setLogout(false)
            const response = await AuthenticateService.login(email, password)
            localStorage.setItem('token', response.data.token)
            this.setAuth(true)
            // localStorage.setItem('userId', response.data.user.id)
            this.joinHubGroup(response.data.user.id)
            this.setRoles(response.data.roles)
            this.setUser(response.data.user)
            this.setUnread(response.data.user.unread)
            this.setHelpNumber(response.data.helpNumber)
            return response.status
        } catch (e: any) {
            return (e.response.data.status)
        } finally {
            this.cartNumber()
            this.setAuthLoading(false)
        }
    }

    async logout() {
        try {
            this.setLogout(true)
            localStorage.removeItem('token');
            let userId = localStorage.getItem('userId') ?? "";
            if (userId !== "") {
                this.leaveHubGroup(userId)
            }
            localStorage.removeItem('userId')
            this.setAuth(false)
            this.setRoles([])
            this.setUser({} as IUser)
            this.setCartNumber(0)
            this.setAuth(false)
            // @ts-ignore
            window.ReactNativeWebView.postMessage('logout');
        } catch (e: any) {
            console.log(e.response)
        }
    }

    async checkAuth() {
        if (!this.Logout) {
            try {
                this.setLogout(false)
                const response = await AuthenticateService.refreshToken(true)
                localStorage.setItem('token', response.data.token)
                this.setAuth(true)
                this.joinHubGroup(response.data.user.id)
                this.setRoles(response.data.roles)
                this.setUser(response.data.user)
                this.setUnread(response.data.user.unread)
                this.setHelpNumber(response.data.helpNumber)
                if (response.data.refreshToken && window.ReactNativeWebView && typeof window.ReactNativeWebView.postMessage === 'function') {
                    window.ReactNativeWebView.postMessage(`refreshToken:${response.data.refreshToken}`);
                }
            } catch (e: any) {
                if(localStorage.getItem("token")){
                    await this.showDialog("Не получилось обновить сессию. Авторизация завершилась ошибкой, токен авторизации недействителен! Попробуйте авторизоваться еще раз.")
                }
                //alert("Не получилось обновить сессию. Авторизация завершилась ошибкой, токен авторизации недействителен! Попробуйте авторизоваться еще раз.")
                await this.logout()
            } finally {
                runInAction(() => {
                    this.setAuthLoading(false)
                    this.cartNumber()
                })
            }
        }
    }

    async cartNumber() {
        try {
            if (this.isAuth && this.roles.includes("Customer")) {
                const response = await CabinetBuyerService.BasketNotEmpty(true);
                this.setCartNumber(response.data)
                return response.data
            }
        } catch (e) {
            console.log("Не получилось обновить уведомление о количестве товаров в корзине!")
        }

    }

    async updateUnread(val: boolean) {
        try {
            if (this.isAuth) {
                await this.setUnread(val)
            }
        } catch (e) {
            console.log("Не получилось обновить количество непрочитанных сообщений!")
        }
    }

    AuthLoadingON() {
        runInAction(() => {
            this.setAuthLoading(true)
        })
    }

    AuthLoadingOFF() {
        runInAction(() => {
            this.setAuthLoading(false)
        })
    }

    async DataLoadingON() {
        await this.setDataLoading(true)
    }

    async DataLoadingOFF() {
        await this.setDataLoading(false)
    }

    ErrorON() {
        runInAction(() => {
            this.setError(true)
        })

    }

    ErrorOFF() {
        runInAction(() => {
            this.setError(false)
        })
    }

    RegionChanged(id: string) {
        runInAction(() => {
            this.setRegionChanged(id)
        })

    }

    FiltersSave(topId: string, topCatName: string, subId: string, subCatName: string, selectedIds: string[]) {
        try {
            runInAction(() => {
                console.log("I am saving new data", topId, topCatName, subId, subCatName, selectedIds)
                this.setFilterInfo({
                    topId: topId,
                    topCatName: topCatName,
                    subId: subId,
                    subCatName: subCatName,
                    selectedIds: selectedIds
                })
                localStorage.setItem("filter", JSON.stringify({
                    topId: topId,
                    topCatName: topCatName,
                    subId: subId,
                    subCatName: subCatName,
                    selectedIds: selectedIds
                }))
            })
        } catch (e) {
            console.log("Не получилось сохранить фильтр!")
        }
    }

    FilterLoad() {
        try {
            runInAction(() => {
                this.setFilterInfo(JSON.parse(localStorage.getItem("filter")
                    ?? '{"topId":"-1","topCatName":"","subId":"-1","subCatName":"","selectedIds":[]}'))
            })
        } catch (e) {
            console.log("Не получилось загрузить фильтр!")
        }

    }

}
