import React, {useContext, useEffect, useState} from "react";
import "../MyOrdersActive/MyOrdersActive.css";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {IMyOrders, IMyOrdersElement} from "../../../../../models/models";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {Context} from "../../../../../index";
import CustomTable, {ITable} from "../../../../customUI/CustomTable/CustomTable";
import Searchbar from "../../../../customUI/Searchbar/Searchbar";
import {
    dateTransformForInput,
    dateTransformTtoDate,
    GetDateForOrder
} from "../../../../../functions/functions";
import {AnimatedButtonMarginDown} from "../../../../customUI/AnimatedButtonMarginDown/AnimatedButtonMarginDown";
import CartEmpty from "../../../../customUI/NoProductsPage/NoProductsPage";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {RoundLinkTo} from "../../../../customUI/RoundLinkTo/RoundLinkTo";

interface TableWithShowModes{
    showMode: boolean[];
    main:ITable;
}
function MyOrdersClosed() {
    const {store} = useContext(Context);
    const [dataArray,setDataArray] = useState<IMyOrders[]>([])
    const [startDate,setStartDate] = useState<Date>()
    const [endDate,setEndDate] = useState<Date>()
    const [totalAmount,setTotalAmount] = useState(0)
    const [totalSum,setTotalSum] = useState(0)
    const [searchword,setSearchword] = useState<string>("")
    // const [numberOfOrders , setNumberOfOrders] = useState<number>(0)
    // const [numberOfUnfinishedOrders , setNumberOfUnfinishedOrders] = useState<number>(0)
    const [showModeForEachRow, setShowModeForEachRow] = useState<boolean[]>([]);
    const [FirstTable, setFirstTable] = useState<TableWithShowModes>({
        showMode:[],
        main:{
            tableId: "InfoTable",
            header: [],
            rows: [],
        }
    });

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])

    useEffect(() => {
        async function GetDataMyOrders() {
            const MyOrders = await  CabinetBuyerService.getMyOrders(false)
            await setDataArray(MyOrders.data);
            MyOrders.data.map((ele:any)=>{
                ele.orders.map((product:any)=> {
                    setTotalSum((prevState) => {
                        return prevState + product.amount * product.price1
                    })
                    setTotalAmount((prevState) => {
                        return prevState + product.amount
                    })

                })
            })
            let ShowModeList:boolean[] = [];
            for(let i=0; i<MyOrders.data.length; i++) {
                ShowModeList.push(false);
            }
            await setShowModeForEachRow(ShowModeList);
        }

        store.DataLoadingON()
        GetDataMyOrders()
            .catch(()=> {
                store.ErrorON()
            })
            .finally(()=> {
                store.DataLoadingOFF()
            })
    }, [])

    const Handler4PrUnOr = (index:number,invoiceId:string) => {
        setFirstTable((actual:TableWithShowModes)=>{
            let newState = {...actual};
            newState.showMode[index] = !newState.showMode[index];
            newState.main.rows = newState.main.rows.map(obj => {
                if (obj.intern===invoiceId){
                    obj.visible[0] = newState.showMode[index];
                }
                return obj;
            });
            return newState;
        });
    }


    const handleClearData = async ()=>{
        await setEndDate(undefined)
        await setStartDate(undefined)
        await setTotalSum(0);
        await setTotalAmount(0);
        setDataArray((prevState)=>{
                let newState = prevState.map(ele=>{
                    let checkSupp = ele.supply_company.toLowerCase().indexOf(searchword.toLowerCase())>-1
                    ele.orders = ele.orders.map(product=>{
                        let checkP = product.name_of_product.toLowerCase().indexOf(searchword.toLowerCase())>-1 || checkSupp
                        if (checkP
                        ){
                            product.not4HistoryCounting = false
                            setTotalSum((prevState)=>{return prevState+product.amount*product.price1})
                            setTotalAmount((prevState)=>{return prevState+product.amount})
                        }
                        else{
                            product.not4HistoryCounting = true
                        }
                        return product})
                    return ele})
                return newState;
            }
        )
    }

    const handleSearchBar = async (text:string) => {
        await setSearchword(text);
        await setTotalSum(0);
        await setTotalAmount(0);
        setDataArray((prevState)=>{
                let newState = prevState.map(ele=>{
                    let checkSupp = ele.supply_company.toLowerCase().indexOf(searchword.toLowerCase())>-1
                    ele.orders = ele.orders.map(product=>{
                        let checkP = product.name_of_product.toLowerCase().indexOf(searchword.toLowerCase())>-1 || checkSupp
                        if (checkP&&
                            (endDate===undefined || new Date(endDate.setHours(23,59,59)) > GetDateForOrder(ele.invoiceTimes))&&
                            (startDate===undefined || new Date(startDate.setHours(0,0,0)) < GetDateForOrder(ele.invoiceTimes))){
                            product.not4HistoryCounting = false
                            setTotalSum((prevState)=>{return prevState+product.amount*product.price1})
                            setTotalAmount((prevState)=>{return prevState+product.amount})
                        }
                        else{
                            product.not4HistoryCounting = true
                        }
                        return product})
                    return ele})
                return newState;
            }
        )
    }

    const handleStart = async (date:Date)=>{
        await setStartDate(date)
        await setTotalSum(0);
        await setTotalAmount(0);
        setDataArray((prevState)=>{
                let newState = prevState.map(ele=>{
                    let checkSupp = ele.supply_company.toLowerCase().indexOf(searchword.toLowerCase())>-1
                    ele.orders = ele.orders.map(product=>{
                        let checkP = product.name_of_product.toLowerCase().indexOf(searchword.toLowerCase())>-1 || checkSupp
                        if (checkP&&
                            (endDate===undefined || endDate > GetDateForOrder(ele.invoiceTimes))&&
                            (date < GetDateForOrder(ele.invoiceTimes))){
                            product.not4HistoryCounting = false
                            setTotalSum((prevState)=>{return prevState+product.amount*product.price1})
                            setTotalAmount((prevState)=>{return prevState+product.amount})
                        }
                        else{
                            product.not4HistoryCounting = true
                        }
                        return product})
                    return ele})
                return newState;
            }
        )
    }

    const handleEnd = async (date:Date)=>{
        await setEndDate(date)
        await setTotalSum(0);
        await setTotalAmount(0);
        setDataArray((prevState)=>{
                let newState = prevState.map(ele=>{
                    let checkSupp = ele.supply_company.toLowerCase().indexOf(searchword.toLowerCase())>-1
                    ele.orders = ele.orders.map(product=>{
                        let checkP = product.name_of_product.toLowerCase().indexOf(searchword.toLowerCase())>-1 || checkSupp
                        if (checkP&&
                            (date > GetDateForOrder(ele.invoiceTimes))&&
                            (startDate===undefined||startDate < GetDateForOrder(ele.invoiceTimes))){
                            product.not4HistoryCounting = false
                            setTotalSum((prevState)=>{return prevState+product.amount*product.price1})
                            setTotalAmount((prevState)=>{return prevState+product.amount})
                        }
                        else{
                            product.not4HistoryCounting = true
                        }
                        return product})
                    return ele})
                return newState;
            }
        )
    }

    function orderList(){
        let tableList:any[] = []
        for(let i=0; i<dataArray.length; i++) {
            let order = dataArray[i];
            let numberListedProducts = order.orders.reduce((result, ordEle:IMyOrdersElement)=>{
                if (ordEle.not4HistoryCounting){
                    return result
                }
                return result + 1
            },0);
            if(numberListedProducts>0){
                tableList.push({
                    content:{
                        ID:<div><div
                            style={{position:"relative", left: "-7px"}}
                            onClick={order.orders.length>0?(()=>{Handler4PrUnOr(i,order.invoiceId)}):(()=>{})}
                        >{order.invoiceId +" / "+dateTransformTtoDate(order.invoiceTimes[order.invoiceTimes.length-1].toString())}
                            {numberListedProducts>1 &&
                                <>
                                    <input
                                        id={order.invoiceId}
                                        type="checkbox"
                                        className={"supplier_scph_drop_img_checkbox"}
                                    />
                                    <img
                                        style={{right:"-17px"}}
                                        alt={"open"}
                                        src={"/Pictures/dropDownIcon.svg"}
                                        className={"supplier_scph_drop_img admin_scph_img_container"}>
                                    </img>
                                </>}
                        </div></div>,
                        Dealer:order.supply_company,
                        Product:(numberListedProducts==1)?
                            order.orders.reduce((result, ordEle:IMyOrdersElement)=>{
                            if (ordEle.not4HistoryCounting){
                                return result
                            }
                            return result + ordEle.name_of_product
                        },""):<></>
                        ,
                        Amount:
                        <div style={{display:"flex",    justifyContent: "center"}}>
                            {order.orders.reduce((result, ordEle:IMyOrdersElement)=>{
                                if (ordEle.not4HistoryCounting){
                                    return result
                                }
                                return result + ordEle.amount
                            },0)}
                            <RoundLinkTo link={SpaRoutes.MYORDER + `/${order.invoiceId}`}/>
                        </div>

                        ,
                        SinglePrice:(numberListedProducts==1)?
                            Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(
                                order.orders.reduce((result, ordEle:IMyOrdersElement)=>{
                            if (ordEle.not4HistoryCounting){
                                return result
                            }
                            return result + ordEle.price1
                        },0)):"",
                        Price:Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(
                            order.orders.reduce((result, ordEle:IMyOrdersElement)=>{
                            if (ordEle.not4HistoryCounting){
                                return result
                            }
                            return result + ordEle.price1*ordEle.amount
                        },0)),
                    },
                    intern:"Header",
                    filter:[],
                    visible:[true],
                })
            }
            if(numberListedProducts>1){
                for (let j=0; j<order.orders.length; j++) {
                    let product = order.orders[j];
                    tableList.push({
                        content:{
                            ID:"",
                            Dealer:"",
                            Product:product.name_of_product,
                            Amount:product.amount,
                            SinglePrice:new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(product.price1),
                            Price:new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(product.amount*product.price1),
                        },
                        intern:order.invoiceId,
                        filter:[],
                        visible:[false],
                        style:{backgroundColor: "#f9f9f9"}
                    })
                }
            }
        }
        return tableList;
    }

    useEffect(()=>{
        if(dataArray){
            let mytable: TableWithShowModes = {
                showMode: showModeForEachRow,
                main:{
                    tableId: "OrdersTable",
                    header: ["№/Число","Поставщик","Товары","Количество","Цена за шт.","Сумма"],
                    rows: orderList()
                }
            }
            setFirstTable(mytable);
        }
    },[dataArray])

    return (
        <>{
            ((!store.isError && !store.isDataLoading) &&
                <div >
                            <div >
                                <CustomNavigateHeader title={"Завершенные заказы"} withTrash={false}/>
                                <div className={"myorderclosed-div"}>
                                    <div>
                                        <div>Дата начала</div>
                                        <div style={{width:"170px"}} className={"profile-datePicker"}>
                                            <input type={"date"}
                                                   name={"startDate"}
                                                   placeholder={"Дата"}
                                                   onChange={(e) => {
                                                       handleStart(new Date(e.target.value))
                                                   }}
                                                   value={startDate?dateTransformForInput(startDate) : ""}
                                            />
                                            <img className={"profile-datePicker-image"}
                                                 src={"/Pictures/datePickerIcon.svg"}/>
                                        </div>
                                    </div>
                                    <div>
                                        <div>Дата окончания</div>
                                        <div style={{width:"170px"}} className={"profile-datePicker"}>
                                            <input type={"date"}
                                                   name={"endDate"}
                                                   placeholder={"Дата"}
                                                   onChange={(e) => {
                                                       handleEnd(new Date(e.target.value))
                                                   }}
                                                   value={endDate?dateTransformForInput(endDate) : ""}
                                            />
                                            <img className={"profile-datePicker-image"}
                                                 src={"/Pictures/datePickerIcon.svg"}/>
                                        </div>
                                        {/*<div data-clicked={clicked}*/}
                                        {/*     className={"closed-order-clear"}*/}
                                        {/*     onClick={handleClearData}>Очистить</div>*/}
                                        <div style={{textAlign: "end",padding: "15px 0"}}>
                                            <AnimatedButtonMarginDown
                                                onclick={handleClearData}
                                                text={"Очистить"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Searchbar
                                    changeHandler={handleSearchBar}
                                    initialText={"Найти товар"}
                                />
                                {dataArray.length!=0?
                                        FirstTable.main.rows.length !== 0?<>
                                                <div style={{maxHeight:"calc(100vh - 400px)", overflow:"auto"}}>
                                                    <CustomTable {...FirstTable.main}/>

                                                </div>
                                            <div style={{margin:"20px"}}>
                                                <div>ИТОГО</div>
                                                <div>Количество: {totalAmount}, Сумма: {new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(totalSum)}</div>
                                            </div>
                                            </>
                                            :
                                            <div style={{marginTop:"20px"}}>
                                                <CartEmpty
                                                    tittle={"Нет завершенных заказов"}
                                                    text={"Не нашел ни одной записи по вашему запросу. Попробуйте сбросить фильтр"}
                                                />
                                            </div>
                                        :
                                        <div style={{marginTop:"20px"}}>
                                            <CartEmpty
                                                tittle={"Нет завершенных заказов"}
                                                text={"У вас пока еще нет ни одного завершенного заказа. Вы можете перейти в корзину и оформить новый заказ, или посмотреть свои активные заказы"}
                                                link1={SpaRoutes.CART}
                                                link1text={"В корзину"}
                                                link2={SpaRoutes.MY_ORDERS_ACTIVE}
                                                link2text={"К активным заказам"}
                                            />
                                        </div>
                                }

                            </div>
                </div>
            )
        }</>
    )
}export default MyOrdersClosed