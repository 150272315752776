import {useEffect, useState} from "react";
import ChooseMonthFromDate from "../../../../Supplier_UI_kit/Calendar/ChooseMonthFromDate";

export default function Calendar(props: any) {
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const [selectedDate, setSelectedDate] = useState(props?.Date?new Date(props.Date):new Date(new Date().getTime() + 24 * 60 * 60 * 1000));
    const [selectedMonth, setSelectedMonth] = useState(selectedDate.getMonth());
    const [selectedYear, setSelectedYear] = useState(selectedDate.getFullYear());
    const currentMonthDates = getMonthDates(selectedMonth, selectedYear);

    function getMonthDates(month: any, year: any) {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDay = new Date(year, month, 1).getDay();
        const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;
        const monthDates = [];

        for (let i = 1; i <= daysInMonth; i++) {
            monthDates.push(new Date(year, month, i));
        }
        for (let i = 0; i < adjustedFirstDay; i++) {
            monthDates.unshift(null);
        }
        return monthDates;
    }

    function handleDateClick(date: any) {
        if (Date.now()-24000*3600 < date) {
            setSelectedDate(date);
            props.onDateSelect(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
        }
    }

    function goToPreviousMonth() {
        if (selectedMonth - 1 == -1) {
            setSelectedMonth(11);
            setSelectedYear((prevYear) => prevYear - 1);
        } else {
            setSelectedMonth((prevMonth) => prevMonth - 1);
        }
    }

    function goToNextMonth() {
        if (selectedMonth + 1 == 12) {
            setSelectedMonth(0);
            setSelectedYear((prevYear) => prevYear + 1);
        } else {
            setSelectedMonth((prevMonth) => prevMonth + 1);
        }
    }

    return (
        <>
            <div style={{display: "flex", justifyContent: "center", marginBottom: "10px"}}>
                <div style={{display: "flex"}} onClick={goToPreviousMonth}>
                    <img className={"CimgChevron CimgChevronLeft"} src="/Pictures/chevron-left.svg"/>
                </div>
                <span id={"CTextMonth"} style={{marginTop: "auto", marginBottom: "auto", textAlign: "center"}}>
                    {ChooseMonthFromDate(selectedMonth.toString())} {selectedYear.toString()}
                </span>
                <div style={{display: "flex"}} onClick={goToNextMonth}>
                    <img className={"CimgChevron CimgChevronRight"} src="/Pictures/chevron-right.svg"/>
                </div>
            </div>
            <table>
                <thead>
                <tr>
                    {daysOfWeek.map((day, index) => (
                        <th key={index}>
                            <div id="CDayOfTheWeekTh">
                                {daysOfWeek[(index + 1) % 7]}
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody className={"CTBody"}>
                {currentMonthDates.map((date, index) => {
                    if (index % 7 === 0) {
                        return (
                            <tr key={index}>
                                {currentMonthDates.slice(index, index + 7).map((date, index) => (
                                    <td
                                        key={index}
                                        onClick={() => date && handleDateClick(date)}
                                        id="CDayTd"
                                    >
                                    <span className={
                                        date && date.getDate() === selectedDate.getDate() && date.getMonth() === selectedDate.getMonth() && date.getFullYear() === selectedDate.getFullYear()
                                            ? 'CalendarSelected'
                                            : date && date.getDate() === new Date(Date.now()).getDate() && date.getMonth() === new Date(Date.now()).getMonth() && date.getFullYear() === new Date(Date.now()).getFullYear()?
                                                'CalendarToday':
                                            date && date < new Date()
                                                    ? 'CalendarPastDays'
                                                    : index === 5 || index === 6
                                                    ? 'CalendarHoliday' : ''
                                    }>
                                        {date ? date.getDate() : ''}
                                    </span>
                                    </td>
                                ))}
                            </tr>
                        );
                    }
                })}
                </tbody>
            </table>
        </>
    );
}