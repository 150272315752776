import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import "../../UserLayout/MenuSticky/Menu/Menu.css";
import Burger from "./Burger/Burger";
import $api from "../../../../api/axios";
import Button_4 from "../../../Supplier_UI_kit/Button_4/Button_4";
import { IUser } from "../../../../models/user/IUser";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {observer} from "mobx-react-lite";

interface Interface {
    unread:boolean;
}
function Header({unread}:Interface) {
    const {store} = useContext(Context)

    useEffect(() => {
        if (store.isHubOn) {
            store.hubConnection.on("UpdateCounter", (forChat: string) => {
                if(!window.location.href.includes(forChat)){
                    store.updateUnread(true);
                }
            });
        }
    }, [store.isHubOn]);

    return (
        <>
            {(!store.isError && !store.isAuthLoading)&&(
                <div className="header-div_dealer">
                    <div className="Hamburger-and-share_dealer">
                         <Burger/>
                        <Button_4 link={SpaRoutes.SUPPLIER_PROFILE}/>
                        {/*<Button_4 />*/}
                    </div>
                    <div className="logo-and-homeButton_dealer">
                        <Link className="img-logo-header_dealer" to={"/"}>
                            <img src="/Pictures/Лого.svg" />
                        </Link>
                    </div>

                    <div className={"header-buttons_dealer"}>
                        <Link to={'/'} className="cabinet_button_dealer">Кабинет</Link>
                        {unread?
                            <Link className={"headerNotification"} to={SpaRoutes.SUPPLIER_CHAT}>

                                <img style={{width:"inherit",height:"inherit"}} src={"/Pictures/Уведомление_2.png"}/>

                            </Link>:
                            <Link className={"headerNotification"} to={SpaRoutes.SUPPLIER_CHAT}>

                                <img style={{width:"inherit",height:"inherit"}}  src="/Pictures/Уведомление_1.png"/>

                            </Link>
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default observer (Header);
