import {useEffect, useState} from "react";
import ChooseMonthFromDate from "./ChooseMonthFromDate";
import "./Calendar.css"
import HomeService from "../../../services/HomeService";
import {ICalendarEvents} from "../../../models/models";

interface ICalendar {
    calendarEvent: ICalendarEvents[];
    itemEventClick?: (date: any) => void;
    itemClick?: () => void;
    updateDropdown?: (month:number,year:number) => void;
}

export default function Calendar({calendarEvent, itemEventClick,updateDropdown, itemClick}: ICalendar) {
    const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
    const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());
    const [currentCity, setCurrentCity] = useState("");
    const currentMonthDates = getMonthDates(currentMonth, currentYear);

    function goToPreviousMonth() {
        if (currentMonth - 1 == -1) {
            if (updateDropdown){
                updateDropdown(12,currentYear-1)
            }
            setCurrentMonth(11);
            setCurrentYear((prevYear) => prevYear - 1);
        } else {
            if (updateDropdown){
                updateDropdown(currentMonth,currentYear)
            }
            setCurrentMonth((prevMonth) => prevMonth - 1);
        }
    }

    function goToNextMonth() {
        if (currentMonth + 1 == 12) {
            if (updateDropdown){
                updateDropdown(1,currentYear+1)
            }
            setCurrentMonth(0);
            setCurrentYear((prevYear) => prevYear + 1);
        } else {
            if (updateDropdown){
                updateDropdown(currentMonth+2,currentYear)
            }
            setCurrentMonth((prevMonth) => prevMonth + 1);

        }
    }

    function getMonthDates(month: any, year: any) {
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDay = new Date(year, month, 1).getDay();
        const adjustedFirstDay = firstDay === 0 ? 6 : firstDay - 1;
        const monthDates = [];
        for (let i = 1; i <= daysInMonth; i++) {
            monthDates.push(new Date(year, month, i));
        }
        for (let i = 0; i < adjustedFirstDay; i++) {
            monthDates.unshift(null);
        }
        return monthDates;
    }

    function handleDateClick(date: any) {
        setSelectedDate(date);
    }

    function CalendarEvent(day: number, month: number, year: number,) {
        let res
        calendarEvent.map((item) => {
            if (item.day == day && item.month == month && item.year == year && item.event) {
                res =
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        margin: "0",
                        border: "none",
                    }}>
                        <div className={"Calendar-eventCount-text"} style={{border: "none", padding: "0", marginRight: "10%"}}>{item.eventCount}</div>
                        <img className={"Calendar-eventCount-img"} src={"/Pictures/bookOpen.svg"}></img>
                    </div>
            }
        })
        return (res)
    }

    function CalendarGreyBgEvent(day: number, month: number, year: number,) {
        let res = false
        calendarEvent.map((item) => {
            if (item.day == day && item.month == month && item.year == year && item.event) {
                res = true
            }
        })
        return (res)
    }

    function CalendarClickEvent(day: number, month: number, year: number,) {


    }

    const DaysOfWeek = () => {
        const res = daysOfWeek.map((day, index) => (
            <th key={index} style={{padding: "0px"}}>
                <div className={index == 5 ? "СBackgroundColorSB" : ""}>
                    <div className={index == 0 ? "CDayOfTheWeekTh CDayOfTheWeekThPN"
                        : index == 5 ? "CDayOfTheWeekTh CDayOfTheWeekThSB"
                            : index == 6 ? "CDayOfTheWeekTh CDayOfTheWeekThVSK"
                                : "CDayOfTheWeekTh"}>
                        {daysOfWeek[(index + 1) % 7]}
                    </div>
                </div>
            </th>
        ))
        return res
    }

    useEffect(() => {
        let date
        calendarEvent.map((item) => {
            if (item.day == selectedDate.getDate() && item.month == selectedDate.getMonth() && item.year == selectedDate.getFullYear() && item.event) {
                if (itemEventClick) {
                    date = item.date
                }
            }
        })
        if (date) {
            if (itemEventClick) {
                itemEventClick(date);
            }
        } else {
            if (itemClick) {
                itemClick();
            }
        }
    }, [selectedDate])

    const Monthes = () => {
        const res = currentMonthDates.map((date, index) => {
            if (index % 7 === 0) {
                return (
                    <tr key={index}>
                        {currentMonthDates.slice(index, index + 7).map((date, index) => {
                            return (
                            <td
                                key={index}
                                onClick={() => date && handleDateClick(date)}
                                id="CDay"
                                style={{padding: "0"}}
                            >
                                <div style={{
                                    ...(date && date.getDate() === selectedDate.getDate() && date.getMonth() === selectedDate.getMonth() && date.getFullYear() == selectedDate.getFullYear()
                                        ? {
                                            border: "2px solid #333333",
                                        } : {}),
                                }}
                                     className={"CalendarItem"}
                                >
                                    <div
                                        className={date && CalendarGreyBgEvent(Number(date.getDate()), currentMonth, currentYear) == true
                                            ? "BgEvent"
                                            : ""}>

                                        <span className={"Calendar-font"}>
                                                {date ? date.getDate() : ''}
                                            </span>
                                        <div className={"CEventDiv"}>
                                            <div>
                                                {date && CalendarEvent(Number(date.getDate()), currentMonth, currentYear)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        )})}
                    </tr>
                );
            }
        })
        return (res)
    }

    return (
        <div className={"CalendarWrapper"}>
            <div className={"CalendarContainer"}>
                <div className={"CLocationWrapper"}>
                    {/*<div className={"CimgLocationWrapper"}>
                        <img id={"CimgLocation"} src="/Pictures/ИконкаЛокации.svg"/>
                    </div>
                    <span id={"CLocationText"} style={{marginTop: "auto", marginBottom: "auto"}}>
                        {currentCity}
                    </span>*/}
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex"}} onClick={goToPreviousMonth}>
                        <img className={"CimgChevron CimgChevronLeft"} src="/Pictures/chevron-left.svg"/>
                    </div>
                    <span id={"CTextMonth"} style={{marginTop: "auto", marginBottom: "auto", textAlign: "center"}}>
                    {ChooseMonthFromDate(currentMonth.toString())} {currentYear}
                </span>
                    <div style={{display: "flex"}} onClick={goToNextMonth}>
                        <img className={"CimgChevron CimgChevronRight"} src="/Pictures/chevron-right.svg"/>
                    </div>
                </div>
                <div className={"CEmptyDiv"}></div>
            </div>

            <table style={{borderCollapse: "collapse"}}>
                <thead>
                <tr>
                    {DaysOfWeek()}
                </tr>
                </thead>
                <tbody className={"CTBody"}>
                {Monthes()}
                </tbody>
            </table>
        </div>
    );
}