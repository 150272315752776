import React, { useState} from "react";
import './Favorite.css'
import CabinetBuyerService from "../../../services/CabinetBuyerService";

async function postFavorite(id:string) {
    return await CabinetBuyerService.watchlistSwitch(id);
}
interface IFavorite {
    favorite?:boolean;
    id:string;
    level2?:string;
    favoriteHandler?:any;
    style?:string;
}

const Favorite = ({favorite, id, level2, favoriteHandler, style}:IFavorite) => {
    const [favoriteIfNot,setFavoriteIfNot] = useState(favorite)
    const onClickHandler = async (e:  React.MouseEvent<HTMLImageElement, MouseEvent> ) => {
        e.stopPropagation();
        const response = await postFavorite(id);
        if(response.status == 200)
            if(favoriteHandler)
                favoriteHandler(level2, id)
            else
                setFavoriteIfNot(!favoriteIfNot)
    }
  return(
      <img
          onClick={(e) => onClickHandler(e)}
          id={`${style?style:"favorite-img-default"}`}
          src={`${
              favoriteHandler?
                  (favorite?
                      ("/Pictures/blue-heart.svg"):
                      ("/Pictures/default-heart.svg")):
                  (favoriteIfNot?
                      ("/Pictures/blue-heart.svg"):
                      ("/Pictures/default-heart.svg"))
          }`}
      />
  )
}
export default Favorite