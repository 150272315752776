import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../..";

import './Navbar.css'
import {IUser} from "../../../../models/user/IUser";
import {navbarMenu} from "../../../../data/AdminNavbar/navbarMenu";
import {Link} from "react-router-dom";
import {CustomIMG} from "../../../customUI/CustomIMG/CustomIMG";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {randomizeDefaultProfileImgPath} from "../../../../functions/functions";

function Navbar() {
    const {store} = useContext(Context)

    const firstname = store.user.firstname
    const image = store.user.image

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    //формирование списка бургера
    let list = [];

    for (let i = 0; i < navbarMenu.length; i++) {
        list.push(
            <Link className="admin-navbar-stroke" to={navbarMenu[i].link}>
                <div className="admin-navbar-stroke-container">
                    <img
                        src={navbarMenu[i].icon}
                        className="admin-navbar-stroke-image"
                    />
                    <p>{navbarMenu[i].name}</p>
                </div>
                <img src={"/Pictures/Vector (Stroke).svg"}/>
            </Link>
        )
    }

    return (
        <div className='admin-navbar-menu'>
            <div className="admin-navbarProfile">
                {
                    store.user?.image?
                        <CustomIMG className="admin-navbarProfilePictures" src={store.user?.image} />:
                        <img className="admin-navbarProfilePictures" src={'/Pictures/Admin-logo.png'} alt="User Logo"/>
                }
                <span className="admin-navbar-admin">Администратор</span>
                <span className="admin-navbar-AdminName">{firstname ? firstname : 'user'}</span>
            </div>

            {list}

            <hr/>
                {/*<div className={"admin-navbar-techSupport"}>Тех. поддержка</div>
                <a className="admin-navbar-stroke admin-navbar-stroke-midlle" href="tel:+79999999999">
                    + 7 999 999 99 99
                </a>*/}
                <Link className="admin-navbar-stroke admin-navbar-stroke-midlle" to={"/logout"}>
                    Выйти
                </Link>

        </div>
    );
}

export default Navbar;

