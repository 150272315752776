import React, {useEffect} from 'react';
import {Link} from "react-router-dom"
import './register.css'
import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {MenuSticky} from "../../Layout/UserLayout/MenuSticky/MenuSticky";
import {SpaRoutes} from "../../../Routes/spaRoutes";

function Register() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <MenuSticky/>
            <div style={{marginTop: "25px"}}>
                <CustomNavigateHeader
                    trashClickHandler={() => {
                    }}
                    title={``}
                    withTrash={false}
                />
                <h1 className="register_header">Регистрация</h1>
                <h2 className="register_header">Кто Вы ?</h2>
                <div className="register_justify_conten_center">
                    <Link className={"register_button"} to={SpaRoutes.BUYER}>
                        <img id="register_img_buyer" className="register_img_buyer"/>
                    </Link>

                    <Link className={"register_button"} to={SpaRoutes.BUYER_COMPANY}>
                        <img id="register_img_buyer_company" className="register_img_buyer_company"/>
                    </Link>

                    <Link className={"register_button"} to={SpaRoutes.SELLER}>
                        <img id="register_img_seller" className="register_img_seller"/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Register;
