import { AxiosResponse } from "axios"
import $api from "../api/axios"
const {
  ApiRoutes: { Admin },
} = require("../Routes/apiRoutes")

export default class AdminService {
  static async Customer(): Promise<AxiosResponse> {
    return await $api.get(Admin.CUSTOMER)
  }
  static async getAdminMarketKabinet(): Promise<AxiosResponse> {
    return await $api.get(Admin.GET_ADMIN_MARKET_KABINET)
  }
  static async getDealers(): Promise<AxiosResponse> {
    return await $api.get(Admin.DEALERS)
  }
  static async getBuyers(): Promise<AxiosResponse> {
    return await $api.get(Admin.BUYERS)
  }
  static async getCategories(): Promise<AxiosResponse> {
    return await $api.get(Admin.GET_CATEGORIES)
  }
  static async getRootList(): Promise<AxiosResponse> {
    return await $api.get(Admin.PRODUCT_ROOT_LIST)
  }
  static async getDailyPayDates(): Promise<AxiosResponse> {
    return await $api.get(Admin.GET_DAILY_PAY_DATES)
  }
  static async postDailyPayInfo(
    supplierId: string,
    date: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.POST_DAILY_PAY_INFO, {
      Info1: supplierId,
      Info2: date,
    })
  }
  static async postDailyPayInfoSingle(
    date: string,
    dropPointId: string,
    supplierId: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.POST_DAILY_PAY_INFO_SINGLE, {
      Info1: date,
      Info2: dropPointId,
      Info3: supplierId,
    })
  }

  static async getAllFilters(): Promise<AxiosResponse> {
    return await $api.get(Admin.ALL_FILTERS)
  }

  static async addFilterAttr(
    filterid: string,
    attrName: string,
    sorter: number
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_ATTR_ADD, {
      Info1: filterid,
      Info2: attrName,
      Number1: sorter,
    })
  }
  static async updateFilterAttr(
    attrId: string,
    attrName: string,
    sorter: number
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_ATTR_UPDATE, {
      Info1: attrId,
      Info2: attrName,
      Number1: sorter,
    })
  }
  static async deleteFilterAttr(attrid: string): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_ATTR_DELETE, {
      Info1: attrid,
    })
  }
  static async addFilter(
    filterName: string,
    sorter: number
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_ADD, {
      Info1: filterName,
      Number1: sorter,
    })
  }
  static async updateFilter(
    filterId: string,
    filterName: string,
    sorter: number
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_UPDATE, {
      Info1: filterId,
      Info2: filterName,
      Number1: sorter,
    })
  }
  static async deleteFilter(filterId: string): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_DELETE, {
      Info1: filterId,
    })
  }
  static async getFilter4TopCat(id: string): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_TC_GET, {
      Info1: id,
    })
  }
  static async updateFilter4TopCat(
    catid: string,
    filterIds: string[]
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_TC_UPDATE, {
      Info1: catid,
      List1: filterIds,
    })
  }
  static async getFilter4SubCat(id: string): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_SC_GET, {
      Info1: id,
    })
  }
  static async updateFilter4SubCat(
    catid: string,
    filterIds: string[]
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.FILTER_SC_UPDATE, {
      Info1: catid,
      List1: filterIds,
    })
  }

  static async getProductRootInfo(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.PRODUCT_ROOT_INFO, data)
  }
  static async postDeleteRootQuestion(
    questionId: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.DELETE_ROOT_QUESTION, { Info1: questionId })
  }
  static async postQuestionRootProductInfo(
    questionId: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.ROOT_QUESTION_PRODUCT_INFO, {
      Info1: questionId,
    })
  }
  static async postRootListForCategory(
    topCatId: string,
    subCatId?: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.ROOT_LIST_FOR_CATEGORY, {
      Info1: topCatId,
      Info2: subCatId,
    })
  }
  static async postProductRootUpdate(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.PRODUCT_ROOT_UPDATE, data, {
      headers: { "Content-Type": `multipart/form-data` },
    })
  }

  static async postProductRootCreate(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.PRODUCT_ROOT_CREATE, data)
  }

  static async postProductRootDelete(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.PRODUCT_ROOT_DELETE, data)
  }
  static async postActivateUser(
    userId: string,
    boolValue: boolean
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.ACTIVATE_USER, {
      Info1: userId,
      Bool: boolValue,
    })
  }
  static async postAddBonusPoints(
    userId: string,
    amount: number,
    comment: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.ADD_BONUS_POINTS, {
      Info1: userId,
      Number1: amount,
      Info2: comment,
    })
  }

  static async postMinusBonusPoints(
    userId: string,
    amount: number,
    comment: string
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.MINUS_BONUS_POINTS, {
      Info1: userId,
      Number1: amount,
      Info2: comment,
    })
  }

  static async getAllCategories(): Promise<AxiosResponse> {
    return await $api.get(Admin.CATEGORIES)
  }
  static async postTopCategoryAdd(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.TOP_CATEGORY_ADD, data)
  }

  static async postTopCategoryUpdate(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.TOP_CATEGORY_UPDATE, data)
  }

  static async postTopCategoryDelete(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.TOP_CATEGORY_DELETE, data)
  }

  static async postSubCategoryAdd(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.SUB_CATEGORY_ADD, data)
  }

  static async postSubCategoryUpdate(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.SUB_CATEGORY_UPDATE, data)
  }

  static async postSubCategoryDelete(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.SUB_CATEGORY_DELETE, data)
  }

  static async getRootQuestionList(): Promise<AxiosResponse> {
    return await $api.get(Admin.GET_PRODUCT_ROOTS)
  }

  static async getCustomer(userId: string): Promise<AxiosResponse> {
    return await $api.post(Admin.GET_KOSMETOLOG_INFORMATION, { Info1: userId })
  }

  static async getBaseSettingsInfo(): Promise<AxiosResponse> {
    return await $api.get(Admin.GET_BASE_SETTINGS_INFO)
  }

  static async updateBaseSettings(data: object): Promise<AxiosResponse> {
    return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_BASIC, data)
  }

  static async updateBaseSettingsUpdatePriceLPMP(
    data: object
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_BASIC_LPMP, data)
  }

  static async updateBaseSettingsUpdatePriceTable(
    data: object
  ): Promise<AxiosResponse> {
    return await $api.post(
      Admin.GET_BASE_SETTINGS_UPDATE_CARD_PRICING_TABLE,
      data
    )
  }

  static async updateBaseSettingsUpdateReklama(
    data: string,
    type: string
  ): Promise<AxiosResponse> {
    switch (type) {
      case "localeTopFirst":
        return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_PRICE_LTF, {
          Info1: data,
        })
      case "localeTopOther":
        return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_PRICE_LTO, {
          Info1: data,
        })
      case "localeBottomFirst":
        return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_PRICE_LBF, {
          Info1: data,
        })
      case "localeBottomOther":
        return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_PRICE_LBO, {
          Info1: data,
        })
      case "globalTop":
        return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_PRICE_GT, {
          Info1: data,
        })
      case "globalBottom":
        return await $api.post(Admin.GET_BASE_SETTINGS_UPDATE_PRICE_GB, {
          Info1: data,
        })
      default:
        return Promise.reject("Что-то пошло не так")
    }
  }

  static async baseSettingsUpdateReklamaGroup(
    data: object
  ): Promise<AxiosResponse> {
    return await $api.post(Admin.BASE_SETTINGS_UPDATE_REKLAMA_GROUP, data)
  }

  static async getSupplier(id: string): Promise<AxiosResponse> {
    return await $api.post(Admin.GET_DEALER_INFORMATION, { Info1: id })
  }

  static async getSupplierMagazines(id: string): Promise<AxiosResponse> {
    return await $api.post(Admin.GET_DEALER_MAGAZINES, { Info1: id })
  }

  static async getTraining(): Promise<AxiosResponse> {
    return $api.get(Admin.TRAINING)
  }

  static async postUpdateNewCalendarEvent(data: any): Promise<AxiosResponse> {
    return $api.post(Admin.UPDATE_CALENDAR_EVENT, data, {
      headers: { "Content-Type": `multipart/form-data` },
    })
  }

  static async getEntertainment4Moderation(): Promise<AxiosResponse> {
    return $api.get(Admin.GET_ENTERTAINMENT_4_MODERATION)
  }

  static async blockEntertainment(id: string): Promise<AxiosResponse> {
    return $api.post(Admin.BLOCK_ENTERTAINMENT, { ReklamaId: id })
  }

  static async rejectEntertainment(id: string): Promise<AxiosResponse> {
    return $api.post(Admin.REJECT_ENTERTAINMENT, { ReklamaId: id })
  }

  static async approveEntertainment(id: string): Promise<AxiosResponse> {
    return $api.post(Admin.APPROVE_ENTERTAINMENT, { ReklamaId: id })
  }

  static async getRegions4AllReservations(
    type: number
  ): Promise<AxiosResponse> {
    return $api.post(Admin.GET_REGIONS_4_All_RESERVATIONS, { type })
  }

  static async getAllReservations(data: object): Promise<AxiosResponse> {
    return $api.post(Admin.GET_ALL_RESERVATIONS, data)
  }

  static async getAllEntertainment4Reservations(): Promise<AxiosResponse> {
    return $api.get(Admin.GET_ALL_ENTERTAINMENT_4_RESERVATIONS)
  }

  static async activate1SForDealer(
    id: string,
    bool: boolean
  ): Promise<AxiosResponse> {
    return $api.post(Admin.ACTIVATE_1S_FOR_DEALER, { Info1: id, Bool1: bool })
  }
}
