import React, {useContext, useEffect, useRef, useState} from "react";
import {IUserEditProfile} from "../../../../models/models";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import {Context} from "../../../../index";
import "./Profile.css"
import {wait} from "@testing-library/user-event/dist/utils";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {useParams} from "react-router-dom";
import AdminService from "../../../../services/AdminService";
import ProfileAdress from "./ProfileAdress";
import {AnimatedButtonMarginDown} from "../../../customUI/AnimatedButtonMarginDown/AnimatedButtonMarginDown";
import KladrInputRegion from "../../../customUI/CustomDropdown/KladrInputRegion";
import KladrInputCity from "../../../customUI/CustomDropdown/KladrInputCity";

function Profile() {
    const [isVisible, setIsVisible] = useState(true)
    let {userId} = useParams();


    const {store} = useContext(Context)
    const [loadedUserData, setLoadedUserData] = useState(false)
    const [phoneError, setPhoneError] = useState<string>("")
    const [postCodeError, setPostCodeError] = useState<string>("")
    const [numberDiplomError, setNumberDiplomError] = useState<string>("")
    const [numberInnError, setNumberInnError] = useState<string>("")
    const [userData, setUserData] = useState<IUserEditProfile>({});
    const [oldData, setOldData] = useState<IUserEditProfile>({});
    const [realRegionId, setRealRegionId] = useState("")
    const [buttonStyle, setButtonStyle] = useState({backgroundColor: "#202020"})
    const [textFieldStyle, setTextFieldStyle] = useState(false)

    const [previewStyle, setPreviewStyle] = useState<string>("smal")
    const [activate, setActivate] = useState(true)
    const inputRef = useRef(null);
    const input2Ref = useRef(null);

    const [profileFile, setProfileFile] = useState<File>()
    const [previewProfile, setPreviewProfile] = useState<string>()
    const [diplomFile, setDiplomFile] = useState<File>();
    const [previewDiplom, setPreviewDiplom] = useState<string>()

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[loadedUserData])

    useEffect(() => {
        if (!loadedUserData)
            if (store.roles.includes('Admin') && userId) {
                setIsVisible(false)
                AdminService.getCustomer(userId).then(response => {
                    let fixdata = response.data;
                    if (response.data["postCode"] == 0) fixdata["postCode"] = "";
                    setUserData(fixdata);
                    setOldData(fixdata);
                    if(response.data["profilePictureUrl"])
                        setPreviewProfile(store.imgServer + response.data["profilePictureUrl"]);
                    else
                        setPreviewProfile("/Pictures/supplierNoImage.svg");
                    if(response.data["diplomPictureUrl"])
                        setPreviewDiplom(store.imgServer + response.data["diplomPictureUrl"])
                    else
                        setPreviewDiplom("/Pictures/supplierNoImage.svg")
                })
                    .then(() => setLoadedUserData(true))
            } else {
                CabinetBuyerService.indexGet().then(response => {
                    let fixdata = response.data;
                    if (response.data["postCode"] == 0) fixdata["postCode"] = "";
                    setUserData(fixdata);
                    setOldData(fixdata);
                    if(response.data["profilePictureUrl"])
                        setPreviewProfile(store.imgServer + response.data["profilePictureUrl"]);
                    else
                        setPreviewProfile("/Pictures/supplierNoImage.svg");
                    if(response.data["diplomPictureUrl"])
                        setPreviewDiplom(store.imgServer + response.data["diplomPictureUrl"])
                    else
                        setPreviewDiplom("/Pictures/supplierNoImage.svg")
                })
                    .then(() => setLoadedUserData(true))
            }
    }, [])


    useEffect(() => {
        if (!profileFile) {
            setPreviewProfile(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(profileFile)
        setPreviewProfile(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [profileFile])

    useEffect(() => {
        if (!diplomFile) {
            setPreviewDiplom(undefined)
            return
        }
        const objectUrl = URL.createObjectURL(diplomFile)
        setPreviewDiplom(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [diplomFile])

    const changePreviewStyle = () => {
        if (previewStyle === "smal")
            setPreviewStyle("big")
        else
            setPreviewStyle("smal")
    }

    const handleInputChange = (e: any) => {
        const {name, value} = e.target;
        let noError = true;
        if (name == "phoneNumber") {
            if (!/_/.test(e.target.value)) {
                setPhoneError("");
            } else {
                setPhoneError("Неверно указан телефон");
                noError = false;
            }
        }
        if (name == "postCode") {
            if (!/_/.test(e.target.value) || e.target.value === "") {
                setPostCodeError("");
            } else {
                setPostCodeError("Неверно указан индекс");
                noError = false;
            }
        }
        if (name == "numberDiplom") {
            if (!/_/.test(e.target.value) || e.target.value === "") {
                setNumberDiplomError("");
            } else {
                setNumberDiplomError("Неверно указан номер диплома");
                noError = false;
            }
        }
        if (name == "numberInn") {
            if (!/_/.test(e.target.value) || e.target.value === "") {
                setNumberInnError("");
            } else {
                setNumberInnError("Неверно указан номер ИНН");
                noError = false;
            }
        }

        setUserData({
            ...userData,
            [name]: value
        });


    };

    const subYears = (num: number) => {
        let date = new Date(Date.now());
        let years = date.getFullYear() - num;
        date.setFullYear(years);
        return date;
    }

    const parseDateFromString = (input: string) => {
        let date = new Date;
        date.setDate(parseInt(input.slice(0, 2)));
        date.setMonth(parseInt(input.slice(3, 5)) - 1);
        date.setFullYear(parseInt(input.slice(6, 10)));
        let test = Date.parse(date.toDateString())
        if (isNaN(test)) {
            return null;
        } else {
            return date;
        }
    }

    const handleDateChange = (date: Date | null) => {
        let value = "";
        if (date != null) {
            date.getDate() < 10 ?
                value += "0" + date.getDate().toString()
                : value += date.getDate().toString();
            value += ".";
            date.getMonth() + 1 < 10 ?
                value += "0" + (date.getMonth() + 1).toString()
                : value += (date.getMonth() + 1).toString();
            value += "." + date.getFullYear();
        }
        setUserData({
            ...userData,
            ["birthday"]: value
        });
    };

    const selectDiplomFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setDiplomFile(undefined)
            return
        }
        setDiplomFile(event.target.files[event.target.files.length - 1])
    };

    const selectProfilePicture = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length === 0) {
            setProfileFile(undefined)
            return
        }
        // I've kept this example simple by using the first image instead of multiple
        setProfileFile(event.target.files[event.target.files.length - 1])
    };

    const onProfileClick = () => {
        // @ts-ignore
        inputRef.current?.click();
    }

    const onDiplomClick = () => {
        // @ts-ignore
        input2Ref.current?.click();
    }
    const uploadDataHandle = () => {
        uploadData()
    }
    const deactivateSubmit = () => {
        setActivate(false);
        setButtonStyle({backgroundColor: "rgb(142, 142, 142)"})
    }
    const activateSubmit = () => {
        setActivate(true)
        setButtonStyle({backgroundColor: "#202020"});
    }


    const uploadData = () => {
        if (activate && phoneError === "" && postCodeError === "" && (numberDiplomError === ""||store.roles.includes('CompanyCustomer')) &&
            numberInnError === "" ) {
            deactivateSubmit()
            const createFormData = (data: any) => {
                return Object.keys(data).reduce((formData, key) => {
                    formData.append(key, data[key]);
                    return formData;
                }, new FormData());
            };
            const refactorUserData = () => {
                let mydata: any = {...userData}
                for (const key in mydata) {
                    if (mydata[key] === null || mydata[key] === undefined)
                        delete mydata[key]
                }
                return mydata
            }
            const data = createFormData(refactorUserData());
            if (profileFile) data.append("profileFile", profileFile)
            if (diplomFile) data.append("diplomFile", diplomFile)
            CabinetBuyerService.indexPost(data).then(response => {
                if (response.status === 200) {
                    setTextFieldStyle(true);
                }
                wait(3000).then(() => {
                    activateSubmit();
                    setTextFieldStyle(false);
                })
            })
        }
    }

    const AdressList = userData.deliveryAddresses&&
        userData.deliveryAddresses.map((adr:any,index:number)=>{
            return <ProfileAdress adress={adr} index={index} />
        })

    const deleteAccount = () => {
        store.showDialog("Вы действительно хотите удалить аккаунт?", true).then(()=>{
            if(store.dialog.dialogResult === true)
            {
                store.DataLoadingON()
                CabinetBuyerService.deleteAccount()
                    .then((r) => {
                        if(r.status == 200){
                            store.logout()
                        }
                    })
                    .finally(() => store.DataLoadingOFF())
            }
        })
    }

    return (
        <>{!loadedUserData ? <div className={"loading"}>Загрузка...</div> :
            <>
                <div className={"profile-container"} data-isAdmin={!isVisible}>
                    <CustomNavigateHeader trashClickHandler={() => {
                    }} title={"Учетные данные"} withTrash={false}/>
                    <div className={"profile-nameInfo"}>
                        <div>
                            <input
                                ref={inputRef}
                                style={{display: 'none'}}
                                type="file"

                                name={"profilePicture"}
                                onChange={selectProfilePicture}
                                accept="image/*"
                            />
                            <img
                                className={"profile-image"}
                                src={previewProfile}
                                onClick={isVisible ? onProfileClick : () => undefined}
                                alt={"profile picture"}
                            />
                        </div>
                        <div className={"profile-names"}>
                            <div className={"profile-name"}>{userData["lastName"]} {userData["firstName"]}</div>
                            <div className={"profile-name"}>{userData["vatherName"]}</div>
                            <div className={"profile-id"}>ID: {userData["id"]}</div>
                        </div>
                    </div>
                    <div className={"input_text"}>Телефон</div>
                    <InputMask
                        id={"input-Phone"}
                        mask="+7 (999) 999-99-99"
                        className={"input-field"}
                        onChange={(event) => handleInputChange(event)}
                        name={"phoneNumber"}
                        value={userData["phoneNumber"]}
                        placeholder={oldData["phoneNumber"]}
                        disabled={!isVisible}
                        required
                    />
                    <div className={"invalid-feedback"} style={{color: "red", marginLeft: "12px"}}>{phoneError}</div>
                    <div className={"input_text"}>Дата рождения</div>
                    <div className={"profile-datePicker"}>
                        <input type={"date"}
                               name={"birthday"}
                               placeholder={"Дата рождения"}
                               disabled={!isVisible}
                               onChange={(e) => {
                                   handleDateChange(new Date(e.target.value))
                               }}
                               value={userData["birthday"] ? parseDateFromString(userData["birthday"])?.toLocaleDateString("fr-ca") : ""}
                        />
                        <img className={"profile-datePicker-image"}
                             src={"/Pictures/datePickerIcon.svg"}/>
                    </div>
                    <div className={"input_text"}>Почта</div>
                    <input
                        className={"input-field"}
                        type="text"
                        name={"email"}
                        value={userData["email"]}
                        placeholder={oldData["email"]}
                        disabled
                    />

                    <div style={{marginTop: "20px"}} className={"input-header"}>Документы</div>
                    <div className={"input_text"}>Название, которое увидят</div>
                    <input
                        className={"input-field"}
                        type="text"
                        name={"displayedName"}
                        disabled={!isVisible}
                        value={userData["displayedName"]}
                        placeholder={oldData["displayedName"]}
                        onChange={(event) => handleInputChange(event)}
                    />
                    <div className={"input_text"}>Номер ИНН</div>
                    <InputMask
                        mask={"999999999999"}
                        className={"input-field"}
                        type="text"
                        name={"numberInn"}
                        disabled={!isVisible}
                        value={userData["numberInn"]}
                        placeholder={oldData["numberInn"]}
                        onChange={(event) => handleInputChange(event)}
                    />
                    <div className={"invalid-feedback"}
                         style={{color: "red", marginLeft: "12px"}}>{numberInnError}</div>

                    <div className={"input_text"}>Регион</div>

                    <div className={"user-select-component-input"}>
                        <KladrInputRegion
                            icon={isVisible}
                            disabled={!isVisible}
                            dropdownSize={"small"}
                            value={userData.regionId}
                            valueSetter={(val) => setUserData({...userData, regionId: val})}
                            onChangeHandler={(name, key) => {
                                console.log(name)
                                if(oldData.regionId != name) {
                                    setUserData({...userData, regionId: name, cityId: ""})
                                    setRealRegionId(key)
                                }
                            }}
                        />
                    </div>

                    <div className={"input_text"}>Город</div>

                    <div className={"user-select-component-input"}>
                        <KladrInputCity
                            icon={isVisible}
                            disabled={!isVisible}
                            dropdownSize={"small"}
                            regionId={realRegionId}
                            value={userData.cityId}
                            valueSetter={(val) => setUserData({...userData, cityId: val})}
                            onChangeHandler={(val) => setUserData({...userData, cityId: val})}
                        />
                    </div>

                    {!store.roles.includes('CompanyCustomer') &&
                        <>
                            <div className={"input_text"}>Номер диплома о ВО</div>
                            <InputMask
                                id={"input-numberDiplom"}
                                mask={"9999999"}
                                className={"input-field"}
                                type="text"
                                name={"numberDiplom"}
                                disabled={!isVisible}
                                value={userData["numberDiplom"]}
                                placeholder={oldData["numberDiplom"]}
                                onChange={(event) => handleInputChange(event)}
                            />
                            <div className={"invalid-feedback"}
                                 style={{color: "red", marginLeft: "12px"}}>{numberDiplomError}</div>
                            <div className={"file-container"}>

                                <img className={"preview diplom-image"}
                                     data-style={previewStyle}
                                     onClick={changePreviewStyle}
                                     src={previewDiplom} alt={"diplom"}/>

                                <div className={"file-button-box"}>
                                    {diplomFile ?
                                        <div className={"diplom-Text"}>{diplomFile.name}</div>
                                        :
                                        <div className={"diplom-Text"}>Скан/Фото диплома</div>
                                    }
                                    <label className={"input-file-trigger"} onClick={onDiplomClick}
                                           style={isVisible ? {} : {display: "none"}}>
                                        <img className={"clip_image"} src="/Pictures/Clip.svg"/>

                                        Прикрепить
                                    </label>
                                    <input
                                        ref={input2Ref}
                                        type="file"
                                        id="diplom"
                                        name={"diplom"}
                                        className={"input-file"}
                                        onChange={selectDiplomFile}
                                        accept="image/*"
                                    />
                                </div>
                            </div>
                        </>}

                    <div id={"upload-message"} style={{visibility: textFieldStyle ? "visible" : "collapse"}}>Обновление
                        успешно.
                    </div>
                    {/*<div className={"upload_button"} onClick={uploadDataHandle} style={isVisible? buttonStyle : {display:"none"}}>*/}
                    {/*    Сохранить*/}
                    {/*</div>*/}
                    <div style={{textAlign: "center", padding: "10px 0 20px"}}>
                        <AnimatedButtonMarginDown onclick={uploadDataHandle} text={"Сохранить"}/>
                    </div>
                    {/*<div style={{height:"35px"}}></div>*/}
                    {AdressList}
                    <AnimatedButtonMarginDown style={{
                        border: "solid 1px black",
                        background: "white",
                        color: "black",
                    }} onclick={async () => {
                        let newList = userData.deliveryAddresses
                        if (!newList) {
                            newList = []
                        }
                        newList.push({})
                        setUserData({
                            ...userData,
                            ["deliveryAddresses"]: newList
                        });

                    }} text={"Добавить адрес доставки"}/>

                    <div className={"profile-deleteAccount"} onClick={() => deleteAccount()}>
                        Удалить аккаунт
                    </div>
                </div>
            </>
        }
        </>
    )
}

export default Profile;