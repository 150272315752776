import './checkbox01.css'

interface ICheckboxProps {
    title?:string;
    changeHandler:(e:any)=>any;
    checked?:boolean;
    disabled?:boolean;
}

function Checkbox01({title, changeHandler, checked, disabled}:ICheckboxProps) {
    return(
        <label id={"checkbox-label"}>
            <input
                type={"checkbox"}
                name={title}
                onChange={changeHandler}
                checked={checked}
                className={"real-checkbox"}
                // disabled={disabled}
            />
            <span className={`${!disabled&&"custom-checkbox"}`}></span>
            {title}
        </label>
    )
}export default Checkbox01;