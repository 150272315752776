import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../index";
import './Catalog.css'
import {useNavigate, useParams} from "react-router-dom";
import ProductCard from "../../customUI/ProductCard/ProductCard";
import HomeService from "../../../services/HomeService";
import FilterForCatalog from "./FilterForCatalog";
import NoProductsPage from "../../customUI/NoProductsPage/NoProductsPage";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import {ICard} from "../../../models/product/productCard";
import {ToogleVisible, ToogleVisible2} from "../../customUI/Searchbar/functions";
import Searchbar from "../../customUI/Searchbar/Searchbar";
import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";

function SubCatPage() {
    const {store} = useContext(Context)
    let {id, search} = useParams()
    const [subCatObj, setsubCatObj] = useState<any>();
    const [itemArray, setitemArray] = useState<ICard[]>([]);
    const [nothingShown, setNothingShown] = useState<boolean>(false);
    const [dataLoaded, setDataLoaded] = useState(false)
    const navigate = useNavigate()
    const [searchword, setSearchword] = useState<string>("");

    useEffect(() => {
        setSearchword(search ? search.slice(1) : "");
    })

    if (search === undefined) {
        search = "";
    }

    const getData = () => {
        if(store.filterInfo.topId && store.filterInfo.topId != "-1") {
            HomeService.catalog(store.filterInfo.subId, store.filterInfo).then(response => {
                setsubCatObj(response.data[0]);
                let cards = (response.data[0].cards.map((card: ICard) => {
                        card.visible = [true];
                        card.content = {Name: card.name_of_product, Id: card.productId, Seller: card.supply_company};
                        return card;
                    }
                ));
                setitemArray(ToogleVisible2(searchword, ["Name", "Id", "Seller"], ["", "", ""], cards))
            })
                .finally(() => {
                    setDataLoaded(true)
                    store.DataLoadingOFF()
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        let oldArray = [{cards: itemArray}]
        setitemArray(ToogleVisible2(searchword, ["Name", "Id", "Seller"], ["", "", ""], itemArray))
    }, [searchword])

    const changeHandler = (text: string) => {
        navigate(SpaRoutes.SUB_CATEGORY + `/${id}` + `/:${text}`)
    }

    useEffect(() => {
        if (itemArray.length == 0)
            setNothingShown(true);
        else if (itemArray.every((item) => {
            return item.visible&&!item.visible[0]
        }))
            setNothingShown(true);
        else
            setNothingShown(false);
    }, [itemArray, searchword])

    /*const SubCardsList: any = () => {
        var list = [];
        for (let i = 0; i < itemArray.length; i++) {
            console.log(itemArray[i].name_of_product, itemArray[i].visible)
            if (itemArray[i].visible) {
                console.log(itemArray[i].name_of_product, itemArray[i].visible)
                list.push(
                    <ProductCard
                        isAuth={store.roles.includes('Customer')}
                        key={itemArray[i].productId}
                        item={itemArray[i]}
                        // style={"product_wrap"}
                    />
                )
            }
        }
        return (list)
    }*/

    const goBack = () => {
        let filter = store.filterInfo
        console.log('filter', filter)
        filter.subId = "-1"
        filter.subCatName = "Подкатегория"
        store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
        navigate(SpaRoutes.CATEGORY + `/${subCatObj.topCatId}/:` + (searchword? searchword : ""))
    }

    return (
        <div id={"CatSelect"}>
            <div id={"CatSelectBox"}>
                <CustomNavigateHeader
                    trashClickHandler={() => {
                    }}
                    title={subCatObj && subCatObj.catName}
                    withTrash={false}
                    onClick={() => goBack()}
                />
                <div className={"catalog_searchBox"}>
                    <Searchbar
                        changeHandler={changeHandler}
                        initialText={"Искать"}
                        value={search.slice(1)}
                    />
                </div>
            </div>

            <FilterForCatalog onUpdate={() => getData()} searchword={searchword}/>
            {!store.isDataLoading &&
            nothingShown ?
                <NoProductsPage
                    tittle={"Не смогли ничего для вас найти"}
                    text={"Не нашли по вашему запросу ни одного продукта, попробуйте изменить параметры фильтра или очистить его"}
                    searchWord={searchword}
                />
                :
                <div id={"card-container"}>
                    {itemArray && itemArray.map((c, i) => {
                        if (c.visible && c.visible[0]) {
                            return (
                                <ProductCard
                                    isAuth={store.roles.includes('Customer') || store.roles.includes("Dealer")}
                                    key={itemArray[i].productId}
                                    item={itemArray[i]}
                                    // style={"product_wrap"}
                                />
                            )
                        }
                    })}
                </div>
            }
        </div>
    )
}

export default SubCatPage;

