import {IdataArray} from "./multipleCheckboxes";
import {ChangeEvent} from "react";

interface IselectAllProps {
    setstate2:(prevState:any)=>void;
    setstate1:any;
    checked:boolean;
}
interface ItoggleCheckProps {
    level2name?:any;
    setstate2:(prevState:any)=>void;
    setstate1:any;
    event:ChangeEvent<HTMLInputElement>;
}

export async function selectAllLevel3({setstate1, setstate2, checked}: IselectAllProps) {
    await setstate1(checked);
    await setstate2((prevState: any[]) => {
        const newState: any[] = {...prevState};
        for (const element in newState) {
            newState[element].checked = checked;
            for (const inputName in newState[element].suppliercards) {
                newState[element].suppliercards[inputName].checked = checked;
            }
        }
        return newState;
    });
};

export async function selectAllLevel2({setstate1, setstate2, event}: ItoggleCheckProps) {
    let level3 = true;
    await setstate2((prevState: any[]) => {
        const newState: any[] = {...prevState};
        let name: any = event.target.name;
        newState[name].checked = event.target.checked;
        for (const inputName in newState[name].suppliercards)
            newState[name].suppliercards[inputName].checked = event.target.checked;
        for (const key in newState)
            if(!newState[key].checked){
                level3 = false
                break;
            }
        return newState;
    })
    setstate1(level3)
};
export async function toggleCheck({setstate2, setstate1, event, level2name}: ItoggleCheckProps) {
    let level2 = true;
    let level3 = true;

    await setstate2((prevState: any[]) => {
        const newState: any[] = {...prevState};
        let name: any = event.target.name;
        newState[level2name].suppliercards[name].checked = event.target.checked;
        for (const key in newState[level2name].suppliercards)
            if (!newState[level2name].suppliercards[key].checked) {
                level2 = false
                break;
            }
        newState[level2name].checked = level2;
        for (const key in newState)
            if (!newState[key].checked) {
                level3 = false
                break;
            }
        return newState;
    });
    setstate1(level3)
};