import React from "react";
import "./MyOrderPageModals.css";

interface ModalProps {
    children: React.ReactNode;
    title: string;
    text:string;
    canceled:boolean;
    orderId:string;
    nameOfProduct:string;
    onClose: () => void;
    openSmallModal:any;
    handler:any;
}

export function MyOrderBigModal(props : ModalProps) {

    const OnClickHandler = () => {
        props.handler(props.canceled,props.orderId);
        if(!props.canceled) props.openSmallModal();
        props.onClose();
    }
    return (
        <>
            <div id="mop-bigM-close-wrapper" onClick={props.onClose} />
            <div id="mop-bigM-wrapper">
                <div id="mop-bigM-container">
                    <div id="mop-bigM-close-container" onClick={props.onClose}>
                        <img src="/Pictures/Закрыть.png" id="mop-bigM-close_button"/>
                    </div>
                    <span id="mop-bigM-text-ty-for-order">{props.title}</span>
                    <span id="mop-bigM-text-notification">{props.text + " " + props.nameOfProduct + "?"}</span>
                    <div className="mop-bigM-line"></div>
                    <span className={"mop-bigM-choice-element mop-bigM-choice-element-agree"} onClick={OnClickHandler}>Да</span>
                    <div className="mop-bigM-vl"></div>
                    <span className={"mop-bigM-choice-element-disagree"} onClick={props.onClose}>Нет</span>
                </div>
            </div>
        </>
    )
}