import React, {useContext, useEffect} from 'react';
import Header from "./Header/Header";
import UserMenuAuth from "./MenuSticky/UserMenuAuth/UserMenuAuth";
import {Context} from "../../../index";
import UserMenu from "./MenuSticky/UserMenu/UserMenu";
import {observer} from "mobx-react-lite";
import ContextProviderContainer from "../../../ContextProviderContainer";
import "./UserLayout.css"

function UserLayout(){
    const {store} = useContext(Context)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])

    return(
        <div className={"user-layout"}>
            <div className={"user-header"}>
                <Header CartitemsNumber={store.CartItemsNumber} unread={store.unread}/>
            </div>
            <div className={"user-content"}>
                <Context.Provider value={{store}}>
                    <ContextProviderContainer/>
                </Context.Provider>
            </div>
        </div>
)
}export default observer (UserLayout)

