import {Link} from "react-router-dom";
import React from "react";
import './RoundLinkTo.css'
interface Interface {
    link:string;
    state?:any;
}

export const RoundLinkTo = ({link, state}:Interface) => {
  return(
      <div className={"RoundLinkTo"}>
          <Link to={link} state={state}>
              <div>
                  <img src={"/Pictures/invoicetablelink.svg"}/>
              </div>
          </Link>
      </div>
  )
}