import {Link} from "react-router-dom";
import React, {useEffect} from "react";
import SuppliersCompanyItem from "./SuppliersCompanyItem";

export default function SuppliersCompanyList(dataOverviewDealer:any){

    const ListOfSuppliers = dataOverviewDealer.map((company:any) => (
        SuppliersCompanyItem(company)))


    return(
        dataOverviewDealer && (
            <>
                {ListOfSuppliers}
            </>
        )
    )
}