import { Swiper, SwiperSlide } from "swiper/react";

// Import CustomSwiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./CustomSwiperSupplier.css"

// import required modules
import { FreeMode, Pagination } from "swiper";
import {ICustomSwiperSupplier} from "../../../models/models";
import ProductCardSupplier from "./ProductCardSupplier/ProductCardSupplier";

export default function CustomSwiperSupplier({data}:ICustomSwiperSupplier) {
    let ListCards:any = [];
    if(data)
        if(data.length!=0)
            ListCards = data.map((card)=>{
                if("visible" in card ? card.visible:true) {
                    return (
                            <SwiperSlide style={{width:"auto",height:"auto"}}>
                                <ProductCardSupplier
                                    key={"rootId" in card ? card.rootId : "id"
                                    in card ? card.id : "productId"
                                    in card ? card.productId :"0"}
                                    item={card}
                                    rootId={"rootId" in card ? card.rootId :"0"}
                                    productId={"productId" in card ? card.productId :"0"}
                                    questionId={"questionId" in card ? card.questionId :"0"}
                                    linkToReview={"linkToReview" in card ? card.linkToReview : undefined}
                                />
                            </SwiperSlide>
                    )
                }

            })
    return (<>
            {data&&data.length!=0&&
                <div className="supplier">
                    <Swiper
                        breakpoints={{
                            // when window width is <1100px (480px)
                            0: {
                                slidesPerView: 2.533333, //подобрано для ширины окна 140px;
                            },
                            // when window width is 1100px<w<1600px (1366px)
                            1100: {
                                slidesPerView: 5.637, //Подобрано таким образом, чтобы div одного слайда был 207px
                            },
                            // when window width is 1600px<w<1920px (1920px)
                            1600: {
                                slidesPerView: 6,
                            },
                        }}
                        spaceBetween={10}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="mySwiper"
                    >
                        {ListCards}
                    </Swiper>
                </div>
            }
        </>
    );
}