
import {ChangeEvent, useState} from "react";
import './Filterbox.css'



export interface IFilterBoxProps {
    title?:string;
    allButton?:IFilterbutton;
    filterButtons:IFilterbutton[];
    updateFilter:(strList:string)=>void;
    filterList:string[];
}
export interface IFilterbutton{
    updateFilter?:(strList:string)=>void;
    filterList?:string[];
    button:any;
    value:string;
}
const FilterButton = (props:IFilterbutton) =>{
    let active = props.filterList?props.filterList.some((item)=>{return item===props["value"]}):false;
    if (props["value"]==="-1"){
        if(props.filterList&&props.filterList.length===0){
            active = true;
        }
    }
    return(
    <div
        className={active?"FilterElementActive":"FilterElement"}
        onClick={()=>{ props.updateFilter&&props.updateFilter(props["value"])}}>
        <div>{props["button"]}</div>
    </div>
    )

}
const Filterbox = (props:IFilterBoxProps)=> {

    return(
        <div className={"FilterContainer"}>
            {props.title&&<div className={"FilterHeader"}>{props.title}</div>}
            <div className={"FilterBox"}>
                {props.allButton&&FilterButton({
                    ...props.allButton,
                    updateFilter:props.updateFilter,
                    filterList:props.filterList})}
                {props.filterButtons.map((item)=>{return FilterButton({
                    ...item,
                    updateFilter:props.updateFilter,
                    filterList:props.filterList})})}
            </div>
        </div>
    )
}
export default Filterbox;

