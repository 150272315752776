import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import './Layout.css'
import UserLayout from "./UserLayout/UserLayout";
import SellerLayout from "./SellerLayout/SellerLayout";
import AdminLayout from "./AdminLayout/AdminLayout";

function Layout() {
    const {store} = useContext(Context)
    let viewport = document.querySelector("meta[name=viewport]");

    async function refresh() {
        await store.checkAuth()
    }

    useEffect(() => {
        refresh()
    }, [])

    let body = document.body

    useEffect(() => {
        if (store.roles.includes("Customer") || (!store.isAuth)) {
            if (window.screen.width <= 500 && window.screen.width > 390) {
                viewport?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no');
            } else if (window.screen.width <= 390 && window.screen.width > 320) {
                viewport?.setAttribute('content', 'width=device-width, initial-scale=0.9, maximum-scale=0.9, minimum-scale=0.9, user-scalable=no');
            } else if (window.screen.width <= 320) {
                viewport?.setAttribute('content', 'width=device-width, initial-scale=0.8, maximum-scale=0.8, minimum-scale=0.8, user-scalable=no');
            } else {
                viewport?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no');
            }
            body.style.touchAction = "pan-y"
        } else {
            viewport?.setAttribute('content', 'width=device-width, initial-scale=1, user-scalable=yes');
            body.style.touchAction = "auto"
        }
    }, [store.roles, store.isAuth]);

    const [showCookie, setShowCookie] = useState(false)
    const [showCookiePopup, setShowCookiePopup] = useState(false)

    const handlerCookiePopup = () => {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
        document.cookie = "cookie_notice_accepted=true; expires=" + expirationDate.toUTCString();
        setShowCookie(false)
    }

    const hideMobileKeyboardOnReturn = (element: HTMLInputElement) => {
        element.addEventListener('keyup', (keyboardEvent) => {
            if (keyboardEvent.code === 'Enter') {
                element.blur();
            }
        });
    };

    document.querySelectorAll<HTMLInputElement>('[type=search]').forEach((element) => {
        hideMobileKeyboardOnReturn(element);
    });

    useEffect(() => {
        const cookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('cookie_notice_accepted'));
        const isMobile = localStorage.getItem("isMobile")
        if (!cookie && isMobile !== "true") {
            setShowCookie(true)
        }
    }, [])

    return (
        <div
            onClick={() => {
                store.ErrorOFF()
            }}
            className={"layout-div"}
        >
            {(store.roles.includes('Customer') || (!store.isAuth)) && <UserLayout/>}
            {(store.roles.includes('Dealer') && (store.isAuth)) && <SellerLayout/>}
            {(store.roles.includes('Admin') && (store.isAuth)) && <AdminLayout/>}
            {showCookie &&
                <>
                    <div className={"cookie-notice"}>
                        <div>
                            Продолжая просмотр сайта, вы соглашаетесь на использование файлов <b
                            onClick={() => setShowCookiePopup(!showCookiePopup)}>Cookie</b>, которые используются для
                            улучшения пользовательского опыта и необходимы для работы сайта.
                        </div>
                        <button onClick={handlerCookiePopup}>
                            Хорошо
                        </button>

                    </div>
                    {showCookiePopup &&
                        <div className={"cookie-notice-popup"}>
                            <div className={"cookie-notice-popup-container"}>
                                <div className={"cookie-notice-popup-header"}>
                                    <div></div>
                                    <div>Согласие на обработку персональных даннных</div>
                                    <div onClick={() => setShowCookiePopup(false)}><img
                                        src={"/Pictures/BlackCross.svg"}/></div>
                                </div>
                                <div className={"cookie-notice-popup-content"}>
                                    <p>Настоящим, свободно, своей волей и в своем интересе выражаю свое согласие ООО
                                        «Тика», 614007, Пермский край, г.о. Пермский, г. Пермь, ул. 25 Октября, д. 77
                                        (далее
                                        Оператор) на автоматизированную обработку моих персональных данных в
                                        соответствии со
                                        следующим перечнем персональных данных:
                                    </p>
                                    <ul>
                                        <li>- мои запросы как посетителя сайта;</li>
                                        <li>- системная информация, данные из моего браузера;</li>
                                        <li>- файлы cookie;</li>
                                        <li>- мой IP-адрес;</li>
                                        <li>- установленные на моем устройстве операционные системы;</li>
                                        <li>- установленные на моем устройстве типы браузеров;</li>
                                        <li>- установленные на моем устройстве расширения и настройки цвета экрана;</li>
                                        <li>- установленные и используемые на моем устройстве языки;</li>
                                        <li>- поддержка JavaScript;</li>
                                        <li>- типы мобильных устройств, используемых мной, если применимо;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </div>
    )
}

export default observer(Layout)

