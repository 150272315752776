import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import UserHome from "./UserHome/UserHome";
import SellerHome from "./SellerHome/SellerHome";
import AdminHome from "./AdminHome/AdminHome";
import "./Home.css"
const Home = () => {
    const {store} = useContext(Context)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            {(store.roles.includes('Customer') || (!store.isAuth)) && <UserHome/>}
            {(store.roles.includes('Dealer') && (store.isAuth)) && <SellerHome/>}
            {(store.roles.includes('Admin') && (store.isAuth)) && <AdminHome/>}
        </div>
    )
}
export default observer(Home)
