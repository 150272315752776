import React, {useEffect} from "react";
import './DropdownLocation.css';
import {useState, useRef} from "react";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import {ISupplierAdressWithNullAdress} from "../../../../../models/supplier/supplier";

interface Interface {
	arr?: ISupplierAdressWithNullAdress[];
	SetAddress: (invoiceId: string, address: string, isDrop: boolean) => void;
	invoiceId: string;
	addressId: string;
	delivery: boolean;
	setPickAdress: (val: boolean) => void;
}

function DropdownLocation({arr, addressId, delivery, SetAddress, invoiceId, setPickAdress}: Interface) {
// function DropdownLocation({arr}:Interface) {
	const [details, setDetails] = useState(false)
	//const [change, setChange] = useState(arr && arr[0].streetandNumber)
	let initialInput = "Выберите адрес"
	if (addressId && addressId !== "" && arr) {
		arr.map((item) => {
			if (item.id === addressId) {
				initialInput = item.streetandNumber ? item.streetandNumber : "Выберите адрес"
			}
		})
	}
	const [change, setChange] = useState(initialInput)
	//const [switchHide,setSwitchHide] = useState(arr && arr[0].streetandNumber);
	const [switchHide, setSwitchHide] = useState("");
	const buttonRef = useRef(null);
	
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	
	function handlerChange(str: string, addrId: string | undefined, isDrop: boolean) {
		setChange(str);
		setDetails(!details);
		setPickAdress(false);
		setSwitchHide(str);
		addrId && SetAddress(invoiceId, addrId, isDrop);
	}
	
	function showList(item: string, isDrop: boolean, addrId: string | undefined) {
		if (item != switchHide) {
			return (< div className={"item"} onClick={() => handlerChange(item, addrId, isDrop)}>
				<div className={"items-location-button"}>{item}</div>
				<div className="item-kind-of-delivery">
					<img className={"item-img-of-kind"} src={isDrop ? "Pictures/VectorStock.svg" : "Pictures/GroupDelivery.svg"}/>
				</div>
			</ div>);
		}
	}
	
	const list = arr && arr.map((item) => (item.streetandNumber &&
		showList(item.streetandNumber, item.isDrop, item.id))
	);
	
	useOnClickOutside(buttonRef, () => {
		setDetails(false)
	})
	
	return (
		<div ref={buttonRef} className={(!details) ? ("dropdown-menu-wrapper open-dd-location")
			: ("dropdown-menu-wrapper")}>
			< div className="header-wrapper" onClick={() => setDetails(prev => !prev)}
			      style={change !== "Выберите адрес" ? {boxShadow: "0px 0px 5px #01ABA2"} : {}}>
				<div className={"header-location-button"}>{change}</div>
				<button className="header-button">
					<img className={"location-header-show-button"} src={"Pictures/Vector 8black.svg"}/>
				</button>
			</ div>
			{details && <div className="items-wrapper">
				{list}
				{delivery &&
					< div style={{justifyContent: "center"}} className={"item"} onClick={() => {
						setDetails(false);
						setPickAdress(true)
					}}>
						<div className={"items-location-button"}>Добавить новый адрес</div>
						{/*<div className= "item-kind-of-delivery">*/}
						{/*    <img className={"item-img-of-kind"} src={isDrop?"Pictures/VectorStock.svg":"Pictures/GroupDelivery.svg"}/>*/}
						{/*</div>*/}
					</ div>}
			</ div>
			}
		</div>
	)
}

export default DropdownLocation