import React, {useContext, useEffect} from 'react';
import Header from "./Header/Header";
import ContextProviderContainer from "../../../ContextProviderContainer";
import {Context} from "../../../index";
import "./AdminLayout.css"
import {observer} from "mobx-react-lite";

function AdminLayout() {
    const {store} = useContext(Context)

    return (
        <div className={"admin-layout"}>
            <div className={"admin-header"}>
                <Header unread={store.unread}/>
            </div>
            <div className={"admin-content"}>
                <Context.Provider value={{store}}>
                    <ContextProviderContainer/>
                </Context.Provider>
            </div>
        </div>
    )
}
export default observer (AdminLayout)

