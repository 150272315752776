import React from "react";
import './FunctionButton.css'

interface IFunctionButton {
    text:string;
    onClickHandler:any;
    style?: React.CSSProperties | undefined;
}

export default function FunctionButton({text, onClickHandler, style}:IFunctionButton){

    return(
        <span onClick={onClickHandler} id={"f-button-wrapper"} style={style}>
            {text}
        </span>
    )
}
