import CustomNavigateHeader from "../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import './AboutUs.css'
import './AboutUs-media.css'
import React, {useContext, useEffect, useState} from "react";
import {IBaseInformation} from "../../../models/models";
import {Context} from "../../../index";
import HomeService from "../../../services/HomeService";

export const AboutUs = () => {
    const {store} = useContext(Context)
    const [baseInfo, setBaseInfo] = useState<IBaseInformation>()
    useEffect(() => {
        store.DataLoadingON()
        HomeService.getBaseInformation()
            .then((response) => setBaseInfo(response.data))
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF())

    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function createMarkup(a: any) {
        if (a)
            return {__html: a};
        else
            return {__html: ""}
    }

    const handlerDO = () => {
        const pdfUrl = '/Files/ОФЕРТА_ПРОКРАСИВОЕ.pdf';

        window.open(pdfUrl, '_blank');
    }

    const handlerPP = () => {
        const pdfUrl = '/Files/ПОЛИТИКА_ПРОКРАСИВОЕ.pdf';

        window.open(pdfUrl, '_blank');
    }

    return (
        <>{(!store.isError && !store.isDataLoading) &&
            <>
                <CustomNavigateHeader trashClickHandler={alert} title={'ООО "Тика"'} withTrash={false}/>
                <div className={"aboutUs-div"}>
                    <p className={"aboutUs-text"}>
                        Компания ООО "Тика" является правообладателем данного сервиса «prokrasivoe.ru», посредством
                        которого возможно упростить бизнес процесс в секторе B2B сферы косметологии. Путь от поставщика
                        медицинских препаратов и прочих косметологических товаров до потребителя ( клиники, салона
                        красоты) сокращен.
                    </p>
                    <p className={"aboutUs-text"}>
                        Наша площадка предоставляет поставщикам возможность размещать свои товары с полным описанием.
                        Косметолог, клиника, являясь конечным покупателем, имеет возможность ознакомиться с товаром и
                        ценами на этой площадке.
                        Сервис «prokrasivoe.ru» автоматизирует сбор и обработку товаров, что ускоряет рутинные процессы
                        при закупе товара.
                        Сервис «prokrasivoe.ru» автоматизировано помогает отслеживать заказы, управлять логистикой и
                        складами. Общение с клиентами можно поручить чат-ботам, чтобы ускорить обработку запросов.
                    </p>
                    <p className={"aboutUs-text"}>
                        Сервис «prokrasivoe.ru» планирует предоставить площадку для развития дистанционного образования
                        в сфере косметологии.
                    </p>
                    <p className={"aboutUs-text"}>
                        Автоматизация и управление бизнес-процессом в сегменте B2B производится в рамках единой
                        платформы
                    </p>
                    <p className={"aboutUs-text aboutUs-text-bold"}>
                        Разработанная программа  включена  в реестр отечественного ПО.
                    </p>
                </div>

                <h1 style={{marginTop: "0px"}} className={"aboutUs-header"}>Адрес и контакты:</h1>
                <div className={"aboutUs-div"} dangerouslySetInnerHTML={createMarkup(baseInfo?.contactText)}>

                </div>

                <h1 style={{marginTop: "0px"}} className={"aboutUs-header"}>Документы:</h1>
                <div className={"aboutUs-documents"}>
                    <div>
                        •
                        <button onClick={handlerDO} className={"aboutUs-documents-button"}>
                            Договор оферты
                        </button>
                    </div>
                    <div>
                        •
                        <button onClick={handlerPP} className={"aboutUs-documents-button"}>
                            Договор политики персональных данных
                        </button>
                    </div>
                </div>
            </>}
        </>
    )
}