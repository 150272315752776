export const ApiRoutes = {
  Authenticate: {
    LOGIN: "/Authenticate/login",
    LOGOUT: "/Authenticate/logout",
    REGISTER: "/Authenticate/register",
    REGISTER_KOSMETOLOG: "/Authenticate/register-kosmetolog",
    REGISTER_DEALER: "/Authenticate/register-dealer",
    REFRESH_TOKEN: "/Authenticate/refresh-token",
    USER_INFO: "/Authenticate/userInfo",
    ACTIVATE_EMAIL: "/Home/activateUser",
    RESEND_EMAIL_ACTIVATION: "/Home/resendEmail",
    RESEND_PAROL_ACTIVATION: "/Home/resendParol",
    CHANGE_PAROL: "/Home/changeParol",
    //FAVORITES: "/favorites",
  },

  Home: {
    INDEX_PRODUCT: "/Home/indexProduct",
    INDEX_WELCOME: "/Home/indexWelcome",
    INDEX_REKLAMA: "/Home/indexReklama",
    MAIN_PAGE_REKLAMA: "/Home/mainPageReklama",
    INDEX_SLIDER: "/Home/indexSlider",
    INDEX_CAT: "/Home/indexCat",
    CAT_LIST: "/Home/catList",
    CATALOG: "/Home/catalog",
    ALL_CATALOG: "/Home/allCatalog",
    REGIONS: "/Home/regions",
    REGIONSFORSELLER: "/Home/regionsForSellers",
    MY_CITY: "/Home/myCity",
    SELLERS_OF_CITY: "/Home/sellersOfCity",
    DETAIL_VIEW_PRODUCT: "/Home/detailViewProduct",
    KLADR: "/Home/kladr",
    OVERVIEW_DEALER_REGION: "/Home/overviewDealerRegion",
    CATEGORIES: "/Home/categories",
    CATEGORIES_WITH_FILTER: "/Home/categoriesWithFilter",
    SETREGION: "/Home/setRegion",
    GET_BASE_INFORMATION: "/Home/BaseInformation",
    USERNAME: "/Home/UserName",
  },

  CabinetBuyer: {
    BASKET: "/CabinetBuyer/Basket",
    BASKET_DELETE_ALL_ORDERS: "/CabinetBuyer/basketdeleteAllOrders",
    BASKET_DELETE_ORDER: "/CabinetBuyer/basketdeleteOrder",
    WATCHLIST_SWITCH: "/CabinetBuyer/watchlistSwitch",
    BASKET_UPDATE_AMOUNT: "/CabinetBuyer/basketUpdateAmount",
    BASKET_INSERT: "/CabinetBuyer/basketInsert",
    INDEX_GET: "/CabinetBuyer/indexGet",
    INDEX_POST: "/CabinetBuyer/indexPost",
    PRODUCT_INFO_FOR_BASKET: "/CabinetBuyer/productInfoForBasket",
    BASKET_NOT_EMPTY: "/CabinetBuyer/BasketNotEmpty",
    FAVORITES: "/CabinetBuyer/favorites",
    MY_ORDERS: "/CabinetBuyer/myOrders",
    ORDER_FORMATION_NOT_EMPTY: "/CabinetBuyer/OrderFormationNotEmpty",
    HISTORY_VIEWED: "/CabinetBuyer/historyViewed",
    SET_INVOICE: "/CabinetBuyer/SetInvoice",
    INVOICE: "/CabinetBuyer/Invoice",
    CANCEL_ORDER: "/CabinetBuyer/cancelOrder",
    REVERT_ORDER: "/CabinetBuyer/revertOrder",
    SET_STATUS_TWO: "/CabinetBuyer/SetStatusTwo",
    ORDER_FORMATION_DELETE_INVOICE: "/CabinetBuyer/OrderFormationDeleteInvoice",
    ORDER_FORMATION: "/CabinetBuyer/OrderFormation",
    SET_PAYMENT: "/CabinetBuyer/SetPayment",
    TRAINING: "/CabinetBuyer/GetAllCalendarEvent",
    SING_UP_FOR_EVENT: "/CabinetBuyer/SignUpForEvent",
    OR_FO_SET_ADRESS: "/CabinetBuyer/OrderFormationSetAdress",
    OR_FO_SET_COMMENT: "/CabinetBuyer/OrderFormationSetComment",
    OR_FO_SET_TIME: "/CabinetBuyer/OrderFormationSetTime",
    UPDATE_SINGLE_ADRESS: "/CabinetBuyer/UpdateSingleAdress",
    UPDATE_SINGLE_ADRESS_DELETE: "/CabinetBuyer/UpdateSingleAdressDelete",
  },
  CabinetSeller: {
    INVOICE_LISTS: "/CabinetSeller/InvoiceLists",
    SHOPS: "/CabinetSeller/Magazines",
    SHOPS_FOR_CREATE_PRODUCT: "/CabinetSeller/MagazinesForCreateProduct",
    PRODUCTS: "/CabinetSeller/MyProducts",
    PROFILE_INFO: "/CabinetSeller/ProfileInfo",
    PROFILE_AVATAR: "/CabinetSeller/ProfileAvatar",
    PROFILE_LOGO: "/CabinetSeller/ProfileLogo",
    UPDATE_INVOICE_STATUS: "/CabinetSeller/InvoiceSetStatus",
    PROFILE_PERSONAL_DATA: "/CabinetSeller/ProfilePersonalData",
    PROFILE_COMPANY_DATA: "/CabinetSeller/ProfileCompanyData",
    PROFILE_CHANGE_PAROL: "/CabinetSeller/ProfileChangeParol",
    ROOT_QUESTION_INFO: "/CabinetSeller/MyRootQuestionInfo",
    ROOT_QUESTION_ADD: "/CabinetSeller/MyRootQuestionAdd",
    ROOT_QUESTION_REMOVE: "/CabinetSeller/MyRootQuestionRemove",
    ROOT_QUESTION_UPDATE: "/CabinetSeller/MyRootQuestionUpdate",
    PRODUCT_INFO: "/CabinetSeller/MyProductInfo",
    ROOT_PRODUCT_INFO: "/CabinetSeller/MyRootProductInfo",
    PRODUCT_CREATE_FROM_ROOT: "/CabinetSeller/MyProductsCreateFromRoot",
    PRODUCT_UPDATE: "/CabinetSeller/MyProductUpdate",
    GET_CATALOG: "/CabinetSeller/Catalog",
    CABINET: "/CabinetSeller/Cabinet",
    ACTIVATE_PRODUCT: "/CabinetSeller/MyProductsActivate",
    DISCOUNT_PRODUCT: "/CabinetSeller/MyProductsDiscount",
    DELETE_PRODUCT: "/CabinetSeller/MyProductsFinalDelete",
    MY_PRODUCT_DELETE: "/CabinetSeller/MyProductDelete",
    GET_BONUS_TRANSACTIONS: "/CabinetSeller/GetBonusTransactions",
    GET_BONUS_BALANCE: "/CabinetSeller/GetBonusBalance",
    GET_CARD_PRICING: "/CabinetSeller/GetCardPricingTable",
    SHOPS_EXTRA: "/CabinetSeller/ManageMagazines",
    SHOPS_INFO: "/CabinetSeller/ManageMagazinesInfo",
    SHOPS_UPDATE: "/CabinetSeller/ManageMagazinesUpdate",
    SHOPS_DELETE: "/CabinetSeller/ManageMagazinesDelete",
    TRAINING: "/CabinetSeller/GetAllCalendarEvent",
    INVOICE: "/CabinetSeller/Invoice",
    MYROOT_QUESTIONS: "/CabinetSeller/MyRootQuestions",
    MYROOT_PRODUCTS: "/CabinetSeller/rootProducts",
    RETURN_DONE: "/CabinetSeller/ReturnDone",
    CANCEL_SEEN: "/CabinetSeller/CancelSeen",
    CANCEL_DONE: "/CabinetSeller/CancelDone",

    GET_ALL_UPCOMING_EVENTS: "/CabinetSeller/GetAllUpcomingEvents",
    SING_UP_FOR_EVENT: "/CabinetSeller/SignUpForEvent",
    CREATE_NEW_CALENDAR_EVENT: "/CabinetSeller/CreateNewCalendarEvent",
    DELETE_CALENDAR_EVENT: "/CabinetSeller/DeleteCalendarEvent",
    GET_CALENDAR_EVENT: "/CabinetSeller/GetCalendarEvent",
    UPDATE_CALENDAR_EVENT: "/CabinetSeller/UpdateCalendarEvent",
    DAILY_PAY_INFO: "/CabinetSeller/DailyPayInfo",
    DAILY_PAY_INFO_SINGLE: "/CabinetSeller/DailyPayInfoSingle",
    GET_BONUS_REMAINING_DAYS: "/CabinetSeller/BonusDayCount",
    CONFIRM_CANCEL: "/CabinetSeller/CancelOrder",
    UPDATE_ENTERTAINMENT: "/Entertainment/UpdateEntertainment",
    DELETE_ENTERTAINMENT: "/Entertainment/DeleteEntertainment",
    GET_MY_ENTERTAINMENT: "/Entertainment/GetMyEntertainment",
    GET_REGIONS_4_RESERVATIONS: "/Entertainment/GetRegions4Reservations",
    GET_RESERVATIONS: "/Entertainment/GetReservations",
    GET_ENTERTAINMENT_4_RESERVATIONS:
      "/Entertainment/GetEntertainment4Reservations",
    BUY_RESERVATIONS: "/Entertainment/BuyReservations",
    CHANGE_RESERVATIONS: "/Entertainment/ChangeReservations",
  },

  Admin: {
    CUSTOMER: "/Admin/Customer",
    GET_ADMIN_MARKET_KABINET: "/Admin/GetAdminMarketKabinet",
    PRODUCT_ROOT_INFO: "/Admin/ProductRootInfo",
    PRODUCT_ROOT_UPDATE: "/Admin/ProductRootUpdate",
    PRODUCT_ROOT_CREATE: "/Admin/ProductRootCreate",
    PRODUCT_ROOT_DELETE: "/Admin/ProductRootDelete",
    DEALERS: "/Admin/Dealers",
    ACTIVATE_USER: "/Admin/ActivateUser",
    GET_PRODUCT_ROOTS: "/Admin/RootQuestionList",
    CATEGORIES: "/Admin/Categories",
    TOP_CATEGORY_ADD: "/Admin/TopCategoryAdd",
    TOP_CATEGORY_UPDATE: "/Admin/TopCategoryUpdate",
    TOP_CATEGORY_DELETE: "/Admin/TopCategoryDelete",
    SUB_CATEGORY_ADD: "/Admin/SubCategoryAdd",
    SUB_CATEGORY_UPDATE: "/Admin/SubCategoryUpdate",
    SUB_CATEGORY_DELETE: "/Admin/SubCategoryDelete",
    BUYERS: "/Admin/Buyers",
    GET_KOSMETOLOG_INFORMATION: "/Admin/GetKosmetologInformation",
    ADD_BONUS_POINTS: "/Admin/AddBonusPoints",
    MINUS_BONUS_POINTS: "/Admin/MinusBonusPoints",
    PRODUCT_ROOT_LIST: "/Admin/RootList",
    ROOT_LIST_FOR_CATEGORY: "/Admin/RootListForCategory",
    GET_CATEGORIES: "/Admin/GetCategories",
    GET_DAILY_PAY_DATES: "/Admin/DailyPayDates",
    POST_DAILY_PAY_INFO: "/Admin/DailyPayInfo",
    POST_DAILY_PAY_INFO_SINGLE: "/Admin/DailyPayInfoSingle",
    ALL_FILTERS: "/Admin/Filters",
    FILTER_ATTR_ADD: "/Admin/FilterAttributeAdd",
    FILTER_ATTR_UPDATE: "/Admin/FilterAttributeUpdate",
    FILTER_ATTR_DELETE: "/Admin/FilterAttributeDelete",
    FILTER_ADD: "/Admin/FilterAdd",
    FILTER_UPDATE: "/Admin/FilterUpdate",
    FILTER_DELETE: "/Admin/FilterDelete",
    FILTER_TC_GET: "/Admin/TopCatFilterGet",
    FILTER_TC_UPDATE: "/Admin/TopCatFilterUpdate",
    FILTER_SC_GET: "/Admin/SubCatFilterGet",
    FILTER_SC_UPDATE: "/Admin/SubCatFilterUpdate",
    GET_DEALER_INFORMATION: "/Admin/GetDealerInformation",
    GET_DEALER_MAGAZINES: "/Admin/DealerMagazines",
    GET_BASE_SETTINGS_INFO: "/Admin/BaseSettingsInfo",
    GET_BASE_SETTINGS_UPDATE_BASIC: "/Admin/BaseSettingsUpdateBasic",
    GET_BASE_SETTINGS_UPDATE_BASIC_LPMP: "/Admin/BaseSettingsUpdatePriceLPMP",
    GET_BASE_SETTINGS_UPDATE_CARD_PRICING_TABLE:
      "/Admin/BaseSettingsUpdateCardPricingTable",
    GET_BASE_SETTINGS_UPDATE_PRICE_LTF: "/Admin/BaseSettingsUpdatePriceLTF",
    GET_BASE_SETTINGS_UPDATE_PRICE_LTO: "/Admin/BaseSettingsUpdatePriceLTO",
    GET_BASE_SETTINGS_UPDATE_PRICE_LBF: "/Admin/BaseSettingsUpdatePriceLBF",
    GET_BASE_SETTINGS_UPDATE_PRICE_LBO: "/Admin/BaseSettingsUpdatePriceLBO",
    GET_BASE_SETTINGS_UPDATE_PRICE_GT: "/Admin/BaseSettingsUpdatePriceGT",
    GET_BASE_SETTINGS_UPDATE_PRICE_GB: "/Admin/BaseSettingsUpdatePriceGB",
    BASE_SETTINGS_UPDATE_REKLAMA_GROUP: "/Admin/BaseSettingsUpdateReklamaGroup",

    ROOT_QUESTION_PRODUCT_INFO: "/Admin/GetSingleRootQuestion",
    DELETE_ROOT_QUESTION: "/Admin/DeleteRootQuestion",
    TRAINING: "/Admin/GetAllCalendarEvent",
    UPDATE_CALENDAR_EVENT: "/Admin/UpdateCalendarEvent",
    GET_ENTERTAINMENT_4_MODERATION:
      "/Entertainment/GetEntertainment4Moderation",
    BLOCK_ENTERTAINMENT: "/Entertainment/blockEntertainment",
    REJECT_ENTERTAINMENT: "/Entertainment/rejectEntertainment",
    APPROVE_ENTERTAINMENT: "/Entertainment/approveEntertainment",
    GET_REGIONS_4_All_RESERVATIONS: "/Entertainment/GetRegions4AllReservations",
    GET_ALL_ENTERTAINMENT_4_RESERVATIONS:
      "/Entertainment/GetAllEntertainment4Reservations",
    GET_ALL_RESERVATIONS: "/Entertainment/GetAllReservations",
    ACTIVATE_1S_FOR_DEALER: "/Admin/Activate1SForDealer",
  },

  hubs: {
    CHAT: "/chat",
  },

  Chat: {
    MESSAGES: "/Chat/SendMessage",
    MESSAGE_DELETE: "/Chat/DeleteMessage",
    MESSAGE_UPDATE: "/Chat/UpdateMessage",
    CHAT_DELETE_MESSAGE: "/Chat/ChatDeleteMessage",
    CHAT_UPDATE_MESSAGE: "/Chat/ChatUpdateMessage",
    DELETE_MESSAGE: "/Chat/DeleteMessage",
    UPDATE_MESSAGE: "/Chat/UpdateMessage",
    CHAT_SAVE_MESSAGE: "/Chat/ChatSaveMessage",
    CHAT_GENERAL: "/Chat/chatGeneral",
    CHAT_GET_MESSAGES: "/Chat/chatGetMessages",
    CHAT_REMOVE_UNREAD: "/Chat/ChatRemoveMessageFromUnread",
    UPDATE_COUNTER: "/Chat/UpdateCounter",
  },
}
