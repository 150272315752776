import React from "react";
import {Ru} from "../../../../functions/functions";

interface ISumOfCart{
    Sum1:number
    Sum2:number
}

export default function CartFooter({Sum1,Sum2}:ISumOfCart) {
    return (
        <>
            <div id="sum_cart_containter">
                {Sum1 != 0 && Sum2 < Sum1 &&
                    <div id="sum1-of-cart">
                        <span>{Ru(Sum1)}</span>
                    </div>
                }
                <div id="sum2-of-cart">
                    <span>Сумма:</span>
                    <img id="img_dashed-cart" src="/Pictures/Line 12.png"/>
                    <span>{Ru(Sum2)}</span>
                </div>
                {Sum2<Sum1&&
                    <div id="sum3-of-cart">
                        <span>экономия от </span>
                        <span id="number_of_saving-background">
                        <span id="number_of_saving">
                            {Intl.NumberFormat(
                                "ru",
                                {style: "currency", currency: "RUB"}
                            ).format(Sum1-Sum2)}
                        </span>
                    </span>
                    </div>
                }
            </div>
        </>
    )
}