import React, { useContext, useEffect, useState } from "react";

import CustomTable, {IRowElement} from "../../../../customUI/CustomTable/CustomTable";
import {ITable} from "../../../../customUI/CustomTable/CustomTable";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import Navbar from "../../../Admin/Navbar/Navbar";
import {ToogleVisible} from "../../../../customUI/Searchbar/functions";
import Searchbar from "../../../../customUI/Searchbar/Searchbar";
import {Link, useParams} from "react-router-dom";
import {ToogleFilter} from "../../../../customUI/FilterBox/functions";
import Filterbox, {IFilterbutton} from "../../../../customUI/FilterBox/Filterbox";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CartEmpty from "../../../../customUI/NoProductsPage/NoProductsPage";
import Boxitem from "../../../../customUI/multipleCheckboxes/boxitem";
import SupplierCard from "../../../Customer/Cart/SupplierCard/SupplierCard";
import CartFooter from "../../../Customer/Cart/CartFooter";
import BottomCartButton from "../../../../customUI/BottomCartButton/BottomCartButton";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {createFilterGroupsForMagazines, DifferenceDates, removeGorod} from "../../../../../functions/functions";
import '../invoiceTables.css'
import SelectFilterbox, {IFilterBoxGroups} from "../../../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../../customUI/Loader/Loader";

const placeholder = 'Найти ...'

function Invoice_canceled() {
    const[loading,setLoading] = useState(false)
    const [invoiceTable, setInvoiceTable] = useState<ITable>({
        tableId:"InvoiceTable",
        header:[],
        rows:[]
    });

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    const[filterGroups,setFiltergroups] = useState<IFilterBoxGroups[]>([])
    const [shopFilter,setShopFilter] = useState<string[]>([])
    useEffect(()=>{
        CabinetSellerService.getMagazines()
            .then(response => {
                setFiltergroups(createFilterGroupsForMagazines(response.data.magazines))

            })
            .then(()=>
                CabinetSellerService.getInvoices(10,""))
            .then(response => {
                let list: IRowElement[] = [];
                for (const invoice of response.data.data) {
                    let date = invoice.statusDates[invoice.invoiceStati.lastIndexOf(9)] +" / "+ invoice.statusTimes[invoice.invoiceStati.lastIndexOf(9)];
                    let date1 = invoice.statusDates[invoice.invoiceStati.lastIndexOf(9)] +" "+ invoice.statusTimes[invoice.invoiceStati.lastIndexOf(9)];
                    let date2 = invoice.statusDates[invoice.invoiceStati.lastIndexOf(10)] +" "+ invoice.statusTimes[invoice.invoiceStati.lastIndexOf(10)];
                   let shop = invoice.magazine.city?invoice.magazine.city:"-";
                    shop += ", ";
                    shop += invoice.magazine.streetAndNumber?invoice.magazine.streetAndNumber:"-";
                    list.push(
                        {
                            content: {
                                InvoiceId: <div className={"invoiceTableDetailcontainer"} data-search-word={invoice.invoiceId} style={{display:"flex"}}>
                                    № {invoice.invoiceId}
                                    <div className={"invoiceTableDetailBox"}>
                                        <Link to={SpaRoutes.SUP_ORDER_PAGE + "/:"+invoice.invoiceId} state={{ price:invoice.priceSum, chosenOption:invoice.paymentOption}}>
                                                <div className={"invoiceTableDetailImage"}><img src={"/Pictures/invoicetablelink.svg"}/></div>
                                        </Link>
                                    </div>
                                </div>,
                                Magazine: <div className={"invoiceTableShop"}>{removeGorod(shop)}</div>,
                                Customer: <div data-search-word={invoice.customerName}><Link to={SpaRoutes.CUSTOMER_INVOICES + `/${invoice.customerId}`}> {invoice.customerName} </Link></div>,
                                StatusTime: <div style={{display:"flex",flexDirection:"column",marginTop:"-8px"}}>
                                    <div>
                                        {invoice.deliveryDate}
                                    </div>
                                    <div style={{fontSize:"12px"}}>
                                        с {invoice.deliveryTimeFrom} до {invoice.deliveryTimeTo}
                                    </div>
                                </div>,
                                Sum: invoice.priceSum,
                                PayType:<div>
                                    <Link to={SpaRoutes.INVOICE_PAYMENT +`/${invoice.invoiceId}`} state={{ price:invoice.priceSum, chosenOption:invoice.paymentOption}}>
                                        <div className={"invoiceTablePaymentImage"}>
                                            <img src={(invoice.paymentOption===0)?("/Pictures/invoicetablelink.svg"):("/Pictures/invoicetablelink-green.svg")}/>
                                        </div>
                                    </Link>
                                </div>,
                                Adress: invoice.adress?invoice.adress:"",
                                Comment:
                                    <div data-search-word={invoice.comment} className={"TableLongInfo"}>
                                        <div className={"TableLongText"}>{invoice.comment ? invoice.comment : "-"}</div>
                                        <div className={"TableLongPopUp"}>{invoice.comment ? invoice.comment : "-"}</div>
                                    </div>
                            },
                            filter:[{Shop:invoice.magazine.dropAdressId},{City:removeGorod(invoice.magazine.city)}],
                            visible:[true,true,true]
                        }
                    )
                }
                let mytable:ITable = {
                    tableId:"InvoiceTable",
                    header:["№ заказа","Адрес склада","Заказчик","Отменено","Сумма (₽)","Оплата","Адрес","Комментарий"],
                    //rows:[]
                    rows:list
                }
                setInvoiceTable(mytable);
            })
            .then(()=>{setLoading(true)})
    }, [])


    const changeHandler = (text:string) => {
        setInvoiceTable((prevState)=>{
                const newState = {...prevState}
                newState.rows = ToogleVisible(text,["InvoiceId","Adress","Customer","Comment"],["","","",""],newState.rows)
                return newState;
            }
        )
    }
    const filterHandler = (filterList:string[]) =>{
        setInvoiceTable((prevState)=>{
            const newState ={...prevState}
            newState.rows = ToogleFilter(filterList,1,"Shop",newState.rows)
            return newState;
        })
    }
    const updateCityFilter=(filter:string)=>{
        if(filter===""){
            setInvoiceTable((prevState)=>{
                const newState ={...prevState}
                newState.rows = ToogleFilter([],2,"City",newState.rows)
                return newState;
            })
        }
        else{
            setInvoiceTable((prevState)=>{
                const newState ={...prevState}
                newState.rows = ToogleFilter([filter],2,"City",newState.rows)
                return newState;
            })
        }
    }
    const updateShopFilter=(filter:string)=>{
        if (filter === "-1"){
            setShopFilter([]);
            filterHandler([]);
        }
        else{
            let newFilter = shopFilter;
            if (shopFilter.indexOf(filter) === -1){
                newFilter.push(filter);
                setShopFilter(newFilter);
                filterHandler(newFilter);
            }
            else{
                newFilter = shopFilter.filter((item)=>{return item !== filter});
                setShopFilter(newFilter);
                filterHandler(newFilter);
            }
        }


    }
    let AllBtn:IFilterbutton = {
        button:<><div className={"FilterText"}>{"All"}</div></>,
        value: "-1"}

    return(
        <>
            {!loading?<Loader load={true} />:
                <div id={"InvoiceTables"}>
                    <CustomNavigateHeader title={"Выполнены"} trashClickHandler={()=>{}} withTrash={false}/>
                    <div id={""}>
                        <div className={"SearchbarTitle"}>Поиск</div>
                        <Searchbar
                            changeHandler={changeHandler}
                            initialText={placeholder}
                        />
                        <SelectFilterbox
                            title = "Склады"
                            filterGroups={filterGroups}
                            updateMainFilter={updateCityFilter}
                            updateFilter={updateShopFilter}
                            filterList = {shopFilter}
                        />
                        <CustomTable {...invoiceTable}/>
                    </div>
                </div>
            }
        </>


    )
}
export default Invoice_canceled;

