import {useContext, useEffect} from "react";
import {Context} from "../../../index";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";

export const Logout = () => {
    const { store } = useContext(Context)
    const [cookies, setCookie, removeCookie] = useCookies(['RefreshToken', 'Email','RegionName', 'RegionId']);
    const navigate = useNavigate()

    const removeMyCookie = async () =>{
        removeCookie("RefreshToken");
        removeCookie("Email");
    }

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(()=>{
        if(store.isAuth)
            removeMyCookie()
                .then(()=>{
                    store.logout()
            })
                .then(()=>{
                    navigate(-1)
                })
        else
            navigate('/')
    },[])
  return(<></>)
}