import React, {useContext, useEffect} from 'react';
import Header from "./Header/Header";
import ContextProviderContainer from "../../../ContextProviderContainer";
import {Context} from "../../../index";
import "./SellerLayout.css"
import {observer} from "mobx-react-lite";


function SellerLayout(){
    const {store} = useContext(Context)

    return(
        <div className={"seller-layout"}>
            <div className={"seller-header"}>
                <Header unread={store.unread}/>
            </div>
            <div className={"seller-content"}>
                <Context.Provider value={{store}}>
                    <ContextProviderContainer/>
                </Context.Provider>
            </div>
        </div>
    )
}export default observer (SellerLayout)

