import React, {useContext, useState} from 'react';
import CustomDropdown from "../../../customUI/CustomDropdown/CustomDropdown";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {IDropdownOption, IFilter, ISubCategory} from "../../../../models/models";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link} from "react-router-dom";

interface IItemMultiFilter {
    itemFilter: IFilter[]
}

const ItemMultiFilter = ({

                             itemFilter
                         }: IItemMultiFilter) => {
    const {store} = useContext(Context)

    let filterBox:any[] = []
    let sortFilter = itemFilter.sort((a:any,b:any)=>{
        if (a.attrSorter > b.attrSorter) return 1
        if(a.attrSorter < b.attrSorter) return -1
        return 0})
    sortFilter.forEach((filter)=>{
        if (filterBox.every((fb)=>{return fb.id !== filter.filterId})){
            filterBox.push({
                id:filter.filterId,
                name:filter.filterName,
                sorter:filter.filterSorter,
                filterAttr:[]
            })
        }
    })
    let sortedFilterBox = filterBox.sort((a:any,b:any)=>{
        if (a.sorter > b.sorter) return 1
        if(a.sorter < b.sorter) return -1
        return 0})
    sortFilter.forEach((filter)=>{
        sortedFilterBox[sortedFilterBox.findIndex((item)=>{return item.id===filter.filterId})].filterAttr
            .push({
                name:filter.attrName
            })

    })
    return (
        <>
            <div style={{display:"flex",flexDirection:"column",marginBottom:"10px"}}>
                {sortedFilterBox.map((item)=>{
                    let attr = item.filterAttr.map((item:any)=>{
                        return <div style={{fontSize:"14px",marginLeft:"10px"}}>{item.name}</div>
                    })
                    return <div className={"admin-multi-Item"}>
                        <div style={{marginRight:"10px"}}>{item.name} :</div>
                        {attr}
                    </div>
                })}
            </div>
        </>
    );
};

export default ItemMultiFilter;