import React from "react";
import "./AdminPopupApply.css";

interface ModalProps{
    title: string;
    textOnButton:string;
    handler:any;
    imageUrl:string;
    onClose: () => void;
}

export function AdminPopupApply(props:ModalProps) {

    const OnClickHandler = () => {
        props.handler();
        props.onClose();
    }
    return (
        <>
            <div className={"supplier-popup-close-wrapper"} onClick={props.onClose}/>
            <div className={"supplier-popup-wrapper"}>
                <div className={"supplier-popup-container"}>
                    <span className={"supplier-popup-title"}>{props.title}</span>
                    <img className={"supplier-popup-image"} src={props.imageUrl} />
                    <span className={"supplier-popup-button"} onClick={OnClickHandler}>
                        {props.textOnButton}</span>
                </div>
            </div>
        </>
    )
}
