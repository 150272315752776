import React, {useState} from "react";
import {boolean} from "yup";

export function ToogleVisible(text:string, attributes:string[],ignore:string[] ,state:any[],id?:number){
    let pattern = text.trim().toLowerCase();
    const newState = [...state];
    newState.forEach((item)=>{
        if (Object.keys(item).indexOf("visible")>-1 && Object.keys(item).indexOf("content")>-1){
            if (pattern === ""){
                item["visible"][id?id:0]=true;}
            else
            {
                item["visible"][id?id:0] = false;
                let attValue = "";
                if(attributes.length === ignore.length){
                    let patternList = pattern.split(" ")
                    let foundAllPatterns = Array(patternList.length).fill(false);
                    for (let k=0;k<patternList.length;k++){
                        for(let i=0;i<attributes.length;i++){
                            if(Object.keys(item["content"]).indexOf(attributes[i])>-1
                                && typeof(item["content"][attributes[i]]) !== undefined
                                && item["content"][attributes[i]] !== undefined)
                            {
                                if(item["content"][attributes[i]]!==undefined && item["content"][attributes[i]]!== null){
                                    attValue = typeof(item["content"][attributes[i]])==='string'?
                                        item["content"][attributes[i]].toLowerCase()
                                        :item["content"][attributes[i]].props["data-search-word"]?
                                            item["content"][attributes[i]].props["data-search-word"].toLowerCase()
                                            :"";
                                    if (ignore[i].length===1)
                                        attValue = attValue.replaceAll(ignore[i],"");
                                    if (ignore[i].length>1)
                                        attValue = attValue.replaceAll(new RegExp(ignore[i],"mg"),"")
                                    if (
                                        Object.keys(item["content"]).indexOf(attributes[i]) >-1
                                        &&
                                        attValue.indexOf(patternList[k])>-1
                                    ){
                                        item.visible[id?id:0]=true;
                                        foundAllPatterns[k]=true;
                                    }
                                }
                            }
                        }
                    }
                    item.visible[id?id:0]=foundAllPatterns.every(v=>v);
                }
            }
        }

    })
    return newState;
}

export function ToogleVisible2(text:string, attributes:string[],ignore:string[] ,state:any[],id?:number) {
    let pattern = text.trim().toLowerCase();
    const newState = [...state];

    newState.forEach(item => {
        if (item.hasOwnProperty("visible") && item.hasOwnProperty("content")) {
            if (pattern === "") {
                item["visible"][id?id:0] = true;
            } else {
                item["visible"][id?id:0] = false;
                let attValue = "";

                if (attributes.length === ignore.length) {
                    let patternList = pattern.split(" ");
                    let foundAllPatterns = Array(patternList.length).fill(false);

                    for (let k = 0; k < patternList.length; k++) {
                        for (let i = 0; i < attributes.length; i++) {
                            if (item["content"].hasOwnProperty(attributes[i]) && item["content"][attributes[i]] !== undefined) {
                                attValue = typeof item["content"][attributes[i]] === 'string'
                                    ? item["content"][attributes[i]].toLowerCase()
                                    : item["content"][attributes[i]].props && item["content"][attributes[i]].props["data-search-word"]
                                        ? item["content"][attributes[i]].props["data-search-word"].toLowerCase()
                                        : "";

                                if (ignore[i].length === 1) {
                                    attValue = attValue.replaceAll(ignore[i], "");
                                } else if (ignore[i].length > 1) {
                                    attValue = attValue.replaceAll(new RegExp(ignore[i], "mg"), "");
                                }

                                if (attValue.indexOf(patternList[k]) > -1) {
                                    foundAllPatterns[k] = true;
                                }
                            }
                        }
                    }

                    item["visible"][id?id:0] = foundAllPatterns.every(Boolean);
                }
            }
        }
    });

    return newState;
}
