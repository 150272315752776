import './NoProductsPage.css'
import {Link, useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import React, {useContext, useEffect} from "react";
import {Context} from "../../../index";

interface Interface {
    tittle?:string;
    text?:string;
    link1?:string;
    link1text?:string;
    link2?:string;
    link2text?:string
    searchWord?: string
}

function NoProductsPage({tittle,text,link1,link2,link1text, link2text, searchWord}:Interface) {
    const {store} = useContext(Context);
    const navigate = useNavigate()
    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    return(
        <div id={"emptycart-container"}>
            {(tittle||text)&&<div id={"emptycart-text-div"}>
                {tittle&&<p id={"empty-h"}>{tittle}</p>}
                {text&&<p id={"empty-title"}>{text}</p>}
            </div>}
            {searchWord&&
                <div className={"emptycart-container-clearFilter"} onClick={() => {
                    store.FiltersSave("-1", "", "-1", "", [])
                    navigate(SpaRoutes.CATALOG_INDEX + `/:${searchWord}`)
                }}>
                    <button>
                        <img src={"/Pictures/broomstickBlack.svg"}/>
                        Очистить фильтр
                    </button>
                </div>
            }
            <div id={"background-div"}>
                <span id={"korzina"}/>
                {(link1&&link1text)&&<Link className={"cartempty-button"} id={"find-button"} to={link1}>{link1text}</Link>}
                {(link2&&link2text)&&<Link className={"cartempty-button"} id={"katalog-button"} to={link2}>{link2text}</Link>}
            </div>
        </div>
    )
}export default NoProductsPage;