import React, {useContext} from 'react';
import "./PreviewReklama.css"
import {Context} from "../../../index";

interface IPreviewReklama {
    reklamaImg: string,
    typePreview?: number,
    typeDevice: "desktop" | "mobile"
    description?: string,
    linkName?: string,
    link?: string,
    onClose: () => void,
    isUser?: boolean
}

const PreviewReklama = ({
                            reklamaImg,
                            typePreview,
                            onClose,
                            description,
                            linkName,
                            link,
                            typeDevice,
                            isUser = false
                        }: IPreviewReklama) => {
    const {store} = useContext(Context)

    return (
        <div className={"previewReklama"}>
            <div className={"previewReklama-content"} data-device={typeDevice}>
                <div className={"previewReklama-title"}>
                    <img onClick={onClose} src={"/Pictures/strelochka.svg"}/>
                    <p>{isUser? "Реклама" : "Предпросмотр рекламы"}</p>
                </div>

                <img className={"previewReklama-image"} src={reklamaImg} data-preview={typePreview}
                     data-device={typeDevice}/>
                {description &&
                <div className={"previewReklama-description"}>
                    {description}
                </div>
                }
                {linkName&&
                <div className={"previewReklama-link"}>
                    <a href={link}>{linkName}</a>
                </div>
                }
            </div>
        </div>
    );
};

export default PreviewReklama;