import {Swiper, SwiperSlide} from "swiper/react";

// Import CustomSwiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./CustomSwiperWithLazyLoading.css";

// import required modules
import {FreeMode, Pagination} from "swiper";
import ProductCard from "../ProductCard/ProductCard";
import {Ikategori} from "../../../models/models";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import {useContext, useState} from "react";
import {Context} from "../../../index";

export default function CustomSwiperWithLazyLoading({data, title, toSub, id, isAuth, searchWord}: Ikategori) {
	let ListCards: any = [];
	const navigate = useNavigate()
	const [sliderShowUntilIndex, setSliderShowUntilIndex] = useState<number>(10);
	const {store} = useContext(Context);
	const moreSlides = () => {
		setSliderShowUntilIndex((oldState) => {
			return oldState + 10
		});
	}
	if (data)
		if (data.length != 0)
			ListCards = data.map((card, index) => {
				if (index < sliderShowUntilIndex)
					return (
						<>
							<SwiperSlide key={index}>
								<ProductCard
									isAuth={isAuth ? isAuth : false}
									key={card.productId}
									item={card}
								/>
							</SwiperSlide>)
						</>
					)
				return <></>
				
				
			})
	
	const goCategory = () => {
		let filter = store.filterInfo
		filter.topId = `${id}`
		filter.topCatName = title
		store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
		navigate(SpaRoutes.CATEGORY + "/" + id + '/:' + (searchWord ? searchWord : ""))
	}
	
	const goSubCategory = () => {
		let filter = store.filterInfo
		filter.subId = `${id}`
		filter.subCatName = title
		store.FiltersSave(filter.topId, filter.topCatName, filter.subId, filter.subCatName, filter.selectedIds)
		navigate(SpaRoutes.SUB_CATEGORY + '/' + id + '/:' + (searchWord ? searchWord : ""))
	}
	
	return (<>
			{data && data.length != 0 &&
				<>
					<div className={"categories-div"} onClick={() => {
						toSub ?
							goSubCategory()
							: goCategory()
					}}>
						<p className={"tag-name"}>{title}<img/></p>
					</div>
					<Swiper
						breakpoints={{
							// when window width is <1100px (480px)
							0: {
								slidesPerView: 2.3, //подобрано для ширины окна 140px;
							},
							// when window width is 1100px<w<1600px (1366px)
							1100: {
								slidesPerView: 5.637, //Подобрано таким образом, чтобы div одного слайда был 207px
							},
							// when window width is 1600px<w<1920px (1920px)
							1600: {
								slidesPerView: 6,
							},
						}}
						spaceBetween={10}
						freeMode={true}
						pagination={{
							clickable: true,
						}}
						modules={[FreeMode, Pagination]}
						className="mySwiper"
					>
						{ListCards}
						{data.length > sliderShowUntilIndex &&
							<SwiperSlide onClick={() => {
								moreSlides()
							}}>
								<div className={"swiperPlusContainer"}>
									<img src="/Pictures/plus.svg"/>
								</div>
							</SwiperSlide>
						}
					
					</Swiper>
				</>
			}
		</>
	);
}
