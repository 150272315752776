import {Link, useNavigate} from "react-router-dom";
import '../../../../../index.css'
import './Menu.css'
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {useState} from "react";

function Menu() {
    const [searchword, setSearchword] = useState<string>("")
    const navigate = useNavigate();
    const updateSearchword = (e:any)=>{
        const {name,value} = e.target;
        setSearchword(value);
    }
    const handleEnterKeyDown = (event:any) => {
        if (event.key === 'Enter') {
            // 👇 Get input value
            if (searchword.length>0){
                navigate(SpaRoutes.CATALOG_INDEX + "/" + searchword);
            }
        }
    };
    return(
        <></>
        // <div id={"Menu"}>
        //     <Link to={SpaRoutes.CATALOG_INDEX} className={"menu-a"}>
        //         <img id={"menu-img"} src={"/Pictures/Значок.svg"}/>
        //         <p id={"menu-p"}>КАТАЛОГ</p>
        //     </Link>
        //     <div className={"find-div"}>
        //         <img className={"lupa-img"} onClick={()=>navigate(SpaRoutes.CATALOG_INDEX + "/" + searchword)} src={"/Pictures/find.svg"}/>
        //         <input placeholder={"Искать на Медлайн"} onKeyDown={(event)=>{handleEnterKeyDown(event)}} type={"text"} onChange={(event)=>{updateSearchword(event)}} className={"find-input"} />
        //     </div>
        // </div>
    )
}export default Menu;